import React, { useEffect, useState } from "react";
import {
	Modal,
	Backdrop,
	Fade,
	Paper,
	Typography,
	Button,
	IconButton,
	TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import FilterCategory from "./FilterCategory";
import filterIcon from "../../../assets/images/filter.png";
import SavedFilterComponent from "./SavedFilterComponent";
import { updateUserSelectedFilters } from "../../../../api/api";

const useStyles = makeStyles((theme) => ({
	modal: {
		display: "flex",
		alignItems: "flex-start",
		justifyContent: "flex-start",
		overflowY: "auto", // Enable scrolling on the entire modal
	},
	paper: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		height: "100vh",
		width: "37rem",
		padding: theme.spacing(2),
		outline: "none",
		position: "relative",
		overflowY: "scroll",
	},
	backdrop: {
		backdropFilter: "blur(2px)",
	},
	header: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "flex-start",
		flexDirection: "column",
		height: "8.8125rem",
		borderBottom: "1px solid #D2D2D2",
		marginBottom: theme.spacing(2),
	},
	headerText: {
		fontFamily: "SF Pro Display Semibold",
		fontSize: "17px",
		fontWeight: "bold",
		lineHeight: "1.24375rem",
		textAlign: "left",
		padding: theme.spacing(2),
		color: "#353839",
	},
	buttons: {
		// position: "absolute",
		bottom: theme.spacing(2),
		left: theme.spacing(2),
		right: theme.spacing(2),
		padding: "10px",
		display: "flex",
		justifyContent: "space-between",
	},
}));

const initialCategories = [
	{
		name: "Assignee",
		subCategories: [],
	},
	{
		name: "Category",
		subCategories: [],
	},
	{
		name: "Timeframe",
		subCategories: [],
	},
	{
		name: "Hotlist",
		subCategories: [],
	},
];
const categoriesOptions = [
	{
		id: "1",
		label: "Leaning Mailbox",
		name: "Leaning Mailbox",
		backgroundColor: "#824B39",
	},
	{
		id: "2",
		label: "Mailbox Repair",
		name: "Mailbox Repair",
		backgroundColor: "#DB6161",
	},
	{
		id: "3",
		label: "Mailbox Rebuild",
		name: "Mailbox Rebuild",
		backgroundColor: "#A47B30",
	},
	{
		id: "5",
		label: "Retaining Walls",
		name: "Retaining Walls",
		backgroundColor: "#5D7A8A",
	},
	{
		id: "6",
		label: "Tuckpointing",
		name: "Tuckpointing",
		backgroundColor: "#819268",
	},
	{
		id: "7",
		label: "Brick/Stone Repairs",
		name: "Brick/Stone Repairs",
		backgroundColor: "#778DC8",
	},
	{
		id: "8",
		label: "Flowerbed Borders",
		name: "Flowerbed Borders",
		backgroundColor: "#898844",
	},
	{
		id: "9",
		label: "Concrete Repairs",
		name: "Concrete Repairs",
		backgroundColor: "#8bc238",
	},
	{
		id: "10",
		label: "Chimney Repairs",
		name: "Chimney Repairs",
		backgroundColor: "#a634b3",
	},
	{
		id: "11",
		label: "Fireplaces",
		name: "Fireplaces",
		backgroundColor: "#ad2646",
	},
	{
		id: "4",
		label: "Un-Assign",
		name: "Un-Assign",
		backgroundColor: "#707070",
	},

	{
		id: "12",
		label: "Stucco",
		name: "Stucco",
		backgroundColor: "#3291FF",
	},

	{
		id: "13",
		label: "Archways",
		name: "Archways",
		backgroundColor: "#EECC33",
	},
	{
		id: "14",
		label: "Stone Patio",
		name: "Stone Patio",
		backgroundColor: "#29ABCA",
	},
	{
		id: "15",
		label: "Commercial Job",
		name: "Commercial Job",
		backgroundColor: "#234f94",
	},
];
const hotlistArray = [
	{
		id: "1",
		label: "Show only hotlist leads",
		name: "Show only hotlist leads",
		backgroundColor: "#824B39",
	},
];

const FilterModal = ({
	open,
	onClose,
	onApplyFilters,
	users,
	showAlert,
	categories,
	handleAddSubCategory,
	handleAddSavedFilter,
	// filterName,
	handleSubCategoryChange,
	handleResetFilters,
	isFilterAdded,
	// setFilterName,
}) => {
	const classes = useStyles();

	// const [categories, setCategories] = useState(initialCategories);
	// const [isFilterAdded, setIsFilterAdded] = useState(false);
	const [filterName, setFilterName] = useState("");
	const [savedFiltersUpdate, setSavedFiltersUpdate] = useState(false);
	const [openCategoryIndex, setOpenCategoryIndex] = useState(null);
	const [openFileNameInput, setOpenFileNameInput] = useState(false);

	// const handleAddSubCategory = (index, newSubCategories) => {
	// 	const newCategories = categories.map((cat, ind) =>
	// 		ind === index
	// 			? { ...cat, subCategories: [...newSubCategories] }
	// 			: cat
	// 	);

	// 	setCategories(newCategories);
	// 	setIsFilterAdded(true); // Mark that a filter has been added
	// };

	// const handleAddSavedFilter = (savedCategories, filterName) => {
	// 	setFilterName(filterName);
	// 	console.log("savedCategories----", savedCategories);
	// 	setCategories(savedCategories);
	// };
	const saveFilter = async () => {
		console.log("filterName", filterName);
		if (filterName.trim() === "") {
			showAlert("Please enter a filter name.");
			setOpenFileNameInput(true);
			return;
		}
		const filterData = {
			name: filterName,
			categories: categories,
		};
		const existingFilters =
			JSON.parse(localStorage.getItem("savedFilters")) || [];
		existingFilters.push(filterData);

		console.log("existingFilters", existingFilters);
		localStorage.setItem("savedFilters", JSON.stringify(existingFilters));
		setSavedFiltersUpdate(true);

		// saving to database
		const data = {
			email: localStorage.getItem("email"),
			filters: existingFilters,
		};
		try {
			const response = await updateUserSelectedFilters(data);
			if (response.data.success) {
				console.log(
					"Filters saved successfully to DB:",
					response.data.data
				);
				setSavedFiltersUpdate(true);
				showAlert("Filter saved successfully!");
				setOpenFileNameInput(false);
			} else {
				console.error(
					"Error saving filters to DB:",
					response.data.message
				);
				showAlert("Error saving filter to the database.");
			}
		} catch (error) {
			console.error("Error saving filters to DB:", error);
			showAlert("Error saving filter to the database.");
		}
		// showAlert("Filter saved successfully!");
	};
	// const handleSubCategoryChange = (catIndex, subIndex, value) => {
	// 	console.log("handleSubCategoryChange");
	// 	const newCategories = [...categories];
	// 	if (value === "") {
	// 		newCategories[catIndex].subCategories.splice(subIndex, 1);
	// 	} else {
	// 		newCategories[catIndex].subCategories[subIndex] = value;
	// 	}
	// 	setCategories(newCategories);
	// 	setIsFilterAdded(true); // Mark that a filter has been added
	// };
	// const handleResetFilters = () => {
	// 	setCategories([...initialCategories]);
	// 	setIsFilterAdded(false);
	// };

	const getDropDownList = (categoryName) => {
		switch (categoryName) {
			case "Assignee":
				return users;
			case "Category":
				return categoriesOptions;
			case "Hotlist":
				return hotlistArray;
			case "Timeframe":
				return [];
			default:
				return [];
		}
	};
	const toggleDropdown = (index) => {
		setOpenCategoryIndex((prevIndex) =>
			prevIndex === index ? null : index
		);
	};
	return (
		<Modal
			className={classes.modal}
			open={open}
			onClose={onClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
				className: classes.backdrop,
			}}
		>
			<Fade in={open}>
				<Paper className={classes.paper}>
					<div>
						<div className={classes.header}>
							<div
								style={{
									display: "flex",
									width: "100%",
									justifyContent: "flex-end",
								}}
							>
								<IconButton onClick={onClose}>
									<CloseIcon />
								</IconButton>
							</div>
							<div
								style={{
									display: "flex",
									width: "100%",
									justifyContent: "space-between",
									alignItems: "center",
								}}
							>
								<Typography className={classes.headerText}>
									Filters
								</Typography>
								{isFilterAdded && (
									<>
										<Button
											style={{
												color: "#1D56FB",
												textTransform: "none", // to prevent uppercase transformation
												boxShadow: "none",
												backgroundcolor: "transparent",
											}}
											onClick={saveFilter}
											color="secondary"
											variant="text" // set variant to text
										>
											<Typography
												style={{
													borderBottom:
														"1px solid #1D56FB",
												}}
											>
												Save Filter
											</Typography>
										</Button>
									</>
								)}
							</div>
						</div>
						<SavedFilterComponent
							savedFiltersUpdate={savedFiltersUpdate}
							handleSaveFilterName={setFilterName}
							icon={filterIcon}
							onAddSavedFilter={handleAddSavedFilter}
							isFilterAdded={isFilterAdded}
							openFileNameInput={openFileNameInput}
						/>
						<div
							style={{
								backgroundColor: "#D2D2D2",
								height: 1,
							}}
						></div>
						{/* render Filters */}
						{categories?.map((category, index) => {
							return (
								<>
									<FilterCategory
										key={index}
										name={category.name}
										subCategories={category.subCategories}
										onAddSubCategory={(newSubCategories) =>
											handleAddSubCategory(
												index,
												newSubCategories
											)
										}
										onSubCategoryChange={(
											subIndex,
											value
										) =>
											handleSubCategoryChange(
												index,
												subIndex,
												value
											)
										}
										dropDownList={getDropDownList(
											category.name
										)}
										isOpen={openCategoryIndex === index}
										toggleDropdown={() =>
											toggleDropdown(index)
										}
									/>
									<div
										style={{
											backgroundColor: "#D2D2D2",
											height: 1,
										}}
									></div>
								</>
							);
						})}
					</div>
					{/* <div
						style={{ height: "20vh", backgroundColor: "green" }}
					></div> */}
					<div className={classes.buttons}>
						<Button
							style={{
								backgroundColor: "black",
								color: "white",
								borderRadius: 2,
								fontSize: "1.1rem",
							}}
							onClick={onClose}
							color="secondary"
							variant="contained"
						>
							Close
						</Button>
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
							}}
						>
							<Button
								style={{
									color: "black",
									borderRadius: 2,
									textTransform: "none", // to prevent uppercase transformation
									boxShadow: "none",
									backgroundcolor: "transparent",
								}}
								onClick={handleResetFilters}
								color="secondary"
								variant="text" // set variant to text
							>
								<Typography
									style={{
										borderBottom: "1px solid #828282",
										fontSize: "1.1rem",
									}}
								>
									Reset
								</Typography>
							</Button>
							{/* {loader ? (
								<Loader
									type="Oval"
									color="#00BFFF"
									height={25}
									width={25}
								/>
							) : ( */}
							<Button
								style={{
									backgroundColor: "#1D56FB",
									color: "white",
									borderRadius: 2,
									fontSize: "1.1rem",
								}}
								onClick={onApplyFilters}
								color="primary"
								variant="contained"
							>
								Apply Filters
							</Button>
							{/* )} */}
						</div>
					</div>
				</Paper>
			</Fade>
		</Modal>
	);
};

export default FilterModal;
