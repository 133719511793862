import React from "react";
import {
	List,
	ListItem,
	ListItemText,
	Checkbox,
	makeStyles,
} from "@material-ui/core";
const useStyles = makeStyles({
	checkbox: {
		"& svg": {
			fontSize: 18,
		},
		"&.Mui-checked": {
			color: "#1D56FB", // Set custom color for checked state
		},
	},
	listItemText: {
		fontSize: "1.9rem",
	},
	listItem: {
		padding: "1px 5px",
		margin: 0,
	},
});
const DropDown = ({ subCategories, setSubCategories, dropDownList }) => {
	const classes = useStyles();
	const handleToggle = (subCategory) => () => {
		const currentIndex = subCategories.findIndex(
			(item) => item.id === subCategory.id
		);
		const newChecked = [...subCategories];

		if (currentIndex === -1) {
			newChecked.push(subCategory);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		setSubCategories(newChecked);
	};

	return (
		<List style={{ padding: "5px 0px", margin: 0 }}>
			{dropDownList?.map((subCategory) => (
				<ListItem
					style={{
						padding: "1px 5px",
						margin: 0,
					}}
					key={subCategory.id}
					dense
					button
					onClick={handleToggle(subCategory)}
				>
					<Checkbox
						className={classes.checkbox}
						size="medium"
						style={{
							padding: "5px",
							margin: 0,
						}}
						edge="start"
						checked={subCategories.some(
							(item) => item.id === subCategory.id
						)}
						color="secondary"
						tabIndex={-1}
						disableRipple
						inputProps={{ "aria-labelledby": subCategory.label }}
					/>
					<ListItemText
						id={subCategory.label}
						primary={subCategory.label}
						primaryTypographyProps={{
							style: {
								fontSize: "1.3rem",
							},
						}}
					/>
				</ListItem>
			))}
		</List>
	);
};

export default DropDown;
