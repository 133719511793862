import { makeStyles } from "@material-ui/core/styles";

export default makeStyles({
  rootContainer: {
    fontFamily: "Gilroy Regular",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    // paddingLeft: "3.5%",
    // paddingRight: "3.5%",
    marginTop: "2vh",
    height: "63vh",
  },
  numberOfTemplates: {
    fontSize: "1.8rem", //"16px",
    paddingTop: "5px",
    paddingBottom: "5px",
    paddingLeft: "2vw",
  },
  elementsContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    overflow: "hidden",
    borderStyle: "solid",
    borderWidth: "2px",
    borderColor: "#dcdcdc",
  },
  input: {
    fontFamily: "Gilroy Light",
    border: "none",
    outline: "none",
    minWidth: "90%",
    fontSize: "1.5rem", //"14px",
    color: "#717171",
    backgroundColor: "transparent",
  },
  searchImage: {
    opacity: "50%",
    height: "18px",
    width: "18px",
    marginLeft: "5%",
    cursor: "pointer",
  },
  line: {
    width: "100%",
    height: "1px",
    backgroundColor: "#dcdcdc",
  },
  uploadFilesContainer: {
    display: "flex",
    flexDirection: "column",
    width: "50%",
    overflow: "hidden",
    paddingLeft: "4vw",
  },
  searchDiv: {
    display: "flex",
    flexDirection: "row",
    // paddingTop: "2%",
    // paddingBottom: "2%",
    // paddingLeft: "7%",
    // paddingRight: "7%",
    padding: "10px 15px",
    width: "20vw",
    minWidth: "300px",
    maxWidth: "400px",
    backgroundColor: "blue",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "white",
    marginLeft: "15px",
  },
  tiles: {
    // paddingLeft: "5%",
    // paddingRight: "5%",
    // paddingTop: "3%",
    // paddingBottom: "3%",
    overflow: "hidden",
    overflowY: "auto",
  },
  elementsContainer2: {
    display: "flex",
    flexDirection: "column",
    width: "48%",
    overflow: "hidden",
    // borderStyle: "solid",
    // borderWidth: "2px",
    // borderColor: "#dcdcdc",
  },
  dropdownContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
    alignItems: "center",
  },
  dropdown: {
    fontFamily: "Arial, Helvetica, sans-serif",
    paddingLeft: "10px",
    marginLeft: "10px",
    fontSize: "12px",
    width: "108px",
    height: "39px",
    borderRadius: "4px",
  },
  filterOptions: {
    fontFamily: "Arial, Helvetica, sans-serif",
    paddingLeft: "10px",
    marginLeft: "10px",
    fontSize: "12px",
    borderRadius: "4px",
  },
  noDetails: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "50vh",
  },
  saveButtonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  saveButton: {
    backgroundColor: "#4256d0",
    height: "37px",
    fontSize: "1.5rem", //"14px",
    color: "white",
    border: "none",
    borderRadius: "6px",
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingTop: " 1px",
    paddingBottom: " 1px",
  },
  contain: {
    marginTop: "20px",
  },
  searchContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  saveContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "fit-content",
  },
});
