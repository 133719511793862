export const FETCH_LEADS = "FETCH_LEADS";
export const FETCH_LEADS_FAILED = "FETCH_LEADS_FAILED";
export const ADD_EMAIL_TEMPLATES = "ADD_EMAIL_TEMPLATES";
export const ADD_SMS_TEMPLATES = "ADD_SMS_TEMPLATES";
export const USER_DATA = "USER_DATA";
export const FILTER_DATA = "FILTER_DATA";
export const FILTER_TRIGGER = "FILTER_TRIGGER";
export const REMOVE_FILTER_DATA = "REMOVE_FILTER_DATA";
export const ADD_SMS_SELECTED_TEMPLATES = "ADD_SMS_SELECTED_TEMPLATES";
export const ADD_EMAIL_SELECTED_TEMPLATES = "ADD_EMAIL_SELECTED_TEMPLATES";
export const SELECTED_FILTER_OPTION = "SELECTED_FILTER_OPTION";
export const FILTER_LABEL = "FILTER_LABEL";
export const FILTER_SMS_LABEL = "FILTER_SMS_LABEL";
export const FILTERED_EMAIL_TEMPS = "FILTERED_EMAIL_TEMPS";
export const FILTERED_SMS_TEMPS = "FILTERED_SMS_TEMPS";
export const SELECTED_FILTER_SMS_OPTION = "SELECTED_FILTER_SMS_OPTION";
// Rename TEMPLATES to ADD_TEMPLATES

export const onGetLeads = () => {
	onLeadsFetchResponse(true);
};

const onLeadsFetchResponse = (status, data = null, error = null) => {
	return {
		type: error == null ? FETCH_LEADS : FETCH_LEADS_FAILED,
		payload: { status },
	};
};
export const removeFilterData = () => ({
	type: REMOVE_FILTER_DATA,
	payload: [],
});
export const addUserData = (templatesData) => ({
	type: USER_DATA,
	payload: templatesData,
});
export const addFilterData = (templatesData) => ({
	type: FILTER_DATA,
	payload: templatesData,
});

export const addFilterTrigger = (templatesData) => ({
	type: FILTER_TRIGGER,
	payload: templatesData,
});
export const addEmailTemplates = (templatesData) => ({
	type: ADD_EMAIL_TEMPLATES,
	payload: templatesData,
});
export const addSmsTemplates = (templatesData) => ({
	type: ADD_SMS_TEMPLATES,
	payload: templatesData,
});
export const addEmailSelectedTemplates = (templatesData) => ({
	type: ADD_EMAIL_SELECTED_TEMPLATES,
	payload: templatesData,
});
export const addSmsSelectedTemplates = (templatesData) => ({
	type: ADD_SMS_SELECTED_TEMPLATES,
	payload: templatesData,
});
export const addSelectedFilter = (templatesData) => ({
	type: SELECTED_FILTER_OPTION,
	payload: templatesData,
});
export const addSelectedSmsFilter = (templatesData) => ({
	type: SELECTED_FILTER_SMS_OPTION,
	payload: templatesData,
});
export const addFilterLabel = (templatesData) => ({
	type: FILTER_LABEL,
	payload: templatesData,
});
export const addFilterSmsLabel = (templatesData) => ({
	type: FILTER_SMS_LABEL,
	payload: templatesData,
});
export const addFilteredEmailTemps = (templatesData) => ({
	type: FILTERED_EMAIL_TEMPS,
	payload: templatesData,
});
export const addFilteredSmsTemps = (templatesData) => ({
	type: FILTERED_SMS_TEMPS,
	payload: templatesData,
});
