import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Loader from "react-loader-spinner";

import FileUpload from "./FileUpload/FileUpload";
import {
  capitalizeFirstLetter,
  checkFileExtension,
} from "../../Helpers/Constants";
import closeCross from "../../assets/images/close-cross.png";
import * as api from "../../../api/api";
import useStyles from "./styles";
import config from "../../Helpers/config";
import GenericDropdown from "../GenericDropdown/GenericDropDown";
const AWS = require("aws-sdk");

AWS.config.update({
  accessKeyId: config.AWS_ACCESS_KEY_ID,
  secretAccessKey: config.AWS_SECRET_ACCESS_KEY,
  region: config.AWS_region,
});

function TemplateAddUpdate({
  title,
  update = false,
  close,
  sms = false,
  data,
  setUpdate,
  showAlert,
  updateLists,
  setUpdateLists,
  options,
}) {
  const classes = useStyles();
  const [handleShowModal, setHandleShowModal] = useState(true);
  const [selectedOption, setSelectedOption] = useState();
  const [files, setFiles] = useState(null);
  const [updating, setUpdating] = useState(false);
  const [postData, setPostData] = useState({
    label: "",
    value: "",
    attachment: null,
    message_type: `${!sms ? "email" : "sms"}`,
    added_by: localStorage.getItem("username"),
  });
  useEffect(() => {
    if (data) {
      if (data.length !== 0) {
        setPostData({
          label: data[0].label,
          value: data[0].value,
          attachment: data[0].attachment
            ? data[0].attachment.attachment_link
              ? data[0].attachment.file_name
                ? {
                    attachment_link: data[0].attachment.attachment_link,
                    file_name: data[0].attachment.file_name,
                  }
                : null
              : null
            : null,
          temp_id: data[0].temp_id,
          message_type: `${!sms ? "email" : "SMS"}`,
          added_by: localStorage.getItem("username"),
        });
      }
    }
  }, [data]);

  const handleCloseModal = (e) => {
    e.preventDefault();
    setHandleShowModal(false);
    close(false);
    setUpdate(false);
    setPostData({
      label: "",
      value: "",
      attachment: null,
    });
  };

  const handleSelectionChange = (obj) => {
    if (obj) {
      setFiles(null);
      setPostData({
        ...postData,
        attachment: {
          attachment_link: obj.documentLink,
          file_name: obj.documentNameToShow,
        },
      });
      setSelectedOption(obj);
    }
  };

  const uploadToS3 = (documentType, documentSize) => {
    const fileExtension = files.name.split(".").pop().toLowerCase();
    const filename1 = files.name.replace(
      /(\.[\w\d_-]+)$/i,
      `${+Date.now().toString()}.${fileExtension}`
    );
    var photoBucket = new AWS.S3({
      params: {
        Bucket: "lms-email-attachement",
      },
    });
    photoBucket.upload(
      {
        ACL: "public-read",
        Body: files,
        Key: filename1, //files.name,
        ContentType: "application/octet-stream",
      },
      async (err, response) => {
        if (err) {
          console.log("Something went wrong", err);
        } else {
          console.log("AWS Response", response);
          if (update) {
            if (postData.value === "" || postData.label === "") {
              showAlert("Title and Description can not be empty");
              return;
            }
            const { status } = await api.updateEmailTemplate({
              ...postData,
              attachment: {
                attachment_link: response.Location,
                file_name: files.name,
              },
            });
            if (status === 200) {
              showAlert("Template updated successfully");
              setUpdateLists(updateLists ? false : true);
            } else {
              showAlert("Template failed to update");
            }
          } else {
            let { status } = await api.addEmailTemplate({
              ...postData,
              attachment: {
                attachment_link: response.Location,
                file_name: files.name,
              },
            });
            if (status === 200) {
              let { status } = await api.addDocuments({
                documentName: filename1, //files.name,
                documentNameToShow: files.name,
                documentLink: response.Location,
                documentSize,
                documentType,
              });
              showAlert("New template added successfully");
              setUpdateLists(updateLists ? false : true);
            } else {
              showAlert("Template failed to add");
            }
          }
        }
        setUpdating(false);
        setHandleShowModal(false);
        close(false);
        setUpdate(false);
      }
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUpdating(true);
    if (postData.value === "" || postData.label === "") {
      showAlert("Title and Description can not be empty");
      setUpdating(false);
      return;
    }
    if (!sms && files) {
      let { allow, type } = await checkFileExtension(files);
      console.log(allow, type);
      if (allow) {
        const {
          data: { allowed, message },
        } = await api.checkDocumentFileName({ filename: files.name });
        if (allowed) {
          let documentSize = parseFloat(files.size / (1024 * 1024)).toFixed(2);
          uploadToS3(type, documentSize);
        } else {
          showAlert(message);
          // setHandleShowModal(false);
          // close(false);
          setUpdate(false);
          setUpdating(false);
        }
      } else {
        showAlert("File type not allowed");
        // setHandleShowModal(false);
        // close(false);
        setUpdate(false);
        setUpdating(false);
      }
      return;
    }

    if (!files) {
      if (update) {
        let { status } = await api.updateEmailTemplate(postData);
        if (status === 200) {
          showAlert("Template updated successfully");
          setUpdateLists(updateLists ? false : true);
        } else {
          showAlert("Template failed to update");
        }
      } else {
        let { status } = await api.addEmailTemplate(postData);
        if (status === 200) {
          showAlert("New template added successfully");
          setUpdateLists(updateLists ? false : true);
        } else {
          showAlert("Template failed to add");
        }
      }
    }
    setHandleShowModal(false);
    close(false);
    setUpdate(false);
    setUpdating(false);
  };

  return (
    <>
      <Modal show={handleShowModal} className={classes.modal} size='md'>
        <div className={classes.mainContainer}>
          <div className={classes.headingContainer}>
            <h3 className={classes.heading}>
              {update ? "Update " : "New "}
              {capitalizeFirstLetter(title)} Template
            </h3>
            <img
              src={closeCross}
              className={classes.closeIcon}
              onClick={handleCloseModal}
            />
          </div>
        </div>
        <hr />
        <div className={classes.line}></div>
        <div className={classes.subheadingsContainer}>
          <h5 className={classes.subheadings}>Template Title:</h5>
          <input
            className={classes.inputs}
            value={postData.label}
            placeholder='Enter the name of template...'
            onChange={(e) =>
              setPostData({ ...postData, label: e.target.value })
            }
          />
          <h5 className={classes.subheadings}>Template Body:</h5>
          <textarea
            className={classes.inputArea}
            value={postData.value}
            placeholder='Enter description of template...'
            onChange={(e) =>
              setPostData({ ...postData, value: e.target.value })
            }
          />
          {!sms ? (
            <div>
              <div>
                <h5 className={classes.subheadings}>Attachment</h5>
                <div>
                  <GenericDropdown
                    options={options}
                    defaultOption=''
                    onSelect={handleSelectionChange}
                    optionSelected={selectedOption}
                  />
                  {/* <select
                    id='filterDropdown'
                    className={classes.filterDropdown}
                    placeholder='Select media file'
                    // onChange={handleSelectionChange}
                  >
                    <option
                      disabled
                      selected={true}
                      className={classes.filterOptions}
                    >
                      Select Attachment from media & files
                    </option>
                    {options?.map((option) => (
                      <option
                        className={classes.filterOptions}
                        key={option._id}
                        value={option.documentName}
                        complete
                        onClick={() => {
                          handleSelectionChange(option.documentName);
                        }}
                      >
                        <p> {option.documentName}</p>
                      </option>
                    ))}
                  </select> */}
                </div>
              </div>
              <div>
                <h5 className={classes.subheadings}>Or</h5>
                <FileUpload
                  setDocument={setFiles}
                  setSelectedOption={setSelectedOption}
                />
              </div>
            </div>
          ) : null}
          {!sms && (
            <p className={classes.filesUploaded}>
              {files
                ? files.name
                : postData.attachment
                ? postData.attachment.file_name
                  ? postData.attachment.file_name
                  : "No file uploaded yet"
                : "No file uploaded yet"}
            </p>
          )}
        </div>
        <div className={classes.buttonsContainer}>
          <button onClick={handleCloseModal} className={classes.cancelButton}>
            Cancel
          </button>
          {updating ? (
            <Loader
              className={classes.loader}
              type='Oval'
              color='black'
              height={38}
              width={38}
            />
          ) : (
            <button className={classes.saveButton} onClick={handleSubmit}>
              {update ? "Update" : "Save"} Template
            </button>
          )}
        </div>
      </Modal>
    </>
  );
}

export default TemplateAddUpdate;
