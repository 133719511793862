import React, { useEffect, useRef, useState } from "react";

import "./Dropdown.css";

const Dropdown = ({
  options,
  defaultOption,
  onSelect,
  optionSelected,
  sequence = false,
  sequenceType = false,
  active = true,
  roundBorders = true,
}) => {
  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    onSelect(optionSelected);
    setSelectedOption(optionSelected);
    setIsOpen(false);
  }, [optionSelected]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleOptionClick = (option) => {
    onSelect(option);
    setSelectedOption(option);
    setIsOpen(false);
  };

  return (
    <div
      ref={dropdownRef}
      className={`dropdown-mhr ${
        !active ? (sequence ? "disable-sequence" : "greyBackground") : ""
      }`}
    >
      <div
        className={`dropdown-button-mhr ${
          roundBorders && "dropdown-button-mhr-roundBorders"
        }`}
        onClick={() => {
          if (active) setIsOpen(!isOpen);
        }}
      >
        {selectedOption ? selectedOption : defaultOption}
        <img src={require("../../assets/images/down_arrow.png")} />
      </div>
      {isOpen && (
        <div className='dropdown-menu-mhr'>
          {!sequenceType && (
            <div
              key={"-1"}
              className='dropdown-item-mhr'
              onClick={() =>
                handleOptionClick(sequence ? "All" : "All Templates")
              }
            >
              {sequence ? "All" : "All Templates"}
            </div>
          )}
          {options.map((option, index) =>
            option.toLowerCase() !== "un-assign" ? (
              <div
                key={index}
                className='dropdown-item-mhr'
                onClick={() => handleOptionClick(option)}
              >
                {option}
              </div>
            ) : null
          )}
          {!sequenceType && !sequence && (
            <div
              key={"-2"}
              className='dropdown-item-mhr'
              onClick={() => handleOptionClick("Selected Templates")}
            >
              Selected Templates
            </div>
          )}
          {!sequenceType && !sequence && (
            <div
              key={"-3"}
              className='dropdown-item-mhr'
              onClick={() => handleOptionClick("Unselected Templates")}
            >
              Unselected Templates
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
