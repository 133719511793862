import React, { useRef, useState, useEffect } from "react";
import ".././Home/Home.css";
// import { useSelector, useDispatch } from 'react-redux';
import Loader from "react-loader-spinner";
import { Modal } from "react-bootstrap";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import axios from "axios";
import {
	baseURL,
	capitalizeFirstLetter,
	replaceText,
	addUserNameEmailConcate,
	addUserToTempObjConcate,
	checkFileExtension2,
} from "../Helpers/Constants";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { IconButton, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import FileUploader from "./FileUploader";
import { addDocuments, getSelectedTemplates } from "../../api/api";
import refferal from "../assets/images/referal_for_message.png";
import refreview from "../assets/images/review-for-text-message.png";
import sentCheckIcon from "../assets/images/sequence-tickcircle.png";
import orangeIcon from "../assets/images/orangeicon.png";

const useStyles = makeStyles((theme) => ({
	formControl: {
		minWidth: 120,
		width: "100%",
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
}));

function FollowUpModalNew(props) {
	const [loader, setLoader] = useState(false);
	const [userLoader, setUserLoader] = useState(false);
	const [showModal, setShowModal] = useState(true);
	const [showAddModal, setShowAddModal] = useState(false);
	const [customTemplate, setCustomTemplate] = useState("");
	const [messageList, setMessageList] = useState([]);
	const [selectedDelVal, setSelectedDelVal] = useState("");
	const [isDeleteModal, setIsDeleteModal] = useState(false);
	const [reviewTab, setReviewTab] = useState(false);
	const [filteredTemplates, setFilteredTemplates] = useState(false);
	const [selectedOption, setSelectedOption] = useState({
		label: "",
		value: "",
		id: "",
	});
	const [selectedDefaultOption, setSelectedDefaultOption] = useState({
		label: "",
		value: "",
		id: "",
	});
	const [uploadingState, setUploadingState] = useState(false);
	const [selectOpen, setSelectOpen] = useState(false);
	const [userName, setUserName] = useState(localStorage.getItem("username"));
	const [userEmail, setUserEmail] = useState(localStorage.getItem("email"));
	const [phone_number, setPhone_number] = useState(
		localStorage.getItem("phone")
	);
	const [defaultEmail, setDefaultEmail] = useState("");
	const [defaultSms, setDefaultSms] = useState("");

	const [binaryFile, setBinaryFile] = useState("");
	const [showDeleteButton, setShowDeleteButton] = useState(true);
	const classes = useStyles();
	const isTempValue = selectedOption.value == "" ? false : true;
	const [attachment, setAttachment] = useState({
		file_name: null,
		attachment_link: null,
	});
	const [documentSize, setDocumentSize] = useState(null);
	const [testingStatus, setTestingStatus] = useState(false);
	const [test_receiver, setTest_receiver] = useState(null);
	const [referral, setReferral] = useState(false);

	const isCustomEntered =
		selectedOption.label == "" || customTemplate == "" || uploadingState
			? true
			: selectedOption.label == "" || customTemplate == ""
			? true
			: false;

	// --------------------functions-----------------------
	let default_email_templates = "";
	let default_sms_templates = "";
	let selected_email_templates = "";
	let selected_sms_templates = "";

	const getDefaults = async () => {
		const { data } = await getSelectedTemplates();
		// console.log(data);
		default_email_templates = data.defaultEmailTemplates;
		default_sms_templates = data.defaultSMSTemplates;
		if (default_email_templates) {
			setDefaultEmail(default_email_templates);
		} else {
			if (
				data.selectedEmailTemplates &&
				data.selectedEmailTemplates.length !== 0
			) {
				selected_email_templates = data.selectedEmailTemplates[0];
				setDefaultEmail(selected_email_templates);

				// setSelectedOption({
				//   label: data.selectedEmailTemplates[0].label,
				//   value: data.selectedEmailTemplates[0].value,
				//   id: data.selectedEmailTemplates[0].temp_id,
				// });
				// if (
				//   data.selectedEmailTemplates[0].attachment &&
				//   data.selectedEmailTemplates[0].attachment.file_name
				// ) {
				//   setAttachment({
				//     attachment_link:
				//       data.selectedEmailTemplates[0].attachment.attachment_link,
				//     file_name: data.selectedEmailTemplates[0].attachment.file_name,
				//   });
				// }
			} else {
				setDefaultEmail("You have no selected templates");
			}
		}
		if (default_sms_templates) {
			setDefaultSms(default_sms_templates);
		} else {
			if (
				data.selectedSmsTemplates &&
				data.selectedSmsTemplates.length !== 0
			) {
				selected_sms_templates = data.selectedSmsTemplates[0];
				setDefaultSms(selected_sms_templates);
			} else {
				setDefaultSms("You have no selected templates");
			}
		}
	};

	// useEffect(() => {
	//   console.log(defaultEmail, defaultSms);
	// }, [defaultEmail, defaultSms]);

	const filter = async (id, data) => {
		// console.log(id, data);
		// let y = await data.find(
		//   (item) => item.temp_id.toString() === id.toString()
		// );
		// setSelectedOption({
		//   label: y.label,
		//   value: y.value,
		//   id: y.temp_id,
		// });
		// if (props.title !== "SMS" && y.attachment && y.attachment.file_name) {
		//   setAttachment({
		//     attachment_link: y.attachment.attachment_link,
		//     file_name: y.attachment.file_name,
		//   });
		// }
	};

	useEffect(() => {
		// if (props.title == "SMS") setTest_receiver(phone_number);
		// else setTest_receiver(userEmail);
		setUserLoader(true);
		getDefaults()
			.then(() =>
				axios
					.get(baseURL() + "/api/v1/users", {
						headers: {
							"x-access-token": localStorage.getItem("jwtToken"),
							"app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
						},
					})
					.then(async (res) => {
						let allTemplate = [];
						let filteredUsers = [];
						let selectionArray = [];
						// filteredUsers = res.data.data.filter((user) => {
						//   return !user.isDeveloper ||
						//     user.isDeveloper.toString() == "false"
						// })
						filteredUsers = res.data.data;
						if (props.title == "SMS") {
							selectionArray = filteredUsers.filter((user) => {
								return user.user == userEmail;
							})[0].sms_selected_templates;
						} else {
							selectionArray = filteredUsers.filter((user) => {
								return user.user == userEmail;
							})[0].email_selected_templates;
						}

						allTemplate = filteredUsers.reduce(
							(accumulator, user) => {
								return accumulator.concat(
									addUserNameEmailConcate(user, props.title)
								);
							},
							[]
						);
						let selectedTemplates = allTemplate.reverse();
						const filteredTemp = await selectedTemplates.filter(
							(item) => selectionArray.includes(item.temp_id)
						);

						setFilteredTemplates(filteredTemp);

						// console.log(allTemplate);

						if (
							(props.title === "SMS" && !default_sms_templates) ||
							(props.title === "SMS" &&
								default_sms_templates &&
								default_sms_templates === "")
						) {
							await filter(
								res.data.data[0].sms_selected_templates[0],
								allTemplate
							);
						} else if (
							(props.title !== "SMS" &&
								default_email_templates &&
								default_email_templates === "") ||
							(props.title !== "SMS" && !default_email_templates)
						) {
							console.log(
								"we are here",
								res.data.data[0].email_selected_templates[0]
							);
							await filter(
								res.data.data[0].email_selected_templates[0],
								allTemplate
							);
						} else {
							let x = filteredTemp.find(
								(item) =>
									item.temp_id.toString() ===
									(props.title === "SMS"
										? default_sms_templates.toString()
										: default_email_templates.toString())
							);
							if (x) {
								setSelectedOption({
									label: x.label,
									value: x.value,
									id: x.temp_id,
								});
								setSelectedDefaultOption({
									label: x.label,
									value: x.value,
									id: x.temp_id,
								});
							}
							if (props.title !== "SMS") {
								if (
									x.attachment &&
									x.attachment.file_name &&
									x.attachment.attachment_link
								)
									setAttachment({
										file_name: x.attachment.file_name,
										attachment_link:
											x.attachment.attachment_link,
									});
							}
						}
						setMessageList(filteredTemp);
						setUserLoader(false);
					})
					.catch((error) => {
						setUserLoader(false);
					})
			)
			.catch((error) => console.log(error));
	}, []);

	const handleCustomTemplate = (event) => {
		setCustomTemplate(event.target.value);
	};
	const handleSetTempName = (event) => {
		setSelectedOption({
			label: event.target.value,
		});
	};

	const handleCustomChange = (event) => {
		setSelectedOption({
			value: event.target.value,
		});
	};
	const handleDeleteTemplate = (value) => {
		setIsDeleteModal(true);
		setShowAddModal(true);
		setSelectedDelVal(value);
	};

	const handleSend = () => {
		props.handleCloseFollowUpMode();
		setShowModal(false);
		console.log("selectedOption.value", selectedOption.value);
		props.autoSave(selectedOption.value, attachment, null);
		props.handleYes(null, props.title);
	};
	const handleChange = (event) => {
		setShowDeleteButton(false);
		const selectedId = messageList.find(
			(option) => option.temp_id === event
		);

		setSelectedOption({
			label: selectedId.label,
			value: selectedId.value,
			id: selectedId,
		});
		// if (selectedAttachment && selectedAttachment.file_name) {
		if (selectedId.attachment && selectedId.attachment.file_name) {
			setAttachment({
				file_name: selectedId.attachment.file_name,
				attachment_link: selectedId.attachment.attachment_link,
			});
		} else {
			handleResetAttachment();
		}
	};
	const handleMenuOpen = () => {
		setSelectOpen(true);
		setShowDeleteButton(true);
	};
	const handleMenuClose = () => {
		setShowDeleteButton(false);
		setSelectOpen(false);
	};

	const handleSetAttachment = (file_name, attachment_link, documentSize) => {
		setAttachment({
			file_name: file_name,
			attachment_link: attachment_link,
		});
		setDocumentSize(documentSize);
	};
	const handleResetAttachment = () => {
		setAttachment({
			file_name: null,
			attachment_link: null,
		});
	};
	const upLoadingStatus = (val) => {
		setUploadingState(val);
	};

	// -------------------View------------------
	return (
		<div style={{ fontFamily: "Arial, Helvetica, sans-serif" }}>
			<Modal
				show={showModal}
				style={{ borderRadius: 10 }}
				size="lg"
				onHide={() => {
					setShowModal(false);
				}}
			>
				<div
					style={{ padding: 40 }}
					// ref={modalRef}
				>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between",
						}}
					>
						<div className="heading-cont">
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "flex-start",
									alignItems: "center",
								}}
							>
								<p
									className={`followup-modal-heading ${
										!reviewTab &&
										`selected-followup-modal-heading`
									} ${
										(props.title &&
											props.title
												.toString()
												.toLowerCase() === "email") ||
										!props.show
											? "normal-followup-modal-heading"
											: ""
									}`}
									onClick={() => setReviewTab(false)}
								>
									Send {capitalizeFirstLetter(props.title)}
								</p>
								{/* {props.title === "SMS" && props.show && (
									<p
										className={`followup-modal-heading ${
											reviewTab &&
											`selected-followup-modal-heading`
										}`}
										onClick={() => {
											if (
												props.lead.auto_review_count >
													0 &&
												props.lead.auto_referral_count >
													0
											)
												props.showAlert(
													"Review and referral already sent."
												);
											else setReviewTab(true);
										}}
									>
										{props.lead.auto_referral_count > 0 && (
											<img
												className="followup-modal-heading-img1"
												src={orangeIcon}
												alt=""
											/>
										)}
										{props.lead.auto_review_count > 0 && (
											<img
												className="followup-modal-heading-img2"
												src={sentCheckIcon}
												alt=""
											/>
										)}
										Send Review/Referral
									</p>
								)} */}
							</div>
							{/* {!reviewTab ? (
								<span
									className="followup-note-lead-name-container"
									style={{
										marginTop:
											(props.title &&
												props.title
													.toString()
													.toLowerCase() ===
													"email") ||
											!props.show
												? "0px"
												: "15px",
									}}
								>
									You are about to send{" "}
									{props.title == "SMS" ? "a" : "an"}{" "}
									{capitalizeFirstLetter(props.title)} to{" "}
									<p
										style={{
											fontSize: 14,
											fontWeight: "600",
											paddingLeft: 5,
										}}
									>
										{props.leadName}
									</p>
									.
								</span>
							) : (
								<span
									className="followup-note-lead-name-container"
									style={{ display: "block" }}
								>
									<b style={{ fontSize: 14 }}>
										Do you want to send Review/Referral?
									</b>
									<br /> Please select your option.
								</span>
							)} */}
						</div>
					</div>

					{!userLoader ? (
						!reviewTab ? (
							<div className="followup-modal-body">
								<p className="select-text-title">
									Select {capitalizeFirstLetter(props.title)}{" "}
									template
								</p>
								{/* <p className="followup-sub-heading">Template</p> */}
								<div className="flex-row-center">
									<FormControl
										style={{ marginRight: loader ? 10 : 0 }}
										disabled={loader}
										variant="outlined"
										className={classes.formControl}
									>
										<Select
											style={{ fontSize: "1.25em" }}
											id="demo-simple-select"
											open={selectOpen}
											displayEmpty={true}
											defaultValue={
												messageList
													? messageList[0]?.label
													: ""
											}
											// labelId="demo-simple-select-filled-label"
											// id="demo-simple-select-"
											value={selectedOption.value}
											onClick={() => {
												setShowDeleteButton(true);
											}}
											// onChange={handleChange}
											onOpen={handleMenuOpen}
											onClose={handleMenuClose}
											MenuProps={{
												onClose: handleMenuClose,
												onExited: handleMenuClose,
											}}
										>
											<MenuItem
												disabled
												value=""
												style={{ fontSize: 13 }}
											>
												<em>
													Select{" "}
													{props.title == "SMS"
														? "a"
														: "an"}{" "}
													{capitalizeFirstLetter(
														props.title
													)}{" "}
													template
												</em>
											</MenuItem>
											{messageList?.map((option) => {
												return (
													<MenuItem
														style={{
															display: "flex",
															justifyContent:
																"space-between",
															width: "100%",
															fontSize: "1.25em",
														}}
														title={option.label}
														value={option.value}
														onClick={() =>
															handleChange(
																option.temp_id
															)
														}
													>
														{option.label}
														{showDeleteButton &&
														option.temp_id !==
															selectedDefaultOption.id &&
														option.email ==
															userEmail &&
														messageList?.length >
															1 ? (
															// option.label !== selectedDefaultOption.label &&
															<IconButton
																edge="end"
																style={{
																	display:
																		"flex",
																	alignSelf:
																		"flex-end",
																	zIndex: 9999,
																	borderRadius: 0,
																}}
																aria-label="delete"
																onClick={() =>
																	handleDeleteTemplate(
																		option.temp_id
																	)
																}
																size="small"
															>
																<p className="temp-del-text">
																	Delete
																</p>
															</IconButton>
														) : (
															<></>
														)}
													</MenuItem>
												);
											})}
										</Select>
									</FormControl>
									{loader ? (
										<Loader
											type="Oval"
											color="gray"
											height={18}
											width={18}
										/>
									) : null}
								</div>
								<div
									className="text-area-container"
									style={{ marginTop: 30 }}
								>
									<p className="followup-sub-heading">
										{props.title == "email"
											? "Email Text"
											: "SMS Text"}
									</p>
									<TextareaAutosize
										onChange={handleCustomChange}
										value={selectedOption.value}
										aria-label="minimum height"
										className="template-textarea"
										minRows={3}
										placeholder=""
									/>
								</div>
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										justifyContent: "space-between",
										alignItems: "center",
									}}
								>
									{attachment.file_name && (
										<a href={attachment.attachment_link}>
											File name: {attachment.file_name}
										</a>
									)}
									{attachment.file_name &&
									attachment.file_name != "" ? (
										<button
											onClick={() =>
												setAttachment({
													file_name: null,
													attachment_link: null,
												})
											}
											style={{
												backgroundColor: "lightgray",
												borderRadius: 20,
												height: 20,
												width: 20,
												border: "none",
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
											}}
										>
											<p
												style={{
													color: "black",
													fontStyle: "bold",
													fontSize: 15,
												}}
											>
												x
											</p>
										</button>
									) : null}
								</div>
							</div>
						) : (
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "center",
									alignItems: "center",
									height: "15vh",
								}}
							>
								<div
									className={`followup-modal-body-review-referral ${
										!referral &&
										"followup-modal-body-review-referral-selected"
									}`}
									onClick={() => setReferral(false)}
								>
									{/* {props.lead &&
										props.lead.auto_review_count > 0 && (
											<img
												className="followup-modal-body-review-referral-check"
												src={sentCheckIcon}
											/>
										)} */}
									<img src={refreview} alt="" />
									<p>Review</p>
								</div>
								<div
									className={`followup-modal-body-review-referral ${
										referral &&
										"followup-modal-body-review-referral-selected"
									}`}
									onClick={() => setReferral(true)}
								>
									{/* {props.lead &&
										props.lead.auto_referral_count > 0 && (
											<img
												className="followup-modal-body-review-referral-check"
												src={sentCheckIcon}
											/>
										)} */}
									<img src={refferal} alt="" />
									<p>Referral</p>
								</div>
							</div>
						)
					) : (
						<div className="followup-modal-loader-body">
							{" "}
							<Loader
								type="Oval"
								color="gray"
								height={38}
								width={38}
							/>
						</div>
					)}
					<div className="followup-modal-footer">
						<div className="followup-modal-footer-button">
							{!reviewTab && (
								<>
									<button
										disabled={
											testingStatus
												? !test_receiver || !isTempValue
												: !isTempValue
										}
										className="note-edit-modal-save-button"
										onClick={() => {
											handleSend();
										}}
									>
										Send
									</button>
									<button
										className="temp-modal-save-button"
										onClick={() => {
											props.handleCloseFollowUpMode();
											setShowModal(false);
										}}
									>
										Close
									</button>
								</>
							)}
						</div>
					</div>
				</div>
			</Modal>
		</div>
	);
}
export default FollowUpModalNew;
