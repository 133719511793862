import React, { Component } from "react";
import LocationListContainer from "./LocationListContainer";
import SVG from "react-inlinesvg";
import axios from "axios";
import Loader from "react-loader-spinner";

import add from "../assets/images/add.svg";
import { baseURL, LOCATIONS_LIST_API } from "../Helpers/Constants";
import plusIcon from "../assets/images/plus_icon.svg";
// import Preloader from '../common/Preloader';

class SettingsContainer extends Component {
  originalLocations = [];
  state = {
    locations: [],
    isLoading: false,
    error: null,
  };

  addNewLocation = (e) => {
    e.preventDefault();
    let temp = [...this.state.locations];
    temp.push({
      edit: true,
      name: "",
      source: "",
    });
    this.setState({ locations: temp });
  };
  editLocation = (index) => {
    let tempArray = [...this.state.locations];
    let temp = { ...tempArray[index] };
    temp.edit = true;
    tempArray.splice(index, 1, temp);
    this.setState({ locations: tempArray });
  };
  saveLocation = (index, item) => {
    this.setState({ error: null });
    let tempArray = [...this.state.locations];
    let temp = { ...item };
    temp.edit = false;
    tempArray.splice(index, 1, temp);
    this.setState({ locations: tempArray });
  };
  onDelete = (index) => {
    let tempArray = [...this.state.locations];
    tempArray.splice(index, 1);
    this.setState({ locations: tempArray });
  };
  onCancel = (index) => {
    let tempArray = [...this.state.locations];
    let temp = { ...tempArray[index] };
    temp.edit = false;
    tempArray.splice(index, 1, temp);
    this.setState({ locations: tempArray });
  };
  onCancelChanges = (index) => {
    this.props.history.goBack();
    // this.props.history.push(generatePortalUrl('protocols'));
  };

  submitLocations = () => {
    let finalLocations = [...this.state.locations];
    for (const iterator of finalLocations) {
      if (iterator.edit) {
        this.setState({ error: "Please save or cancel un-saved changes" });
        return;
      }
    }
    let seen = new Set();
    var hasDuplicates = finalLocations.some(function (currentObject) {
      return seen.size === seen.add(currentObject.source).size;
    });
    if (hasDuplicates) {
      this.setState({ error: "source names can not be same" });
      return;
    }
    this.setState({ isLoading: true });
    finalLocations.forEach((location) => {
      let index = this.originalLocations.findIndex(
        (e) => e._id == location._id
      );
      if (index >= 0) {
        location["status"] = "u";
      } else {
        location["status"] = "a";
      }
    });
    this.originalLocations.forEach((oLocation) => {
      let index = finalLocations.findIndex((e) => e._id == oLocation._id);
      if (index < 0) {
        let temp = { ...oLocation };
        temp["status"] = "d";
        finalLocations.push(temp);
      }
    });
    this.postLocations(finalLocations);
  };

  postLocations = (finalLocations) => {
    axios
      .post(baseURL() + "/api/v1/leads/sources", finalLocations, {
        headers: {
          "x-access-token": localStorage.getItem("jwtToken"),
          "app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
        },
      })
      .then((res) => {
        this.props.onCancelClick();
        this.props.showAlert("Lead source mapping updated");
      })
      .catch((error) => {});
  };
  componentDidMount() {
    this.fetchLocationsList();
  }

  fetchLocationsList = () => {
    this.setState({ isLoading: true });
    axios
      .get(baseURL() + "/api/v1/leads/sources", {
        headers: {
          "x-access-token": localStorage.getItem("jwtToken"),
          "app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
        },
      })
      .then((res) => {
        this.originalLocations = res.data.sources;
        this.setState({ locations: res.data.sources, isLoading: false });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
      });
  };

  render() {
    return (
      <div>
        <div className="page-heading">
          <div className="row">
            <div className="col-12 location-settings-container">
              <LocationListContainer
                locations={this.state.locations}
                addNewLocation={this.addNewLocation}
                editLocation={this.editLocation}
                saveLocation={this.saveLocation}
                onDelete={this.onDelete}
                onCancel={this.onCancel}
              />

              <a
                onClick={(e) => {
                  this.addNewLocation(e);
                }}
                className="form-add link add-new-source-item"
              >
                <button
                  // onClick={props.onEdit}
                  style={{
                    backgroundImage: `url(${plusIcon})`,
                    width: 16,
                    height: 16,
                    border: "none",
                    backgroundRepeat: "no-repeat",
                    backgroundColor: "transparent",
                    backgroundSize: "contain",
                    cursor: "pointer",
                    // marginLeft: -7,
                    marginTop: 1,
                  }}
                ></button>
                <span style={{ marginLeft: 5, fontSize: 12 }}>
                  <b>Add new source</b>
                </span>
              </a>

              {this.state.error && (
                <div style={{ color: "red" }}> {this.state.error}</div>
              )}

              {(this.originalLocations.length > 0 ||
                this.state.locations.length > 0) && (
                <div className="settings-footer-buttons-container">
                  <button
                    onClick={this.props.onCancelClick}
                    className="btn btn-secondary btn--cancel settings-footer-buttons-container-button"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={this.submitLocations}
                    className="btn btn-primary btn--next settings-footer-buttons-container-button"
                  >
                    Save
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        {this.state.isLoading && (
          <div
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loader type="Oval" color="#00BFFF" height={80} width={80} />
          </div>
        )}
      </div>
    );
  }
}
export default SettingsContainer;
