import React from 'react';
import Select from 'react-select';
class LeadStatusDropdown extends React.PureComponent {

  options = []
  constructor(props) {
    super(props);
    this.state = {
      status: {
          label:this.props.row.value.value,
          value:this.props.row.value.value
      }
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.row.value.value !== this.props.row.value.value)
      this.setState({ status: {
          label:this.props.row.value.value,
          value:this.props.row.value.value
      } })
  }
 
  
  onChangeAssigne = (selectedOption) => {

    this.setState({ status: selectedOption })
    this.props.updateLeadStatus(this.props.row.original._id,selectedOption.value, this.props.row.index)
  }

  render() {

    return <Select
      value={this.state.status}
      options = {[
        {
            label: 'Pending',
            value: 'Pending',
        },
        {
            label: 'In Progress',
            value: 'In Progress',
        },
        {
            label: 'Completed',
            value: 'Completed',
        },
    ]}

      key={this.props.row.index + 'lead_status'}
      onChange={(selectedOption) => {
        this.onChangeAssigne(selectedOption)
      }}
      menuPlacement="auto"

    />
  }
}
export default LeadStatusDropdown;


