export const FETCH_ALL = "FETCH_ALL";
export const LENGTH = "LENGTH";
export const CREATE = "CREATE";
export const SEARCH = "SEARCH";
export const CHECKNAME = "CHECKNAME";
export const UPDATE = "UPDATE";
export const DELETE = "DELETE";

export const START_LOADING = "START_LOADING";
export const END_LOADING = "END_LOADING";
export const DELETED_TRUE = "DELETED_TRUE";
export const DELETED_FALSE = "DELETED_FALSE";
export const UPDATED_TRUE = "UPDATED_TRUE";
export const UPDATED_FALSE = "UPDATED_FALSE";
export const SEARCHING_TRUE = "SEARCHING_TRUE";
export const SEARCHING_FALSE = "SEARCHING_FALSE";
export const SWITCH_TABS = "SWITCH_TABS";
export const TAB_DATA = "TAB_DATA";
