import React, { useState } from "react";
import {
	IconButton,
	Typography,
	Chip,
	Collapse,
	makeStyles,
} from "@material-ui/core";
import {
	startOfWeek,
	endOfWeek,
	startOfMonth,
	endOfMonth,
	startOfQuarter,
	endOfQuarter,
	subWeeks,
	subMonths,
	subQuarters,
	endOfDay,
	startOfDay,
} from "date-fns";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Remove";
import DropDown from "./DropDown";
import DateRangePicker from "./DateRangePicker";
import userIcon from "../../../assets/images/user1.png";
import categoryIcon from "../../../assets/images/briefcase.png";
import timeFrameIcon from "../../../assets/images/clock1.png";
import starIcon from "../../../assets/images/Vector copy.png";

const IconEnum = {
	Assignee: userIcon,
	Category: categoryIcon,
	Timeframe: timeFrameIcon,
	Hotlist: starIcon,
};
const useStyles = makeStyles((theme) => ({
	container: {
		transition: "height 0.3s ease",
		marginBottom: "8px",
		width: "80%",
		overflowY: "scroll",
	},
	open: {
		height: "auto", // Adjust as per your needs when dropdown is open
	},
	closed: {
		height: "30vh", // Adjust as per your needs when dropdown is closed
	},
}));

const FilterCategory = ({
	name,
	subCategories,
	onAddSubCategory,
	onSubCategoryChange,
	dropDownList,
	isOpen,
	toggleDropdown,
}) => {
	const handleDatesChange = ({ startDate, endDate }) => {
		const dateRange = {
			label: `From ${startDate.toLocaleDateString()} to ${endDate.toLocaleDateString()}`,
			startDate: startOfDay(startDate),
			endDate: endOfDay(endDate),
		};
		onAddSubCategory([dateRange]);
	};
	const classes = useStyles();
	return (
		<div
			style={{
				padding: name === "Timeframe" ? "5px 0px" : "0px 0px",
				backgroundColor: isOpen ? "#F8F8F8" : "white",
			}}
		>
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
				}}
			>
				<Typography
					// variant="subtitle2"
					// gutterBottom
					style={{
						display: "flex",
						alignItems: "center",
						gap: "8px",
					}}
				>
					<img
						src={IconEnum[name]}
						alt={name}
						style={{
							width: "16px",
							height: "16px",
							marginRight: "5px",
						}}
					/>
					<span
						style={{
							marginRight: "5px",
							fontSize: "14px",
							fontWeight: 600,
							fontFamily: "SF Pro Display Semibold",
						}}
					>
						{name}
					</span>
					<span
						style={{
							background: "#D9D9D9",
							padding: "2px 10px",
							borderRadius: "10px",
							marginLeft: "5px",
						}}
					>
						x{subCategories.length}
					</span>
				</Typography>
				<IconButton onClick={toggleDropdown}>
					{!isOpen ? <AddIcon /> : <CloseIcon />}
				</IconButton>
			</div>
			<Collapse in={isOpen}>
				{name === "Timeframe" ? (
					<DateRangePicker onDatesChange={handleDatesChange} />
				) : (
					<div
						style={{
							backgroundColor: isOpen ? "#F8F8F8" : "white",
							overflowY: name === "Hotlist" ? "hidden" : "scroll",
							height: name === "Hotlist" ? "5vh" : "30vh",
						}}
					>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								marginBottom: "8px",
								width: "100%",
							}}
						>
							<DropDown
								dropDownList={dropDownList}
								subCategories={subCategories}
								setSubCategories={onAddSubCategory}
							/>
						</div>
					</div>
				)}
			</Collapse>
			<div
				style={{
					display: "none", ///Chips are hide as per guidline by Sir Umer
					flexWrap: "wrap",
					gap: "8px",
				}}
			>
				{subCategories.map((subCategory, index) => (
					<Chip
						key={index}
						label={subCategory.label}
						onDelete={() => onSubCategoryChange(index, "")}
						style={{
							margin: "4px",
							// background: "none",
							borderRadius: "4px",
							border: "1px solid #ccc",
							height: name === "Timeframe" ? "32px" : "28px",
							padding: "0 3px",
							fontSize: "1.1rem",
						}}
						deleteIcon={
							<span
								style={{
									background: "none",
									fontSize: 15,
									marginTop: -4,
									marginLeft: 5,
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								x
							</span>
						}
					/>
				))}
			</div>
		</div>
	);
};

export default FilterCategory;
