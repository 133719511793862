import React from "react";
import { Bar } from "react-chartjs-2";
const legendMargin = {
  id: "legendMargin",
  beforeInit: function (chart) {
    const fitValue = chart.legend.fit;
    chart.legend.fit = function fit() {
      fitValue.bind(chart.legend)();
      return (this.height += 20);
    };
  }
};

let newLegendClickHandler = function () {    
  return false;
};

function BarChart({ chartData }) {
  return (
    <Bar
      data={chartData}
      options={{
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              ticks: { display: true, beginAtZero: true, fontColor: '#B1B1B1' },
              gridLines: {
                display: false,
                drawBorder: true,
              },
              barThickness: 50,
            },
          ],
          yAxes: [
            {
              ticks: { display: true, beginAtZero: true, fontColor: '#B1B1B1' },
              gridLines: {
                display: true,
                drawBorder: true,
              },
            },
          ],
        },
        legend: {
          position: "top",
          align: "start",
          onClick: newLegendClickHandler
        },
      }}
      plugins={[legendMargin]}
      
    />
  );
}

export default BarChart;
