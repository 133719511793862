/* eslint-disable no-redeclare */ /* eslint-disable eqeqeq */

import React, { Component, Suspense } from "react";
import copyIcon from "../assets/images/CopyClipboard.png";
import FittedImage from "react-fitted-image";
import ReactTable from "react-table";
import withDraggableColumns from "react-table-hoc-draggable-columns";
import "react-table-hoc-draggable-columns/dist/styles.css";
import "react-table/react-table.css";
import { confirmAlert } from "react-confirm-alert"; // Import
import moment from "moment";
import Popup from "reactjs-popup";
import axios from "axios";
import Pagination from "./Pagination";
import {
	IsTextHTML,
	baseURL,
	calculateZoneSpecificTime,
	capitalizeFirstLetter,
	hasDatePassed,
	secretUserLeadFilter,
	updateReminderLead,
} from "../Helpers/Constants";
// import ArchiveButton from "../Leads/ArchiveButton";
import AssigneDropdown from "../Leads/AssigneDropdown";
import EditableTextView from "../Leads/EditableTextView";
import deleteIcon from "../assets/images/lead-delete-newer.svg";
// import bidSuccessIcon from "../assets/images/bidaccepted.svg";
// import bidUnSuccessIcon from "../assets/images/bidrejected.svg";
// import bidDraft from "../assets/images/biddraft.svg";
// import bidSent from "../assets/images/bidsent.svg";
// import messageSuccess from "../assets/images/msgtick.svg";
// import messageUnsuccess from "../assets/images/msgcross.svg";
// import Select from "@material-ui/core/Select";
// import InputLabel from "@material-ui/core/InputLabel";
// import MenuItem from "@material-ui/core/MenuItem";
// import FormHelperText from "@material-ui/core/FormHelperText";
// import FormControl from "@material-ui/core/FormControl";
// import { IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import "../Home/Home.css";
import { debounce } from "lodash";
import exportIcon from "../assets/images/export_icon.svg";
// import emailIcon from "../assets/images/mailblack.svg";
import emailIcon from "../assets/images/email-new-button.svg";
import ReviewIcon from "../assets/images/ReviewIcon.png";
// import starIcon from "../assets/images/star-icon.png";
import starIcon from "../assets/images/star-new-unfilled.png";
// import refmessage from "../assets/images/sms-neweer-icon.svg";
// import orangeIcon from "../assets/images/orangeicon.png";
import unstarIcon from "../assets/images/hotlist-star-selected.png";
import smsIcon from "../assets/images/text-message-newer.png";
import sentCheckIcon from "../assets/images/sequence-tickcircle.png";
import autoEmailsentCheckIcon from "../assets/images/orange_tick.png";
import ExportCustomers from "./ExportCustomers";
import SnoozeButton from "../Leads/SnoozeButton";
import LeadStatusDropdown from "../Leads/LeadStatusDropdown";
import Loader from "react-loader-spinner";
import NotesTextView from "../Leads/NotesTextView";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import FollowUpModal from "../ReusabelComponents/FollowUpModal";
import linkifyHtml from "linkify-html"; // import ReactDOMServer from 'react-dom-server';
import { StartSequenceModal } from "../ReusabelComponents/Modals/Hotlist/StartSequence/StartSequencesModal";
import { PauseSequencDetailsModal } from "../ReusabelComponents/Modals/Hotlist/SequenceDetails2/PauseSequencDetailsModal";
import { SequenceConfirmation } from "../ReusabelComponents/Modals/Confirmation/SequenceConfirmation";
import {
	removeSequence,
	sendReferralReview,
	startPauseSequence,
} from "../../api/api";
import { ReminderModal } from "../ReusabelComponents/Modals/Reminder/reminder";
import EditModal from "../ReusabelComponents/EditModal";
import { CategoryModal } from "../ReusabelComponents/Modals/Category/category";
import NewAssigneeDropdown from "../Leads/AssigneeDropdownNew";
// import {
// 	addFilterData,
// 	addFilterTrigger,
// 	removeFilterData,
// } from "../Store/actions";
import { connect } from "react-redux";
import FilterModal from "../ReusabelComponents/Modals/Filters/Filters";
import MultipleEmailSms from "./MultipleEmailSms";
import FollowUpModalNew from "../ReusabelComponents/FollowUpModalNew";
var dateFormat = require("dateformat");
const ReactTableDraggableColumns = withDraggableColumns(ReactTable);
const copy = require("clipboard-copy");
const arrayMove = require("array-move");
const CancelToken = axios.CancelToken;
let source = CancelToken.source();
// const select = "initial value";

const categoriesOptions = [
	{
		id: "1",
		label: "Leaning Mailbox",
		name: "Leaning Mailbox",
		backgroundColor: "#824B39",
	},
	{
		id: "2",
		label: "Mailbox Repair",
		name: "Mailbox Repair",
		backgroundColor: "#DB6161",
	},
	{
		id: "3",
		label: "Mailbox Rebuild",
		name: "Mailbox Rebuild",
		backgroundColor: "#A47B30",
	},
	{
		id: "5",
		label: "Retaining Walls",
		name: "Retaining Walls",
		backgroundColor: "#5D7A8A",
	},
	{
		id: "6",
		label: "Tuckpointing",
		name: "Tuckpointing",
		backgroundColor: "#819268",
	},
	{
		id: "7",
		label: "Brick/Stone Repairs",
		name: "Brick/Stone Repairs",
		backgroundColor: "#778DC8",
	},
	{
		id: "8",
		label: "Flowerbed Borders",
		name: "Flowerbed Borders",
		backgroundColor: "#898844",
	},
	{
		id: "9",
		label: "Concrete Repairs",
		name: "Concrete Repairs",
		backgroundColor: "#8bc238",
	},
	{
		id: "10",
		label: "Chimney Repairs",
		name: "Chimney Repairs",
		backgroundColor: "#a634b3",
	},
	{
		id: "11",
		label: "Fireplaces",
		name: "Fireplaces",
		backgroundColor: "#ad2646",
	},
	{
		id: "4",
		label: "Un-Assign",
		name: "Un-Assign",
		backgroundColor: "#707070",
	},
];

const useStyles = makeStyles((theme) => ({
	formControl: {
		// margin: theme.spacing(1),
		minWidth: 120,
		width: "50%",
		backgroundColor: "red",
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
}));
const PaddingRowComponent = () => {
	return (
		<tr>
			<td colSpan='3' style={{ paddingLeft: "2em" }}>
				&nbsp;
			</td>
		</tr>
	);
};
class LeadsScreen extends Component {
	pageSize = 30;
	myMessage = null;
	emailAttachement = null;
	test_receiver = null;
	filteredValue = "";
	selectedAssignee = "All";
	optionsBidStatus = [
		{ value: "Draft", label: "Draft" },
		{ value: "Waiting", label: "Waiting" },
		{ value: "Rejected", label: "Rejected" },
		{ value: "Sent", label: "Sent" },
	];
	optionsLeadStatus = [
		{ value: "Pending", label: "Pending" },
		{ value: "In Progress", label: "In Progress" },
		{ value: "Completed", label: "Completed" },
	];
	optionsServiceFee = [
		{ value: "99", label: "99" },
		{ value: "125", label: "125" },
	];
	filteredData = [];
	filtersArray = [
		{ id: "archived", value: "Un-Archived" },
		{ id: "star", value: "star" },
		{ id: "assigned_to", value: "All" },
		{ id: "utc_time_stamp", value: "All" },
	];

	//table
	selectColumn = {
		Header: "Select",
		accessor: "email",
		width: 50,
		maxWidth: 60,
		minWidth: 40,
		Filter: () => (
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					flexDirection: "column",
				}}
			>
				<input
					className='checkbox-input1'
					style={{
						transform: "scale(1.5)",
						height: 80,
					}}
					type='checkbox'
					checked={this.state.selectAll}
					onChange={this.handleSelectAll}
				/>
			</div>
		),
		Cell: ({ row }) => (
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					height: "100%",
				}}
			>
				<input
					style={{
						transform: "scale(1.5)",
					}}
					type='checkbox'
					checked={this.state.selectedEmails?.some(
						(item) => item.email === row.email && item.name === row.name
					)}
					onChange={(e) => {
						this.handleCheckboxChange(
							e,
							row.name,
							row.email,
							row._original._id
						);
					}}
				/>
			</div>
		),
	};

	firstColumns = [
		// {
		//   Header: "",
		//   accessor: "bid_status",
		//   filterable: false,
		//   resizable: false,
		//   width: 60,
		//   maxWidth: 70,
		//   Cell: (row) => {
		//     let source = "";
		//     let sourceToolTip = "";
		//     switch (row.original.source) {
		//       case "Omega":
		//         source = "O";
		//         sourceToolTip = "Omega";
		//         break;
		//       case "Plano":
		//         source = "P";
		//         sourceToolTip = "Plano";
		//         break;
		//       case "Mailbox Lead":
		//         source = "ML";
		//         sourceToolTip = "Mailbox Lead";
		//         break;
		//       case "Omega 2.0":
		//         source = "O2";
		//         sourceToolTip = "Omega Masonary";
		//         break;
		//       case "Brick Repair":
		//         source = "BR";
		//         sourceToolTip = "Omega Masonary";
		//         break;
		//       default:
		//         source = "D";
		//         sourceToolTip = "Default";
		//         break;
		//     }
		//     let bidImage = "";
		//     let toolTip = "";
		//     switch (row.value) {
		//       case "Draft":
		//         bidImage = bidDraft;
		//         toolTip = "Bid Status: Draft";
		//         break;
		//       case "Sent":
		//         bidImage = bidSent;
		//         toolTip = "Bid Status: Sent";
		//         break;
		//       // case "Waiting":
		//       //   bidImage = bidSuccessIcon;
		//       //   toolTip = "Bid Status: Waiting";
		//       //   break;
		//       case "Accepted":
		//         bidImage = bidSuccessIcon;
		//         toolTip = "Bid Status: Accepted";
		//         break;
		//       // case "Rejected":
		//       //   bidImage = bidUnSuccessIcon;
		//       //   toolTip = "Bid Status: Rejected";
		//       //   break;
		//       case "Declined":
		//         bidImage = bidUnSuccessIcon;
		//         toolTip = "Bid Status: Declined";
		//         break;
		//       default:
		//         bidImage = bidDraft;
		//         toolTip = "Bid Status: Draft";
		//     }
		//     let smsIcon = "";
		//     let smsToolTip = "";
		//     if (
		//       row.original.harvestSMSSent == null ||
		//       !row.original.harvestSMSSent
		//     ) {
		//       smsIcon = messageUnsuccess;
		//       smsToolTip = "Estimate Sms: Unsent";
		//     } else {
		//       smsIcon = messageSuccess;
		//       smsToolTip = "Estimate Sms: Sent";
		//     }

		//     return (
		//       <React.Fragment>
		//         <Popup
		//           trigger={
		//             <div
		//               style={{
		//                 // marginLeft: 7,
		//                 width: "55%",
		//                 position: "relative",
		//                 display: "inline-block",
		//               }}
		//             >
		//               <img
		//                 style={{ cursor: "pointer", width: 24, height: 25.5 }}
		//                 src={smsIcon}
		//               />
		//               <br></br>
		//             </div>
		//           }
		//           on={"hover"}
		//           position='top left'
		//         >
		//           <div>{smsToolTip}</div>
		//         </Popup>
		//         {/* <Popup
		//           trigger={
		//             <div
		//               style={{
		//                 // marginLeft: 7,
		//                 width: "50%",
		//                 display: "inline-block",
		//                 // height: 20,
		//                 position: "relative",
		//               }}
		//             >
		//               <img
		//                 style={{ cursor: "pointer", width: "100%", height: 17 }}
		//                 src={bidImage}
		//               />
		//             </div>
		//           }
		//           on={"hover"}
		//           position='top left'
		//         >
		//           <div>{toolTip}</div>
		//         </Popup> */}
		//         <Popup
		//           trigger={
		//             <div
		//               style={{
		//                 width: "45%",
		//                 display: "inline-block",
		//               }}
		//             >
		//               <p
		//                 style={{ fontWeight: "bold", fontSize: 14, color: "black" }}
		//               >
		//                 {source}
		//               </p>
		//             </div>
		//           }
		//           on={"hover"}
		//           position='top left'
		//         >
		//           <div>Lead Source: {sourceToolTip}</div>
		//         </Popup>
		//       </React.Fragment>
		//     );
		//   },
		// },
		{
			Header: "Snooze",
			accessor: "snooze",
			filterable: false,
			width: 90,
			maxWidth: 250,
			minWidth: 60,
			minResizeWidth: 60,
			Cell: (row) => (
				<SnoozeButton
					row={row}
					handleSubmit={this.snoozeLead}
					confirmAction={this.props.confirmAction}
				/>
			),
		},
		// {
		//   Header: "Web Source",
		//   accessor: "source",
		//   width: 86,
		//   maxWidth: 86,
		//   minWidth: 70,
		//   // resizeable: false,
		//   minResizeWidth: 60,
		//   Cell: (row) => (
		//     <span style={{ alignSelf: "center", color: "black" }}>
		//       {row.value == "" ? "Default" : row.value}
		//     </span>

		//     // this.getLeadCardDateFormat(row.value)
		//   ),
		//   filterMethod: (filter, row) => {
		//     return row[filter.id]
		//       .toLowerCase()
		//       .includes(filter.value.toLowerCase());
		//   },
		//   Filter: ({ filter, onChange }) => (
		//     <input
		//       style={{
		//         backgroundColor: "#9399a4",
		//         color: "#e4e4ea",
		//         fontSize: 11,
		//         width: "100%",
		//       }}
		//       onChange={(e) => {
		//         onChange(e.target.value.trim());
		//         // this.setState({
		//         //   searchSource: e.target.value.trim(),
		//         // });
		//         // setTimeout(()=>{
		//         //   this.searchLead();
		//         // }, 1000);
		//       }}
		//     />
		//   ),
		// },
		{
			Header: "Timestamp",
			accessor: "utc_time_stamp",
			width: 96,
			maxWidth: 96,
			minWidth: 60,
			resizable: true,
			Cell: (row) => (
				<span style={{ alignSelf: "center", color: "black" }}>
					{moment(row.value).local().format("MM-DD-YY h:mm A")}
				</span>
			),

			Filter: ({ filter, onChange }) => (
				<select
					className='select'
					onChange={(event) => {
						this.showLoader();
						localStorage.setItem("timeFilterValue", event.target.value);
						onChange(event.target.value);
					}}
					style={{
						width: "100%",
						height: 22,
						textAlignLast: "left",
						fontSize: 11,
					}}
					key={"time_filter"}
					value={filter ? filter.value : "All"}
				>
					<option value='All'>All</option>
					<option value='Today'>Today</option>
					<option value='Yesterday'>Yesterday</option>
					<option value='This Week'>This Week</option>
					<option value='This Month'>This Month</option>
				</select>
			),
			filterMethod: (filter, row) => {
				switch (filter.value) {
					case "All":
						return true;
					case "Today":
						var leadDate = moment(row[filter.id]).local();
						var todayDate = moment().local();
						if (
							leadDate.month() === todayDate.month() &&
							leadDate.year() === todayDate.year() &&
							leadDate.date() === todayDate.date()
						)
							return true;
						else return false;
					case "Yesterday":
						var leadDate = moment(row[filter.id]).local();
						var todayDate = moment().local();

						if (
							leadDate.month() === todayDate.month() &&
							leadDate.year() === todayDate.year() &&
							leadDate.date() === todayDate.date() - 1
						)
							return true;
						else return false;
					case "This Week":
						var leadDate = moment(row[filter.id]).local();
						var todayDate = moment().local();
						if (
							leadDate.month() === todayDate.month() &&
							leadDate.year() === todayDate.year() &&
							leadDate.week() === todayDate.week()
						)
							return true;
						else return false;
					case "This Month":
						var leadDate = moment(row[filter.id]).local();
						var todayDate = moment().local();
						if (
							leadDate.month() === todayDate.month() &&
							leadDate.year() === todayDate.year()
						)
							return true;
						else return false;
					default:
						return true;
				}
			},
		},
		{
			Header: "Description",
			accessor: "svc_requested",
			width: 170,
			maxWidth: 250,
			minWidth: 80,
			minResizeWidth: 120,
			filterMethod: (filter, row) => {
				// this.filteredData = row;
				// console.log("filter", this.filteredData);
				return row[filter.id]
					.toLowerCase()
					.includes(filter.value.toLowerCase());
			},
			Filter: ({ filter, onChange }) => (
				<input
					style={{
						backgroundColor: "#9399a4",
						color: "#e4e4ea",
						fontSize: 11,
						width: "100%",
					}}
					onChange={(e) => {
						onChange(e.target.value.trim());
						// this.setState({
						//   searchSvcRequested: e.target.value.trim(),
						// });
						// setTimeout(()=>{
						//   this.searchLead();
						// }, 1000);
					}}
				/>
			),
			Cell: (row) => (
				<EditableTextView
					showAlert={this.props.showAlert}
					row={row}
					columnName='svc_requested'
					handleSubmit={this.handleSubmit}
					editableClassName='editableTwoLine'
					isClickable={false}
					isEditable={true}
				/>
			),
		},
		{
			Header: "Name",
			accessor: "name",
			width: 150,
			maxWidth: 150,
			minWidth: 80,
			minResizeWidth: 80,

			Cell: (row) => (
				<div>
					{/* <p>PDFs</p> */}
					<EditableTextView
						showAlert={this.props.showAlert}
						row={row}
						columnName='name'
						handleSubmit={this.handleSubmit}
						editableClassName='editableTwoLine'
						isClickable={false}
						isEditable={true}
					/>
				</div>
			),
			filterMethod: (filter, row) => {
				return row[filter.id]
					?.toLowerCase()
					.includes(filter.value.toLowerCase());
			},
			Filter: ({ filter, onChange }) => (
				<input
					style={{
						backgroundColor: "#9399a4",
						color: "#e4e4ea",
						fontSize: 11,
						width: "100%",
					}}
					onChange={(e) => {
						onChange(e.target.value.trim());
						// this.setState({
						//   searchName: e.target.value.trim(),
						// });
						// setTimeout(()=>{
						//   this.searchLead();
						// }, 1000);
						//   flag = !flag;
						//
					}}
				/>
			),
		},
		{
			Header: "Email",
			accessor: "email",
			minWidth: 450,
			width: 470,
			maxWidth: 570,
			// width: 195,
			// maxWidth: 250,
			// minWidth: 100,
			minResizeWidth: 200,

			filterMethod: (filter, row) => {
				return row[filter.id]
					.toLowerCase()
					.includes(filter.value.toLowerCase());
			},
			Filter: ({ filter, onChange }) => (
				<input
					style={{
						backgroundColor: "#9399a4",
						color: "#e4e4ea",
						fontSize: 11,
						width: "100%",
					}}
					onChange={(e) => {
						onChange(e.target.value.trim());
						// this.setState({
						//   searchEmail: e.target.value.trim(),
						// });
						// setTimeout(()=>{
						//   this.searchLead();
						// }, 1000);
					}}
				/>
			),
			Cell: (row) => {
				return (
					<div
						style={{
							alignItems: "center",
							display: "flex",
							flexDirection: "row",
							width: "100%",
						}}
					>
						<Popup
							trigger={
								<div
									style={{
										marginBottom: 8,
										marginRight: 2,
										marginLeft: 7,
										width: 15,
										height: 15,
										position: "relative",
									}}
								>
									<img
										alt=''
										onClick={() =>
											this.contactLead(
												row.original._id,
												"email",
												row.original.assigned_to,
												row.index
											)
										}
										style={{
											cursor: "pointer",
											width: "2.4rem",
											height: "2.4rem",
										}}
										src={emailIcon}
									/>
									{row.original.email_auto_contact_count > 0 ? (
										<img
											alt=''
											style={{
												width: 10,
												height: 10,
												top: -3,
												right: -5,
												position: "absolute",
											}}
											src={sentCheckIcon}
										/>
									) : null}
									{row.original.automatic_email_sent != null &&
									row.original.automatic_email_sent ? (
										<img
											alt=''
											style={{
												width: 10,
												height: 10,
												top: -3,
												left: -5,
												position: "absolute",
											}}
											src={autoEmailsentCheckIcon}
										/>
									) : null}
								</div>
							}
							on={"hover"}
							position='top left'
						>
							{row.original.email_auto_contact_count > 0
								? `Email already sent.`
								: `Send canned email.`}
						</Popup>
						<div className='sequence-container'>
							<div
								className='email-hotlist-mhr'
								style={{
									display: "flex",
									paddingLeft: 10,
									marginRight: "10px",
									textOverflow: "ellipsis",
									whiteSpace: "nowrap",
									maxLines: "1",
									display: " -webkit-box",
									WebkitBoxOrient: "vertical",
									minWidth: "20%",
								}}
							>
								<EditableTextView
									showAlert={this.props.showAlert}
									row={row}
									columnName='email'
									handleSubmit={this.handleSubmit}
									contactLead={this.contactLead}
									editableClassName='editableOneLine'
									urlPrefix='mailto:'
									isClickable={true}
								/>
							</div>
							<Popup
								trigger={
									row.original.fakeEmailWarning ? (
										<img
											src={require("../assets/images/invalidEmail.png")}
											className='invalidEmailIcon'
										/>
									) : null
									// <img
									// 	src={require("../assets/images/tick_mark.png")}
									// 	className="invalidEmailIcon "
									// />
								}
								on={"hover"}
								position='top left'
								arrow={false}
							>
								{row.original.fakeEmailWarning
									? "Warning: This email may not be valid"
									: "This email is valid"}
							</Popup>
							{/* sequence feature commented */}
							{/* {!row.original.sequenceStatus ||
						row.original.sequenceStatus === "0" ? (
							<div
								className="mhr-seqeunce-container "
								onClick={() => {
									this.setState({
										showStartSequenceModal: true,
										rowId: row.original._id,
										email: true,
									});
								}}
							>
								<img
									src={require("../assets/images/sequence-play.png")}
								/>
								<p>Sequence</p>
							</div>
						) : row.original.sequenceStatus === "2" ? (
							<div
								className="mhr-seqeunce-container mhr-seqeunce-container-yellow "
								onClick={async () => {
									const currentDate = new Date();
									const passed = await hasDatePassed(
										row.original.nextTemplateDate,
										currentDate.toLocaleDateString("en-US")
									);
									if (passed) {
										this.setState({
											unPauseId:
												row.original.assignedSequence,
											showUpdateDatePopup: true,
											pause: false,
											email: true,
											rowId: row.original._id,
										});
									} else {
										this.setState({
											showPauseSequenceModal: true,
											pause: false,
											email: true,
											leadId: row.original._id,
										});
									}
								}}
							>
								<img
									src={require("../assets/images/sequence-paused.png")}
								/>
								<p>Sequence</p>
							</div>
						) : row.original.nextTemplateNumber ===
								row.original.totalTemplates + 1 ||
						  row.original.sequenceStatus === "3" ? (
							<div
								className="mhr-seqeunce-container mhr-seqeunce-container-green"
								onClick={() => {
									this.props.showAlert(
										"Sequence already completed"
									);
									this.setState({
										showPauseSequenceDetailsModal: true,
										email: true,
										showPauseButton: false,
										pause: true,
										sequenceId:
											row.original.assignedSequence,
										totalTemplates:
											row.original.totalTemplates,
										startDate:
											row.original.startTemplateDate,
										nextTemplateNumber:
											row.original.nextTemplateNumber,
										firstTemplateDate:
											row.original.firstTemplateDate,
										secondTemplateDate:
											row.original.secondTemplateDate,
										thirdTemplateDate:
											row.original.thirdTemplateDate,
										leadId: row.original._id,
										selectedSequence:
											row.original.emailSequence,
									});
								}}
							>
								<p>Sequence</p>
								<img
									src={require("../assets/images/sequence-tickcircle-green.png")}
								/>
							</div>
						) : (
							<div className="mhr-seqeunce-container-played">
								<img
									className="mhr-seqeunce-container-played-img"
									src={require("../assets/images/sequence-pause.png")}
									onClick={() => {
										this.setState({
											showPauseSequenceModal: true,
											pause: true,
											email: true,
											leadId: row.original._id,
										});
									}}
								/>
								<div
									onClick={() => {
										this.setState({
											showPauseSequenceDetailsModal: true,
											email: true,
											pause: true,
											showPauseButton: true,
											sequenceId:
												row.original.assignedSequence,
											totalTemplates:
												row.original.totalTemplates,
											startDate:
												row.original.startTemplateDate,
											nextTemplateNumber:
												row.original.nextTemplateNumber,
											firstTemplateDate:
												row.original.firstTemplateDate,
											secondTemplateDate:
												row.original.secondTemplateDate,
											thirdTemplateDate:
												row.original.thirdTemplateDate,
											leadId: row.original._id,
											selectedSequence:
												row.original.emailSequence,
										});
									}}
								>
									{row.original.nextTemplateNumber > 1 &&
									row.original.totalTemplates > 0 ? (
										<img
											className="mhr-sequence-checked-image"
											src={require("../assets/images/sequence-tickcircle.png")}
										/>
									) : (
										row.original.totalTemplates > 0 && (
											<div>
												<p>1</p>
											</div>
										)
									)}
									{row.original.nextTemplateNumber > 2 &&
									row.original.totalTemplates > 1 ? (
										<img
											className="mhr-sequence-checked-image"
											src={require("../assets/images/sequence-tickcircle.png")}
										/>
									) : (
										row.original.totalTemplates > 1 && (
											<div>
												<p>2</p>
											</div>
										)
									)}
									{row.original.nextTemplateNumber > 3 &&
									row.original.totalTemplates > 2 ? (
										<img
											className="mhr-sequence-checked-image"
											src={require("../assets/images/sequence-tickcircle.png")}
										/>
									) : (
										row.original.totalTemplates > 2 && (
											<div>
												<p>3</p>
											</div>
										)
									)}
								</div>
							</div>
						)} */}
						</div>
					</div>
				);
			},
		},
		{
			Header: "Phone",
			accessor: "phone_number",
			width: 300,
			maxWidth: 650,
			minWidth: 220,
			// width: 180,
			// maxWidth: 200,
			// minWidth: 100,
			minResizeWidth: 200,
			filterMethod: (filter, row) => {
				return row[filter.id]
					.toLowerCase()
					.replace(/\(/g, "")
					.replace(/\)/g, "")
					.replace(/-/g, "")
					.replace(/\s/g, "")
					.includes(
						filter.value
							.toLowerCase()
							.replace(/\(/g, "")
							.replace(/\)/g, "")
							.replace(/-/g, "")
							.replace(/\s/g, "")
					);
			},
			Filter: ({ filter, onChange }) => (
				<input
					style={{
						backgroundColor: "#9399a4",
						color: "#e4e4ea",
						fontSize: 11,
						width: "100%",
					}}
					onChange={(e) => {
						onChange(e.target.value.trim());
						// this.setState({
						//   searchPhoneNumber: e.target.value.trim(),
						// });
						// setTimeout(()=>{
						//   this.searchLead();
						// }, 1000);
					}}
				/>
			),
			Cell: (row) => (
				<div
					style={{
						alignItems: "center",
						display: "flex",
						flexDirection: "row",
						width: "100%",
					}}
				>
					<Popup
						trigger={
							<div
								style={{
									// marginBottom: 8,
									// marginRight: 1,
									marginLeft: 7,
									width: 23,
									minWidth: 23,
									height: 23,
									position: "relative",
								}}
							>
								<img
									onClick={() =>
										this.contactLead(
											row.original._id,
											"phone",
											row.original.assigned_to,
											row.index
										)
									}
									style={{
										cursor: "pointer",
										width: "100%",
										height: "100%",
									}}
									src={smsIcon}
								/>
								{row.original.auto_contact_count > 0 ? (
									<img
										style={{
											width: 10,
											height: 10,
											top: -2,
											right: -5,
											position: "absolute",
										}}
										src={sentCheckIcon}
									/>
								) : null}
							</div>
						}
						on={"hover"}
						position='top left'
					>
						{row.original.auto_contact_count > 0
							? `Canned sms already sent.`
							: `Send canned sms.`}
					</Popup>

					<div
						className='sequence-container-sms'
						// style={{ paddingRight: "15px" }}
					>
						<div
							className='email-hotlist-mhr'
							style={{
								display: "flex",
								flex: 1,
								paddingLeft: 10,
								textOverflow: "ellipsis",
								whiteSpace: "nowrap",
								maxLines: "1",
								display: " -webkit-box",
								WebkitBoxOrient: "vertical",
								minWidth: "20%",
							}}
						>
							<EditableTextView
								showAlert={this.props.showAlert}
								row={row}
								columnName='phone_number'
								handleSubmit={this.handleSubmit}
								editableClassName='editableOneLine'
								isClickable={false}
								isEditable={true}
							/>
						</div>
						{/* sequence feature commented */}

						{/* {!row.original.sequenceStatusSms ||
						row.original.sequenceStatusSms === "0" ? (
							<div
								className="mhr-seqeunce-container "
								onClick={() => {
									this.setState({
										showStartSequenceModal: true,
										rowId: row.original._id,
										email: false,
									});
								}}
							>
								<img
									src={require("../assets/images/sequence-play.png")}
								/>
								<p>Sequence</p>
							</div>
						) : row.original.sequenceStatusSms === "2" ? (
							<div
								className="mhr-seqeunce-container mhr-seqeunce-container-yellow "
								onClick={async () => {
									const currentDate = new Date();
									// const date = await formatDate(
									//   row.original.nextTemplateDateSmsOriginal
									// );
									const passed = await hasDatePassed(
										row.original.nextTemplateDateSms,
										currentDate.toLocaleDateString("en-US")
									);
									if (passed) {
										this.setState({
											unPauseId:
												row.original
													.assignedSequenceSms,
											showUpdateDatePopup: true,
											pause: false,
											email: false,
											rowId: row.original._id,
										});
									} else {
										this.setState({
											showPauseSequenceModalSms: true,
											pause: false,
											email: false,
											leadId: row.original._id,
										});
									}
								}}
							>
								<img
									src={require("../assets/images/sequence-paused.png")}
								/>
								<p>Sequence</p>
							</div>
						) : row.original.nextTemplateNumberSms ===
								row.original.totalTemplatesSms + 1 ||
						  row.original.sequenceStatusSms === "3" ? (
							<div
								className="mhr-seqeunce-container mhr-seqeunce-container-green"
								onClick={() => {
									this.props.showAlert(
										"Sequence already completed"
									);
									this.setState({
										showPauseSequenceDetailsModal: true,
										email: false,
										showPauseButton: false,
										pause: true,
										sequenceId:
											row.original.assignedSequenceSms,
										totalTemplates:
											row.original.totalTemplatesSms,
										startDate:
											row.original.startTemplateDateSms,
										nextTemplateNumber:
											row.original.nextTemplateNumberSms,
										firstTemplateDate:
											row.original.firstTemplateDateSms,
										secondTemplateDate:
											row.original.secondTemplateDateSms,
										thirdTemplateDate:
											row.original.thirdTemplateDateSms,
										leadId: row.original._id,
										selectedSequence:
											row.original.smsSequence,
									});
								}}
							>
								<p>Sequence</p>
								<img
									src={require("../assets/images/sequence-tickcircle-green.png")}
								/>
							</div>
						) : (
							row.original.sequenceStatusSms === "1" && (
								<div className="mhr-seqeunce-container-played">
									<img
										className="mhr-seqeunce-container-played-img"
										src={require("../assets/images/sequence-pause.png")}
										onClick={() => {
											this.setState({
												showPauseSequenceModal: true,
												pause: true,
												email: false,
												leadId: row.original._id,
											});
										}}
									/>
									<div
										onClick={() =>
											this.setState({
												showPauseSequenceDetailsModal: true,
												email: false,
												showPauseButton: true,
												pause: true,
												sequenceId:
													row.original
														.assignedSequenceSms,
												totalTemplates:
													row.original
														.totalTemplatesSms,
												startDate:
													row.original
														.startTemplateDateSms,
												nextTemplateNumber:
													row.original
														.nextTemplateNumberSms,
												firstTemplateDate:
													row.original
														.firstTemplateDateSms,
												secondTemplateDate:
													row.original
														.secondTemplateDateSms,
												thirdTemplateDate:
													row.original
														.thirdTemplateDateSms,
												leadId: row.original._id,
												selectedSequence:
													row.original.smsSequence,
											})
										}
									>
										{row.original.nextTemplateNumberSms >
											1 &&
										row.original.totalTemplatesSms > 0 ? (
											<img
												className="mhr-sequence-checked-image"
												src={require("../assets/images/sequence-tickcircle.png")}
											/>
										) : (
											row.original.totalTemplatesSms >
												0 && (
												<div>
													<p>1</p>
												</div>
											)
										)}
										{row.original.nextTemplateNumberSms >
											2 &&
										row.original.totalTemplatesSms > 1 ? (
											<img
												className="mhr-sequence-checked-image"
												src={require("../assets/images/sequence-tickcircle.png")}
											/>
										) : (
											row.original.totalTemplatesSms >
												1 && (
												<div>
													<p>2</p>
												</div>
											)
										)}
										{row.original.nextTemplateNumberSms >
											3 &&
										row.original.totalTemplatesSms > 3 ? (
											<img
												className="mhr-sequence-checked-image"
												src={require("../assets/images/sequence-tickcircle.png")}
											/>
										) : (
											row.original.totalTemplatesSms >
												2 && (
												<div>
													<p>3</p>
												</div>
											)
										)}
									</div>
								</div>
							)
						)} */}
					</div>
				</div>
			),
		},
		{
			Header: "Loc & Category",
			accessor: "address",
			width: 160,
			maxWidth: 200,
			minWidth: 100,
			minResizeWidth: 140,
			filterMethod: (filter, row) => {
				// this.filteredData = row._original;
				console.log("filter", filter);
				return (
					row[filter.id].toLowerCase().includes(filter.value.toLowerCase()) ||
					row?._original?.category?.name
						.toLowerCase()
						.includes(filter.value.toLowerCase())
				);
				// return row[filter.id]
				//   .toLowerCase()
				//   .replace(/-/g, "")
				//   .replace(/./g, "")
				//   .replace(/,/g, "")
				//   .replace(/\s/g, "")
				//   .includes(
				//     filter.value
				//       .toLowerCase()
				//       .replace(/-/g, "")
				//       .replace(/./g, "")
				//       .replace(/,/g, "")
				//       .replace(/\s/g, "")
				//   );
			},
			Filter: ({ filter, onChange }) => (
				<input
					style={{
						backgroundColor: "#9399a4",
						color: "#e4e4ea",
						fontSize: 11,
						width: "100%",
					}}
					onChange={(e) => {
						onChange(e.target.value.trim());
						// this.setState({
						//   searchAddress: e.target.value.trim(),
						// });
						// setTimeout(()=>{
						//   this.searchLead();
						// }, 1000);
					}}
				/>
			),
			Cell: (row) => (
				<Popup
					contentStyle={{ display: "flow", flex: 1 }}
					on={"hover"}
					position='left center'
					trigger={
						<div className='loc-category-main'>
							<img
								onClick={(e) => {
									if (
										row.original.address &&
										row.original.address !== "" &&
										row.original.address != "Empty"
									) {
										copy(row.original.address);
										this.props.showAlert("Copied to clipboard");
									}
								}}
								src={require("../assets/images/location-icon.png")}
								alt=''
							/>
							<div
								style={{
									backgroundColor: row?.original?.category
										? categoriesOptions.find(
												(item) => item.name === row?.original?.category?.name
										  )
											? categoriesOptions.find(
													(item) => item.name === row?.original?.category?.name
											  ).backgroundColor
											: "#707070"
										: "#707070",
									borderColor: row?.original?.category
										? categoriesOptions.find(
												(item) => item.name === row?.original?.category?.name
										  )
											? darkenHexColor(
													categoriesOptions.find(
														(item) =>
															item.name === row?.original?.category?.name
													).backgroundColor,
													0.2
											  )
											: "#454545"
										: "#454545",
								}}
								onClick={() => {
									this.setState({
										showCategoryModal: true,
										leadName: row.original.name,
										row: row,
									});
								}}
							>
								<p>
									{row?.original?.category
										? row?.original?.category?.name
											? row?.original?.category?.name.toLowerCase() ===
											  "un-assign"
												? "un-assigned"
												: row?.original?.category?.name
											: "un-assigned"
										: "un-assigned"}
								</p>
							</div>
						</div>
					}
				>
					<div
						style={{
							width: "100%",
							height: "100%",
							display: "flex",
							flexDirection: "row",
							justifyContent: "flex-start",
							alignItems: "center",
						}}
					>
						<div
							onClick={(e) => {
								window.open(
									"http://maps.google.com/?q=" + row.original.address
								);
							}}
							style={{
								width: "fit-content",
								height: "100%",
								display: "flex",
								flexDirection: "row",
								justifyContent: "flex-start",
								alignItems: "center",
								color: "#5289ff",
							}}
						>
							<p>{row.original.address}</p>
							<FittedImage
								fit='auto'
								src={require("../assets/images/open_url_icon_thick.png")}
								style={{
									width: 15,
									height: 15,
									marginLeft: 5,
									cursor: "pointer",
								}}
							/>
						</div>
						<FittedImage
							fit='auto'
							src={require("../assets/images/edit_icon_blue.png")}
							style={{
								width: 15,
								height: 15,
								marginLeft: 10,
								cursor: "pointer",
							}}
							onClick={() => this.setState({ isInEditMode: true })}
						/>
					</div>
					<EditModal
						defaultValue={row.original.address}
						show={this.state.isInEditMode}
						submitValue={(value) => {
							this.handleSubmit(
								"address",
								row.original["address"],
								value,
								row.original._id
							);
							this.setState({ isInEditMode: false });
						}}
						onCancelEditModal={() => this.setState({ isInEditMode: false })}
						showAlert={this.props.showAlert}
						text={"Edit address"}
					/>
				</Popup>
			),
		},

		{
			Header: "Lead Status",
			accessor: "lead_status",
			filterable: false,
			resizable: true,
			width: 150,
			maxWidth: 160,
			Cell: (row) => (
				<LeadStatusDropdown
					row={row}
					updateLeadStatus={this.updateLeadStatus}
				/>
			),
			style: { overflow: "visible", paddingLeft: 10, paddingRight: 10 },
		},

		//Review column
		// {
		//   Header: "Review",
		//   accessor: "auto_review_count",
		//   filterable: false,
		//   width: 90,
		//   maxWidth: 250,
		//   minWidth: 60,
		//   minResizeWidth: 60,
		//   Cell: (row) => (
		//     <div
		//       style={{
		//         alignItems: "center",
		//         display: "flex",
		//         flexDirection: "row",
		//         width: "50%",
		//       }}
		//     >
		//       <Popup
		//         trigger={
		//           <div
		//             style={{
		//               marginBottom: 8,
		//               marginRight: 2,
		//               marginLeft: 7,
		//               width: 15,
		//               minWidth: 15,
		//               height: 15,
		//               position: "relative",
		//             }}
		//           >
		//             <img
		//               onClick={() =>
		//                 this.contactLead(
		//                   row.original._id,
		//                   "review",
		//                   row.original.assigned_to,
		//                   row.index
		//                 )
		//               }
		//               style={{ cursor: "pointer", width: "31px", height: "27px" }}
		//               src={ReviewIcon}
		//             />
		//             {row.original.auto_review_count > 0 ? (
		//               <img
		//                 style={{
		//                   width: 10,
		//                   height: 10,
		//                   top: -2,
		//                   right: -5,
		//                   position: "absolute",
		//                 }}
		//                 src={sentCheckIcon}
		//               />
		//             ) : null}
		//           </div>
		//         }
		//         on={"hover"}
		//         position="top right"
		//       >
		//         <div>Send a Review</div>
		//       </Popup>

		//       {/* <EditableTextView
		//         showAlert={this.props.showAlert}
		//         row={row}
		//         columnName=""
		//         handleSubmit={this.handleSubmit}
		//         editableClassName="editableOneLine"
		//         isClickable={false}
		//         isEditable={true}
		//       /> */}
		//     </div>
		//   ),
		// },
		// {
		//   Header: "Text Messages",
		//   accessor: "auto_referral_count",
		//   filterable: false,
		//   width: 90,
		//   maxWidth: 250,
		//   minWidth: 60,
		//   minResizeWidth: 60,
		//   Cell: (row) => (
		//     <div
		//       style={{
		//         alignItems: "center",
		//         display: "flex",
		//         flexDirection: "row",
		//         justifyContent: "center",
		//         width: "100%",
		//       }}
		//     >
		//       {row.original.auto_review_count == 0 ||
		//       row.original.auto_referral_count == 0 ? (
		//         <Popup
		//           trigger={
		//             <div
		//               style={{
		//                 // marginBottom: 8,
		//                 // marginRight: 2,
		//                 // marginLeft: 7,
		//                 width: "fit-content",
		//                 minWidth: "fit-content",
		//                 height: "100%",
		//                 position: "relative",
		//               }}
		//             >
		//               <img
		//                 onClick={() =>
		//                   this.contactLead(
		//                     row.original._id,
		//                     "review",
		//                     row.original.assigned_to,
		//                     row.index
		//                   )
		//                 }
		//                 style={{ cursor: "pointer", width: "24px", height: "24px" }}
		//                 src={refmessage}
		//               />
		//               {row.original.auto_referral_count > 0 ? (
		//                 <img
		//                   style={{
		//                     width: 11,
		//                     height: 11,
		//                     top: -2,
		//                     right: -10,
		//                     position: "absolute",
		//                   }}
		//                   src={orangeIcon}
		//                 />
		//               ) : (
		//                 ""
		//               )}
		//               {row.original.auto_review_count > 0 ? (
		//                 <img
		//                   style={{
		//                     width: 11,
		//                     height: 11,
		//                     top: -2,
		//                     right: 1,
		//                     position: "absolute",
		//                   }}
		//                   src={sentCheckIcon}
		//                 />
		//               ) : (
		//                 ""
		//               )}
		//             </div>
		//           }
		//           on={"hover"}
		//           position='top right'
		//         >
		//           <div>
		//             {" "}
		//             {row.original.auto_review_count > 0 &&
		//             row.original.auto_referral_count > 0
		//               ? "Referral/Review already sent"
		//               : row.original.auto_review_count == 0 &&
		//                 row.original.auto_referral_count == 0
		//               ? "Send a Referral/Review"
		//               : row.original.auto_referral_count > 0 &&
		//                 row.original.auto_review_count == 0
		//               ? "Send a Review"
		//               : "Send Referral"}
		//           </div>

		//           {/* <div>Send Review/Referral Message.</diauto_referral_countv> */}
		//         </Popup>
		//       ) : (
		//         <Popup
		//           trigger={
		//             <div
		//               style={{
		//                 // marginBottom: 8,
		//                 // marginRight: 2,
		//                 // marginLeft: 7,
		//                 width: "fit-content",
		//                 minWidth: "fit-content",
		//                 height: "100%",
		//                 position: "relative",
		//               }}
		//             >
		//               <img
		//                 onClick={() =>
		//                   this.contactLead(
		//                     row.original._id,
		//                     "review",
		//                     row.original.assigned_to,
		//                     row.index
		//                   )
		//                 }
		//                 style={{ cursor: "pointer", width: "24px", height: "24px" }}
		//                 src={refmessage}
		//               />
		//               {row.original.auto_referral_count > 0 ? (
		//                 <img
		//                   style={{
		//                     minHidth: 10,
		//                     minWeight: 10,
		//                     width: 10,
		//                     height: 10,
		//                     top: -3,
		//                     right: 0,
		//                     position: "absolute",
		//                   }}
		//                   src={orangeIcon}
		//                 />
		//               ) : (
		//                 ""
		//               )}
		//               {row.original.auto_review_count > 0 ? (
		//                 <img
		//                   style={{
		//                     width: 11,
		//                     minWidth: 11,
		//                     height: 11,
		//                     minHeight: 11,
		//                     top: -3,
		//                     right: 9,
		//                     position: "absolute",
		//                   }}
		//                   src={sentCheckIcon}
		//                 />
		//               ) : (
		//                 ""
		//               )}
		//             </div>
		//           }
		//           on={"hover"}
		//           position='top right'
		//         >
		//           <div>
		//             {" "}
		//             {row.original.auto_review_count > 0 &&
		//             row.original.auto_referral_count > 0
		//               ? "Referral/Review already sent"
		//               : row.original.auto_review_count == 0 &&
		//                 row.original.auto_referral_count == 0
		//               ? "Send a Referral/Review"
		//               : row.original.auto_referral_count > 0 &&
		//                 row.original.auto_review_count == 0
		//               ? "Send a Review"
		//               : "Send Referral"}
		//           </div>
		//           {/* <div>Review and referral already sent.</div> */}
		//         </Popup>
		//       )}

		//       {/* <EditableTextView
		//         showAlert={this.props.showAlert}
		//         row={row}
		//         columnName=""
		//         handleSubmit={this.handleSubmit}
		//         editableClassName="editableOneLine"
		//         isClickable={false}
		//         isEditable={true}
		//       /> */}
		//     </div>
		//   ),
		// },

		// {
		//   Header: "Easy Access Links",
		//   accessor: "extra_links",
		//   width: 170,
		//   maxWidth: 250,
		//   minWidth: 80,
		//   minResizeWidth: 120,
		//   filterMethod: (filter, row) => {
		//     return row[filter.id]
		//       .toLowerCase()
		//       .includes(filter.value.toLowerCase());
		//   },
		//   Filter: ({ filter, onChange }) => (
		//     <input
		//       style={{
		//         backgroundColor: "#9399a4",
		//         color: "#e4e4ea",
		//         fontSize: 11,
		//         width: "100%",
		//       }}
		//       onChange={(e) => {
		//         onChange(e.target.value.trim());
		//         // this.setState({
		//         //   searchExtraLinks: e.target.value.trim(),
		//         // });
		//         // setTimeout(()=>{
		//         //   this.searchLead();
		//         // }, 1000);
		//       }}
		//     />
		//   ),
		//   Cell: (row) => (
		//     <EditableTextView
		//       showAlert={this.props.showAlert}
		//       row={row}
		//       columnName='extra_links'
		//       handleSubmit={this.handleSubmit}
		//       editableClassName='editableTwoLine'
		//       isClickable={false}
		//       isEditable={true}
		//     />
		//   ),
		// },
		{
			Header: "Notes",
			accessor: "notes",
			width: 170,
			maxWidth: 250,
			minWidth: 80,
			minResizeWidth: 120,
			filterMethod: (filter, row) => {
				return row[filter.id]
					.map((e) => e.note)
					.join(",")
					.toLowerCase()
					.includes(filter.value.toLowerCase());
			},
			Filter: ({ filter, onChange }) => (
				<input
					style={{
						backgroundColor: "#9399a4",
						color: "#e4e4ea",
						fontSize: 11,
						width: "100%",
					}}
					onChange={(e) => {
						onChange(e.target.value.trim());
						// this.setState({
						//   searchNotes: e.target.value.trim(),
						// });
						// setTimeout(()=>{
						//   this.searchLead();
						// }, 1000);
					}}
				/>
			),

			Cell: (row) => (
				<Popup
					contentStyle={{ display: "flow", flex: 1 }}
					trigger={
						<div
							style={{
								flex: 1,
								display: "flex",
								width: "100%",
								height: "100%",
								position: "relative",
							}}
						>
							<NotesTextView
								selectedColumn={this.props.selectedCol}
								showAlert={this.props.showAlert}
								row={row}
								columnName='notes'
								handleSubmit={this.saveNote}
								editableClassName='editableTwoLine'
								isClickable={false}
								isEditable={true}
								allowToUpdate={this.props.allowToUpdate}
								updateDataByID={this.props.updateDataByID}
								users={this.props.allUsers}
							/>
						</div>
					}
					on={"hover"}
				>
					{IsTextHTML(
						row.value.length > 0
							? row.value[row.value.length - 1].note
							: "plain text"
					) ? (
						<p
							dangerouslySetInnerHTML={{
								__html:
									row.value.length > 0
										? linkifyHtml(row.value[row.value.length - 1].note, {
												target: {
													url: "_blank",
													email: null,
												},
										  })
										: "",
							}}
						></p>
					) : (
						<p className='display-linebreak'>
							{row.value.length > 0 &&
							row.value[row.value.length - 1].note != undefined
								? linkifyHtml(row.value[row.value.length - 1].note, {
										target: {
											url: "_blank",
											email: null,
										},
								  })
								: ""}
						</p>
					)}

					{/* {row.value.length > 0 ? row.value[row.value.length - 1].note : "n/a"} */}
					<button
						style={{
							color: "#0a84fe",
							fontSize: 10,
							cursor: "pointer",
							merginLeft: 10,
							background: "none",
							border: "none",
						}}
						onClick={() =>
							this.handleCopy(
								row.value.length > 0 ? row.value[row.value.length - 1].note : ""
							)
						}
					>
						<FittedImage
							fit='auto'
							src={copyIcon}
							style={{
								width: 17,
								height: 17,
								marginLeft: 5,
								cursor: "pointer",
							}}
						/>
					</button>
				</Popup>
			),
		},
		{
			Header: "Assigned To",
			accessor: "assigned_to",
			width: 150,
			maxWidth: 200,
			minWidth: 80,
			minResizeWidth: 80,

			filterMethod: (filter, row) => {
				this.filteredValue = filter.value;

				if (filter.value === "All") return true;
				if (
					(filter.value && filter.value.toString() == "Un-Assign") ||
					(filter.value && filter.value.toString().toLowerCase() === "unassign")
				) {
					if (row[filter.id] == null || row[filter.id] == "") {
						return true;
					} else return false;
				}

				if (row[filter.id] == null || row[filter.id].toString().trim() == "")
					return false;
				try {
					var flag =
						row[filter.id].toString().toLowerCase() ==
						filter.value.toString().toLowerCase();
					// .includes(filter.value.toString().toLowerCase());
					return flag;
				} catch (e) {
					return true;
				}
			},
			Filter: ({ filter, onChange }) => (
				<select
					className='select'
					onChange={(event) => {
						this.selectedAssignee = event.target.value;
						this.props.allowToUpdate();
						if (event.target.value == "All") this.setState({ pageSize: 30 });
						else this.setState({ pageSize: 50 });
						onChange(event.target.value);
					}}
					style={{
						width: "100%",
						height: 22,
						textAlign: "center",
						textAlignLast: "left",
						fontSize: 11,
					}}
					key={"assigned_to_filter"}
				>
					<option value='All'>All</option>
					{this.props?.users?.map((user) => (
						<option key={user.value} value={user.name}>
							{user.label}
						</option>
					))}
					;
				</select>
			),
			Cell: (row) => {
				let userss = [...(this.props?.users || [])];
				return (
					<NewAssigneeDropdown
						row={row}
						rowValue={row?.value}
						users={userss}
						updateAssignee={this.updateAssignee}
					/>
				);
			},
			style: {
				overflow: "visible",
				paddingLeft: 10,
				paddingRight: 10,
			},
		},
		// {
		//   Header: "Archive",
		//   accessor: "archived",
		//   filterable: true,
		//   width: 70,
		//   maxWidth: 80,
		//   minWidth: 70,
		//   minResizeWidth: 60,
		//   Cell: (row) => (
		//     <ArchiveButton
		//       row={row}
		//       index={row.index}
		//       key={row.index + "archived"}
		//       updateLeadArchive={this.updateLeadArchive}
		//     />
		//   ),

		//   Filter: ({ filter, onChange }) => (
		//     <select
		//       className='select'
		//       onChange={(event) => {
		//         localStorage.setItem("archiveFilterValue", event.target.value);
		//         onChange(event.target.value);
		//       }}
		//       style={{
		//         width: "100%",
		//         height: 22,
		//         textAlignLast: "left",
		//         fontSize: 10,
		//       }}
		//       key={"archive_filter"}
		//       value={filter ? filter.value : "Un-Archived"}
		//     >
		//       <option value='All'>All</option>
		//       <option value='Archived'>A</option>
		//       <option value='Un-Archived'>UA</option>
		//     </select>
		//   ),
		//   filterMethod: (filter, row) => {
		//     switch (filter.value) {
		//       case "All":
		//         return true;
		//       case "Archived":
		//         return row[filter.id];
		//       case "Un-Archived":
		//         return !row[filter.id];
		//       default:
		//         return true;
		//     }
		//   },
		// },

		{
			Header: "Reminder",
			accessor: "reminder",
			filterable: true,
			resizable: true,
			width: 90,
			maxWidth: 250,
			minWidth: 60,
			minResizeWidth: 60,
			Filter: ({ filter, onChange }) => (
				<select
					className='select'
					onChange={(event) => {
						onChange(event.target.value);
					}}
					style={{
						width: "100%",
						height: 22,
						textAlignLast: "left",
						fontSize: 10,
					}}
					key={"reminder_filter"}
					value={filter ? filter.value : "All"}
				>
					<option value='All'>All</option>
					<option value='Unset'>Unset</option>
					<option value='Set'>Set</option>
				</select>
			),
			filterMethod: (filter, row) => {
				let bol = row._original?.reminderToShow;
				switch (filter.value) {
					case "All":
						return true;
					case "Unset":
						return !bol;
					case "Set":
						return bol;
					default:
						return true;
				}
			},
			Cell: (row) => (
				<Popup
					trigger={
						<div
							style={{
								width: "100%",
								height: "100%",
								display: "flex",
								flexDirection: "row",
								justifyContent: "center",
								alignItems: "center",
							}}
							onClick={async () => {
								if (
									!row.original.assigned_to ||
									(row.original.assigned_to !==
										localStorage.getItem("username") &&
										!row.original.reminderToShow) ||
									(row.original.assigned_to !==
										localStorage.getItem("username") &&
										row.original.reminderToShow?.creator_id !==
											localStorage.getItem("userId") &&
										localStorage.getItem("isAdmin") != "admin")
								) {
									let message;
									if (
										!row.original.assigned_to ||
										row.original.assigned_to == ""
									) {
										message =
											"You are about to assign this lead to yourself. Are you sure?";
									} else {
										message =
											"You are about to re-assign this lead to yourself from " +
											row.original.assigned_to +
											". Are you sure?";
									}
									this.props.confirmAction(
										"Confirmation",
										message,
										() => {
											this.updateAssignee(
												localStorage.getItem("email"),
												row.original._id,
												0
											);
										},
										() => {}
									);
								} else
									this.setState({
										reminderModal: true,
										rowId: row.original._id,
										leadName: row.original.name,
										updateReminder:
											row.original.reminderToShow &&
											row.original.reminderToShow.reminder_status
												? row.original.reminderToShow.reminder_status
												: false,
										reminderId:
											row.original.reminderToShow &&
											row.original.reminderToShow.creator_id
												? row.original.reminderToShow.creator_id
												: "",
										reminderDate:
											row.original.reminderToShow &&
											row.original.reminderToShow.reminder_date
												? row.original.reminderToShow.reminder_date
												: "",
										reminderDescription:
											row.original.reminderToShow &&
											row.original.reminderToShow.reminder_description
												? row.original.reminderToShow.reminder_description
												: "",
										reminderAdmin:
											localStorage.getItem("isAdmin").toLowerCase() ===
												"admin" &&
											row.original.assigned_to !==
												localStorage.getItem("username")
												? true
												: false,
										leadAssignedTo: row.original.assigned_to,
									});
							}}
						>
							{(row.original.assigned_to === localStorage.getItem("username") &&
								row.original.reminderToShow?.creator_id ===
									localStorage.getItem("userId")) ||
							(localStorage.getItem("isAdmin").toLowerCase() === "admin" &&
								row.original.reminderToShow?.created_by &&
								row.original.assigned_to ==
									row.original.reminderToShow?.created_by) ? (
								row.original.temp_color ? (
									<div style={{ position: "relative" }}>
										<img
											src={require("../assets/gif/reminder-dancing.gif")}
											style={{
												width: "36px",
												height: "36px",
												cursor: "pointer",
											}}
										/>
										<img
											src={require("../assets/images/sequence-tickcircle.png")}
											style={{
												position: "absolute",
												right: "0",
												bottom: "0",
												marginBottom: "-4px",
												marginRight: "3px",
												width: "15px",
												height: "15px",
											}}
										/>
									</div>
								) : (
									<img
										src={require("../assets/images/reminder-set.png")}
										style={{
											width: "30px",
											height: "30px",
											cursor: "pointer",
										}}
									/>
								)
							) : (
								<img
									src={require("../assets/images/reminder-unset.png")}
									style={{
										width: "28px",
										height: "28px",
										cursor: "pointer",
									}}
								/>
							)}
						</div>
					}
					on={"hover"}
					position='bottom right'
				>
					<div>
						{row.original.reminderToShow &&
						row.original.reminderToShow?.creator_id ===
							localStorage.getItem("userId") ? (
							row.original?.reminderToShow?.reminder_date.toString() ===
							this.props.today.toString() ? (
								row.original?.reminderToShow?.reminder_description ? (
									<div
										dangerouslySetInnerHTML={{
											__html:
												row.original?.reminderToShow?.reminder_description,
										}}
									></div>
								) : (
									"Edit reminder for this lead"
								)
							) : (
								"Edit reminder for this lead"
							)
						) : (
							"Set reminder for this lead"
						)}
					</div>
				</Popup>
			),
		},
		{
			Header: "Hotlist",
			accessor: "starred_by_user_ids",
			filterable: false,
			width: 90,
			maxWidth: 250,
			minWidth: 60,
			minResizeWidth: 60,
			Cell: (row) => (
				<div
					style={{
						alignItems: "center",
						display: "flex",
						flexDirection: "row",
						justifyContent: "center",
						width: "100%",
					}}
				>
					<Popup
						trigger={
							<div
								style={{
									width: "40%",
									height: "100%",
									position: "relative",
									display: "flex",
									justifyContent: "center",
									position: "relative",
								}}
							>
								<img
									onClick={() =>
										this.updateLeadStar(
											row.original._id,
											row.original.assigned_to,
											row.index,
											row
										)
									}
									style={{
										cursor: "pointer",
										width: "26px",
										height: "26px",
									}}
									src={row.original.starred_by_user_ids ? unstarIcon : starIcon}
								/>
								{row.original.starred_by_user_ids}
							</div>
						}
						on={"hover"}
						position='top right'
					>
						{row.original.starred_by_user_ids > 0
							? "Remove from hotlist."
							: "Add to hotlist."}
					</Popup>

					{/* <EditableTextView
            showAlert={this.props.showAlert}
            row={row}
            columnName="hotlist"
            handleSubmit={this.handleSubmit}
            editableClassName="editableOneLine"
            isClickable={false}
            isEditable={true}
          />  */}
				</div>
			),
		},
		// {
		//   Header: "Category",
		//   accessor: "category",
		//   filterable: true,
		//   resizable: true,
		//   width: 90,
		//   maxWidth: 250,
		//   minWidth: 90,
		//   minResizeWidth: 90,
		//   Filter: ({ filter, onChange }) => (
		//     <select
		//       className='select'
		//       onChange={(event) => {
		//         onChange(event.target.value);
		//       }}
		//       style={{
		//         width: "100%",
		//         height: 22,
		//         textAlignLast: "left",
		//         fontSize: 10,
		//       }}
		//       key={"category_filter"}
		//       value={filter ? filter.value : "All"}
		//     >
		//       <option value='All'>All</option>
		//       {categoriesOptions.map((val, index) => (
		//         <option key={index} value={val.name}>
		//           {val.name}
		//         </option>
		//       ))}
		//     </select>
		//   ),
		//   filterMethod: (filter, row) => {
		//     if (!filter.value || filter.value === "All") return true;
		//     else return filter.value === row._original?.category?.name;
		//   },
		//   Cell: (row) => (
		//     <GeneralDropDown
		//       options={categoriesOptions}
		//       defaultValue={row.original.category}
		//       color={
		//         row.original.assigned_to
		//           ? this.props.users && this.props.users.length !== 0
		//             ? this.props.users.find(
		//                 (item) => item.name === row.original.assigned_to
		//               )
		//               ? this.props.users.find(
		//                   (item) => item.name === row.original.assigned_to
		//                 ).color
		//               : "white"
		//             : "white"
		//           : "white"
		//       }
		//       assigned_to={row.original.assigned_to}
		//       changeFunction={async (selectedOption) => {
		//         try {
		//           const { data, status } = await updateLeadCategory(
		//             row.original._id,
		//             {
		//               category: selectedOption,
		//             }
		//           );
		//           if (status) {
		//             this.props.UpdateLeadAfterAssigningSequence(
		//               row.original._id,
		//               data.data
		//             );
		//             return true;
		//           }
		//         } catch (error) {
		//           return false;
		//         }
		//       }}
		//       row={row}
		//     />
		//   ),
		//   style: { overflow: "visible", paddingLeft: 10, paddingRight: 10 },
		// },
		{
			Header: "Delete",
			accessor: "actions",
			filterable: false,
			width: 90,
			maxWidth: 250,
			minWidth: 60,
			minResizeWidth: 60,
			Cell: (row) => (
				<button
					onClick={() => {
						this.props.confirmAction(
							"Delete Lead",
							"Are you sure you want to delete this lead?",
							() => {
								this.deleteLead(row.original._id, () => {
									this.props.showAlert("Lead has been deleted.");
								});
							},
							() => {}
						);
					}}
					style={{
						backgroundImage: `url(${deleteIcon})`,
						width: 22,
						height: 22,
						marginLeft: 19,
						// marginTop: 20,
						border: "none",
						backgroundRepeat: "no-repeat",
						backgroundColor: "transparent",
						backgroundSize: "contain",
						cursor: "pointer",
					}}
				></button>
			),
		},
		{
			Header: "CallRail Source",
			accessor: "channel",
			resizable: true,
			width: 180,
			maxWidth: 200,
			minWidth: 100,
			minResizeWidth: 100,

			Cell: (row) => (
				<div className='call-rail-select-cont'>
					<span style={{ alignSelf: "center", color: "black" }}>
						{row.value == "" ? "Default" : capitalizeFirstLetter(row.value)}
					</span>
					<p className='call-rail-source-value'>
						{row.value == "" ? "Default" : row?.original?.channel_source}
					</p>
					{/* <Form>
            <Form.Group className="mb-3">
              <select
                className="form-control web-sources-drop-down"
                style={{ height: '34px' }}
                // onChange={this.handleCllrailChannelChange}
                key={"time_filter" + "source"}
                value={"row.value"}

              // onChange={(e) => {
              //   setSource(e.target.value);
              // }}
              >
                <option disabled style={{ fontSize: 13 }}
                  value="rpc">
                  RPC            </option>
                <option disabled style={{ fontSize: 13 }}
                  value="organic">
                  Organic</option>
                <option disabled style={{ fontSize: 13 }}
                  value="direct">
                  Direct            </option>
              </select>
            </Form.Group>
          </Form> */}
				</div>

				// this.getLeadCardDateFormat(row.value)
			),
			filterMethod: (filter, row) => {
				if (row[filter.id])
					return row[filter.id]
						.toLowerCase()
						.includes(filter?.value?.toLowerCase());
			},
			Filter: ({ filter, onChange }) => (
				<input
					style={{
						backgroundColor: "#9399a4",
						color: "#e4e4ea",
						fontSize: 11,
						width: "100%",
					}}
					onChange={(e) => {
						onChange(e.target.value.trim());
						// this.setState({
						//   searchChannel: e.target.value.trim(),
						// });
						// setTimeout(()=>{
						//   this.searchLead();
						// }, 1000);
					}}
				/>
			),
		},
	];
	//table end

	state = {
		pageSize: 30,
		selectedRowIndex: -1,
		isLoading: false,
		filterModal: false,
		timeCompleted: true,
		TotalNumberOfPages: 0,
		isFiltered: false,
		pageNum: 0,
		showSnoozeModal: false,
		searchInProgress: false,
		sourceValue: "dummy",
		renderFilter: false,
		filteredLeadsData: [],
		/** for search query */
		// searchText: "",
		// searchName: "",
		// searchSource:  "",
		// searchChannel: "",
		// searchEmail: "",
		// searchAddress: "",
		// searchPhoneNumber: "",
		// searchSvcRequested: "",
		// searchNotes: "",
		// searchExtraLinks: "",

		tempLeadsData: null,
		dataToDisplay: [],

		//===========
		showStartSequenceModal: false,
		showSequenceDetailsModal: false,
		showPauseSequenceModal: false,
		showPauseSequenceDetailsModal: false,
		showPauseSequenceModalSms: false,
		unPauseSequenceModal: false,
		showUpdateDatePopup: false,
		rowId: null,
		email: true,
		sequenceId: null,
		totalTemplates: null,
		startDate: null,
		leadId: null,
		pause: false,
		unPauseId: null,
		showPauseButton: true,
		selectedSequence: {},
		firstTemplateDate: null,
		secondTemplateDate: null,
		thirdTemplateDate: null,
		//==== Reminder ======
		reminderModal: false,
		updateReminder: false,
		leadName: "",
		reminderId: "",
		reminderDate: "",
		reminderDescription: "",
		todayDate: "",
		reminderAdmin: false,
		leadAssignedTo: "",
		updatingAssigned: false,
		isInEditMode: false,
		showCategoryModal: false,
		row: {},
		rowIdForAssigneeDropdown: null,
		openAssigneeDropdown: false,
		selectedEmails: [],
		selectAll: false,
	};

	reOrderAndResizeColumns = (order, columnSize, columns) => {
		let orders = JSON.parse(order);
		for (let i = 0; i < orders.length; i++) {
			const index = columns.findIndex((item) => item.accessor === orders[i]);
			if ((index) => 0) {
				columns = arrayMove(columns, index, i);
			}
		}

		for (let i = 0; i < columnSize.length; i++) {
			const index = columns.findIndex(
				(item) => item.accessor === columnSize[i].column
			);
			if ((index) => 0) {
				if (
					columnSize[i].size != null &&
					columnSize[i].size !== "" &&
					columns[index] !== undefined
				)
					columns[index].width = Number.parseInt(columnSize[i].size);
			}
		}

		//
		return columns;
	};
	// eslint-disable-next-line no-useless-constructor
	constructor(props) {
		super(props);
		this.users = props.users;
		this.myRef = React.createRef();
		this.firstColumns = this.reOrderAndResizeColumns(
			this.props.columnsOrder,
			this.props.columnsSize,
			this.firstColumns
		);
		localStorage.setItem("archiveFilterValue", "Un-Archived");
		localStorage.setItem("starFilterValue", "star");
		localStorage.setItem("timeFilterValue", "All");
	}

	componentWillMount() {
		if (this.state.tempLeadsData == null) {
			this.setState({
				tempLeadsData: this.props.tempData,
			});
		}
	}
	selectAllCheckbox() {
		return (
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					flexDirection: "column",
					marginTop: 30,
					backgroundColor: "red",
				}}
			>
				<input
					className='checkbox-input1'
					style={{ transform: "scale(2.5)", height: 80 }}
					type='checkbox'
					checked={this.state.selectAll}
					onChange={this.handleSelectAll}
				/>
			</div>
		);
	}
	handleSelectAll = (e) => {
		let filteredLeads = [];
		console.log("this.filteredValue", this.filteredValue);
		if (this.filteredValue === "All") {
			filteredLeads = this.props.leads;
		} else {
			filteredLeads = this.props.leads.filter((lead) => {
				return lead.assigned_to == this.filteredValue;
			});
		}
		// console.log("filteredLleads", filteredLeads);
		// trimmedLeads = this.trimArray(filteredLeads);
		const { checked } = e.target;
		const allEmails = filteredLeads.map((lead, index) => ({
			name: lead.name,
			email: lead.email,
			id: lead._id,
			index: index,
		}));
		console.log("allEmails", allEmails);
		this.setState({
			selectAll: checked,
			selectedEmails: checked ? allEmails : [],
		});
	};

	handleCheckboxChange = (e, name, email, id) => {
		const { checked } = e.target;
		console.log("Checkbox clicked:", checked, "Name:", name, "Email:", email);

		if (checked) {
			// Add the selected email to the state
			this.setState(
				(prevState) => ({
					selectedEmails: [
						...(prevState?.selectedEmails || []),
						{ name, email, id },
					],
				}),
				() => {
					console.log("Selected emails:", this.state.selectedEmails);
				}
			);
		} else {
			// Remove the deselected email from the state
			this.setState(
				(prevState) => ({
					selectedEmails: (prevState?.selectedEmails || []).filter(
						(selectedEmail) =>
							!(selectedEmail.name === name && selectedEmail.email === email)
					),
				}),
				() => {
					console.log("Selected emails:", this.state.selectedEmails);
				}
			);
		}
	};
	handleSubmit = (key, oldValue, value, leadID) => {
		//
		if (oldValue === value) {
			return;
		}

		//
		//
		axios
			.put(
				baseURL() + "/api/v1/leads/" + leadID,
				{
					[key]: value,
				},
				{
					headers: {
						"x-access-token": localStorage.getItem("jwtToken"),
						"app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
						socket_id: localStorage.getItem("socket_id"),
					},
				}
			)
			.then((res) => {
				this.props.allowToUpdate();
				this.props.updateDataByID(leadID, res.data.data);
				// this.props.showAlert("Changes Saved");
			})
			.catch((error) => {
				this.setState({
					isInProgress: false,
				});
				this.props.showAlert("Failed to save changes, please try again");
			});
	};
	handleCopy = (str) => {
		if (str != "") {
			function listener(e) {
				e.clipboardData.setData("text/html", str);
				e.clipboardData.setData("text/plain", str);
				e.preventDefault();
			}
			document.addEventListener("copy", listener);
			document.execCommand("copy");
			document.removeEventListener("copy", listener);
			// var strippedHtml = text.replace(/<[^>]+>/g, '');
			// copy(strippedHtml);
			this.props.showAlert("Copied to clipboard", 1000);
		}
	};
	saveNote = (key, oldValue, value, leadID) => {
		//
		if (oldValue === value) {
			return;
		}

		axios
			.post(
				baseURL() + "/api/v1/leads/note",
				{
					lead_id: leadID,
					added_by: "Web Portal",
					text: value,
				},
				{
					headers: {
						"x-access-token": localStorage.getItem("jwtToken"),
						"app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
						socket_id: localStorage.getItem("socket_id"),
					},
				}
			)
			.then((res) => {
				this.props.allowToUpdate();
				this.props.updateDataByID(leadID, res.data.data);
			})
			.catch((error) => {
				this.setState({
					isInProgress: false,
				});
				this.props.showAlert("Failed to save changes, please try again");
			});
	};
	clearChecks = () => {
		this.setState({ selectedEmails: [], selectAll: false });
	};
	handleOpenFollowUpMode = () => {
		this.setState({ showAutoFollowUpModal: true });
	};
	handleSetTitle = (val) => {
		this.setState({ multiEmailSmsTitle: val });
	};
	handleCloseFollowUpMode = () => {
		this.setState({ showAutoFollowUpModal: false });
	};
	deleteLead = (id, callback) => {
		//
		axios
			.delete(baseURL() + "/api/v1/leads/" + id, {
				headers: {
					"x-access-token": localStorage.getItem("jwtToken"),
					"app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
					socket_id: localStorage.getItem("socket_id"),
				},
			})
			.then((res) => {
				// this.props.allowToUpdate();
				// this.props.deleteLead(id);
				callback();
			})
			.catch((error) => {
				this.setState({
					isInProgress: false,
				});
				this.props.showAlert("Failed to save changes, please try again");
			});
	};
	handleCloseExport = () => {
		this.setState({ isExported: false });
		// this.props.removeFilterData();
		// this.props.addFilterTrigger(0);
	};

	saveAutoMessage = (message, emailAttachment, test_receiver) => {
		console.log("saveAutoMessage", message, emailAttachment);
		this.myMessage = message;
		this.emailAttachment = emailAttachment;
		this.test_receiver = test_receiver;
		// this.setState({ autoFollowUpMessage: message })
	};

	autoFollowUpApiCallForBulk = async (id = null, type) => {
		console.log(
			"bulk email",
			this.state.selectedEmails.length,
			this.myMessage,
			this.emailAttachment,
			type
		);
		// const idsArray = this.state.selectedEmails.map((item) => item.id);
		const leadIDsSet = new Set(
			this.state.selectedEmails.map((item) => item.id)
		);
		const idsArray = [...leadIDsSet];

		const leadEmailsSet = new Set(
			this.state.selectedEmails.map((item) => item.email)
		);
		const emailArray = [...leadEmailsSet];
		console.log("idss array", idsArray);
		this.props.showProgressAlert(`Sending ${type} in progress`);
		// this.props.onPressContactLead();
		await axios
			.post(
				baseURL() + "/api/v1/leads/auto-followup-bulk",
				{
					ids: idsArray,
					emails: emailArray,
					via: type,
					message: this.myMessage,
					attachment: this.emailAttachment.file_name
						? this.emailAttachment
						: null,
				},
				{
					// timeout: 40000,
					headers: {
						"x-access-token": localStorage.getItem("jwtToken"),
						"app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
						socket_id: localStorage.getItem("socket_id"),
					},
				}
			)
			.then((res) => {
				this.props.showAlert("Successfully sent", 500);

				this.props.allowToUpdate();

				this.clearChecks();
			})
			.catch((error) => {
				console.log(error);
				this.setState({
					isInProgress: false,
				});
				this.props.updateLeadAfterAutoFollowup(id, null);
				this.props.showAlert("Failed to save changes, please try again");
			});
	};
	autoFollowUpApiCall = async (id, type) => {
		console.log("emailAttachment", this.emailAttachment);
		this.props.onPressContactLead();
		await axios
			.post(
				baseURL() + "/api/v1/leads/auto-followup",
				{
					lead_id: id,
					via: type,
					message: this.myMessage,
					attachment: this.emailAttachment.file_name
						? this.emailAttachment
						: null,
					test_receiver: this.test_receiver ? this.test_receiver : null,
				},
				{
					timeout: 25000,
					headers: {
						"x-access-token": localStorage.getItem("jwtToken"),
						"app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
						socket_id: localStorage.getItem("socket_id"),
					},
				}
			)
			.then((res) => {
				if (!res.data.success) {
					this.props.showAlert(res.data.message);
					this.props.updateLeadAfterAutoFollowup(id, null);
					return;
				}
				this.props.allowToUpdate();
				// var currentUser = this.props.users.find(x=>x.id == res.data.data.assign_to_id)
				// this.assigneeDropDown.onUpdateAssignee(currentUser)
				this.props.updateLeadAfterAutoFollowup(id, res.data.data, type);
			})
			.catch((error) => {
				console.log(error);
				this.setState({
					isInProgress: false,
				});
				this.props.updateLeadAfterAutoFollowup(id, null);
				this.props.showAlert("Failed to save changes, please try again");
			});
	};

	confirmAction = (title, message, yesCallback, lead) => {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<FollowUpModal
						title={title == "phone" ? "SMS" : title}
						message={message}
						lead={lead}
						leadName={lead.name}
						show={true}
						handleYes={yesCallback}
						handleNo={onClose}
						autoSave={this.saveAutoMessage}
						showAlert={this.props.showAlert}
						sendReferralReview={this.sendReferral}
					/>
				);
			},
			// title: title,
			// message: message,
			// buttons: [
			//   {
			//     label: "Yes",
			//     onClick: () => yesCallback(),
			//   },
			//   {
			//     label: "No",
			//     onClick: () => noCallback(),
			//   },
			// ],
		});
	};

	//contact Lead function for canned messages
	contactLead = (id, type, email, index, callback) => {
		console.log("send email called", type);
		let messageToshow = "";
		var lead = this.props.leads.find((x) => x._id == id);

		if (type == "email") {
			//for email
			messageToshow = "Are you sure you want to send canned email?";
			// if (lead.email_auto_contact_count > 0) {
			//   this.props.showAlert("Canned email already sent");
			//   return;
			// }
			if (lead.email == "" || lead.email == "N/A") {
				this.props.showAlert("Invalid email.");
				return;
			}
		} else if (type == "phone") {
			//for phone
			messageToshow = "Are you sure you want to send canned sms?";
			// if (lead.auto_contact_count > 0) {
			//   this.props.showAlert("Canned sms already sent");
			//   return;
			// }
			if (
				lead.phone_number == "" ||
				lead.phone_number == "N/A" ||
				lead.phone_number.includes(".")
			) {
				this.props.showAlert("Invalid phone number.");
				return;
			}
		} else if (type == "review") {
			//for phone
			//messageToshow = "Are you sure you want to send canned sms?";
			if (lead.auto_review_count > 0 && lead.auto_referral_count > 0) {
				this.props.showAlert("Reveiw and Referral messages already sent");
				return;
			}
		}

		if (type == "phone") {
			var date = new Date(); //calculateZoneSpecificTime(-6)  //-5 central standard time
			var timeInMinutes =
				date.getHours() * 60 + date.getMinutes() + date.getSeconds() / 60;
			// if time in not between 9-5 and lead is auto contacted return disabled

			if (timeInMinutes < 420 || timeInMinutes > 1080) {
				this.props.showAlert(
					"Canned sms/Review can only be sent between 7am to 6pm CST"
				);
				return;
			}
		}

		if (
			email != null &&
			localStorage.getItem("username") != email &&
			localStorage.getItem("isAdmin") != "admin"
		) {
			let message =
				"You are about to re-assign this lead to yourself from " +
				email +
				". Are you sure?";
			if (!email || email == "") {
				message =
					"You are about to assign this lead to yourself. Are you sure?";
			}
			console.log("role", localStorage.getItem("isAdmin"));
			this.props.confirmAction(
				"Confirmation",
				message,
				() => {
					this.updateAssignee(localStorage.getItem("email"), id, index);
				},
				() => {}
			);
		} else {
			if (type == "review") {
				this.props.confirmReviewAction(
					lead,
					"Contact Lead",
					messageToshow,
					() => {},
					async (type, test_receiver) => {
						this.props.onPressContactLead();
						await axios
							.post(
								baseURL() + "/api/v1/leads/auto-followup",
								{
									lead_id: id,
									via: type,
									test_receiver: test_receiver ? test_receiver : null,
								},
								{
									timeout: 25000,
									headers: {
										"x-access-token": localStorage.getItem("jwtToken"),
										"app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
										socket_id: localStorage.getItem("socket_id"),
									},
								}
							)
							.then((res) => {
								if (!res.data.success) {
									this.props.showAlert(res.data.message);
									this.props.updateLeadAfterAutoFollowup(id, null);
									return;
								}
								this.props.allowToUpdate();
								// var currentUser = this.props.users.find(x=>x.id == res.data.data.assign_to_id)
								// this.assigneeDropDown.onUpdateAssignee(currentUser)
								this.props.updateLeadAfterAutoFollowup(id, res.data.data, type);
							})
							.catch((error) => {
								console.log("[ContactLead]:", error);
								this.setState({
									isInProgress: false,
								});
								this.props.updateLeadAfterAutoFollowup(id, null);
								this.props.showAlert(
									"Failed to save changes, please try again"
								);
							});
					}
				);
			} else {
				this.confirmAction(
					type,
					messageToshow,
					() => this.autoFollowUpApiCall(id, type),
					lead
				);

				return;

				console.log("typee==>", type);
				this.props.confirmAction(
					"Contact Lead",

					messageToshow,

					async () => {
						this.props.onPressContactLead();
						await axios
							.post(
								baseURL() + "/api/v1/leads/auto-followup",
								{
									lead_id: id,
									via: type,
								},
								{
									timeout: 5000,
									headers: {
										"x-access-token": localStorage.getItem("jwtToken"),
										"app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
										socket_id: localStorage.getItem("socket_id"),
									},
								}
							)
							.then((res) => {
								if (!res.data.success) {
									this.props.showAlert(res.data.message);
									this.props.updateLeadAfterAutoFollowup(id, null);
									return;
								}
								this.props.allowToUpdate();
								// var currentUser = this.props.users.find(x=>x.id == res.data.data.assign_to_id)
								// this.assigneeDropDown.onUpdateAssignee(currentUser)
								this.props.updateLeadAfterAutoFollowup(id, res.data.data, type);
							})
							.catch((error) => {
								console.log(error);
								this.setState({
									isInProgress: false,
								});
								this.props.updateLeadAfterAutoFollowup(id, null);
								this.props.showAlert(
									"Failed to save changes, please try again"
								);
							});
					},

					() => {}
				);
			}
		}
	};

	sendReferral = async (id, type, test_receiver) => {
		try {
			this.props.onPressContactLead();
			const res = await sendReferralReview({
				lead_id: id,
				via: type,
				test_receiver: test_receiver ? test_receiver : null,
			});

			if (!res.data.success) {
				this.props.showAlert(res.data.message);
				this.props.updateLeadAfterAutoFollowup(id, null);
				return;
			}
			this.props.allowToUpdate();
			this.props.updateLeadAfterAutoFollowup(id, res.data.data, type);
			return { response: true };
		} catch (error) {
			console.log("[Error]:", error);
			this.setState({
				isInProgress: false,
			});
			this.props.updateLeadAfterAutoFollowup(id, null);
			this.props.showAlert("Failed to save changes, please try again");
			return { response: true };
		}
	};

	showLoader = () => {
		//
		//  this.props.setLoadingStatus(true);
	};
	shouldComponentUpdate(nextProps, nextState) {
		return this.props.isDataUpdated();
	}
	// handleOpenFilterModal = () => {
	// 	this.setState({
	// 		filterModal: true,
	// 	});
	// };
	// handleCloseFilterModal = () => {
	// 	this.setState({
	// 		filterModal: false,
	// 	});
	// };
	// applyFilters = (leads, filters) => {
	// 	return leads.filter((lead) => {
	// 		return filters.every((filter) => {
	// 			if (filter.name === "Assignee") {
	// 				return filter.subCategories.includes(lead.assigned_to);
	// 			}
	// 			if (filter.name === "Category") {
	// 				return filter.subCategories.includes(lead.category.name);
	// 			}
	// 			return true;
	// 		});
	// 	});
	// };
	// handleClearFilters = () => {
	// 	this.setState({
	// 		renderFilter: false,
	// 	});
	// };
	// handleApplyFilters = (filters) => {
	// 	console.log("Applied Filters:", filters);
	// 	const newFilteredLeads = this.applyFilters(
	// 		this.state.dataToDisplay,
	// 		filters
	// 	);
	// 	this.setState({
	// 		filteredLeadsData: newFilteredLeads,
	// 		renderFilter: true,
	// 	});
	// 	console.log("filteredLeads:", newFilteredLeads);

	// 	// this.handleCloseModal();
	// };

	onExportCustomersClick = () => {
		this.dataUpdated = true;
		let temp = this.state.selectedTabIndex === 0 ? 95 : 170;
		this.setState({ isExported: true });
		if (this.state.showExportCustomersView) {
			this.props.allowToUpdate();
			this.setState({
				innerAvailableHeight: this.state.innerAvailableHeight + temp,
			});
		} else {
			this.props.allowToUpdate();
			this.setState({
				innerAvailableHeight: this.state.innerAvailableHeight - temp,
			});
		}
		this.setState({
			showExportCustomersView: !this.state.showExportCustomersView,
		});
	};

	onExportCustomersCancelButtonClick = () => {
		this.props.allowToUpdate();
		this.setState({ isExported: false });
		// this.props.addFilterTrigger(0);
		// this.props.removeFilterData();
	};

	updateAssignee = (assign_to, lead_id, index) => {
		console.log("payload", assign_to, lead_id);
		// this.props.allowToUpdate();

		axios
			.post(
				baseURL() + "/api/v1/leads/assign-lead/",
				{
					lead_id: lead_id,
					assign_to: assign_to,
				},
				{
					headers: {
						"x-access-token": localStorage.getItem("jwtToken"),
						"app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
						socket_id: localStorage.getItem("socket_id"),
					},
				}
			)
			.then((res) => {
				console.log("Assignee response", res);
				// this.props.allowToUpdate();
				// this.props.updateData(lead_id, res.data.data, true);
				this.setState({
					updatingAssigned: false,
				});
				// this.props.updateAssigne(this.selectedRowIndex,assign_to);
			})
			.catch((error) => {});
	};
	updateBidStatus = (lead_id, bid_status, assigned_by, index) => {
		axios
			.post(
				baseURL() + "/api/v1/leads/bid-status/",
				{
					lead_id: lead_id,
					bid_status: bid_status,
					assigned_by: assigned_by,
				},
				{
					headers: {
						"x-access-token": localStorage.getItem("jwtToken"),
						"app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
						socket_id: localStorage.getItem("socket_id"),
					},
				}
			)
			.then((res) => {
				this.props.allowToUpdate();
				this.props.updateData(lead_id, res.data.data);
			})
			.catch((error) => {});
	};
	updateServiceFee = (lead_id, service_fee, assigned_by, index) => {
		axios
			.post(
				baseURL() + "/api/v1/leads/service-fee/",
				{
					lead_id: lead_id,
					service_fee: service_fee,
					assigned_by: assigned_by,
				},
				{
					headers: {
						"x-access-token": localStorage.getItem("jwtToken"),
						"app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
						socket_id: localStorage.getItem("socket_id"),
					},
				}
			)
			.then((res) => {
				this.props.allowToUpdate();
				this.props.updateData(lead_id, res.data.data);
			})
			.catch((error) => {});
	};
	updateLeadStatus = (lead_id, lead_status, index) => {
		axios
			.post(
				baseURL() + "/api/v1/leads/update-lead-status/",
				{
					lead_id: lead_id,
					lead_status: lead_status,
				},
				{
					headers: {
						"x-access-token": localStorage.getItem("jwtToken"),
						"app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
						socket_id: localStorage.getItem("socket_id"),
					},
				}
			)
			.then((res) => {
				this.props.allowToUpdate();
				this.props.updateData(lead_id, res.data.data);
			})
			.catch((error) => {});
	};

	updateLeadStar = (id, email, index, row) => {
		var lead = this.props.leads.find((x) => x._id == id);
		//Aassiging the lead
		// if (
		// 	email != null &&
		// 	localStorage.getItem("username") != email &&
		// 	localStorage.getItem("isAdmin") != "admin"
		// ) {
		// 	let message =
		// 		"You are about to re-assign this lead to yourself from " +
		// 		email +
		// 		". Are you sure?";
		// 	if (!email || email == "") {
		// 		message = "You are about to assign this lead to yourself?";
		// 	}
		// 	this.props.confirmAction(
		// 		"Confirmation",
		// 		message,
		// 		() => {
		// 			this.updateAssignee(
		// 				localStorage.getItem("email"),
		// 				id,
		// 				index
		// 			);
		// 		},
		// 		() => {}
		// 	);
		// } else {
		// Asked for permission
		if (lead.starred_by_user_ids) {
			// let message = "Are you sure you want to remove this lead from Hotlist?";

			// this.props.confirmAction(
			//   "Confirmation",
			//   message,
			//   () => {
			axios
				.post(
					baseURL() + "/api/v1/leads/update-starred-status/",
					{
						lead_id: row.original._id,
						star_by: localStorage.getItem("username"),
					},
					{
						headers: {
							"x-access-token": localStorage.getItem("jwtToken"),
							"app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',

							socket_id: localStorage.getItem("socket_id"),
						},
					}
				)

				.then((res) => {
					// console.log(row);
					console.log("Lead star Updated step 2");
					// this.props.allowToUpdate();
					// this.props.updateData(
					//   this.selectedLead._id,
					//   res.data.data,
					//   true,
					//   () => {}
					// );
				})

				.catch((error) => {});
			// },
			// () => {}
			// );
		} else {
			// let message = "Are you sure you want to add this lead to Hotlist? ";
			// this.props.confirmAction(
			//   "Confirmation",
			//   message,
			//   () => {
			axios
				.post(
					baseURL() + "/api/v1/leads/update-starred-status/",
					{
						lead_id: row.original._id,
						star_by: localStorage.getItem("username"),
					},
					{
						headers: {
							"x-access-token": localStorage.getItem("jwtToken"),
							"app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',

							socket_id: localStorage.getItem("socket_id"),
						},
					}
				)

				.then((res) => {
					// console.log(row);
					console.log("Lead star Updated step 2");
					// this.props.allowToUpdate();
					// this.props.updateData(
					//   this.selectedLead._id,
					//   res.data.data,
					//   true,
					//   () => {}
					// );
				})

				.catch((error) => {});
			//   },
			//   () => {}
			// );
		}
	};

	updateLeadArchive = (index, row) => {
		console.log("update lead Archive front 1");
		this.selectedLead = row.original;
		axios
			.post(
				baseURL() + "/api/v1/leads/archive-status/",
				{
					lead_id: this.selectedLead._id,
					archived_by: localStorage.getItem("username"),
				},
				{
					headers: {
						"x-access-token": localStorage.getItem("jwtToken"),
						"app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
						socket_id: localStorage.getItem("socket_id"),
					},
				}
			)
			.then((res) => {
				console.log("Lead Updated step 2");
				this.props.allowToUpdate();
				this.props.updateData(
					this.selectedLead._id,
					res.data.data,
					true,
					() => {}
				);
			})
			.catch((error) => {});
	};
	snoozeLead = (index, row, endDate, option) => {
		this.selectedLead = row.original;
		axios
			.post(
				baseURL() + "/api/v1/leads/snooze-lead/",
				{
					lead_id: this.selectedLead._id,
					snoozed_by: localStorage.getItem("username"),
					end_time: endDate,
					option: option,
				},
				{
					headers: {
						"x-access-token": localStorage.getItem("jwtToken"),
						"app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
						socket_id: localStorage.getItem("socket_id"),
					},
				}
			)
			.then((res) => {
				this.props.allowToUpdate();
				this.props.deleteLead(this.selectedLead._id);
				this.props.showAlert("Lead has been snoozed.");
			})
			.catch((error) => {});
	};

	searchLead = debounce((val) => {
		// Search locally for leads
		const localLeads = this.searchLeads(val);
		console.log("Local search result", localLeads);

		// Process local leads similarly to API response
		const processLeads = (leads) => {
			// Apply the same filtering or processing to the local leads as you would to API results
			const filteredLeads = secretUserLeadFilter(leads);
			if (filteredLeads.length === 0 && val.trim() !== "") {
				filteredLeads.length = 1; // Ensure at least one result
			}
			return filteredLeads;
		};

		// If no leads are found locally, proceed with API call
		if (localLeads.length === 0) {
			if (val && val.toString().trim().length > 0) {
				console.log("search api call");
				// Cancel any ongoing requests
				if (this.source) {
					this.source.cancel("Operation canceled due to new request.");
				}

				// Create a new CancelToken source
				this.source = axios.CancelToken.source();

				this.setState({ searchInProgress: true });

				axios
					.post(
						baseURL() + "/api/v1/leads/searchLead",
						{
							search_text: val,
						},
						{
							headers: {
								"x-access-token": localStorage.getItem("jwtToken"),
								"app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
								socket_id: localStorage.getItem("socket_id"),
							},
							cancelToken: this.source.token,
						}
					)
					.then((response) => {
						const apiLeads = response.data.message;
						const filteredApiLeads = processLeads(apiLeads);
						this.setState({ searchInProgress: false });
						this.props.allowToUpdate();
						this.props.updateSearchData(filteredApiLeads, val, true, () => {});
					})
					.catch((error) => {
						if (axios.isCancel(error)) {
							console.log("Request canceled", error.message);
						} else {
							console.log("Search failed", error);
						}
						this.setState({ searchInProgress: false });
					});
			} else {
				console.log("Not Searchable", this.state.tempLeadsData.length);
				this.props.allowToUpdate();
				this.props.updateSearchData(
					this.state.tempLeadsData,
					val,
					true,
					() => {}
				);
			}
		} else {
			// Process local leads similarly to API results
			const filteredLocalLeads = processLeads(localLeads);
			this.props.updateSearchData(filteredLocalLeads, val, true, () => {});
		}
	}, 400);

	searchLeads = (searchText) => {
		console.log("Leads", this.props.leads);
		const leads = this.props.leads;
		// let matchedLeads = [];
		const regex = new RegExp(searchText, "i"); // Create a case-insensitive regular expression

		return leads
			.filter((lead) => {
				// Check all conditions
				return (
					(lead.name && regex.test(lead.name)) ||
					(lead.source && regex.test(lead.source)) ||
					(lead.channel && regex.test(lead.channel)) ||
					(lead.email && regex.test(lead.email)) ||
					(lead.address && regex.test(lead.address)) ||
					(lead.category &&
						lead.category.name &&
						regex.test(lead.category.name)) ||
					(lead.phone_number && regex.test(lead.phone_number)) ||
					(lead.stripped_phone_number &&
						regex.test(lead.stripped_phone_number)) ||
					(lead.svc_requested && regex.test(lead.svc_requested)) ||
					(lead.notes &&
						lead.notes.some(
							(noteObj) => noteObj.note && regex.test(noteObj.note)
						)) ||
					(lead.extra_links && regex.test(lead.extra_links))
				);
			})
			.sort((a, b) => b._id - a._id) // Sort by _id in descending order
			.slice(0, 100); // Limit to 100 results
	};

	// searchLead = debounce((val) => {
	// 	// console.log("name @ searchLead: " + val);
	// 	if (val && val.toString().trim().length > 0) {
	// 		source && source.cancel("Operation canceled due to new request.");
	// 		// save the new request for cancellation
	// 		source = axios.CancelToken.source();

	// 		// console.log("Do Search");
	// 		this.setState({ searchInProgress: true });
	// 		// set loader
	// 		axios
	// 			.post(
	// 				baseURL() + "/api/v1/leads/searchLead",
	// 				{
	// 					search_text: val,
	// 				},
	// 				{
	// 					headers: {
	// 						"x-access-token": localStorage.getItem("jwtToken"),
	// 						"app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
	// 						socket_id: localStorage.getItem("socket_id"),
	// 					},
	// 					cancelToken: source.token,
	// 				}
	// 			)
	// 			.then((res) => {
	// 				// console.log("TL = " + res.data.message.length);
	// 				const filteredLeads = secretUserLeadFilter(
	// 					res.data.message
	// 				);
	// 				if (
	// 					filteredLeads &&
	// 					filteredLeads.length <= 0 &&
	// 					val.trim() != ""
	// 				) {
	// 					filteredLeads.length = 1;
	// 				}
	// 				this.setState({ searchInProgress: false });
	// 				this.props.allowToUpdate();
	// 				// const filteredLeads = secretUserLeadFilter(
	// 				// 	res.data.message
	// 				// );
	// 				this.props.updateSearchData(
	// 					filteredLeads,
	// 					val,
	// 					true,
	// 					() => {}
	// 				);
	// 			})
	// 			.catch((error) => {
	// 				console.log("search failed");
	// 				this.setState({
	// 					isInProgress: false,
	// 				});
	// 			});
	// 	} else {
	// 		// if(val == ""){}
	// 		// console.log(this.state.tempLeadsData);
	// 		console.log("Not Searchable" + this.state.tempLeadsData.length);
	// 		this.props.allowToUpdate();
	// 		this.props.updateSearchData(
	// 			this.state.tempLeadsData,
	// 			val,
	// 			true,
	// 			() => {}
	// 		);
	// 	}
	// }, 400);

	getLeadCardDateFormat = (date) => {
		let d = new Date(date);
		d = calculateZoneSpecificTime(-6, d);
		return dateFormat(new Date(d), "mm/dd/yy h:MM TT");
	};
	onTabClick = (index) => {
		//
		if (index !== this.state.selectedTabIndex)
			this.setState({ selectedTabIndex: index });
	};
	oncolumnsdragged = (columns) => {
		axios
			.post(
				baseURL() + "/api/v1/users/updateUserWebViewColumnOrder",
				{
					email: localStorage.getItem("email"),
					columnsOrder: this.getNewOrderofColumns(columns),
				},
				{
					headers: {
						"x-access-token": localStorage.getItem("jwtToken"),
						"app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
						socket_id: localStorage.getItem("socket_id"),
					},
				}
			)
			.then((res) => {})
			.catch((error) => {});
	};
	getNewOrderofColumns = (columns) => {
		var cols = [];
		for (var i = 0; i < columns.length; i++) {
			cols.push(columns[i].accessor);
		}
		return cols;
	};
	updateColumnSize = debounce((data) => {
		//
		//
		//

		axios
			.post(
				baseURL() + "/api/v1/users/updateUserWebViewColumnSize",
				{
					email: localStorage.getItem("email"),
					column: data[0].id,
					size: data[0].value,
				},
				{
					headers: {
						"x-access-token": localStorage.getItem("jwtToken"),
						"app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
						socket_id: localStorage.getItem("socket_id"),
					},
				}
			)
			.then((res) => {})
			.catch((error) => {});
	}, 2000);

	refreshData = () => {};
	handleFilteredChange = (filters) => {
		// this.props.addFilterTrigger(1);
		console.log("filteredData", filters);
		this.filteredData = [];
		this.filtersArray = filters;
		// console.log("filteredData", filteredData);

		// Update your state with the filtered or processed data
	};
	async fetchData() {
		const newData1 = await updateReminderLead(
			this.props.leads,
			this.props.users
		);
		this.setState({
			dataToDisplay: newData1,
		});
	}

	componentDidMount() {
		if (this.props.leads.length > 0) {
			const { innerRef } = this.props;
			if (innerRef) {
				innerRef(this);
			}
			this.fetchData();
		} else {
			this.setState({ dataToDisplay: [] });
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.leads !== this.props.leads) {
			this.fetchData();
		}
	}

	// componentDidUpdate(prevProps, prevState) {
	// 	const updateData1 = async () => {
	// 		const newData1 = await updateReminderLead(
	// 			this.props.leads,
	// 			this.props.users
	// 		);
	// 		this.setState({
	// 			dataToDisplay: newData1,
	// 		});
	// 	};
	// 	if (prevProps.leads !== this.props.leads) {
	// 		updateData1();
	// 	}
	// }
	// componentDidMount() {
	// 	if (this.props.leads.length > 0) {
	// 		const { innerRef } = this.props;
	// 		if (innerRef) {
	// 			innerRef(this);
	// 		}

	// 		const updateData1 = async () => {
	// 			const newData1 = await updateReminderLead(
	// 				this.props.leads,
	// 				this.props.users
	// 			);
	// 			this.setState({
	// 				dataToDisplay: newData1,
	// 			});
	// 		};
	// 		if (this.props.leads && this.props.leads.length !== 0)
	// 			updateData1();
	// 	} else this.setState({ dataToDisplay: [] });
	// }
	render() {
		let displayableColumns = [];
		if (this.props.renderFilter) {
			displayableColumns.push(this.selectColumn);
		}
		this.firstColumns.forEach((element) => {
			if (
				element.accessor == "created_at" ||
				element.accessor == "actions" ||
				element.accessor == "bid_status" ||
				element.accessor == "channel" ||
				element.accessor == "category" ||
				element.accessor == "reminder"
			) {
				displayableColumns.push(element);
			} else if (
				this.props.selectedColumns.find((e) => e == element.accessor) != null
			) {
				displayableColumns.push(element);
			}
		});
		// const FilterLeadss = async () => {
		//   const LeadsToDisplay = await updateReminderLead(this.props.leads);
		//   return LeadsToDisplay;
		// };
		// FilterLeadss();
		return (
			<React.Fragment>
				<div style={{ marginTop: 0, backgroundColor: "white" }}>
					{this.state.timeCompleted ? (
						<Suspense fallback={<div>Loading...</div>}>
							<div style={{ height: "100%", overflowY: "hidden" }}>
								{this.state.searchInProgress ? (
									<div
										style={{
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											width: "100%",
											height: "100%",
											position: "absolute",
											backgroundColor: "white",
											// backgroundColor: "#242933",
											zIndex: 999,
										}}
									>
										<Loader
											type='Oval'
											color='#00BFFF'
											height={80}
											width={80}
										/>
									</div>
								) : (
									<ReactTableDraggableColumns
										// page={this.state.pageNum}
										draggableColumns={{
											onDraggedColumnChange: this.oncolumnsdragged,
											mode: "reorder",
											draggable: [
												// "snooze",
												"channel",
												"actions",
												"source",
												"created_at",
												"updated_at",
												"lead_number",
												"urgency",
												"archived",
												"starred_by_user_ids",
												"auto_review_count",
												"auto_referral_count",
												"is_complete",
												"lead_status",
												"service_fee",
												"bid_status",
												"assigned_to",
												"address",
												"time_stamp",
												"name",
												"phone_number",
												"svc_requested",
												"notes",
												"extra_links",
												"email",
												"utc_time_stamp",
												"lop",
												"reminder",
												"category",
											],
										}}
										className='-striped -highlight leadScreen'
										defaultSorted={
											[
												// {
												//   id: "utc_time_stamp",
												//   desc: true,
												// },
											]
										}
										PadRowComponent={PaddingRowComponent}
										// PadRowComponent={<span>&emsp;&emsp;</span>}
										showPaginationTop={false}
										data={
											this.state.renderFilter
												? this.state.filteredLeadsData
												: this.state.dataToDisplay
										} //this.props.leads}
										columns={displayableColumns}
										searching={true}
										sortable={false}
										minRows={0}
										resizable={true}
										filterable={true}
										PaginationComponent={Pagination}
										defaultFiltered={[
											{
												id: "archived",
												value: localStorage.getItem("archiveFilterValue"),
											},
											{
												id: "star",
												value: localStorage.getItem("starFilterValue"),
											},
											{
												id: "assigned_to",
												value: this.selectedAssignee,
											},
											{
												id: "utc_time_stamp",
												value: localStorage.getItem("timeFilterValue"),
											},
										]}
										noDataText='No record found'
										pageSize={this.state.pageSize}
										style={{
											height: this.props.tableHeight,
										}}
										onFilteredChange={this.handleFilteredChange}
										onPageChange={(pageIndex) => {
											// apply pagination when no search has applied
											// if (this.props.searchInputVal.trim().length <= 0) {
											if (pageIndex === parseInt(this.props.leads.length / 30))
												this.props.fetchMoreData(
													this.state.pageSize,
													pageIndex
												);
											// }
										}}
										renderTotalPagesCount={(pages) => {
											this.setState({
												TotalNumberOfPages: pages,
											});
											return pages;
										}}
										getTdProps={(state, rowInfo, column) => {
											return {
												onClick: (e) => {
													if (rowInfo != null) {
														this.selectedLead = rowInfo.original;
														this.selectedRowIndex = rowInfo.index;
													}
												},
											};
										}}
										getTrProps={(state, rowInfo, column, instance) => {
											if (rowInfo != null) {
												// let filteredData1 = [];
												// filteredData1.push(
												// 	rowInfo.original
												// );
												this.filteredData.push(rowInfo.original);
												// this.props.addFilterData(
												// 	this.filteredData
												// );

												// localStorage.setItem(
												// 	"filteredData1",
												// 	JSON.stringify(
												// 		filteredData1
												// 	)
												// );

												if (rowInfo.original.first) {
													// console.log(rowInfo);
												}
												return {
													key: rowInfo.original._id,
													style: {
														display: "flex",
														// background: this.props.SelectedUser ===  rowInfo.original.assigned_to ? this.props.highlightColor : null,
														background: rowInfo.original.temp_color
															? rowInfo.original.temp_color
															: this.props.getRowColorByAssignee(
																	rowInfo.original.assigned_to
															  ),
														justifyContent: "center",
														alignItems: "center",
														height: 30,
														borderBottom:
															rowInfo.original.first && "2.7px dashed black",
														// marginBottom: rowInfo.original.first && "-13px",
													},
													data: this.props.leads[rowInfo.index],
												};
											} else {
												return {};
											}
										}}
										getTbodyProps={(state, rowInfo, column, rtInstance) => {
											return {
												style: { overflowY: "overlay" },
											};
										}}
										onResizedChange={(data, data1) => {
											this.updateColumnSize(data);
										}}
										ref={(r) => (this.reactTable = r)}
									/>
								)}
							</div>
						</Suspense>
					) : null}
					{this.state.filterModal && (
						<FilterModal
							open={this.state.filterModal}
							onClose={this.handleCloseFilterModal}
							onApplyFilters={this.handleApplyFilters}
						/>
					)}
					{/* <div
						className="ExportElement"
						style={{
							// borderTop:'2px solid #f7f7f7',
							position: "relative",
							bottom: 30,
							height: 0,
							left: "5%",
							width: "15%",
							zIndex: 1,
							justifyContent: "space-between",
							alignItems: "center",
							flexDirection: "row",
							display:
								this.state.searchInProgress === false
									? "flex"
									: "none",
						}}
					>
						<button
							onClick={this.handleOpenFilterModal}
							style={{
								width: "8.3rem",
								height: 30,
								backgroundColor: "#0A84FE",
								color: "white",
								borderRadius: 5,
							}}
						>
							Filters{" "}
						</button>
						<span
							onClick={this.handleClearFilters}
							style={{
								width: "12.3rem",
								height: 30,
								// backgroundColor: "#0A84FE",
								color: "gray",
								borderRadius: 5,
								textDecoration: "underline",
							}}
						>
							Clear All Filters
						</span>
					</div> */}
					{/* <div
						className="ExportElement"
						style={{
							// borderTop:'2px solid #f7f7f7',
							position: "relative",
							bottom: 30,
							left: "74%",
							height: 0,
							zIndex: 1,
							flexDirection: "row",
							display:
								this.state.searchInProgress === false
									? "flex"
									: "none",
						}}
						onClick={() => {
							this.onExportCustomersClick();
						}}
					>
						<button
							style={{
								width: 30,
								height: 20,
								backgroundImage: `url(${exportIcon})`,
							}}
						></button>
						<span
							style={{
								color: "#0A84FE",
								textTransform: "uppercase",
								fontSize: 12,
								fontFamily: "SF Pro Text Bold",
							}}
						>
							Export Data
						</span>
					</div> */}

					<div
						style={{
							position: "absolute",
							bottom: 0,
							zIndex: 1,
							width: "100%",
						}}
					>
						{this.state.isExported ? (
							<ExportCustomers
								showAlert={this.props.showAlert}
								cancel={this.onExportCustomersCancelButtonClick}
								// filteredData={this.filteredData}
								filtersArray={this.filtersArray}
								handleCloseExport={this.handleCloseExport}
							/>
						) : null}
					</div>

					{this.state.showModel && (
						<div
							style={{
								backgroundColor: "black",
								color: "#0A84FE",
								textTransform: "uppercase",
								fontSize: 12,
								// zIndex:99999,
								position: "absolute",
								fontFamily: "SF Pro Text Bold",
							}}
						>
							<h4> Do you want to send the review/referal</h4>
							<span> selcect any option</span>
						</div>
					)}

					{this.state.selectedEmails?.length > 0 ? (
						<div
							style={{
								position: "absolute",
								bottom: 20,
								zIndex: 1,
								width: "100%",
								display: "flex",
								justifyContent: "center",
							}}
						>
							<MultipleEmailSms
								showAlert={this.props.showAlert}
								selectedEmails={this.state.selectedEmails}
								handleOpenFollowUpMode={this.handleOpenFollowUpMode}
								handleSetTitle={this.handleSetTitle}
								clearChecks={this.clearChecks}
								handelSelectedEmails={this.handelSelectedEmails}
								pageIndex={this.state.pageIndex}
							/>
						</div>
					) : null}
					{this.state.showAutoFollowUpModal && (
						<FollowUpModalNew
							title={this.state.multiEmailSmsTitle}
							message={"message"}
							leadName={"lead.name"}
							show={true}
							handleYes={this.autoFollowUpApiCallForBulk}
							// handleNo={onClose}
							autoSave={this.saveAutoMessage}
							showAlert={this.props.showAlert}
							sendReferralReview={this.sendReferral}
							handleCloseFollowUpMode={this.handleCloseFollowUpMode}
							autoFollowUpApiCallForBulk={this.autoFollowUpApiCallForBulk}
						/>
					)}
					{this.state.showStartSequenceModal && (
						<StartSequenceModal
							title='Select Sequence'
							show={this.state.showStartSequenceModal}
							yesFunc={() => {}}
							onHide={() => this.setState({ showStartSequenceModal: false })}
							rowId={this.state.rowId}
							email={this.state.email}
							showAlert={this.props.showAlert}
							state={this.state}
							UpdateLeadAfterAssigningSequence={
								this.props.UpdateLeadAfterAssigningSequence
							}
						/>
					)}
					{this.state.unPauseSequenceModal && (
						<StartSequenceModal
							title='Update Sequence Date'
							show={this.state.showStartSequenceModal}
							yesFunc={async () => {}}
							onHide={() =>
								this.setState({
									showStartSequenceModal: false,
									unPauseSequenceModal: false,
								})
							}
							rowId={this.state.rowId}
							email={this.state.email}
							showAlert={this.props.showAlert}
							state={this.state}
							UpdateLeadAfterAssigningSequence={
								this.props.UpdateLeadAfterAssigningSequence
							}
							unPause={true}
							unPauseId={this.state.unPauseId}
						/>
					)}
					{this.state.showPauseSequenceDetailsModal && (
						<PauseSequencDetailsModal
							title='Sequence Details'
							show={this.state.showPauseSequenceDetailsModal}
							showAlert={this.props.showAlert}
							sequenceId={this.state.sequenceId}
							showPauseButton={this.state.showPauseButton}
							selectedSequence={this.state.selectedSequence}
							totalTemplates={this.state.totalTemplates}
							startDate={this.state.startDate}
							nextTemplateNumber={this.state.nextTemplateNumber}
							leadId={this.state.leadId}
							state={this.state}
							email={this.state.email}
							firstTemplateDate={this.state.firstTemplateDate}
							secondTemplateDate={this.state.secondTemplateDate}
							thirdTemplateDate={this.state.thirdTemplateDate}
							dltFunc={async () => {
								const result = await unassignSequence(
									this.state.leadId,
									this.state.email
								);
								if (result.success) {
									if (this.state.email) {
										this.props.UpdateLeadAfterAssigningSequence(
											this.state.leadId,
											result.data.updatedData
										);
									} else {
										this.props.UpdateLeadAfterAssigningSequence(
											this.state.leadId,
											result.data.updatedData
										);
									}
									this.props.showAlert("Sequence Unassigned");
								} else this.props.showAlert("Operation failed");
							}}
							yesFunc={async () => {
								const { status, data } = await startPauseSequence(
									this.state.leadId,
									{
										email: this.state.email,
									}
								);
								if (status === 200) {
									if (this.state.pause) {
										if (this.state.email) {
											this.props.UpdateLeadAfterAssigningSequence(
												this.state.leadId,
												data.updatedData
											);
										} else {
											this.props.UpdateLeadAfterAssigningSequence(
												this.state.leadId,
												data.updatedData
											);
										}
										this.props.showAlert("Sequence Paused");
									} else {
										if (this.state.email) {
											this.props.UpdateLeadAfterAssigningSequence(
												this.state.leadId,
												data.updatedData
											);
										} else {
											this.props.UpdateLeadAfterAssigningSequence(
												this.state.leadId,
												data.updatedData
											);
										}
										this.props.showAlert("Sequence Started");
									}
								} else {
									this.props.showAlert("Operation failed");
								}
							}}
							onHide={() =>
								this.setState({
									showPauseSequenceDetailsModal: false,
								})
							}
						/>
					)}
					{this.state.showPauseSequenceModal && (
						<SequenceConfirmation
							show={this.state.showPauseSequenceModal}
							onHide={() => {
								this.setState({
									showPauseSequenceModal: false,
								});
							}}
							title={
								this.state.pause
									? "Are you sure you want to pause the sequence?"
									: "Are you sure you want to start the sequence?"
							}
							description={
								this.state.pause
									? "After you pause the sequence there will be an option to continue the sequence"
									: "After you start the sequence there will be an option to pause the sequence"
							}
							yesFunc={async () => {
								const { status, data } = await startPauseSequence(
									this.state.leadId,
									{
										email: this.state.email,
									}
								);
								if (status === 200) {
									if (this.state.pause) {
										if (this.state.email) {
											this.props.UpdateLeadAfterAssigningSequence(
												this.state.leadId,
												data.updatedData
											);
										} else {
											await this.props.UpdateLeadAfterAssigningSequence(
												this.state.leadId,
												data.updatedData
											);
										}
										this.props.showAlert("Sequence Paused");
									} else {
										if (this.state.email) {
											this.props.UpdateLeadAfterAssigningSequence(
												this.state.leadId,
												data.updatedData
											);
										} else {
											this.props.UpdateLeadAfterAssigningSequence(
												this.state.leadId,
												data.updatedData
											);
										}
										this.props.showAlert("Sequence Started");
									}
								} else {
									this.props.showAlert("Operation failed");
								}
							}}
							yesTitle={this.state.pause ? "Pause" : "Start"}
							showAlert={this.props.showAlert}
							state={this.state}
						/>
					)}
					{this.state.showPauseSequenceModalSms && (
						<SequenceConfirmation
							show={this.state.showPauseSequenceModalSms}
							onHide={() => {
								this.setState({
									showPauseSequenceModalSms: false,
								});
							}}
							title={
								this.state.pause
									? "Are you sure you want to pause the sequence?"
									: "Are you sure you want to start the sequence?"
							}
							description={
								this.state.pause
									? "After you pause the sequence there will be an option to continue the sequence"
									: "After you start the sequence there will be an option to pause the sequence"
							}
							yesFunc={async () => {
								const { status, data } = await startPauseSequence(
									this.state.leadId,
									{
										email: false,
									}
								);
								if (status === 200) {
									if (this.state.pause) {
										await this.props.UpdateLeadAfterAssigningSequence(
											this.state.leadId,
											data.updatedData
										);
										this.props.showAlert("Sequence Paused");
									} else {
										this.props.UpdateLeadAfterAssigningSequence(
											this.state.leadId,
											data.updatedData
										);
										this.props.showAlert("Sequence Started");
									}
								} else {
									this.props.showAlert("Operation failed");
								}
							}}
							yesTitle={this.state.pause ? "Pause" : "Start"}
							showAlert={this.props.showAlert}
							state={this.state}
						/>
					)}
					{this.state.showUpdateDatePopup && (
						<SequenceConfirmation
							show={this.state.showUpdateDatePopup}
							onHide={() => {
								this.setState({ showUpdateDatePopup: false });
							}}
							title={"Update Sequence Date"}
							description={
								"The set sequence date has passed. Please update the sequence date to proceed."
							}
							yesFunc={async () => {
								this.setState({
									unPauseSequenceModal: true,
									showUpdateDatePopup: false,
								});
							}}
							yesTitle={"Update"}
							showAlert={this.props.showAlert}
							state={this.state}
						/>
					)}
					{this.state.reminderModal && (
						<ReminderModal
							show={this.state.reminderModal}
							leadId={this.state.rowId}
							leadName={this.state.leadName}
							UpdateLeadAfterAssigningSequence={
								this.props.UpdateLeadAfterAssigningSequence
							}
							reminderId={this.state.reminderId}
							reminderDate={this.state.reminderDate}
							reminderDescription={this.state.reminderDescription}
							showAlert={this.props.showAlert}
							update={this.state.updateReminder}
							reminderAdmin={this.state.reminderAdmin}
							onHide={() => this.setState({ reminderModal: false })}
							leadAssignedTo={this.state.leadAssignedTo}
							leads={this.props.leads}
							updatingAssigned={this.state.updatingAssigned}
							assignLead={() => {
								let message;
								if (
									!this.state.leadAssignedTo ||
									this.state.leadAssignedTo == ""
								) {
									message =
										"You are about to assign this lead to yourself. Are you sure?";
								} else {
									message =
										"You are about to re-assign this lead to yourself from " +
										this.state.leadAssignedTo +
										". Are you sure?";
								}
								this.props.confirmAction(
									"Confirmation",
									message,
									() => {
										this.setState({
											updatingAssigned: true,
										});
										this.updateAssignee(
											localStorage.getItem("email"),
											this.state.rowId,
											0
										);
									},
									() => {}
								);
							}}
						/>
					)}
					<CategoryModal
						show={this.state.showCategoryModal}
						noFunc={() => this.setState({ showCategoryModal: false })}
						leadName={this.state.leadName}
						row={this.state.row}
						users={this.props.users}
						updateLeadAfterAssigningSequence={
							() => {}
							// this.props.UpdateLeadAfterAssigningSequence
						}
					/>
				</div>
			</React.Fragment>
		);
	}
}

// eslint-disable-next-line no-extend-native
Date.prototype.getWeek = function () {
	var date = new Date(this.getTime());
	date.setHours(0, 0, 0, 0);
	// Thursday in current week decides the year.
	date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
	// January 4 is always in week 1.
	var week1 = new Date(date.getFullYear(), 0, 4);
	// Adjust to Thursday in week 1 and count number of weeks from date to week1.
	return (
		1 +
		Math.round(
			((date.getTime() - week1.getTime()) / 86400000 -
				3 +
				((week1.getDay() + 6) % 7)) /
				7
		)
	);
};

// Returns the four-digit year corresponding to the ISO week of the date.
// eslint-disable-next-line no-extend-native
Date.prototype.getWeekYear = function () {
	var date = new Date(this.getTime());
	date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
	return date.getFullYear();
};
async function unassignSequence(leadId, email) {
	const { status, data } = await removeSequence(leadId, {
		type: email,
	});
	if (status === 200) {
		return { success: true, data: data };
	} else {
		return { success: false, data: null };
	}
}

function darkenHexColor(hex, percent) {
	hex = hex.replace(/^#/, "");

	// Parse hex into RGB
	const r = parseInt(hex.substring(0, 2), 16);
	const g = parseInt(hex.substring(2, 4), 16);
	const b = parseInt(hex.substring(4, 6), 16);

	// Darken the color
	const darkenedR = Math.round(r * (1 - percent));
	const darkenedG = Math.round(g * (1 - percent));
	const darkenedB = Math.round(b * (1 - percent));

	// Convert back to hex
	const darkenedHex = (
		(darkenedR << 16) +
		(darkenedG << 8) +
		darkenedB
	).toString(16);

	// Ensure leading zeroes are present if needed
	return "#" + ("000000" + darkenedHex).slice(-6);
}
// export default LeadsScreen;
// const mapStateToProps = (state) => {
//   return {
//     _userData: state._userData
//   };
// };
const mapStateToProps = (state) => ({
	filterData: state.reducer._filterData,
	filterTrigger: state.reducer._filterTrigger,

	// Assuming _filterData is stored in your reducer
});

// const mapDispatchToProps = (dispatch) => {
// 	return {
// 		addFilterData: (filterData) => dispatch(addFilterData(filterData)),
// 		addFilterTrigger: (trigger) => dispatch(addFilterTrigger(trigger)),

// 		removeFilterData: () => dispatch(removeFilterData()),
// 	};
// };

// export default connect(mapStateToProps, mapDispatchToProps)(LeadsScreen);

// const mapDispatchToProps = (dispatch) => {
// 	return {
// 		addFilterData: (filterData) => dispatch(addFilterData(filterData)),
// 	};
// };

// // export default App;
export default LeadsScreen;

// export default connect(mapDispatchToProps)(LeadsScreen);
