import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";

import { ReactComponent as SearchIcon } from "../../assets/images/searchIcon.svg";
import TemplateTiles from "./TemplateTiles/TemplateTiles";
import Details from "./Details/Details";
import { toggleItem } from "../../Helpers/Constants";
import Dropdown from "../Dropdown/Dropdown";
import useStyles from "./styles";
import { reorderSelectedTemplates } from "../../../api/api";

function Template({
  templateData,
  headerCollapse,
  templeDataOne,
  selectedTemplates,
  setSelectedEmailTemplates,
  setUpdate,
  getId,
  setGetId,
  showAlert,
  updateLists,
  setUpdateLists,
  users,
  emailDefaultTemplate,
  smsDefaultTemplate,
  filteredResults,
  setFilteredResults,
  setSelectedOption,
  selectedOption,
  updatePage,
  setUpdatePage,
  loadingBar,
  detailsLoadingBar,
  setLoadingBar,
  attachment,
  type,
  showSaveButton,
  setShowSaveButton,
  newSelectedTemplates,
  setNewSelectedTemplates,
  handleUpdateTemplate,
  searching,
  setSearching,
  setIdToUpdate,
  searchObjects,
  setSearchObjects,
  searchText,
  setSearchText,
}) {
  const classes = useStyles();

  const [updateData, setUpdateData] = useState(false);
  const [selectedTemplated, setSelectedTemplates] = useState([]);
  const [first, setFirst] = useState(true);
  const [temp, setTemp] = useState("");
  const [options, setOptions] = useState(["All Templates"]);
  const [MainData, setMainData] = useState([]);
  const [initialDetails, setinitialDetails] = useState([]);
  const [showDetailsLoader, setShowDetailsLoader] = useState(true);
  const [detailsNoData, setDetailsNoData] = useState(false);

  useEffect(() => setinitialDetails(templeDataOne), [templeDataOne]);

  useEffect(() => {
    setMainData(templateData);
  }, [templateData]);

  useEffect(() => {
    if (first) {
      setSelectedTemplates(selectedTemplates);
      setFirst(false);
    }
  }, [selectedTemplates]);

  useEffect(() => setOptions(users), [users]);

  useEffect(() => {
    if (searching) {
      if (searchText) {
        if (searchText !== "") {
          handleSearch();
        }
      }
    }
  }, [filteredResults]);

  // const handleChangeSelection = async (id, type) => {
  //   // if (type === "email") {
  //   let selectedTempArray = [...selectedTemplates];

  //   if (selectedTemplates.length == 1 && selectedTemplates.includes(id)) {
  //     return;
  //   }
  //   setSelectedEmailTemplates(toggleItem(selectedTempArray, id));
  //   // }
  // };

  useEffect(() => {
    if (temp !== "") {
      setShowSaveButton(true);
      // handleChangeSelection(temp, type);
    } //"email");
  }, [temp]);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };

  const handleReset = () => {
    setSearching(false);
    setSearchObjects([]);
    setinitialDetails(templeDataOne);
  };

  const filterData = (searchText, data) => {
    const query = searchText.toLowerCase();
    let searchResult = data.filter((item) => {
      if (item.name && item.label) {
        if (
          item.label.toLowerCase().includes(query) ||
          item.name.toLowerCase().includes(query)
        ) {
          arr = [...arr, item];
        }
      }
    });
    return searchResult;
  };
  let arr = [];

  const searchEmpty = () => {
    setSearching(false);
    setSearchObjects([]);
    setinitialDetails(templeDataOne);
    setGetId(0);
  };

  const handleSearch = async () => {
    if (MainData || filteredResults) {
      if (searchText) {
        if (searchText !== "") {
          filterData(searchText, templateData && templateData);
          setSearchObjects(arr);
          setSearching(true);
          setinitialDetails(arr[0]);
          if (arr.length === 0) {
            // setGetId(templeDataOne && templeDataOne.temp_id);
            return;
          } else setGetId(0);
        } else {
          setSearching(false);
          setSearchObjects([]);
          setinitialDetails(templeDataOne);
          setGetId(0);
        }
      } else {
        setSearching(false);
        setSearchObjects([]);
        setinitialDetails(templeDataOne);
        setGetId(0);
      }
    }
  };

  const handleEnterPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  //Drag-n-Drop / Rearrange
  const [grabbedTile, setTile] = useState(0);

  const handleDragStart = (tile) => {
    if (
      selectedOption.toLowerCase() === "selected templates" &&
      tile &&
      tile.temp_id
    ) {
      setTile(tile.temp_id);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = async (event) => {
    if (selectedOption.toLowerCase() !== "selected templates") {
      event.preventDefault();
    } else {
      const classname = event.target.className;
      let fileExtension = await classname.split(".").pop().toLowerCase();

      if (searching && searchObjects.length !== 0) {
        // const rearrangedTiles = await rearrange(searchObjects, fileExtension);
        // setSearchObjects(rearrangedTiles);
      } else {
        const rearrangedTiles = await rearrange(MainData, fileExtension);
        setMainData(rearrangedTiles.data);
        const idsOfTemplates = await rearrangedTiles.data.map((tile) => {
          return tile.temp_id;
        });
        setGetId(rearrangedTiles.index);
        if (type.toLowerCase() === "email") {
          const { data, status } = await reorderSelectedTemplates({
            email: true,
            templates: idsOfTemplates,
          });
          if (status === 200) showAlert("Templates reordered successfully");
          else showAlert("Templates reordering failed");
        } else {
          const { data, status } = await reorderSelectedTemplates({
            email: false,
            templates: idsOfTemplates,
          });
          if (status === 200) showAlert("Templates reordered successfully");
          else showAlert("Templates reordering failed");
        }
      }
    }
  };
  const rearrange = async (data, fileExtension) => {
    const index = data.findIndex((tile) => tile.temp_id === fileExtension);
    if (index >= 0) {
      const objectToPlace = data.find((tile) => tile.temp_id === grabbedTile);
      const updatedTiles = data.filter((tile) => tile.temp_id !== grabbedTile);

      await updatedTiles.splice(index, 0, objectToPlace);
      return { data: updatedTiles, index: index }; //, reorder: idsOfTemplates
    } else return data;
  };

  return (
    <div className={classes.contain}>
      <div className={classes.saveButtonContainer}>
        <div className={classes.searchContainer}>
          <div className={classes.dropdownContainer}>
            <p className={classes.numberOfTemplates}>
              {`Templates (${
                searching
                  ? searchObjects.length
                  : MainData
                  ? MainData.length
                  : "0"
              })`}
            </p>
            <Dropdown
              options={options}
              defaultOption='Selected Templates'
              onSelect={handleOptionSelect}
              optionSelected={selectedOption}
            />
          </div>
          <div className={classes.saveContainer}>
            {showSaveButton && (
              <button
                className={classes.saveButton}
                onClick={handleUpdateTemplate}
              >
                Save Selected Templates
              </button>
            )}
            <div className={classes.searchDiv}>
              <input
                style={{
                  width: "80%",
                  border: "none",
                  outline: "none",
                  fontFamily: "Gilroy Light",
                  fontSize: "1.5rem",
                }}
                placeholder='Search by Title, created by'
                value={searchText}
                onKeyPress={handleEnterPress}
                onChange={(e) => {
                  if (e.target.value === "") searchEmpty();
                  setSearchText(e.target.value);
                }}
              />
              <SearchIcon onClick={handleSearch} />
            </div>
          </div>
        </div>
      </div>
      {!loadingBar ? (
        (searching && searchObjects.length !== 0) ||
        (MainData && !searching && MainData.length !== 0) ? (
          <div className={classes.rootContainer}>
            <div className={classes.elementsContainer}>
              {/* <div className={classes.searchDiv}>
                <input
                  className={classes.input}
                  placeholder='Search by Title, created by'
                  value={searchText}
                  onKeyPress={handleEnterPress}
                  onChange={(e) => {
                    if (e.target.value === "") searchEmpty();
                    setSearchText(e.target.value);
                  }}
                />
                <SearchIcon
                  className={classes.searchImage}
                  onClick={handleSearch}
                />
              </div> */}
              <div className={classes.line}></div>
              <div className={`${classes.tiles} templateTiles`}>
                {searching ? (
                  searchObjects.length !== 0 ? (
                    searchObjects.map((tile, index) => (
                      // tile.label || tile.value &&
                      <TemplateTiles
                        key={index}
                        id={index}
                        handleDragStart={() => handleDragStart(tile)}
                        handleDragOver={handleDragOver}
                        handleDrop={handleDrop}
                        total={
                          searching ? searchObjects.length : MainData.length
                        }
                        creator={tile.name}
                        title={tile.label}
                        description={tile.value}
                        link={tile.attachment ? tile.attachment : null}
                        tempId={tile.temp_id}
                        selectedTemplates={selectedTemplates}
                        setSelectedEmailTemplates={setSelectedEmailTemplates}
                        update={updateData}
                        setUpdate={setUpdateData}
                        setEdit={setUpdate}
                        setShowData={setGetId}
                        temp={setTemp}
                        select={getId}
                        showAlert={showAlert}
                        type={type}
                        updateLists={updatePage}
                        setUpdateLists={setUpdatePage}
                        attachment={attachment}
                        setIdToUpdate={setIdToUpdate}
                        defaultTemplate={
                          type === "email"
                            ? emailDefaultTemplate === tile.temp_id
                              ? true
                              : false
                            : smsDefaultTemplate === tile.temp_id
                            ? true
                            : false
                        }
                      />
                    ))
                  ) : (
                    <div style={{ padding: "30px", backgroundColor: "blue" }}>
                      <p>No Data Available to Show</p>
                    </div>
                  )
                ) : MainData ? (
                  MainData.length !== 0 ? (
                    MainData.map((tile, index) => (
                      // tile.label || tile.value &&
                      <TemplateTiles
                        key={index}
                        id={index}
                        draggable={
                          selectedOption.toLowerCase() ===
                            "selected templates" && true
                        }
                        handleDragStart={() => handleDragStart(tile)}
                        handleDragOver={handleDragOver}
                        handleDrop={handleDrop}
                        selectedOption={selectedOption}
                        total={
                          searching ? searchObjects.length : MainData.length
                        }
                        creator={tile.name}
                        title={tile.label}
                        description={tile.value}
                        link={tile.attachment ? tile.attachment : null}
                        tempId={tile.temp_id}
                        selectedTemplates={selectedTemplates}
                        setSelectedEmailTemplates={setSelectedEmailTemplates}
                        update={updateData}
                        setUpdate={setUpdateData}
                        setEdit={setUpdate}
                        setShowData={setGetId}
                        temp={setTemp}
                        select={getId}
                        showAlert={showAlert}
                        type={type}
                        updateLists={updatePage}
                        setUpdateLists={setUpdatePage}
                        attachment={attachment}
                        setIdToUpdate={setIdToUpdate}
                        setShowSaveButton={setShowSaveButton}
                        defaultTemplate={
                          type === "email"
                            ? emailDefaultTemplate === tile.temp_id
                              ? true
                              : false
                            : smsDefaultTemplate === tile.temp_id
                            ? true
                            : false
                        }
                      />
                    ))
                  ) : (
                    <div
                      style={{
                        height: "53vh",
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <p style={{ textAlign: "center", fontSize: "12px" }}>
                        No template available to display
                      </p>
                    </div>
                  )
                ) : (
                  <div style={{ padding: "30px" }}>
                    <p>No template available to display</p>
                  </div>
                )}
              </div>
            </div>
            {/* {initialDetails ? (
              <div className={classes.elementsContainer2}>
                <Details
                  ddata={initialDetails}
                  completeData={searching ? searchObjects : MainData}
                  checkId={getId}
                  setIdToUpdate={setIdToUpdate}
                  setCheckId={setGetId}
                  setUpdate={setUpdate}
                  showAlert={showAlert}
                  type={type}
                  attachment={attachment}
                  updateLists={updatePage}
                  setUpdateLists={setUpdatePage}
                  showDetailsLoader={showDetailsLoader}
                  setShowDetailsLoader={setShowDetailsLoader}
                  detailsNoData={detailsNoData}
                  setDetailsNoData={setDetailsNoData}
                  loadingBar={loadingBar}
                  detailsLoadingBar={detailsLoadingBar}
                  setLoadingBar={setLoadingBar}
                  defaultTemplate={
                    type === "email" ? emailDefaultTemplate : smsDefaultTemplate
                  }
                />
              </div>
            ) : (
              <div className={classes.elementsContainer2}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <p style={{ textAlign: "center", fontSize: "12px" }}>
                    No data to display
                  </p>
                </div>
              </div>
            )} */}
          </div>
        ) : (
          <div className='mhr-no-data'>
            <p>No Templates to Display </p>
            {searching && (
              <h6 onClick={handleReset}>
                {"\n"}
                Click here to reset search
              </h6>
            )}
          </div>
        )
      ) : (
        loadingBar && (
          <div className={classes.noDetails}>
            <Loader type='Oval' color='black' height={38} width={38} />
          </div>
        )
      )}
    </div>
  );
}

export default Template;
