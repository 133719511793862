import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Loader from "react-loader-spinner";

import "./Confirmations.css";

export const Confirmation = ({ show, onHide, title, description, yesFunc }) => {
  const [loading, setLoading] = useState(false);
  const handleConfirm = async () => {
    setLoading(true);
    await yesFunc();
    setLoading(false);
    onHide();
  };
  return (
    <div className='mhr-confirmModalContainer'>
      <Modal
        show={show}
        onHide={onHide}
        style={{ top: "40%", borderRadius: 10 }}
        size='md'
      >
        <Modal.Body>
          <div className='mhr-descriptionContainer'>
            <h3>{title}</h3>
            <p>{description}</p>
          </div>
        </Modal.Body>
        <div className='mhr-buttonsContainer'>
          {loading ? (
            <div className='mhr-loader-button'>
              <Loader
                style={{ marginLeft: "10px" }}
                type='Oval'
                color='black'
                height={30}
                width={30}
              />
            </div>
          ) : (
            <button onClick={handleConfirm}>Yes</button>
          )}
          <button onClick={onHide}> Cancel</button>
        </div>
      </Modal>
    </div>
  );
};
