import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";

import { updateLeadCategory } from "../../../../api/api";
import { CategoryDropDown } from "../../Dropdown/CategoryDropdown/CategoryDropdown";
import "./category.css";

const categoriesOptions = [
	{
		id: "1",
		label: "Leaning Mailbox",
		name: "Leaning Mailbox",
		backgroundColor: "#824B39",
	},
	{
		id: "2",
		label: "Mailbox Repair",
		name: "Mailbox Repair",
		backgroundColor: "#DB6161",
	},
	{
		id: "3",
		label: "Mailbox Rebuild",
		name: "Mailbox Rebuild",
		backgroundColor: "#A47B30",
	},
	{
		id: "5",
		label: "Retaining Walls",
		name: "Retaining Walls",
		backgroundColor: "#5D7A8A",
	},
	{
		id: "6",
		label: "Tuckpointing",
		name: "Tuckpointing",
		backgroundColor: "#819268",
	},
	{
		id: "7",
		label: "Brick/Stone Repairs",
		name: "Brick/Stone Repairs",
		backgroundColor: "#778DC8",
	},
	{
		id: "8",
		label: "Flowerbed Borders",
		name: "Flowerbed Borders",
		backgroundColor: "#898844",
	},
	{
		id: "9",
		label: "Concrete Repairs",
		name: "Concrete Repairs",
		backgroundColor: "#8bc238",
	},
	{
		id: "10",
		label: "Chimney Repairs",
		name: "Chimney Repairs",
		backgroundColor: "#a634b3",
	},
	{
		id: "11",
		label: "Fireplaces",
		name: "Fireplaces",
		backgroundColor: "#ad2646",
	},
	{
		id: "4",
		label: "Un-Assign",
		name: "Un-Assign",
		backgroundColor: "#707070",
	},

	{
		id: "12",
		label: "Stucco",
		name: "Stucco",
		backgroundColor: "#3291FF",
	},

	{
		id: "13",
		label: "Archways",
		name: "Archways",
		backgroundColor: "#EECC33",
	},
	{
		id: "14",
		label: "Stone Patio",
		name: "Stone Patio",
		backgroundColor: "#29ABCA",
	},
	{
		id: "15",
		label: "Commercial Job",
		name: "Commercial Job",
		backgroundColor: "#D3D3D3",
	},
];

export const CategoryModal = ({
	show,
	noFunc,
	leadName,
	row,
	users,
	updateLeadAfterAssigningSequence,
}) => {
	const [confirmLoader, setConfirmLoader] = useState(false);
	const [selectedOption, setSelectedOption] = useState();

	return (
		<>
			{show && (
				<div className="modalReminder">
					<div className="ModalBodyReminder">
						<h1>Assign Category</h1>
						<h3>
							You are assigning Category to <b>{leadName},</b>{" "}
							Please select a category from the following
						</h3>
						<h2>Category</h2>
						<div className="dropDownCOntainer">
							<CategoryDropDown
								options={categoriesOptions}
								active={true}
								onSelect={setSelectedOption}
								optionSelected={row.original.category}
								backColor={selectedOption?.backgroundColor}
							/>
						</div>

						<div className="buttonsDivCategory">
							<button onClick={noFunc}>Close</button>
							{confirmLoader ? (
								<div className="ReminderLoader">
									<Loader
										className="modalLoader"
										type="Oval"
										color="black"
										height={35}
										width={35}
									/>
								</div>
							) : (
								<button
									style={{
										borderColor: "#333",
										backgroundColor: "#333",
										color: "white",
									}}
									onClick={async () => {
										setConfirmLoader(true);
										try {
											const { data, status } =
												await updateLeadCategory(
													row.original._id,
													{
														category:
															selectedOption,
													}
												);
											if (status) {
												updateLeadAfterAssigningSequence(
													row.original._id,
													data.data
												);
												setConfirmLoader(false);
												noFunc();
												return true;
											}
										} catch (error) {
											setConfirmLoader(false);
											noFunc();
											return false;
										}
									}}
								>
									Confirm
								</button>
							)}
						</div>
					</div>
				</div>
			)}
		</>
	);
};
