import React, { Component } from "react";
// import "../../scss/settings.scss";
import editIcon from "../assets/images/editiconblack.svg";
import deleteIcon from "../assets/images/deleteiconblack.svg";
import tickIcon from "../assets/images/tick_icon.svg";
import crossIcon from "../assets/images/cross_icon.svg";
class LocationListItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      item: this.props.item,
      errors: {},
    };
  }
  handleEdit = () => {
    this.props.editLocation(this.props.index);
  };

  handleDone = () => {
    let temp = { ...this.state.item };
    temp.nameError = null;
    temp.sourceError = null;
    let hasError = false;
    if (this.state.item.name == "") {
      temp.nameError = "Please add name";
      hasError = true;
    }
    if (this.state.item.source == "") {
      temp.sourceError = "Please add source";
      hasError = true;
    }
    this.setState({ item: temp });

    if (hasError === false) {
      this.props.saveLocation(this.props.index, temp);
    }
  };
  onSave = (e) => {
    let temp = { ...this.state.item };
    temp[e.target.name] = e.target.value;
    this.setState({ item: temp });
  };
  onCancel = () => {
    if (this.props.item.name == "" && this.props.item.source == "") {
      this.props.onDelete(this.props.index);
    } else {
      this.setState({ item: this.props.item });
      this.props.onCancel(this.props.index);
    }
  };
  renderEditbleRow() {
    const hasErrors =
      this.state.item.nameError ||
      this.state.item.docCodeError ||
      this.state.item.accCodeError;
    return (
      <div
        style={{ fontSize: "1.3rem" }}
        className={`row flex-table-form-item row list--item ${
          hasErrors ? "packing-details-row-error" : ""
        }`}
      >
        <div
          className='col-lg-4 location_input_container margin-right-10'
          style={{ fontSize: "1.3rem" }}
        >
          <input
            style={{ fontSize: "1.3rem" }}
            className={`form-control ${
              this.state.item.nameError ? "has-error" : ""
            }`}
            type='text'
            name='name'
            defaultValue={this.state.item.name}
            disbaled={this.state.edit ? "disabled" : ""}
            onChange={this.onSave}
          />
        </div>
        <div className='col-lg-4 location_input_container margin-right-10'>
          <input
            style={{ fontSize: "1.3rem" }}
            className={`form-control ${
              this.state.item.sourceError ? "has-error" : ""
            }`}
            type='text'
            name='source'
            defaultValue={this.state.item.source}
            disbaled={this.state.edit ? "disabled" : ""}
            onChange={this.onSave}
          />
        </div>
        <div className='col-lg-4 location-item-edit-icons'>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "center",
              alignItems: "center,",
            }}
          >
            <button
              onClick={this.onCancel}
              style={{
                backgroundImage: `url(${crossIcon})`,
                width: 20,
                height: 20,
                border: "none",
                backgroundRepeat: "no-repeat",
                backgroundColor: "transparent",
                backgroundSize: "contain",
                cursor: "pointer",
                marginLeft: 30,
              }}
            ></button>

            <div
              onClick={this.handleDone}
              style={{
                backgroundImage: `url(${tickIcon})`,
                width: 20,
                height: 20,
                border: "none",
                backgroundRepeat: "no-repeat",
                backgroundColor: "transparent",
                backgroundSize: "contain",
                cursor: "pointer",
                marginLeft: 30,
              }}
            ></div>
          </div>
        </div>
      </div>
    );
  }

  renderNormalRow() {
    return (
      <div
        style={{ fontSize: "1.3rem" }}
        className='row border-bottom-primary-color'
      >
        <div className='col-lg-4 location_input_container margin-right-10 list--item-label'>
          <label className='font-weight-normal'>{this.state.item.name}</label>
        </div>
        <div className='col-lg-4 location_input_container margin-right-10 list--item-label'>
          <label className='font-weight-normal'>{this.state.item.source}</label>
        </div>

        <div
          className='col-lg-4 location_input_container '
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            paddingRight: 0,
          }}
        >
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "center",
              alignItems: "center,",
            }}
          >
            <button
              onClick={this.handleEdit}
              style={{
                backgroundImage: `url(${editIcon})`,
                width: 20,
                height: 20,
                border: "none",
                backgroundRepeat: "no-repeat",
                backgroundColor: "transparent",
                backgroundSize: "contain",
                cursor: "pointer",
                marginLeft: 30,
              }}
            ></button>

            <div
              onClick={() => {
                this.props.onDelete(this.props.index);
              }}
              style={{
                backgroundImage: `url(${deleteIcon})`,
                width: 20,
                height: 20,
                border: "none",
                backgroundRepeat: "no-repeat",
                backgroundColor: "transparent",
                backgroundSize: "contain",
                cursor: "pointer",
                marginLeft: 30,
              }}
            ></div>
          </div>
        </div>
      </div>
    );
  }
  render() {
    //
    return this.props.item.edit
      ? this.renderEditbleRow()
      : this.renderNormalRow();
  }
}
export default LocationListItem;
