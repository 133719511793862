import React from 'react';
import editIcon from '../assets/images/editiconblack.svg';
import deleteIcon from '../assets/images/deleteiconblack.svg';
import tickIcon from '../assets/images/tick_icon.svg';
import crossIcon from '../assets/images/cross_icon.svg';

const editModes = {
	view: props => (
		<div
			style={{
				justifyContent: 'center',
				alignItems: 'center',
				display: 'flex'
			}}
		>
			<div
				style={{
					flexDirection: 'row',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center,'
					// width: '80%'
				}}
			>
				<button
					onClick={props.onEdit}
					style={{
						backgroundImage: `url(${editIcon})`,
						width: 24,
						height: 24,
						border: 'none',
						backgroundRepeat: 'no-repeat',
						backgroundColor: 'transparent',
						backgroundSize: 'contain',
						cursor: 'pointer',
						marginLeft: 30
					}}
				></button>
				{props.onDelete !== null ? (
					<div
						onClick={props.onDelete}
						style={{
							backgroundImage: `url(${deleteIcon})`,
							width: 24,
							height: 24,
							border: 'none',
							backgroundRepeat: 'no-repeat',
							backgroundColor: 'transparent',
							backgroundSize: 'contain',
							cursor: 'pointer',
							marginLeft: 30
						}}
					></div>
				) : null}
			</div>
		</div>
	),
	edit: props => (
		<div
			style={{
				justifyContent: 'center',
				alignItems: 'center',
				display: 'flex'
			}}
		>
			<div
				style={{
					flexDirection: 'row',
					display: 'flex',
					justifyContent: 'space-between',
					width: '80%'
				}}
			>
				<button
					onClick={props.onSave}
					style={{
						backgroundImage: `url(${tickIcon})`,
						width: 42,
						height: 28,
						border: 'none',
						backgroundRepeat: 'no-repeat',
						backgroundColor: 'transparent',
						backgroundSize: 'contain',
						cursor: 'pointer',
						marginLeft: 15
					}}
				></button>
				<button
					onClick={props.onCancel}
					style={{
						backgroundImage: `url(${crossIcon})`,
						marginLeft: 30,
						width: 42,
						height: 28,
						border: 'none',
						backgroundRepeat: 'no-repeat',
						backgroundColor: 'transparent',
						backgroundSize: 'contain',
						cursor: 'pointer'
					}}
				></button>
			</div>
		</div>
	),
	empty: props => null
	// delete: props =>(
	//   <div onClick={props.onDelete} style = {{color : '#4888BF',cursor : 'pointer'}}>Delete</div>
	// )
};

export default function ActionsCell(props) {
	const {
		mode,
		actions: { onEdit, onCancel, onSave, onDelete }
	} = props.columnProps.rest;
	const Buttons = editModes[mode];
	return (
		<Buttons
			onEdit={() => onEdit(props.index)}
			onDelete={() => onDelete(props.index)}
			onCancel={onCancel}
			onSave={() => onSave(props.index)}
		/>
	);
}
