import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Loader from "react-loader-spinner";

import "./EmailTemplateModal.css";

export const EmailTemplateModal = ({
  show,
  onHide,
  title,
  fileName,
  yesFunc,
  type,
  input,
  getInput,
  textArea,
  loader,
  setLoader,
  showAlert,
  edit = false,
}) => {
  const [handleShowModal, setHandleShowModal] = useState(true);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [name, setName] = useState("");

  const handleCloseModal = (e) => {
    e.preventDefault();
    setHandleShowModal(false);
    onHide();
  };

  // useEffect(() => {
  //   setName(filename1);
  // }, [fileName]);

  const handleConfirm = async () => {
    if ((isValidEmail && !edit) || edit) {
      setLoader(true);
      await yesFunc();
      onHide();
    } else {
      showAlert("Enter a valid email address");
    }
  };
  return (
    <Modal show={handleShowModal} className='emailModal' size='md'>
      <div>
        <div className='headingEmailModal'>
          <h2>{title}</h2>
          <img
            src={require("../../../assets/images/close-cross.png")}
            onClick={handleCloseModal}
          />
        </div>
        <div className='emailModalLine'></div>
        <div className='emailTemplateModalBody'>
          <h3>{edit ? "File Name" : "File"}</h3>
          <div
            className={`fileDiv ${
              type === "pdf" ? "fileDivPDF" : "fileDivIMG"
            }`}
          >
            <div
              className={`fileDivElipse ${
                type === "pdf" ? "fileDivElipsePDF" : "fileDivElipseIMG"
              }`}
            >
              <p>{type.toString().toUpperCase()}</p>
            </div>
            <div className='fileDivText'>
              <p>{fileName}</p>
            </div>
            {/* <img src={require("../../../assets/images/close-cross.png")} /> */}
          </div>
          <h3>{edit ? "Change" : "Send"} to</h3>
          {!edit ? (
            <input
              placeholder='Enter recepient email...'
              onChange={(e) => {
                const inputValue = e.target.value;
                const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inputValue);
                setIsValidEmail(isValid);
                input(inputValue);
              }}
            />
          ) : (
            <input
              placeholder='Enter file name'
              value={getInput}
              onChange={(e) => input(e.target.value)}
            />
          )}
          {!edit && (
            <>
              <h3>Message</h3>
              <textarea
                placeholder='Type your details here ...'
                onChange={(e) => textArea(e.target.value)}
              />
            </>
          )}
        </div>
      </div>
      <div className='buttonsContainerModal'>
        <button className='cancelButton' onClick={handleCloseModal}>
          Cancel
        </button>
        {loader ? (
          <Loader
            className='modalLoader'
            type='Oval'
            color='black'
            height={38}
            width={38}
          />
        ) : (
          <button className='sendButton' onClick={handleConfirm}>
            {edit ? "Save" : "Send"}
          </button>
        )}
      </div>
    </Modal>
  );
};
