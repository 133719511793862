import React, { useRef, useState } from "react";
import Loader from "react-loader-spinner";

import "./FileUpload.css";

const FileUpload = ({
  setDocument,
  media = false,
  uploadMedia,
  mediaLoader = false,
  setSelectedOption,
}) => {
  const [dragging, setDragging] = useState(false);
  const fileInputRef = useRef(null);

  const handleDragEnter = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    setDocument(null);
    setDragging(false);
    const newFiles = e.dataTransfer.files[0];
    setDocument(newFiles);
    if (media) {
      await uploadMedia(newFiles);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
  };

  const handleFileInputChange = async (e) => {
    setDocument(null);
    const file = e.target.files[0];
    setDocument(file);
    setSelectedOption(null);
    if (media) await uploadMedia(file);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleDivClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div
      className={`file-upload  ${media && "mediaTab"} ${
        dragging && "dragging"
      }`}
      onDragEnter={handleDragEnter}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      onClick={handleDivClick}
    >
      {!media && (
        <div className='documentDiv'>
          <div className='documentDiv2'>
            <img
              className='documentImage'
              src={require("../../../assets/images/uploadDocument.png")}
            />
            <text className='documentText'>
              Upload or drop a file right here
            </text>
          </div>
          <text className='documentText2'>JPG, PNG, GIF, PDF</text>
        </div>
      )}
      {media &&
        (mediaLoader ? (
          <Loader type='Oval' color='black' height={38} width={38} />
        ) : (
          <div className='mediaTabDisplay'>
            <img
              className='documentImage'
              src={require(!media
                ? "../../../assets/images/uploadDocument.png"
                : "../../../assets/images/upload-file.png")}
            />
            <div>
              <text>Upload or drop a file right here</text>
              <p>JPG, PNG, GIF, PDF</p>
            </div>
          </div>
        ))}
      <input
        type='file'
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileInputChange}
        disabled={mediaLoader ? true : false}
      />
    </div>
  );
};

export default FileUpload;
