import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "react-loader-spinner";

import Dropdown from "../../../ReusabelComponents/Dropdown/Dropdown";
import SequencesDropdown from "../../../ReusabelComponents/Sequences/Dropdown/SequencesDropdown";
import { SequenceModal } from "../../../ReusabelComponents/Modals/SequenceDetails/sequenceModal";
import {
  SEQUENCES_CHANGESTATUS,
  SEQUENCES_CREATED_FALSE,
  SEQUENCES_DROPDOWN3,
  SEQUENCES_EDITED_FALSE,
  SEQUENCES_TYPE,
} from "../../../Store/Sequences/sequenceActionTypes";
import {
  createSequence,
  deleteSequence,
  updateSequence,
} from "../../../Store/Sequences/sequenceActions";
import { Confirmation } from "../../../ReusabelComponents/Modals/Confirmation/confirmation";
import "./rightDiv.css";

function RightDiv({ showAlert, selectedTemplatesEmail, selectedTemplatesSMS }) {
  const dispatch = useDispatch();
  const {
    sequenceDataToShow,
    sequence_emailTemps,
    sequence_smsTemps,
    sequence_dropwdown2option,
    sequence_saved_status,
    sequence_edited_status,
    sequence_type,
    sequence_changeStatus,
    sequence_selectedIndex,
    saveButtonLoader,
    sequence_deleted,
    sequence_changeCategory,
  } = useSelector((state) => state.sequences);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [show, setShow] = useState(false);
  const [getDelete, setDelete] = useState(false);
  const [data, setData] = useState(null);
  const [count1, setCount1] = useState(0);
  const [count2, setCount2] = useState(0);
  const [count1Error, setCount1Error] = useState(false);
  const [count2Error, setCount2Error] = useState(false);
  const [showEditConfirmation, setShowEditConfirmation] = useState(false);

  const [dropOne, setDropOne] = useState(null);
  const [dropTwo, setDropTwo] = useState(null);
  const [dropThree, setDropThree] = useState(null);

  const [showData, setShowData] = useState([]);
  const [Option, setOption] = useState([]);

  useEffect(() => {
    if (sequence_dropwdown2option && sequence_dropwdown2option.length !== 0)
      dispatch({
        type: SEQUENCES_DROPDOWN3,
        payload: { data: sequence_dropwdown2option, option: 0 },
      });
  }, [sequence_dropwdown2option]);

  const handleCLoseModal = () => {
    setShow(false);
  };

  useEffect(() => {
    if (sequence_saved_status) {
      showAlert("New sequence has been created");
      dispatch({ type: SEQUENCES_CREATED_FALSE });
      handleEditStatus(0);
    } else if (sequence_edited_status) {
      showAlert("Sequence has been updated");
      dispatch({ type: SEQUENCES_EDITED_FALSE });
      handleEditStatus(0);
    }
  }, [sequence_saved_status, sequence_edited_status]);

  const handleTypeSelect = (option) => {
    if (!option) option = "Email";
    dispatch({ type: SEQUENCES_TYPE, payload: option });
  };
  useEffect(() => {
    handleEditStatus(0);
    setShowData(
      sequence_type
        ? sequence_type === "Email"
          ? sequence_emailTemps
          : sequence_smsTemps
        : sequence_emailTemps
    );
  }, []);
  useEffect(() => {
    setShowData(
      sequence_type === "Email" ? sequence_emailTemps : sequence_smsTemps
    );
    if (sequence_changeStatus !== 0) {
      setDropOne(
        sequence_type === "Email"
          ? sequence_emailTemps[0]
          : sequence_smsTemps[0]
      );
      if (count1 !== null && count1 !== 0) {
        setDropTwo(
          sequence_type === "Email"
            ? sequence_emailTemps.length !== 0
              ? sequence_emailTemps[0]
              : null
            : sequence_smsTemps.length !== 0
            ? sequence_smsTemps[0]
            : null
        );
        if (count2 !== null && count2 !== 0) {
          setDropThree(
            sequence_type === "Email"
              ? sequence_emailTemps.length !== 0
                ? sequence_emailTemps[0]
                : null
              : sequence_smsTemps.length !== 0
              ? sequence_smsTemps[0]
              : null
          );
        }
      }
    }
  }, [sequence_type]);

  const handleEditStatus = (num) => {
    if (num === 0 || num === 1 || num === 2) {
      dispatch({ type: SEQUENCES_CHANGESTATUS, payload: num });
    }
  };

  useEffect(() => {
    if (sequenceDataToShow && sequenceDataToShow.length !== 0) {
      switch (sequence_changeStatus) {
        case 1:
          setTitle("");
          setDescription("");
          dispatch({
            type: SEQUENCES_TYPE,
            payload: "Email",
          });
          setCount1(0);
          setCount2(0);
          setDropOne(
            sequence_type
              ? sequence_type === "Email"
                ? sequence_emailTemps && sequence_emailTemps.length
                  ? sequence_emailTemps[0]
                  : ""
                : sequence_smsTemps && sequence_smsTemps.length
                ? sequence_smsTemps[0]
                : ""
              : sequence_emailTemps[0]
          );
          setDropTwo(
            sequence_type
              ? sequence_type === "Email"
                ? sequence_emailTemps && sequence_emailTemps.length
                  ? sequence_emailTemps[0]
                  : ""
                : sequence_smsTemps && sequence_smsTemps.length
                ? sequence_smsTemps[0]
                : ""
              : sequence_emailTemps[0]
          );
          setDropThree(
            sequence_type
              ? sequence_type === "Email"
                ? sequence_emailTemps && sequence_emailTemps.length
                  ? sequence_emailTemps[0]
                  : ""
                : sequence_smsTemps && sequence_smsTemps.length
                ? sequence_smsTemps[0]
                : ""
              : sequence_emailTemps[0]
          );
          if (sequence_changeCategory === "SMS")
            dispatch({ type: SEQUENCES_TYPE, payload: "SMS" });
          break;

        default:
          setTitle(
            sequenceDataToShow &&
              sequenceDataToShow[sequence_selectedIndex] &&
              sequenceDataToShow[sequence_selectedIndex].sequenceTitle
              ? sequenceDataToShow[sequence_selectedIndex].sequenceTitle
              : ""
          );
          setDescription(
            sequenceDataToShow &&
              sequenceDataToShow[sequence_selectedIndex] &&
              sequenceDataToShow[sequence_selectedIndex].sequenceDescription
              ? sequenceDataToShow[sequence_selectedIndex].sequenceDescription
              : ""
          );

          if (
            sequenceDataToShow &&
            sequenceDataToShow[sequence_selectedIndex] &&
            sequenceDataToShow[sequence_selectedIndex].sequenceType
          ) {
            dispatch({
              type: SEQUENCES_TYPE,
              payload: sequenceDataToShow[sequence_selectedIndex].sequenceType,
            });
            let data = [];
            if (
              sequenceDataToShow[sequence_selectedIndex].sequenceType &&
              sequenceDataToShow[sequence_selectedIndex].sequenceType ===
                "Email"
            ) {
              data = sequence_emailTemps;
            } else {
              data = sequence_smsTemps;
            }
            if (
              sequenceDataToShow[sequence_selectedIndex] &&
              sequenceDataToShow[sequence_selectedIndex].templateOne
            ) {
              setDropOne(
                sequenceDataToShow[sequence_selectedIndex].templateOne
              );
            }
            if (
              sequenceDataToShow[sequence_selectedIndex].templateTwo &&
              sequenceDataToShow[sequence_selectedIndex].templateTwo.temp_id
            ) {
              setCount1(
                sequenceDataToShow[sequence_selectedIndex].templateTwoAfter
              );
              if (sequenceDataToShow[sequence_selectedIndex].templateTwo) {
                setDropTwo(
                  sequenceDataToShow[sequence_selectedIndex].templateTwo
                );
              } else {
                setCount1(0);
              }
            } else {
              setCount1(0);
            }
            if (
              sequenceDataToShow[sequence_selectedIndex].templateThree &&
              sequenceDataToShow[sequence_selectedIndex].templateThree.temp_id
            ) {
              setCount2(
                sequenceDataToShow[sequence_selectedIndex].templateTwoAfter
              );
              if (sequenceDataToShow[sequence_selectedIndex].templateThree) {
                setDropThree(
                  sequenceDataToShow[sequence_selectedIndex].templateThree
                );
              } else {
                setCount2(0);
              }
            } else {
              setCount2(0);
            }
          }
          break;
      }
    }
  }, [
    sequence_changeStatus,
    sequenceDataToShow,
    sequence_selectedIndex,
    sequence_emailTemps,
    sequence_smsTemps,
  ]);

  useEffect(() => {
    filterOptions(
      sequence_type === "Email"
        ? sequence_emailTemps && sequence_emailTemps.length !== 0
          ? sequence_emailTemps
          : []
        : sequence_smsTemps && sequence_smsTemps.length !== 0
        ? sequence_smsTemps
        : [],
      sequence_type === "Email" ? selectedTemplatesEmail : selectedTemplatesSMS
    );
  }, [selectedTemplatesEmail, selectedTemplatesSMS, sequence_type]);

  const filterOptions = async (options, selectedTemplates) => {
    const selectedOptions = await options.filter((item) => {
      if (
        selectedTemplates &&
        selectedTemplates.length !== 0 &&
        selectedTemplates.includes(item.temp_id)
      )
        return item;
    });
    const newOptions = await options.filter(
      (item) =>
        item.name === localStorage.getItem("username") &&
        !selectedTemplates.includes(item.temp_id)
    );
    const removeOptions = await options.filter(
      (item) =>
        item.name !== localStorage.getItem("username") &&
        !selectedTemplates.includes(item.temp_id)
    );
    const sortedOptions = [...selectedOptions, ...newOptions, ...removeOptions];
    setOption(sortedOptions);
    return sortedOptions;
  };

  return (
    <>
      <div className='rightNav'>
        <p>
          <u>
            {sequence_changeStatus === 0
              ? `Sequence Details`
              : sequence_changeStatus === 1
              ? "New Sequence"
              : "Edit Sequence Details"}
          </u>
        </p>
        {sequence_changeStatus !== null && sequence_changeStatus === 0 ? (
          sequenceDataToShow &&
          sequenceDataToShow.length !== 0 &&
          sequenceDataToShow[sequence_selectedIndex] &&
          sequenceDataToShow[sequence_selectedIndex].createdBy
            .toString()
            .toLowerCase() ===
            localStorage.getItem("username").toString().toLowerCase() && (
            <div className='actionButtons'>
              <img
                className='actionButtonsImg'
                src={require("../../../assets/images/edit-button.png")}
                onClick={() => {
                  handleEditStatus(2);
                }}
              />
              {sequenceDataToShow && sequenceDataToShow.length !== 0 ? (
                !sequence_deleted ? (
                  <img
                    className='actionButtonsImg'
                    src={require("../../../assets/images/deleteButton1.png")}
                    onClick={() => {
                      if (
                        sequenceDataToShow[sequence_selectedIndex] &&
                        sequenceDataToShow[sequence_selectedIndex]._id
                      );
                      setDelete(true);
                    }}
                  />
                ) : (
                  <Loader
                    style={{ marginLeft: "10px" }}
                    type='Oval'
                    color='black'
                    height={30}
                    width={30}
                  />
                )
              ) : null}
            </div>
          )
        ) : !saveButtonLoader ? (
          <div className='actionButtons'>
            <img
              className='sequenceCancelButton'
              src={require("../../../assets/images/sequence-cross.png")}
              onClick={() => {
                handleEditStatus(0);
              }}
            />

            <div
              className='saveSequenceContainer'
              onClick={() => {
                if (sequence_changeStatus === 1) {
                  dispatch(
                    createSequence({
                      sequenceTitle: title,
                      sequenceDescription: description,
                      sequenceType: sequence_type,
                      templateOne: dropOne
                        ? dropOne
                        : sequence_type
                        ? sequence_emailTemps[0]
                        : sequence_smsTemps[0],
                      templateTwo:
                        count1 !== 0 ? (dropTwo ? dropTwo : null) : null,
                      templateTwoAfter: count1,
                      templateThree:
                        count1 !== 0 && count2 !== 0
                          ? dropThree
                            ? dropThree
                            : null
                          : null,
                      templateThreeAfter: count2,
                    })
                  );
                } else if (sequence_changeStatus === 2) {
                  setShowEditConfirmation(true);
                }
              }}
            >
              <img src={require("../../../assets/images/blue-tick.png")} />
              <p>Save Sequence</p>
            </div>
          </div>
        ) : (
          <Loader type='Oval' color='black' height={30} width={30} />
        )}
      </div>
      <div className='rightDivInner'>
        <div className='sequenceDetails'>
          <div className='titleInput'>
            <p className='titleInputLabel'>Title</p>
            <input
              className={sequence_changeStatus === 0 && "disabledInput"}
              placeholder='Enter title of the new sequence..'
              disabled={sequence_changeStatus === 0 ? true : false}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className='typeDropDown'>
            <p>Sequence Type</p>
            <div className='drop'>
              <Dropdown
                options={["Email", "SMS"]}
                defaultOption='Email'
                onSelect={handleTypeSelect}
                sequence={true}
                sequenceType={true}
                optionSelected={sequence_type ? sequence_type : "Email"}
                active={sequence_changeStatus !== 0 ? true : false}
                roundBorders={false}
              />
            </div>
          </div>
        </div>
        <div className='titleInput'>
          <p className='titleInputLabel'>Description</p>
          <textarea
            className={sequence_changeStatus === 0 && "disabledInput"}
            placeholder='Enter description of the new sequence'
            value={description}
            disabled={sequence_changeStatus === 0 ? true : false}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        <p className='sequenceSubheading'>
          <u>Sequence</u>
        </p>

        {/* =========1======== */}

        <div className='startDivContainer'>
          <div className='startDiv'>
            <p>Start</p>
            <div className='ellipse1'></div>
            <div className='line1'></div>
          </div>
        </div>

        <div className='sequencePattern'>
          <div className='sequenceCount'>
            <p>1.</p>
          </div>
          <div className='sequenceDropDown'>
            <SequencesDropdown
              title={
                sequence_changeStatus !== 1
                  ? dropOne
                    ? dropOne.label
                    : "Select template..."
                  : "Select template..."
              }
              onSelect={(option) => {
                if (option) {
                  setDropOne(option);
                }
              }}
              optionSelected={
                sequence_changeStatus == 1
                  ? Option && Option.length !== 0
                    ? Option[0]
                    : []
                  : dropOne
                  ? dropOne
                  : Option && Option.length !== 0
                  ? Option[0]
                  : []
              }
              options={Option}
              active={sequence_changeStatus !== 0 ? true : false}
            />
          </div>
          {dropOne && (
            <div
              className='viewDiv'
              onClick={() => {
                setShow(true);
                setData(dropOne);
              }}
            >
              <img
                src={require("../../../assets/images/sequence-view-icon.png")}
                alt='View Details'
              />
            </div>
          )}
        </div>

        {/* =========2======== */}

        <div className='middleDiv'>
          <div className='line-div'>
            <div className='line1'></div>
          </div>
          <div className='scheduleAfter'>
            <div className='scheduleLine'></div>
            <div
              className={`scheduleText ${count1Error && "scheduleTextError"}`}
            >
              <p>{`Schedule after day(s): ${
                sequence_changeStatus !== null && sequence_changeStatus === 0
                  ? count1
                  : ""
              }`}</p>
              {sequence_changeStatus !== null &&
                sequence_changeStatus !== 0 && (
                  <div className='DaysCounter'>
                    <button
                      onClick={() => {
                        if (count1 !== 0) {
                          if (count1 - 1 === 0) setCount2(0);
                          setCount1(count1 - 1);
                        }
                      }}
                    >
                      -
                    </button>
                    <p>{count1}</p>
                    <button
                      onClick={() => {
                        setCount1(count1 + 1);
                        setCount1Error(false);
                        setCount2Error(false);
                      }}
                    >
                      +
                    </button>
                  </div>
                )}
            </div>
            <div className='scheduleLine'></div>
          </div>
        </div>

        <div
          className={`sequencePattern ${
            sequence_changeStatus !== null &&
            sequence_changeStatus !== 0 &&
            count1 === 0
              ? "sequencePatternCenter"
              : "sequencePatternStart"
          }`}
        >
          <div className='sequenceCount'>
            <p>2.</p>
          </div>
          <div
            className='sequenceDropDown'
            onClick={() => {
              if (sequence_changeStatus !== 0 && count1 === 0) {
                showAlert("Number of days between the templates can not be 0");
                setCount1Error(true);
              }
            }}
          >
            <SequencesDropdown
              title={
                sequence_changeStatus !== 1 && count1 !== 0
                  ? dropTwo
                    ? dropTwo.label
                    : "Select template..."
                  : "Select template..."
              }
              onSelect={(option) => {
                if (option) setDropTwo(option);
              }}
              optionSelected={
                sequence_changeStatus == 1
                  ? Option && Option.length !== 0
                    ? Option[0]
                    : []
                  : dropTwo
                  ? dropTwo
                  : Option && Option.length !== 0
                  ? Option[0]
                  : []
              }
              options={Option}
              active={
                sequence_changeStatus !== 0 && count1 !== 0 ? true : false
              }
            />
          </div>
          {sequence_changeStatus !== 0 && count1 !== 0 && (
            <div
              className='viewDiv'
              onClick={() => {
                setCount1(0);
                setCount2(0);
              }}
            >
              <img
                src={require("../../../assets/images/sequence-delete.png")}
                alt='View Details'
              />
            </div>
          )}
          {((sequence_changeStatus !== null &&
            sequence_changeStatus === 0 &&
            sequence_dropwdown2option &&
            count1 !== 0) ||
            count1 !== 0) && (
            <div
              className='viewDiv'
              onClick={() => {
                setShow(true);
                setData(dropTwo);
              }}
            >
              <img
                src={require("../../../assets/images/sequence-view-icon.png")}
                alt='View Details'
              />
            </div>
          )}
        </div>

        {/* =========3======== */}

        <div className='middleDiv'>
          <div className='line-div'>
            <div className='line1'></div>
          </div>
          <div className='scheduleAfter'>
            <div className='scheduleLine'></div>
            <div
              className={`scheduleText ${count2Error && "scheduleTextError"}`}
            >
              <p>{`Schedule after day(s): ${
                sequence_changeStatus !== null && sequence_changeStatus === 0
                  ? count2
                  : ""
              }`}</p>
              {sequence_changeStatus !== null &&
                sequence_changeStatus !== 0 && (
                  <div className={`DaysCounter`}>
                    <button
                      onClick={() => {
                        if (count1 !== 0 && count2 !== 0) setCount2(count2 - 1);
                      }}
                    >
                      -
                    </button>
                    <p>{count2}</p>
                    <button
                      onClick={() => {
                        if (sequence_changeStatus !== 0 && count1 !== 0) {
                          setCount2(count2 + 1);
                          setCount2Error(false);
                        }
                      }}
                    >
                      +
                    </button>
                  </div>
                )}
            </div>
            <div className='scheduleLine'></div>
          </div>
        </div>

        <div
          className={`sequencePattern ${
            sequence_changeStatus !== null &&
            sequence_changeStatus !== 0 &&
            count2 === 0
              ? "sequencePatternCenter"
              : "sequencePatternStart"
          }`}
        >
          <div className='sequenceCount'>
            <p>3.</p>
          </div>
          <div
            className='sequenceDropDown'
            onClick={() => {
              if (sequence_changeStatus !== 0 && count2 === 0) {
                showAlert("Number of days between the templates can not be 0");
                if (count1 == 0) setCount1Error(true);
                setCount2Error(true);
              }
            }}
          >
            <SequencesDropdown
              title={
                sequence_changeStatus !== 1 && count1 !== 0 && count2 !== 0
                  ? dropThree
                    ? dropThree.label
                    : "Select template..."
                  : "Select template..."
              }
              top={true}
              onSelect={(option) => {
                setDropThree(option);
              }}
              optionSelected={
                sequence_changeStatus == 1
                  ? Option && Option.length !== 0
                    ? Option[0]
                    : []
                  : dropThree
                  ? dropThree
                  : Option && Option.length !== 0
                  ? Option[0]
                  : []
              }
              options={Option}
              active={
                sequence_changeStatus !== 0 && count1 !== 0 && count2 !== 0
                  ? true
                  : false
              }
            />
          </div>

          {count1 !== 0 && count2 !== 0 && sequence_changeStatus !== 0 && (
            <div
              className='viewDiv'
              onClick={() => {
                setCount2(0);
              }}
            >
              <img
                src={require("../../../assets/images/sequence-delete.png")}
                alt='View Details'
              />
            </div>
          )}
          {((sequence_changeStatus !== null &&
            sequence_changeStatus === 0 &&
            count2 !== 0) ||
            count2 !== 0) && (
            <div
              className='viewDiv'
              onClick={() => {
                setShow(true);
                setData(dropThree);
              }}
            >
              <img
                src={require("../../../assets/images/sequence-view-icon.png")}
                alt='View Details'
              />
            </div>
          )}
        </div>
        <div className='endDiv'>
          <div className='line1'></div>
          <img src={require("../../../assets/images/down_arrow.png")} />
          <p>End</p>
        </div>
      </div>

      {show && data && (
        <SequenceModal
          handleShowModal={handleCLoseModal}
          onHide={() => setShow(false)}
          setData={setData}
          data={data}
        />
      )}
      {showEditConfirmation && (
        <Confirmation
          show={showEditConfirmation}
          onHide={() => {
            setShowEditConfirmation(false);
          }}
          title={"Are you sure you want to edit this sequence?"}
          description={
            "The changes will not be applied to the already set sequences."
          }
          yesTitle={"Confirm"}
          yesFunc={() => {
            if (
              sequenceDataToShow &&
              sequenceDataToShow[sequence_selectedIndex]
            )
              dispatch(
                updateSequence(sequenceDataToShow[sequence_selectedIndex]._id, {
                  sequenceTitle: title,
                  sequenceDescription: description,
                  sequenceType: sequence_type,
                  templateOne: dropOne
                    ? dropOne
                    : sequence_type
                    ? sequence_emailTemps[0]
                    : sequence_smsTemps[0],
                  templateTwo: dropTwo ? dropTwo : null,
                  templateTwoAfter: count1,
                  templateThree:
                    count1 !== 0 && count2 !== 0
                      ? dropThree
                        ? dropThree
                        : null
                      : null,
                  templateThreeAfter: count2,
                })
              );
          }}
        />
      )}

      {getDelete && (
        <Confirmation
          show={getDelete}
          onHide={() => setDelete(false)}
          title='Delete Sequence'
          description='Are you sure you want to delete this sequence'
          yesFunc={() =>
            dispatch(
              deleteSequence(sequenceDataToShow[sequence_selectedIndex]._id)
            )
          }
        />
      )}
    </>
  );
}

export default RightDiv;
