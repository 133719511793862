import axios from "axios";
import { baseURL } from "../components/Helpers/Constants";

const API = axios.create({
	baseURL: baseURL(),
	headers: {
		"x-access-token": localStorage.getItem("jwtToken"),
		"app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
		socket_id: localStorage.getItem("socket_id"),
	},
});

//Templates
//========================================================= Email Templates =================================================================//

export const getAllTemplates = () =>
	API.post("/api/v1/users/all-selected-templates", {
		email: localStorage.getItem("email"),
	});

export const updateEmailTemplate = (data) =>
	API.post("/api/v1/users/update-template-message", data);

export const addEmailTemplate = (data) =>
	API.post("/api/v1/users/add-template-message", data);

export const updateDefaultTemplate = (data) =>
	API.post("/api/v1/users/updateDefaultTemplate", data);

export const deleteEmailTemplate = (data) =>
	API.post("/api/v1/users/delete-template-message", data);

export const selectedEmailTemplate = (data) =>
	API.post("/api/v1/users/selected-template", data);

export const getSelectedTemplates = (data) =>
	API.get("/api/v1/users/getSelectedTemplates", data);

export const reorderSelectedTemplates = (data) =>
	API.post("/api/v1/users/reorderSelectedTemplates", data);

//========================================================= Media and Files =================================================================//

export const getAllDocuments = (page) =>
	API.get(`/api/v1/mediaFiles/getAllDocuments/${page}`);

export const getDocuments = () => API.get(`/api/v1/mediaFiles/getDocuments`);

export const getSpecificUserDocuments = (page, data) =>
	API.post(`/api/v1/mediaFiles/getSpecificUserDocuments/${page}`, data);

export const getTotalNumber = () =>
	API.get(`/api/v1/mediaFiles/getTotalNumber`);

export const addDocuments = (data) =>
	API.post(`/api/v1/mediaFiles/addDocuments`, data);

export const updateDocumentName = (id, data) =>
	API.post(`/api/v1/mediaFiles/updateDocumentName/${id}`, data);

export const checkDocumentFileName = (data) =>
	API.post(`/api/v1/mediaFiles/checkDocumentFileName`, data);

export const searchDocuments = (id, data) =>
	API.post(`/api/v1/mediaFiles/searchDocuments/${id}`, data);

export const searchSpecificUserDocuments = (id, data) =>
	API.post(`/api/v1/mediaFiles/searchSpecificUserDocuments/${id}`, data);

export const SendTemplate = (id, data) =>
	API.post(`/api/v1/mediaFiles/SendTemplate/${id}`, data);

export const deleteDocument = (id) =>
	API.delete(`/api/v1/mediaFiles/deleteDocument/${id}`);

//========================================================= Sequences ======================================================================//

export const fetchAllSequences = (data) =>
	API.get(`/api/v1/sequencesAutomation/getAllSequences`, data);

export const createSequence = (data) =>
	API.post(`/api/v1/sequencesAutomation/addSequences`, data);

export const updateSequence = (id, data) =>
	API.post(`/api/v1/sequencesAutomation/updateSequence/${id}`, data);

export const searchSequence = (data) =>
	API.post(`/api/v1/sequencesAutomation/searchSequence`, data);

export const deleteSequence = (id) =>
	API.delete(`/api/v1/sequencesAutomation/deleteSequence/${id}`);

//========================================================= Hotlist ======================================================================//

export const assignSequence = (id, data) =>
	API.post(`/api/v1/sequencesAutomation/assignSequence/${id}`, data);

export const removeSequence = (id, data) =>
	API.post(`/api/v1/sequencesAutomation/unassignSequence/${id}`, data);

export const fetchSpecificSequence = (id) =>
	API.get(`/api/v1/sequencesAutomation/getSpecificSequence/${id}`);

export const startPauseSequence = (id, data) =>
	API.post(`/api/v1/sequencesAutomation/startPauseSequence/${id}`, data);

export const startPauseSequenceWithNewDate = (id, data) =>
	API.post(
		`/api/v1/sequencesAutomation/startPauseSequenceWithNewDate/${id}`,
		data
	);

//========================================================= Leads ======================================================================//

export const setReminder = (data) => API.post(`/api/v1/leads/reminder`, data);

export const removeReminder = (id, data) =>
	API.post(`/api/v1/leads/deleteReminder/${id}`, data);

export const updateReminder = (id, data) =>
	API.post(`/api/v1/leads/updateReminderDescription/${id}`, data);

export const updateLeadCategory = (id, data) =>
	API.post(`/api/v1/leads/updateLeadCategory/${id}`, data);

export const sendReferralReview = (data) =>
	API.post(`api/v1/leads/auto-followup`, data);

export const sendPdfMms = (data) => API.post(`api/v1/leads/lead-pdf-mms`, data);
export const fetchFilteredLeads = (params) => {
	console.log("payload", params);
	return API.get(`api/v1/leads/filteredLeads`, { params });
};

//========================================================= Users ======================================================================//

export const updateUserSelectedOptions = (data) =>
	API.post(`/api/v1/users/updateUserSelectedOptions`, data);

export const updateUserSelectedFilters = (data) =>
	API.post(`/api/v1/users/updateUserSelectedFilters`, data);
