/* eslint-disable eqeqeq */
import React from "react";
import FittedImage from "react-fitted-image";
import Popup from "reactjs-popup";
import { confirmAlert } from "react-confirm-alert"; // Import

import DescriptionModal from "../ReusabelComponents/DescriptionModal";
import { EditModal } from "../ReusabelComponents/EditModal";
import { IsTextHTML, convertHTMLToPlainText } from "../Helpers/Constants";

import linkIcon from "../assets/images/open_url_icon_thick.png";
import descrpIcon from "../assets/images/copy_icon.png";
import copyIcon from "../assets/images/edit_icon_blue.png";
import ExportModal from "../ReusabelComponents/LeadDetailModal";

const uploadImage = require("../assets/images/Download_pdf.svg");

const copy = require("clipboard-copy");
// import ContentEditable from 'react-contenteditable';
class EditableTextView extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			row: props?.row,
			isInEditMode: false,
			showDescrpModal: false,
			displayText: "",
			displayText2: "",
			isHTML: false,
			showExportModal: false,
			popUpOpen: false,
			position:
				props?.row?.index % 30 < 6
					? ["right top"]
					: props?.row?.index % 30 > 26
					? ["right bottom"]
					: ["right center"],
		};
		this.runInitialFunction();

		this.includeKeys = [
			"name",
			"svc_requested",
			"address",
			"email",
			"phone_number",
			"utc_time_stamp",
			// "source",
			// "urgency",
			// "assigned_to",
			// "category",
			// "channel",
		];
	}

	runInitialFunction = async () => {
		if (this.props?.row.value != undefined) {
			await this.firstFunction();
			await this.secondFunction();
		}
	};

	handleCloseExportModal = () => {
		this.setState({ showExportModal: false });
		this.setState({ popUpOpen: false });
	};

	handlePopupOpen = () => {
		this.setState({ popUpOpen: true });
	};

	handlePopupClose = () => {
		this.setState({ popUpOpen: false });
	};

	async firstFunction() {
		let temp = this.props?.row?.value ? this.props?.row?.value : "";
		if (this.props?.columnName === "urgency") {
			temp = temp.replace(".0", "");
		} else if (this.props?.columnName == "phone_number") {
			//Phone No format fixed
			var cellNo;
			cellNo = this.props?.row?.value ? this.props?.row?.value : "";
			var cellNo = cellNo.replace(/\D/g, "");
			var index = 3;
			var result = cellNo.slice(0, index) + "-" + cellNo.slice(index);
			index = 7;
			cellNo = result.slice(0, index) + "-" + result.slice(index);
			temp = cellNo;
		} else if (this.props?.columnName == "notes") {
			if (this.props?.row?.value != undefined) {
				temp =
					this.props?.row?.value[this.props?.row?.value?.length - 1]
						?.note; //.toString().split('\n').join(',');
			}
		}
		this.text = temp;
		if (this.text == null) {
		}
	}
	a = 0;
	async secondFunction() {
		if (this.props.columnName == "notes") {
			if (this.props.row.value != undefined) {
				let temp =
					this.props.row.value[this.props.row.value.length - 1].note;
				this.text = temp;
			}
		}
		if (this.props.columnName == "svc_requested") {
			this.setState({
				isHTML: IsTextHTML(
					this.text?.length > 0 ? this.text : "plain text"
				),
			});
			this.setState({
				displayText2: IsTextHTML(
					this.text?.length > 0 ? this.text : "plain text"
				)
					? convertHTMLToPlainText(this.text)
					: this.text,
			});
			this.setState({ displayText: this.text });
		} else this.setState({ displayText: this.text });
		if (this.state.displayText == null) {
			this.setState({ displayText: "" });
		}
		if (
			this.props.selectedColumn == 3 &&
			this.props.columnName == "notes"
		) {
			if (
				this.state.displayText == null ||
				this.state.displayText == ""
			) {
				this.setState({ displayText: "Emty Notes" });
			}
		} else if (
			this.state.displayText == null ||
			this.state.displayText == ""
		) {
			this.setState({ displayText: "Empty" });
		}
	}

	componentDidMount() {
		this.setState({ row: this.props.row });
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.columnName == "notes") {
			if (this.props.row.value.length > 0) {
				this.text =
					this.props.row.value[this.props.row.value.length - 1].note;
			}
		}
		if (
			prevState.row.original[this.props.columnName] !==
			this.props.row.original[this.props.columnName]
		) {
			this.runInitialFunction();
		}
	}

	confirmAction = (title, message, yesCallback, noCallback) => {
		confirmAlert({
			title: title,
			message: message,
			buttons: [
				{
					label: "Yes",
					onClick: () => yesCallback(),
				},
				{
					label: "No",
					onClick: () => noCallback(),
				},
			],
		});
	};

	submitValue = (value) => {
		this.text = value;
		this.props.handleSubmit(
			this.props.columnName,
			this.props.row.original[this.props.columnName],
			value,
			this.props.row.original._id
		);
		this.setState({
			isInEditMode: false,
		});
	};
	onCancelEditModal = () => {
		this.setState({
			isInEditMode: false,
		});
	};

	onCancelDescrpModal = () => {
		this.setState({
			showDescrpModal: false,
		});
	};
	onPopupOpen = () => {
		this.setState({ copied: false });
	};

	render() {
		return (
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					width: this.props.columnName == "name" ? 200 : "100%",
				}}
			>
				<Popup
					onOpen={this.handlePopupOpen}
					open={this.state.popUpOpen}
					style={{
						position: "absolute",
						zIndex: 9999,
						wordBreak: "break-all",
						width: "100%",
					}}
					on={"hover"}
					trigger={
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							{this.props.columnName == "name" && (
								<span
									style={{
										cursor: "pointer",
										margin: 10,
									}}
									onClick={() => {
										this.setState({
											showExportModal: true,
											popUpOpen: false,
										});
									}}
								>
									<img
										src={uploadImage}
										style={{
											height: 20,
											width: 20,
											marginTop:
												this.props.selectedTabIndex ===
												3
													? 0
													: 0,
										}}
									/>
								</span>
							)}
							{this.state.showExportModal && (
								<ExportModal
									showModal={this.state.showExportModal}
									handleClose={this.handleCloseExportModal}
									dataObject={this.props.row.original}
									includeKeys={this.includeKeys}
									showAlert={this.props.showAlert}
									handlePopupClose={this.handlePopupClose}
								/>
							)}
						</div>
					}
					position={this.state.position}
				>
					Export to PDF
				</Popup>

				<Popup
					onOpen={this.onPopupOpen}
					style={{
						position: "absolute",
						zIndex: 9999,
						wordBreak: "break-all",
						width: "100%",
					}}
					on={"hover"}
					trigger={
						<div
							className={this.props.editableClassName}
							style={{
								overflow: "hidden",
								justifyContent: "space-between",
								alignItems: "center",
								display: "flex",
								flexDirection: "row",
								textOverflow: "ellipsis",
							}}
						>
							<span
								style={{
									alignSelf: "center",
									color: "black",
									cursor: "pointer",
									display: "block",
									wordBreak: "break-all",
									textOverflow: "ellipsis",
									whiteSpace: "nowrap",
									overflow: "hidden",
									// paddingTop:
									// 	this.props.selectedColumn == 3 &&
									// 	this.props.columnName == "name"
									// 		? 10
									// 		: null,
									paddingLeft:
										this.props.selectedColumn == 3 &&
										this.props.columnName == "name"
											? 5
											: null,
									paddingRight: 15,
									// backgroundColor: this.props.selectedColumn == 3 && this.props.columnName == "name" ? "green" : null
								}}
								onClick={(e) => {
									if (this.props.columnName == "name") {
										window.open(
											"https://josecastro.harvestapp.com/estimates/" +
												this.props.row.original
													.harvestEstimateID
										);
									} else {
										if (e.target.innerText != "Empty") {
											copy(e.target.innerText);
											this.props.showAlert(
												"Copied to clipboard"
											);
										}
									}
								}}
							>
								{this.props.columnName == "notes" ||
								this.props.columnName == "extra_links"
									? this.state.displayText
											.toString()
											.split("\n")
											.reverse()
											.join(",")
									: this.props.columnName ===
											"svc_requested" && this.state.isHTML
									? this.state.displayText2.toString()
									: this.state.displayText.toString()}
								{this.props.selectedColumn == 3 &&
								this.props.columnName == "notes" ? (
									<span
										style={{
											textDecoration: "underline",
											paddingLeft: 3,
										}}
									>
										{" "}
										{
											this.props.row.original.updated_at.split(
												"T"
											)[0]
										}
									</span>
								) : null}
							</span>
							<EditModal
								defaultValue={this.text}
								show={this.state.isInEditMode}
								submitValue={this.submitValue}
								onCancelEditModal={this.onCancelEditModal}
								showAlert={this.props.showAlert}
								isDescription={
									this.props.columnName === "svc_requested"
								}
							/>
							{this.state.showDescrpModal ? (
								<DescriptionModal
									show={this.state.showDescrpModal}
									descriptionModal={
										this.props.row.original.svc_requested
									}
									onCancelDescrpModal={
										this.onCancelDescrpModal
									}
								/>
							) : null}
						</div>
					}
					position={this.state.position}
				>
					{this.props.isClickable ? (
						<div
							className={
								// this.props.columnName === 'email' ||
								// { a: this.a } //if not working uncomment this and original was //{a}
								this.a
							}
							style={{ whiteSpace: "normal" }}
						>
							<span>
								<a
									style={{ overflow: "hidden" }}
									href={this.props.urlPrefix + this.text}
									target="_blank"
									// className="editable-text"
								>
									{this.props.columnName == "notes" ||
									this.props.columnName == "extra_links"
										? this.state.displayText
												.toString()
												.split("\n")
												.reverse()
												.map((e, i) => {
													if (
														this.props.columnName ==
															"extra_links" &&
														e != "Empty"
													) {
														return (
															<a
																href={e}
																target="_blank"
																key={"line" + i}
															>
																{e} <br />
															</a>
														);
													} else {
														return (
															<p key={"line" + i}>
																{e} <br />
															</p>
														);
													}
												})
										: this.state.displayText.toString()}
									{this.state.displayText != "" && (
										<FittedImage
											fit="auto"
											src={linkIcon}
											style={{
												width: 15,
												height: 15,
												marginLeft: 5,
												cursor: "pointer",
											}}
										/>
									)}
								</a>
								<button
									style={{
										color: "#0a84fe",
										fontSize: 10,
										cursor: "pointer",
										merginLeft: 10,
										background: "none",
										border: "none",
									}}
									onClick={() => {
										this.setState({ isInEditMode: true });
									}}
								>
									{this.state.displayText != "" ? (
										!this.state.copied ? (
											<FittedImage
												fit="auto"
												src={copyIcon}
												style={{
													width: 15,
													height: 15,
													marginLeft: 5,
													cursor: "pointer",
												}}
											/>
										) : (
											<div>Copied</div>
										)
									) : null}
								</button>
							</span>
						</div>
					) : (
						<div
							className={this.a}
							style={{
								whiteSpace: "normal",
								display: "flex",
								flexDirection: "row",
								justifyContent: "flex-start",
								alignItems: "flex-start",
							}}
						>
							{this.props.columnName == "notes" ||
							this.props.columnName == "extra_links" ? (
								this.state.displayText
									.toString()
									.split("\n")
									.reverse()
									.map((e, i) => {
										if (
											this.props.columnName ==
												"extra_links" &&
											e != "Empty"
										) {
											return (
												<a
													href={e}
													target="_blank"
													key={"line" + i}
												>
													{e} <br />
												</a>
											);
										} else {
											return (
												<p key={"line" + i}>
													{e} <br />
												</p>
											);
										}
									})
							) : this.props.columnName === "svc_requested" &&
							  this.state.isHTML ? (
								<div
									style={{
										width: "fit-content",
									}}
									dangerouslySetInnerHTML={{
										__html: this.state.displayText.toString(),
									}}
								></div>
							) : (
								this.state.displayText.toString()
							)}

							{true && (
								<button
									style={{
										color: "#0a84fe",
										fontSize: 10,
										cursor: "pointer",
										merginLeft: 10,
										background: "none",
										border: "none",
									}}
									onClick={() => {
										this.setState({ isInEditMode: true });
									}}
								>
									{this.state.displayText != "" ? (
										!this.state.copied ? (
											<FittedImage
												fit="auto"
												src={copyIcon}
												style={{
													width: 15,
													height: 15,
													marginLeft: 5,
													cursor: "pointer",
												}}
											/>
										) : (
											<div>Copied</div>
										)
									) : null}
								</button>
							)}

							{this.props.columnName == "name" &&
								this.props.selectedColumn == 3 && (
									<button
										style={{
											color: "#0a84fe",
											fontSize: 10,
											cursor: "pointer",
											merginLeft: 10,
											background: "none",
											border: "none",
										}}
										onClick={() => {
											this.setState({
												showDescrpModal: true,
											});
										}}
									>
										<FittedImage
											fit="auto"
											src={descrpIcon}
											style={{
												width: 15,
												height: 15,
												marginLeft: 5,
												cursor: "pointer",
											}}
										/>
									</button>
								)}
						</div>
					)}
				</Popup>
			</div>
		);
	}
}
export default EditableTextView;
