import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "react-loader-spinner";

import SequencesTile from "../../../ReusabelComponents/Sequences/Tile/SequencesTile";
import Dropdown from "../../../ReusabelComponents/Dropdown/Dropdown";
import {
  SEARCH_SEQUENCES,
  SEQUENCES_ALL,
  SEQUENCES_EMAIL,
  SEQUENCES_FILTER_BY_USERS,
  SEQUENCES_SMS,
  SEQUENCES_SWITCH_CATEGORY,
} from "../../../Store/Sequences/sequenceActionTypes";
import "./leftDiv.css";

function LeftDiv({ users }) {
  const dispatch = useDispatch();
  const {
    sequence_size,
    sequenceDataToShow,
    sequence_changeCategory,
    currentUser,
    sequenceisLoading,
  } = useSelector((state) => state.sequences);

  const [options, setOptions] = useState("Recent");
  const [input, SetInput] = useState("");

  useEffect(() => setOptions(users), [users]);

  const handleOptionSelect = (option) => {
    if (!option) option = "All";
    dispatch({ type: SEQUENCES_FILTER_BY_USERS, payload: option });
  };

  const handleSearch = (searchText) => {
    dispatch({ type: SEARCH_SEQUENCES, payload: searchText });
    dispatch({
      type: SEQUENCES_SWITCH_CATEGORY,
      payload: sequence_changeCategory,
    });
    dispatch({ type: SEQUENCES_FILTER_BY_USERS, payload: "All" });

    if (sequence_changeCategory === "All") dispatch({ type: SEQUENCES_ALL });
    else if (sequence_changeCategory === "Email")
      dispatch({ type: SEQUENCES_EMAIL });
    else dispatch({ type: SEQUENCES_SMS });
  };

  const handleEnterPress = (event) => {
    if (event.key === "Enter") {
      handleSearch(input);
    }
  };

  return (
    <div className='leftDivMainContainer'>
      <div className=' sequencesNav'>
        <p
          className={`sequencesNavp ${
            sequence_changeCategory === "All" && "sequencesNavPBold"
          }`}
          onClick={() => {
            dispatch({ type: SEQUENCES_SWITCH_CATEGORY, payload: "All" });
            dispatch({ type: SEQUENCES_ALL });
          }}
        >{`All (${sequence_size})`}</p>
        <p
          className={`sequencesNavp ${
            sequence_changeCategory === "Email" && "sequencesNavPBold"
          }`}
          onClick={() => {
            dispatch({ type: SEQUENCES_SWITCH_CATEGORY, payload: "Email" });
            dispatch({ type: SEQUENCES_EMAIL });
          }}
        >
          Email Sequences
        </p>
        <p
          className={`sequencesNavp ${
            sequence_changeCategory === "SMS" && "sequencesNavPBold"
          }`}
          onClick={() => {
            dispatch({ type: SEQUENCES_SWITCH_CATEGORY, payload: "SMS" });
            dispatch({ type: SEQUENCES_SMS });
          }}
        >
          SMS Sequences
        </p>
        <Dropdown
          options={options}
          defaultOption='Recent'
          onSelect={handleOptionSelect}
          sequence={true}
          optionSelected={currentUser}
        />
      </div>
      <div className='searchBar'>
        <input
          placeholder='Search by Title, created by'
          value={input}
          onKeyPress={handleEnterPress}
          onChange={(e) => {
            if (!e.target.value) handleSearch("");
            SetInput(e.target.value);
          }}
        />
        <img
          src={require("../../../assets/images/searchIcon.png")}
          alt='search'
          onClick={() => {
            handleSearch(input);
          }}
        />
      </div>
      <div className='sequencesTiles'>
        {!sequenceisLoading ? (
          sequenceDataToShow ? (
            sequenceDataToShow.length !== 0 ? (
              sequenceDataToShow.map((item, index) => (
                <SequencesTile
                  key={index}
                  index={index}
                  title={item.sequenceTitle}
                  type={item.sequenceType}
                  description={item.sequenceDescription}
                  creator={item.createdBy}
                />
              ))
            ) : (
              <div className='loaderDiv1'>No sequences to show</div>
            )
          ) : (
            <div className='loaderDiv1'>
              <Loader
                style={{ marginLeft: "10px" }}
                type='Oval'
                color='black'
                height={30}
                width={30}
              />
            </div>
          )
        ) : (
          <div className='loaderDiv1'>
            <Loader
              style={{ marginLeft: "10px" }}
              type='Oval'
              color='black'
              height={30}
              width={30}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default LeftDiv;
