import React from "react";
import { Pie } from "react-chartjs-2";

let newLegendClickHandler = function () {    
  return false;
};

function PieChart({ chartData }) {
  return (
    <Pie
      options={{
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          position: 'left',
          onClick: newLegendClickHandler,
        }
      }}
      data={chartData}
    />
  );
}

export default PieChart;
