import React, { useState, useEffect } from "react";
import "react-table-hoc-draggable-columns/dist/styles.css";
import "react-table/react-table.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import * as api from "../../../api/api";
import MediaAndFiles from "../MediaAndFiles/MediaAndFiles";
import Mail from "../../assets/images/mail.png";
import SMS from "../../assets/images/sms_add_template.png";
import TemplateAddUpdate from "../../ReusabelComponents/TemplateAddUpdate/TemplateAddUpdate";
import Template from "../../ReusabelComponents/Templates/Template";
import "./templates.css";
import FileUpload from "../../ReusabelComponents/TemplateAddUpdate/FileUpload/FileUpload";
import { checkFileExtension } from "../../Helpers/Constants";
import config from "../../Helpers/config";
import Sequences from "../Sequences/Sequences";
import { useDispatch, useSelector } from "react-redux";
import { SEQUENCES_CHANGESTATUS } from "../../Store/Sequences/sequenceActionTypes";
const AWS = require("aws-sdk");

AWS.config.update({
  accessKeyId: config.AWS_ACCESS_KEY_ID,
  secretAccessKey: config.AWS_SECRET_ACCESS_KEY,
  region: config.AWS_region,
});

const Templates = ({ showAlert, users, headerCollapse }) => {
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const [getId, setGetId] = useState("0");
  const [showAddModal, setShowAddModal] = useState(false);
  const [updateLists, setUpdateLists] = useState(false);
  const [data, setData] = useState([]);
  const [selectedEmailTemplates, setSelectedEmailTemplates] = useState([]);
  const [updateSelectedEmail, setUpdateSelectedEmail] = useState(false);
  const [allEmailTemplates, setAllEmailTemplates] = useState([]);
  const [allSmsTemplates, setAllSmsTemplates] = useState([]);
  const [selectedSmsTemplates, setSelectedSmsTemplates] = useState("");
  const [emailDefaultTemplate, setEmailDefaultTemplate] = useState("");
  const [smsDefaultTemplate, setSmsDefaultTemplate] = useState("");
  const [idToUpdate, setIdToUpdate] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);
  const [selectedTemplates, setSelectedTemplates] = useState([]);
  const [selectedOption, setSelectedOption] = useState("Selected Templates");
  const [tab, setTab] = useState("Email Templates");
  const [updatePage, setUpdatePage] = useState(false);
  const [loadingBar, setLoadingBar] = useState(false);
  const [detailsLoadingBar, setDetailsLoadingBar] = useState(false);
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [loader, setLoader] = useState(false);
  const [mediaDoc, setMediaDoc] = useState(null);
  const [updateMedia, setUpdateMedia] = useState(null);
  const [searching, setSearching] = useState(false);
  const [searchObjects, setSearchObjects] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [mediaLoader, setMediaLoader] = useState(false);
  const [mediaOptions, setMediaOptions] = useState([]);
  const [selectedOption2, setSelectedOption2] = useState();
  const [first, setFirst] = useState(true);

  useEffect(() => {
    applyFilter();
  }, [selectedOption]);

  useEffect(() => {
    FetchData();
  }, []);

  useEffect(() => {
    if (selectedOption.toLowerCase() === "selected templates") FetchData();
    else FetchData(true);
  }, [updatePage]);

  const switch1 = async () => {
    if (!first) await FetchData();
    await TabSwitch(
      allEmailTemplates,
      selectedEmailTemplates,
      allSmsTemplates,
      selectedSmsTemplates
    );
  };
  useEffect(() => {
    setShowSaveButton(false);
    switch1();
    // setSelectedOption("Selected Templates");
  }, [tab]);

  useEffect(() => {
    getMediaOptions();
  }, []);

  useEffect(() => {
    if (showAddModal) getMediaOptions();
  }, [showAddModal]);

  const getMediaOptions = async () => {
    let mediaOption = await api.getDocuments();
    if (mediaOption && mediaOption.data && mediaOption.data.length !== 0)
      setMediaOptions(mediaOption.data);
  };

  const uploadMedia = async (mediaDoc) => {
    setMediaLoader(true);
    if (mediaDoc) {
      let { allow, type } = await checkFileExtension(mediaDoc);
      if (allow) {
        const {
          data: { allowed, message },
        } = await api.checkDocumentFileName({
          filename: mediaDoc.name,
        });
        if (allowed) {
          uploadToS3(type, mediaDoc);
        } else {
          showAlert(message);
          setMediaLoader(false);
        }
        // setMediaDoc(null);
      } else {
        showAlert("File type not allowed to upload");
        // setMediaDoc(null);
        setMediaLoader(false);
      }
    }
  };

  const uploadToS3 = async (type, mediaDoc) => {
    const fileExtension = mediaDoc.name.split(".").pop().toLowerCase();
    const filename1 = mediaDoc.name.replace(
      /(\.[\w\d_-]+)$/i,
      `-${+Date.now().toString()}.${fileExtension}`
    );
    var photoBucket = new AWS.S3({
      params: {
        Bucket: "lms-email-attachement",
      },
    });
    photoBucket.upload(
      {
        ACL: "public-read",
        Body: mediaDoc,
        Key: filename1,
        ContentType: "application/octet-stream",
      },
      async (err, response) => {
        if (err) {
          console.log("Something went wrong", err);
          // setMediaDoc(null);
        } else {
          try {
            let documentSize = parseFloat(
              mediaDoc.size / (1024 * 1024)
            ).toFixed(2);
            const { status } = await api.addDocuments({
              documentName: filename1, //mediaDoc.name,
              documentNameToShow: mediaDoc.name,
              documentLink: response.Location,
              documentType: type,
              documentSize,
            });
            setLoader(false);
            if (status !== 200) {
              showAlert("File already exists. File name should be unique");
              setMediaLoader(false);
            } else {
              showAlert("File has been uploaded successfully");
              setUpdateMedia(updateMedia ? false : true);
              console.log("Uploaded to S3");
            }
            setMediaDoc(null);
            setMediaLoader(false);
          } catch (error) {
            console.log(error);
            // setMediaDoc(null);
            setMediaLoader(false);
          }
        }
      }
    );
  };

  const handleUpdateTemplate = async () => {
    const result = await api.selectedEmailTemplate({
      selectedTemplates: selectedTemplates,
      message_type: tab === "Email Templates" ? "email" : "sms",
    });
    if (result.data.code === 200) {
      showAlert("New selected templates list has been saved");
      setShowSaveButton(false);
      await FetchData();
    }
  };

  const applyFilter = async () => {
    if (selectedOption !== "") {
      switch (selectedOption) {
        case "Recents":
          setFilteredResults(data);
          break;
        case "All Templates":
          setFilteredResults(data);
          break;
        case "Selected Templates":
          let results = await filterObjectsByIds(true);
          setFilteredResults(results);
          break;
        case "Unselected Templates":
          let results2 = await filterObjectsByIds(false);
          setFilteredResults(results2);
          break;

        default:
          var filteredObjects = data.filter(function (object) {
            return object.name === selectedOption;
          });
          setFilteredResults(filteredObjects);
          break;
      }
    } else {
      setFilteredResults(data);
    }
  };

  const filterObjectsById = async (data, selected) => {
    // return data.filter((obj) => selected.includes(obj.temp_id));
    const temps = selected
      .map((templateId) => data.find((temp) => temp.temp_id === templateId))
      .filter(Boolean);
    return temps;
  };

  const filterObjectsByIds = async (selected) => {
    if (selected) {
      // return data.filter((obj) => selectedTemplates.includes(obj.temp_id));
      const temps = selectedTemplates
        .map((templateId) => data.find((temp) => temp.temp_id === templateId))
        .filter(Boolean);
      return temps;
    }
    if (!selected)
      return data.filter((obj) => !selectedTemplates.includes(obj.temp_id));
  };

  const handleCLoseModal = () => {
    setShowAddModal(false);
  };

  const handleAddTemplate = () => {
    setShowAddModal(true);
  };

  const compareByUpdatedAt = (a, b) => {
    if (!a.updated_at && !b.updated_at) {
      return 0;
    }
    if (!a.updated_at) {
      return 1;
    }
    if (!b.updated_at) {
      return -1;
    }
    return new Date(b.updated_at) - new Date(a.updated_at);
  };

  const TabSwitch = async (email, selectedEmail, sms, selectedSms) => {
    setDetailsLoadingBar(true);

    switch (tab) {
      case "Email Templates":
        let data1 = await filterObjectsById(email, selectedEmail);
        setData(email);
        setFilteredResults(data1);
        setSelectedTemplates([]);
        setSelectedTemplates(selectedEmail);
        // setGetId(0);
        break;
      case "SMS Templates":
        let data2 = await filterObjectsById(sms, selectedSms);
        setData(sms);
        setFilteredResults(data2);
        setSelectedTemplates([]);
        setSelectedTemplates(selectedSms);
        // setGetId(sms[0].temp_id);

        break;
      default:
        setData(email);
        let data3 = await filterObjectsById(email, selectedEmail);
        // setGetId(email[0].temp_id);
        setFilteredResults(data3);
        setSelectedTemplates([]);
        setSelectedTemplates(selectedEmail);
        break;
    }
    setGetId(0);
    setSearching(false);
    setSearchObjects([]);
    setSearchText("");
    setDetailsLoadingBar(false);
    return;
  };

  const FetchData = async (filter = false) => {
    setLoadingBar(true);
    setSelectedOption("Selected Templates");
    const { data } = await api.getAllTemplates();
    const result = await api.getSelectedTemplates();
    const all_email_templates =
      data.data.all_email_templates.sort(compareByUpdatedAt);
    // const templates = data.data.selected_email_array;
    const all_sms_templates =
      data.data.all_sms_templates.sort(compareByUpdatedAt);
    const selected_sms_array = result.data.selectedSmsTemplates;
    const selected_email_array = result.data.selectedEmailTemplates;
    const default_sms_template = result.data.defaultSMSTemplates;
    const default_email_template = result.data.defaultEmailTemplates;
    // setData(all_sms_templates);
    // setFilteredResults(all_email_templates);
    setAllEmailTemplates(all_email_templates);
    setSelectedTemplates(selected_email_array);
    setSelectedEmailTemplates(selected_email_array);
    setUpdateSelectedEmail(true);
    setEmailDefaultTemplate(default_email_template);
    setSmsDefaultTemplate(default_sms_template);
    setAllSmsTemplates(all_sms_templates);
    setSelectedSmsTemplates(selected_sms_array);
    await TabSwitch(
      all_email_templates,
      selected_email_array,
      all_sms_templates,
      selected_sms_array
    );
    if (filter) {
      let x = selectedOption;
      setSelectedOption("");
      setSelectedOption(x);
    }
    setLoadingBar(false);
    setFirst(false);
  };

  return (
    <>
      <hr style={{ margin: 0 }} />
      <div
        className={`mainContainer ${
          headerCollapse && "mainContainerHeaderClose"
        }`}
      >
        <div className='headingContainer'>
          <div className='containerHeader'>
            <h3>Template Dashboard </h3>
            <img src={require("../../assets/images/right-arrow.png")} />
            <p>{tab}</p>
          </div>
          {tab !== "Media and Files" && (
            <button className='button'>
              <div
                className='buttonContainer'
                onClick={() => {
                  if (tab === "Sequences") {
                    dispatch({ type: SEQUENCES_CHANGESTATUS, payload: 1 });
                  } else handleAddTemplate();
                }}
              >
                <img
                  className={
                    tab === "Sequences" ? "buttonImageSequence" : `buttonImage`
                  }
                  src={
                    tab === "SMS Templates"
                      ? SMS
                      : tab === "Email Templates"
                      ? Mail
                      : require("../../assets/images/sequences_button.png")
                  }
                />
                <p>
                  {tab !== "Sequences" ? "Add" : "Create"} {tab}
                </p>
              </div>
            </button>
          )}
        </div>
        <div className='innerToMain'>
          <p className='subheading'>
            {tab === "Media and Files"
              ? "Upload files for attachments"
              : tab === "Sequences"
              ? "Choose and create new sequences"
              : "Choose and create new templates"}
          </p>
          <div className='dragDropContainer'>
            <div className='flex'>
              <p
                className={
                  tab === "Email Templates"
                    ? !loadingBar
                      ? "categories bold"
                      : "categories bold cursorDisabled"
                    : !loadingBar
                    ? "categories"
                    : "categories cursorDisabled"
                }
                onClick={() => {
                  !loadingBar && setTab("Email Templates");
                }}
              >
                Email Templates
              </p>
              <p
                className={
                  tab === "SMS Templates"
                    ? !loadingBar
                      ? "categories bold"
                      : "categories bold cursorDisabled"
                    : !loadingBar
                    ? "categories"
                    : "categories cursorDisabled"
                }
                onClick={() => {
                  !loadingBar && setTab("SMS Templates");
                }}
              >
                SMS Templates
              </p>
              <p
                className={
                  tab === "Sequences"
                    ? !loadingBar
                      ? "categories bold"
                      : "categories bold cursorDisabled"
                    : !loadingBar
                    ? "categories"
                    : "categories cursorDisabled "
                }
                onClick={() => {
                  !loadingBar && setTab("Sequences");
                }}
              >
                Sequences
              </p>
              <p
                className={
                  tab === "Media and Files"
                    ? !loadingBar
                      ? "categories bold"
                      : "categories bold cursorDisabled"
                    : !loadingBar
                    ? "categories"
                    : "categories cursorDisabled"
                }
                onClick={() => {
                  !loadingBar && setTab("Media and Files");
                }}
              >
                Media & Files
              </p>
            </div>
            <div
              style={{
                width: "20vw",
                maxWidth: "400px",
                height: "8.5vh",
                maxHeight: "81px",
              }}
            >
              {tab === "Media and Files" && (
                <FileUpload
                  setDocument={setMediaDoc}
                  uploadMedia={uploadMedia}
                  mediaLoader={mediaLoader}
                  setSelectedOption={() => {}}
                  media={true}
                />
              )}
            </div>
          </div>
          <div className='templateLine'></div>
          {/* <hr /> */}
          {(tab === "Email Templates" || tab === "SMS Templates") && (
            <Template
              templateData={filteredResults ? filteredResults : data}
              templeDataOne={filteredResults ? filteredResults[0] : data[0]}
              headerCollapse={headerCollapse}
              selectedTemplates={selectedTemplates}
              setSelectedEmailTemplates={setSelectedTemplates}
              setUpdate={setShow}
              getId={getId}
              setGetId={setGetId}
              showAlert={showAlert}
              updateLists={updateLists}
              setUpdateLists={setUpdateLists}
              users={users.map((obj) => obj.label)}
              emailDefaultTemplate={emailDefaultTemplate}
              smsDefaultTemplate={smsDefaultTemplate}
              filteredResults={filteredResults}
              setFilteredResults={setFilteredResults}
              setSelectedOption={setSelectedOption}
              selectedOption={selectedOption}
              updatePage={updatePage}
              setUpdatePage={setUpdatePage}
              loadingBar={loadingBar}
              attachment={tab === "Email Templates" ? true : false}
              setLoadingBar={setLoadingBar}
              type={tab === "Email Templates" ? "email" : "sms"}
              detailsLoadingBar={detailsLoadingBar}
              showSaveButton={showSaveButton}
              setShowSaveButton={setShowSaveButton}
              handleUpdateTemplate={handleUpdateTemplate}
              searching={searching}
              setSearching={setSearching}
              setIdToUpdate={setIdToUpdate}
              searchObjects={searchObjects}
              setSearchObjects={setSearchObjects}
              searchText={searchText}
              setSearchText={setSearchText}
            />
          )}
          {tab === "Sequences" && (
            <Sequences
              users={users.map((obj) => obj.label)}
              showAlert={showAlert}
              selectedTemplatesEmail={selectedEmailTemplates}
              selectedTemplatesSMS={selectedSmsTemplates}
            />
          )}
          {tab === "Media and Files" && (
            <MediaAndFiles
              tab={tab}
              showAlert={showAlert}
              updateLists={updateLists}
              updatePage={updatePage}
              setUpdatePage={setUpdatePage}
              loadingBar={loadingBar}
              updateMedia={updateMedia}
              users={users}
            />
          )}
        </div>
      </div>
      {showAddModal && (
        <TemplateAddUpdate
          title={tab === "Email Templates" ? "Email" : "SMS"}
          handleShowModal={handleCLoseModal}
          close={setShowAddModal}
          setUpdate={setShow}
          showAlert={showAlert}
          updateLists={updatePage}
          setUpdateLists={setUpdatePage}
          options={mediaOptions}
          sms={tab === "Email Templates" ? false : true}
        />
      )}
      {show && (
        <TemplateAddUpdate
          title={tab === "Email Templates" ? "Email" : "SMS"}
          update={true}
          handleShowModal={handleCLoseModal}
          close={setShowAddModal}
          data={data.filter((item) => item.temp_id === idToUpdate)}
          setUpdate={setShow}
          showAlert={showAlert}
          updateLists={updatePage}
          setUpdateLists={setUpdatePage}
          options={mediaOptions}
          sms={tab === "Email Templates" ? false : true}
        />
      )}
    </>
  );
};

export default Templates;
