import React, { Component } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CSVLink } from "react-csv";
import axios from "axios";
import { baseURL, calculateZoneSpecificTime } from "../Helpers/Constants";
import exportIcon from "../assets/images/exportIcon.svg";
import { connect } from "react-redux";
import { addFilterData } from "../Store/actions";
var dateFormat = require("dateformat");

class ExportCustomers extends Component {
	// eslint-disable-next-line no-useless-constructor
	constructor(props) {
		super(props);
		this.state = {
			fromDate: new Date(),
			toDate: new Date(),
			isInProgress: false,
			isCSVAvailable: false,
			display: "block",
			onCancelClick: false,
		};
	}
	handleChangeFromDate = (date) => {
		this.setState({
			fromDate: date,
		});
		//dateFormat(new Date(date), "yyyy-mm-dd")
	};
	handleChangeToDate = (date) => {
		this.setState({
			toDate: date,
		});
		var tempFromDate = new Date(this.state.fromDate)
			.setHours(0, 0, 0)
			.getTime();
		var tempToDate = new Date(date).getTime();
		if (tempToDate < tempFromDate) {
			this.setState({
				fromDate: date,
			});
		}
	};

	onExportButtonClick = () => {
		this.setState({
			isCSVAvailable: false,
		});
		// console.log(
		// 	"onExportButtonClick called",
		// 	this.props.filtersArray.length,
		// 	this.props.filteredData,
		// 	localStorage.getItem("filteredData1")
		// );
		//
		//
		if (this.props.filterTrigger === 1) {
			if (this.props.filterData?.length <= 0) {
				this.props.showAlert("No data available");
			} else {
				this.setState({
					CSVFileName: "LMS-Filtered-Cutomers-" + ".csv",
				});
				let data = this.props.filterData;
				let uniqueData = new Map(data.map((item) => [item._id, item]));
				uniqueData = Array.from(uniqueData.values());
				const temp = uniqueData.map(
					({ name, phone_number, email, address }) => ({
						name,
						phone_number,
						email,
						address,
					})
				);
				console.log("tempdata", temp);
				this.exportableData = temp;
				this.setState({
					isCSVAvailable: true,
				});
			}
		} else {
			this.setState({
				isInProgress: true,
			});
			axios
				.post(
					baseURL() + "/api/v1/leads/customersData",
					{
						fromDate: new Date(
							this.state.fromDate.setHours(0, 0, 0)
						).getTime(),
						toDate: this.state.toDate.getTime(),
					},
					{
						headers: {
							"x-access-token": localStorage.getItem("jwtToken"),
							"app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
						},
					}
				)
				.then((res) => {
					if (res.data.data.length <= 0) {
						this.props.showAlert(
							"No data available for selected date."
						);
					} else {
						// eslint-disable-next-line no-useless-concat
						this.setState({
							CSVFileName:
								"LMS-Customers-" +
								dateFormat(
									new Date(this.state.fromDate),
									"yyyy-mm-dd"
								) +
								"-" +
								dateFormat(
									new Date(this.state.toDate),
									"yyyy-mm-dd"
								) +
								".csv",
						});
						console.log(res.data.data);

						let temp = [];
						for (var i = 0; i < res.data.data.length; i++) {
							let itemDate = new Date(
								res.data.data[i].utc_time_stamp
							);
							itemDate = calculateZoneSpecificTime(-5, itemDate);
							console.log(this.state.fromDate);
							console.log(itemDate);
							console.log(this.state.toDate);
							// if(itemDate.getTime() <= this.state.toDate.getTime() && itemDate.getTime() >= this.state.fromDate.getTime()){
							delete res.data.data[i].utc_time_stamp;
							temp.push(res.data.data[i]);
							// }
						}

						this.exportableData = temp;
						this.setState({
							isInProgress: false,
							isCSVAvailable: true,
						});
					}
				})
				.catch((error) => {
					this.setState({
						isInProgress: false,
					});
				});
		}
	};

	render() {
		console.log(
			"filterData in Export",
			this.props.filterData,
			this.props.filterTrigger
		);
		return (
			<React.Fragment>
				<div
					style={{
						backgroundColor: "#0A84FE",
						display: "block",
					}}
				>
					<div
						className="row"
						style={{ height: "60px", alignItems: "Center" }}
					>
						<div className="col-md-4"></div>
						<div
							className="col-md-6"
							style={{
								justifyContent: "center",
								alignItems: "center",
								// margin: '6px'
							}}
						>
							{this.props.filterTrigger === 0 ? (
								<>
									<span
										style={{
											marginRight: 10,
											textTransform: "uppercase",
											color: "#065099",
											fontSize: 10,
											fontFamily: "SF Pro Text Medium",
										}}
									>
										From{" "}
									</span>
									<DatePicker
										maxDate={this.state.toDate}
										selected={this.state.fromDate}
										onChange={this.handleChangeFromDate}
									/>
									<span
										style={{
											marginRight: 10,
											textTransform: "uppercase",
											margin: 10,
											color: "#065099",
											fontSize: 10,
											fontFamily: "SF Pro Text Medium",
										}}
									>
										To{" "}
									</span>
									<DatePicker
										maxDate={new Date()}
										selected={this.state.toDate}
										onChange={this.handleChangeToDate}
									/>
								</>
							) : null}
							<button
								style={{
									backgroundColor: "transparent",
									color: "white",
									width: 100,
									height: 30,
									textTransform: "uppercase",
									border: "none",
									fontSize: 12,
									fontFamily: "SF Pro Text Bold",
								}}
								onClick={this.onExportButtonClick}
							>
								{" "}
								Export{" "}
							</button>
							{this.state.isCSVAvailable ? (
								<CSVLink
									style={{
										color: "white",
										fontSize: 12,
										fontFamily: "SF Pro Text Bold",
										paddingRight: 17,
										textTransform: "uppercase",
									}}
									onClick={() =>
										this.props.handleCloseExport()
									}
									data={this.exportableData}
									filename={this.state.CSVFileName}
								>
									Download CSV
								</CSVLink>
							) : null}
							<button
								style={{
									backgroundColor: "transparent",
									color: "white",
									fontSize: 12,
									fontFamily: "SF Pro Text Regular",
									height: 30,
									textTransform: "uppercase",
									border: "none",
								}}
								onClick={this.props.cancel}
							>
								{" "}
								Cancel{" "}
							</button>
						</div>
						<div className="col-md-2"></div>
					</div>
				</div>
				<div
					className="ExportElement"
					style={{
						position: "relative",
						bottom: 30,
						left: "74%",
						height: "0",
						zIndex: 1,
					}}
					// onClick={() => {
					// 	this.onExportCustomersClick();
					// }}
				>
					<button
						style={{
							width: 30,
							height: 20,
							color: "white",
							backgroundImage: `url(${exportIcon})`,
						}}
					></button>
					<span
						style={{
							color: "white",
							textTransform: "uppercase",
							fontWeight: 500,
							fontSize: 12,
							fontFamily: "SF Pro Text Medium",
						}}
					>
						{this.props.filterTrigger == 1
							? "Export Filtered Leads"
							: "Export Customers"}
					</span>
				</div>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	filterData: state.reducer._filterData,
	filterTrigger: state.reducer._filterTrigger,
	// Assuming _filterData is stored in your reducer
});

const mapDispatchToProps = (dispatch) => ({
	addFilterData: (filterData) => dispatch(addFilterData(filterData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExportCustomers);

// const mapStateToProps = (state) => {
// 	return {
// 		_filterData: state._filterData,
// 	};
// };

// // export default App;
// export default connect(mapStateToProps, null)(ExportCustomers);

// export default ExportCustomers;
