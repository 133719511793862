import React, { Component } from 'react';
import Checklist from './Checklist';
import './index.css';
class ColumnsChecklist extends Component {
  // eslint-disable-next-line no-useless-constructor
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    componentDidMount() {
    
    }
  
    render() {
        return (
            <div className='columns-container'>
                <div className='columns-page'>
                    {
                        this.props.displayableColumns.map((element, index)=>{
                            return <Checklist key={element.key} onUpdateColumnSelection = {this.props.onUpdateColumnSelection} _key = {element.key} allAvailableColumns={this.props.allAvailableColumns} heading={element.name} selectedColumns={element.selectedColumns}/>
                        })
                    }
                </div>
            </div>
        );
    }
}
export default ColumnsChecklist;
