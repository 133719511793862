import {
  FETCH_ALL_SEQUENCES,
  SEQUENCES_UPDATE,
  CREATESEQUENCE,
  SEARCH_SEQUENCES,
  SEQUENCES_LENGTH,
  DELETE_SEQUENCE,
  SEQUENCES_START_LOADING,
  SEQUENCES_END_LOADING,
  SEQUENCES_SWITCH_CATEGORY,
  SEQUENCES_DELETED_TRUE,
  SEQUENCES_DELETED_FALSE,
  SEQUENCES_SEARCHING_TRUE,
  SEQUENCES_SEARCHING_FALSE,
  SEQUENCES_TAB_DATA,
  SEQUENCES_FETCH_EMAIL,
  SEQUENCES_FETCH_SMS,
  SEQUENCES_TYPE,
  SEQUENCES_CHANGESTATUS,
  SEQUENCES_ERROR,
  SEQUENCES_CLEAR_ERROR,
  SEQUENCES_DROPDOWN2,
  SEQUENCES_DROPDOWN3,
  SEQUENCES_SELECTEDINDEX,
  SEQUENCES_EMAIL,
  SEQUENCES_SMS,
  SEQUENCES_ALL,
  SEQUENCES_FILTER_BY_USERS,
  SEQUENCES_SAVE_BUTTON_START_LOADING,
  SEQUENCES_SAVE_BUTTON_END_LOADING,
  TEMPLATE1,
  TEMPLATE2,
  TEMPLATE3,
  SEQUENCES_CREATED_FALSE,
  SEQUENCES_EDITED_FALSE,
  SEQUENCE_ERROR_TRUE,
} from "./sequenceActionTypes";

const initialState = {
  sequenceisLoading: false,
  sequenceData: [],
  sequence_size: 0,
  sequence_error: false,
  // sequence_currentPage: 1,
  sequence_numberOfPages: 0,
  sequence_totalItems: 0,
  sequence_deleted: false,
  sequence_searchedData: [],
  sequence_searching: false,
  sequence_searchedDataTotal: 0,
  // selectedTab: 0,
  // tabData: [],
  // tabDataTotal: 0,
  sequence_emailTemps: [],
  sequence_smsTemps: [],
  sequence_type: "Email",
  sequence_dropwdown2option: [],
  sequence_dropwdown3option: [],
  sequence_changeStatus: 0, // 0 - Read Only, 1 - Create, 2 - Edit
  sequence_changeCategory: "All",
  sequence_selectedIndex: 0,
  sequenceDataToShow: null,
  sequenceDataAfterTabSwitch: [],
  sequenceUserFilterData: [],
  sequenceUserFilteredData: [],
  sequenceUserFiltered: false,
  currentUser: "All",
  saveButtonLoader: false,
  templateOne: null,
  templateTwo: null,
  templateThree: null,
  sequence_saved_status: false,
  sequence_edited_status: false,
  sequence_error: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SEQUENCES_START_LOADING:
      return { ...state, sequence_error: false, sequenceisLoading: true };
    case SEQUENCES_END_LOADING:
      return { ...state, sequence_error: false, sequenceisLoading: false };
    case SEQUENCES_SAVE_BUTTON_START_LOADING:
      return { ...state, sequence_error: false, saveButtonLoader: true };
    case SEQUENCES_SAVE_BUTTON_END_LOADING:
      return { ...state, sequence_error: false, saveButtonLoader: false };
    case SEQUENCES_DELETED_TRUE:
      return { ...state, sequence_error: false, sequence_deleted: true };
    case SEQUENCES_DELETED_FALSE:
      return { ...state, sequence_error: false, sequence_deleted: false };
    case SEQUENCES_SEARCHING_TRUE:
      return { ...state, sequence_error: false, sequence_searching: true };
    case SEQUENCES_SEARCHING_FALSE:
      return { ...state, sequence_error: false, sequence_searching: false };
    case FETCH_ALL_SEQUENCES:
      return {
        ...state,
        sequenceData: action.payload,
        sequence_size: action.payload ? action.payload.length : 0,
        sequenceDataToShow: action.payload,
        sequenceDataAfterTabSwitch: action.payload,
        sequenceUserFilterData: action.payload,
        sequenceUserFilteredData: action.payload,
        sequence_error: false,
      };
    case SEQUENCES_FILTER_BY_USERS: {
      let data = [];
      let filterApplied = false;
      if (action.payload !== "All") {
        data = state.sequenceDataAfterTabSwitch.filter(
          (post) => post.createdBy === action.payload
        );
        filterApplied = true;
      } else {
        data = state.sequenceDataAfterTabSwitch;
        filterApplied = false;
      }
      return {
        ...state,
        sequenceDataToShow: data,
        sequenceUserFilterData: data,
        sequenceUserFilteredData: data,
        sequenceUserFiltered: filterApplied,
        currentUser: action.payload,
        sequence_size: data ? (data.length !== 0 ? data.length : 0) : 0,
        sequence_error: false,
      };
    }
    case SEQUENCES_ALL:
      return {
        ...state,
        sequenceDataToShow: state.sequence_searching
          ? state.sequenceUserFiltered
            ? state.sequenceUserFilteredData
            : state.sequence_searchedData
          : state.sequenceData,
        sequenceDataAfterTabSwitch: state.sequence_searching
          ? state.sequenceUserFiltered
            ? state.sequenceUserFilteredData
            : state.sequence_searchedData
          : state.sequenceData,
        sequenceUserFilterData: state.sequence_searching
          ? state.sequenceUserFiltered
            ? state.sequenceUserFilteredData
            : state.sequence_searchedData
          : state.sequenceData,
        sequenceUserFilteredData: state.sequence_searching
          ? state.sequenceUserFiltered
            ? state.sequenceUserFilteredData
            : state.sequence_searchedData
          : state.sequenceData,
        sequence_size: state.sequence_searching
          ? state.sequenceUserFiltered
            ? state.sequenceUserFilteredData.length
              ? state.sequenceUserFilteredData.length
              : 0
            : state.sequence_searchedData
            ? state.sequence_searchedData.length
            : 0
          : state.sequenceData
          ? state.sequenceData.length !== 0
            ? state.sequenceData.length
            : 0
          : 0,
        sequence_error: false,
      };
    case SEQUENCES_EMAIL: {
      let originalData = state.sequence_searching
        ? state.sequenceUserFiltered
          ? state.sequenceUserFilteredData
          : state.sequence_searchedData
        : state.sequenceData;
      let data = originalData.filter((post) => post.sequenceType === "Email");
      return {
        ...state,
        sequenceDataToShow: data,
        sequenceDataAfterTabSwitch: data,
        sequenceUserFilterData: data,
        sequenceUserFilteredData: data,
        sequence_size: data ? (data.length !== 0 ? data.length : 0) : 0,
        sequence_error: false,
      };
    }
    case SEQUENCES_SMS: {
      let originalData = state.sequence_searching
        ? state.sequenceUserFiltered
          ? state.sequenceUserFilteredData
          : state.sequence_searchedData
        : state.sequenceData;
      let data = originalData.filter((post) => post.sequenceType === "SMS");
      return {
        ...state,
        sequenceDataToShow: data,
        sequenceDataAfterTabSwitch: data,
        sequenceUserFilterData: data,
        sequenceUserFilteredData: data,
        sequence_size: data ? (data.length !== 0 ? data.length : 0) : 0,
        sequence_error: false,
      };
    }
    case SEQUENCES_UPDATE:
      return {
        ...state,
        sequenceData: state.sequenceData.map((post) =>
          post._id === action.payload._id ? action.payload : post
        ),
        sequenceDataToShow: state.sequenceDataToShow.map((post) =>
          post._id === action.payload._id ? action.payload : post
        ),
        sequenceUserFilterData: state.sequenceUserFilterData.map((post) =>
          post._id === action.payload._id ? action.payload : post
        ),
        sequenceUserFilteredData: state.sequenceUserFilteredData.map((post) =>
          post._id === action.payload._id ? action.payload : post
        ),
        sequenceDataAfterTabSwitch: state.sequenceDataAfterTabSwitch.map(
          (post) => (post._id === action.payload._id ? action.payload : post)
        ),
        sequence_searchedData: state.sequence_searching
          ? state.sequence_searchedData.map((post) =>
              post._id === action.payload._id ? action.payload : post
            )
          : state.sequence_searchedData,
        sequence_edited_status: true,
        sequence_error: false,
      };
    case SEQUENCES_FETCH_EMAIL:
      return { ...state, sequence_emailTemps: action.payload };
    case SEQUENCES_FETCH_SMS:
      return { ...state, sequence_smsTemps: action.payload };
    case SEARCH_SEQUENCES: {
      let arr = [];
      let searching = false;
      let originalData = state.sequenceUserFiltered
        ? state.sequenceUserFilteredData
        : state.sequenceData;
      if (action.payload && action.payload !== "") {
        const query = action.payload.trim().toLowerCase();
        let searchResults = originalData.filter((item) => {
          if (item.sequenceTitle && item.createdBy) {
            if (
              item.sequenceTitle.trim().toLowerCase().includes(query) ||
              item.createdBy.trim().toLowerCase().includes(query)
            ) {
              arr = [...arr, item];
            }
          }
        });
        searching = true;
      } else {
        arr = state.sequenceUserFilteredData;
        searching = false;
      }
      return {
        ...state,
        sequence_searchedData: arr,
        sequenceDataAfterTabSwitch: arr,
        sequence_searching: searching,
        sequence_error: false,
      };
    }
    case SEQUENCES_LENGTH:
      return { ...state, sequence_error: false, sequence_size: action.payload };
    case CREATESEQUENCE:
      return {
        ...state,
        sequenceData: [action.payload.data, ...state.sequenceData],
        sequenceDataToShow: [action.payload.data, ...state.sequenceDataToShow],
        sequenceUserFilteredData: [
          action.payload.data,
          ...state.sequenceUserFilteredData,
        ],
        sequenceDataAfterTabSwitch: [
          action.payload.data,
          ...state.sequenceDataAfterTabSwitch,
        ],
        sequence_searchedData: state.sequence_searching
          ? [action.payload.data, ...state.sequence_searchedData]
          : state.sequence_searchedData,
        sequence_size: state.sequenceData.length + 1,
        sequence_saved_status: true,
        sequence_error: false,
      };
    case SEQUENCES_SWITCH_CATEGORY:
      return {
        ...state,
        sequence_changeCategory: action.payload,
        sequence_error: false,
      };
    case SEQUENCES_ERROR:
      return {
        ...state,
        sequence_error: action.payload,
        sequence_error: false,
      };
    case SEQUENCES_CLEAR_ERROR:
      return { ...state, sequence_error: false, sequence_error: false };
    case SEQUENCES_TYPE:
      return { ...state, sequence_error: false, sequence_type: action.payload };
    case SEQUENCES_CHANGESTATUS:
      return {
        ...state,
        sequence_error: false,
        sequence_changeStatus: action.payload,
      };
    case DELETE_SEQUENCE:
      return {
        ...state,
        sequence_error: false,
        sequence_deleted: false,
        sequenceData: state.sequenceData.filter(
          (post) => post._id !== action.payload
        ),
        sequenceDataToShow: state.sequenceDataToShow.filter(
          (post) => post._id !== action.payload
        ),
        sequenceUserFilteredData: state.sequenceUserFilteredData.filter(
          (post) => post._id !== action.payload
        ),
        sequenceDataAfterTabSwitch: state.sequenceDataAfterTabSwitch.filter(
          (post) => post._id !== action.payload
        ),
        sequence_size:
          state.sequenceData.length !== 0 ? state.sequenceData.length - 1 : 0,
        sequence_error: false,
      };
    case SEQUENCES_SELECTEDINDEX:
      return {
        ...state,
        sequence_selectedIndex: action.payload,
        sequence_error: false,
      };
    case SEQUENCES_DROPDOWN2:
      return {
        ...state,
        sequence_error: false,
        sequence_dropwdown2option: action.payload.data.filter((item) => {
          if (
            item &&
            item.temp_id
            // &&
            // action.payload.data[parseInt(action.payload.option)] &&
            // action.payload.data[parseInt(action.payload.option)].temp_id
          ) {
            return (
              item.temp_id &&
              item.temp_id !==
                action.payload.data[parseInt(action.payload.option)].temp_id
            );
          }
        }),
      };
    case SEQUENCES_DROPDOWN3: {
      if (
        action.payload &&
        action.payload.data &&
        action.payload.option &&
        action.payload.data[action.payload.option]
      ) {
        return {
          ...state,
          sequence_error: false,
          sequence_dropwdown3option: action.payload.data.filter((item) => {
            if (
              item &&
              item.temp_id &&
              action.payload.data &&
              action.payload.data.length !== 0 &&
              action.payload.data.length >= parseInt(action.payload.option)
            ) {
              return (
                item.temp_id !==
                action.payload.data[parseInt(action.payload.option)].temp_id
              );
            }
          }),
        };
      } else return state;
    }
    case TEMPLATE1: {
      // console.log(action.payload);
      return { ...state, sequence_error: false, templateOne: action.payload };
    }
    case TEMPLATE2:
      return { ...state, sequence_error: false, templateTwo: action.payload };
    case TEMPLATE3:
      return { ...state, sequence_error: false, templateThree: action.payload };
    case SEQUENCES_CREATED_FALSE:
      return {
        ...state,
        sequence_saved_status: false,
        saveButtonLoader: false,
        sequence_error: false,
      };
    case SEQUENCES_EDITED_FALSE:
      return {
        ...state,
        sequence_edited_status: false,
        saveButtonLoader: false,
        sequence_error: false,
      };
    case SEQUENCE_ERROR_TRUE:
      return { ...state, error: true };

    default:
      return state;
  }
};
