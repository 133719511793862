import React from "react";
import ".././Home/Home.css";
import EditModalSingleLine from "./EditModalSingleLine";
import EditModalMultiLine from "./EditModalMultiLine";

export class EditModal extends React.PureComponent {
  render() {
    if (!Array.isArray(this.props.defaultValue)) {
      return (
        <EditModalSingleLine
          defaultValue={this.props.defaultValue}
          show={this.props.show}
          submitValue={this.props.submitValue}
          onCancelEditModal={this.props.onCancelEditModal}
          showAlert={this.props.showAlert}
          text={this.props.text}
          isDescription={this.props.isDescription}
        />
      );
    } else {
      return (
        <EditModalMultiLine
          defaultValue={this.props.defaultValue}
          show={this.props.show}
          submitValue={this.props.submitValue}
          onCancelEditModal={this.props.onCancelEditModal}
          showAlert={this.props.showAlert}
        />
      );
    }
  }
}
export default EditModal;
