import React, { useState, useEffect } from 'react'
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import Delete from "../assets/images/Delete.png"
import Edit from "../assets/images/Edit.png"
import CopyClip from "../assets/images/CopyClip.png"
import { createTheme } from '@material-ui/core/styles'
import { FormControlLabel, MuiThemeProvider } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from "react-bootstrap";

// internal exports
import { addEmailSelectedTemplates, addSmsSelectedTemplates, addFilteredEmailTemps, addFilteredSmsTemps } from '../Store/actions';
import { toggleItem } from '../Helpers/Constants';
import { handleDeleteTemplate } from '../Helpers/ApiCalls';
const copy = require("clipboard-copy");

// -----------------------------------------

function TemplateCard({ item, index, Listlength, type, showAlert, fetchDataChange, setUserLoader, handleUpdateNote }) {

    const [emailSelectedTemplates, setEmailSelectedTemplates] = useState([])
    const [smsSelectedTemplates, setSmsSelectedTemplates] = useState([])
    const [userName, setUserName] = useState(localStorage.getItem("username"))
    const [userEmail, setUserEmail] = useState(localStorage.getItem("email"))
    const [ShowDelModal, setShowDelModal] = useState(false)
    const [isCheckButtonDisable, setIsCheckButtonDisable] = useState(false)


    const _emailSelectedTemplates = useSelector((state) => state._emailSelectedTemplates);
    const _smsSelectedTemplates = useSelector((state) => state._smsSelectedTemplates);
    const _filteredEmailTemps = useSelector((state) => state._filteredEmailTemps)
    const _filteredSmsTemps = useSelector((state) => state._filteredSmsTemps)
    const dispatch = useDispatch()
    useEffect(() => {
        setSmsSelectedTemplates(_smsSelectedTemplates)
        setEmailSelectedTemplates(_emailSelectedTemplates)
        if (type == "email") {
            setIsCheckButtonDisable((_emailSelectedTemplates.length == 1) && (_emailSelectedTemplates[0] == item.temp_id))
        }
        else {
            setIsCheckButtonDisable((_smsSelectedTemplates.length == 1) && (_smsSelectedTemplates[0] == item.temp_id))

        }
    }, [])

    const IsCheckButtonDisable = (item) => {
        if (type == "email") {
            return ((_emailSelectedTemplates.length == 1) && (_emailSelectedTemplates[0] == item.temp_id))
        }
        else {
            return ((_smsSelectedTemplates.length == 1) && (_smsSelectedTemplates[0] == item.temp_id))

        }
    }
    const handleCopyText = (val) => {
        copy(val)
        showAlert("Template is copied successflly")
    }

    const handleChangeSelection = (id, type) => {
        console.log("---------", id, type)
        if (type == "email") {
            let selectedTempArray = [..._emailSelectedTemplates]

            if (_emailSelectedTemplates.length == 1 && _emailSelectedTemplates.includes(id)) { return }
            // let selectedTempArray = [...selectedTemplates]
            setEmailSelectedTemplates(toggleItem(selectedTempArray, id))
            dispatch(addEmailSelectedTemplates(toggleItem(selectedTempArray, id)))
        }
        else {
            console.log("in sms")
            let selectedTempArray = [..._smsSelectedTemplates]
            if (_smsSelectedTemplates.length == 1 && _smsSelectedTemplates.includes(id)) { return }
            setSmsSelectedTemplates(toggleItem(selectedTempArray, id))
            dispatch(addSmsSelectedTemplates(toggleItem(selectedTempArray, id)))
        }

    }

    const DeleteTemplate = () => {
        const compareByUpdatedAt = (a, b) => {
            if (!a.updated_at && !b.updated_at) {
                return 0;
            }
            if (!a.updated_at) {
                return 1;
            }
            if (!b.updated_at) {
                return -1;
            }
            return new Date(b.updated_at) - new Date(a.updated_at);
        };

        handleDeleteTemplate(item.temp_id, type, showAlert, fetchDataChange, setUserLoader).then((response) => {
            if (response.status === true) {
                console.log("in dele temp")
                if (response.type == "email") {
                    let newArray = [..._filteredEmailTemps]

                    let reduxArray = newArray.filter((temp) => {
                        return temp.temp_id != item.temp_id
                    })
                    let sortedReduxArray = reduxArray.sort(compareByUpdatedAt)

                    dispatch(addFilteredEmailTemps(sortedReduxArray))
                }
                else {
                    let newArray = [..._filteredSmsTemps]
                    let reduxArray = newArray.filter((temp) => {
                        return temp.temp_id != item.temp_id
                    })
                    let sortedReduxArray = reduxArray.sort(compareByUpdatedAt)
                    dispatch(addFilteredSmsTemps(sortedReduxArray))
                }
            }
            else {
                console.log()
            }
        })
    }
    // console.log("item", _emailSelectedTemplates)

    return (
        <>
            <Card
                sx={{ minWidth: 275 }} className='temp-card-cont' style={{ fontFamily: "Arial, Helvetica, sans-serif" }}>
                <CardContent style={{ paddingBottom: 0 }}>
                    <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "row" }}> <Typography variant="h5" component="div" style={{ fontWeight: "bold", display: "flex", flexDirection: "row" }}>
                        Title :
                        <Typography variant='subtitle1' className='typo-nowrap' style={{ fontSize: 13 }}> {item?.label}</Typography>
                    </Typography>
                        <Typography variant="h5" component="div" style={{ fontWeight: "bold" }}>
                            {index + 1} / {Listlength}
                        </Typography>
                    </div>
                    <Typography variant='h6' style={{ marginTop: 5, fontWeight: "bold", display: "flex", flexDirection: "row" }} >
                        Created by :
                        <p style={{ fontSize: 13, paddingLeft: 2 }}>  {item?.name}</p>
                    </Typography>

                    <Typography variant='h6' style={{ fontWeight: "bold" }} >
                        Template Text :
                    </Typography>
                    <div className='template-card-body'>
                        <Typography variant="subtitle1" style={{ fontSize: 13 }}>
                            {item?.value}
                        </Typography>

                    </div>

                </CardContent>
                <div className='action-cont' style={{ display: "flex", justifyItems: "center", flexDirection: "column" }}>
                    <div className='attachment-cont'>{item.attachment && item.attachment.file_name ?
                        <Typography variant="subtitle1" className='template-attachment' style={{ fontSize: 10 }}>
                            <p style={{ fontWeight: "bold", paddingRight: 1 }}>Attachement : </p>
                            {item.attachment.file_name}
                        </Typography> : <Typography variant="subtitle1" className='template-attachment' style={{ fontSize: 10 }}>
                            No file Attachment                        </Typography>}
                    </div>
                    <div className='line'></div>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%", paddingLeft: 15, paddingRight: 15 }}>
                        <div style={{ justifyContent: "center", display: "flex" }}>
                            <button style={{ borderStyle: "hidden", backgroundColor: "transparent" }}
                                onClick={() => handleCopyText(item.value)}
                            >
                                <img
                                    style={{ cursor: "pointer", width: 23, height: 23, }}
                                    src={CopyClip}
                                />
                            </button>

                            {item?.name == userName && <button style={{ borderStyle: "hidden", backgroundColor: "transparent" }}
                                onClick={() => handleUpdateNote(item, type, item.attachment)}
                            >

                                <img
                                    style={{ cursor: "pointer", width: 23, height: 23 }}
                                    src={Edit}
                                />
                            </button>
                            }
                            {item?.name == userName && <button
                                disabled={IsCheckButtonDisable(item)}
                                style={{ borderStyle: "hidden", backgroundColor: "transparent" }}
                                onClick={() => setShowDelModal(true)}
                            >
                                <img
                                    style={{ cursor: "pointer", width: 23, height: 23, }}
                                    src={Delete}
                                />
                            </button>}
                        </div>
                        <div className='card-checkbox-container'>
                            <Typography style={{ width: "100%", alignSelf: "center", textAlign: "center", paddingRight: 2 }}>Select template</Typography>
                            <input type='checkbox'
                                style={{ fontSize: 18, height: 27, width: 27, alignSelf: "center", textAlign: "center", }}
                                onClick={() => {
                                    handleChangeSelection(item.temp_id, type)
                                }}
                                checked={type == "email" ? _emailSelectedTemplates?.includes(item.temp_id) : _smsSelectedTemplates?.includes(item.temp_id)} />

                        </div>
                    </div>
                </div>
            </Card>
            <div>
                <Modal show={ShowDelModal}
                    style={{ top: "20%", borderRadius: 10 }}
                    size="md"
                >

                    <Modal.Body
                    >
                        <div style={{ padding: 25 }}>
                            <p style={{ fontSize: 15, fontWeight: "500" }}>
                                Are you sure you want to delete this template?</p>
                        </div>

                    </Modal.Body>
                    <div className="modal-footer-container">
                        <button
                            // disabled={isDeleteModal ? false : isCustomEntered}
                            className="note-edit-modal-save-button"

                            onClick={() => DeleteTemplate()}
                        >Yes
                        </button>
                        <button
                            className="note-edit-modal-save-button"
                            onClick={() => {

                                setShowDelModal(false)
                            }}> Cancel</button>
                    </div>
                </Modal>
            </div>
        </>
    )
}

export default TemplateCard