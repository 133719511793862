export const FETCH_ALL_SEQUENCES = "FETCH_ALL_SEQUENCES";
export const SEQUENCES_UPDATE = "SEQUENCES_UPDATE";
export const SEQUENCES_LENGTH = "SEQUENCES_LENGTH";
export const CREATESEQUENCE = "CREATESEQUENCE";
export const SEARCH_SEQUENCES = "SEARCH_SEQUENCES";
// export const CHECKNAME = "CHECKNAME";
export const DELETE_SEQUENCE = "DELETE_SEQUENCE";
export const SEQUENCES_ERROR = "SEQUENCES_ERROR";
export const SEQUENCES_CLEAR_ERROR = "SEQUENCES_CLEAR_ERROR";
export const SEQUENCES_TYPE = "SEQUENCES_TYPE";

export const SEQUENCES_SAVE_BUTTON_START_LOADING =
  "SEQUENCES_SAVE_BUTTON_START_LOADING";
export const SEQUENCES_SAVE_BUTTON_END_LOADING =
  "SEQUENCES_SAVE_BUTTON_END_LOADING";
export const SEQUENCES_START_LOADING = "SEQUENCES_START_LOADING";
export const SEQUENCES_END_LOADING = "SEQUENCES_END_LOADING";

export const SEQUENCES_DELETED_TRUE = "SEQUENCES_DELETED_TRUE";
export const SEQUENCES_DELETED_FALSE = "SEQUENCES_DELETED_FALSE";
export const SEQUENCES_SEARCHING_TRUE = "SEQUENCES_SEARCHING_TRUE";
export const SEQUENCES_SEARCHING_FALSE = "SEQUENCES_SEARCHING_FALSE";
export const SEQUENCES_SWITCH_CATEGORY = "SEQUENCES_SWITCH_CATEGORY";
export const SEQUENCES_TAB_DATA = "SEQUENCES_TAB_DATA";
export const SEQUENCES_CHANGESTATUS = "SEQUENCES_CHANGESTATUS";
export const SEQUENCES_SELECTEDINDEX = "SEQUENCES_SELECTEDINDEX";

export const SEQUENCES_DROPDOWN2 = "SEQUENCES_DROPDOWN2";
export const SEQUENCES_DROPDOWN3 = "SEQUENCES_DROPDOWN3";

export const SEQUENCES_FETCH_EMAIL = "SEQUENCES_FETCH_EMAIL";
export const SEQUENCES_FETCH_SMS = "SEQUENCES_FETCH_SMS";
export const SEQUENCES_FILTER_BY_USERS = "SEQUENCES_FILTER_BY_USERS";
export const SEQUENCES_ALL = "SEQUENCES_ALL";
export const SEQUENCES_EMAIL = "SEQUENCES_EMAIL";
export const SEQUENCES_SMS = "SEQUENCES_SMS";

export const TEMPLATE1 = "TEMPLATE1";
export const TEMPLATE2 = "TEMPLATE2";
export const TEMPLATE3 = "TEMPLATE3";

export const SEQUENCES_CREATED_FALSE = "SEQUENCES_CREATED_FALSE";
export const SEQUENCES_EDITED_FALSE = "SEQUENCES_EDITED_FALSE";

export const SEQUENCE_ERROR_TRUE = "SEQUENCE_ERROR_TRUE";
