import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Popup from "reactjs-popup";

import {
	deletePost,
	handleUpdateName,
} from "../../Store/MediaAndFiles/mediaActions";
import { SendTemplate } from "../../../api/api";
import { EmailTemplateModal } from "../Modals/EmailTemplate/EmailTemplateModal";
import { Confirmation } from "../Modals/Confirmation/confirmation";
import { getFormattedDate } from "../../Helpers/Constants";
// import "../../../../node_modules/reactjs-popup/";
import "./MediaTile.css";

function MediaTile({
	id,
	title,
	modifiedTitle,
	size,
	type,
	time,
	link,
	creator,
	showAlert,
}) {
	const dispatch = useDispatch();
	const [isHovered, setIsHovered] = useState(false);
	const [sendEmail, setSendEmail] = useState(false);
	const [editName, setEditName] = useState(false);
	const [loader, setLoader] = useState(false);
	const [getDelete, setDelete] = useState(false);
	const [input, setInput] = useState("");
	const [textArea, setTextArea] = useState("");
	const [time1, setTime] = useState("");

	const handleDelete = () => {
		dispatch(deletePost(id));
	};
	useEffect(() => {
		const fetchTime = async () => {
			const time2 = await getFormattedDate(time);
			setTime(time2);
		};
		fetchTime();
	}, [time]);

	const handleSend = async () => {
		const { status } = await SendTemplate(id, {
			to: input,
			message: textArea,
		});
		if (status === 201) showAlert("Email has been sent successfully");
		else showAlert("Email sending failed");
		setLoader(false);
	};

	const handleUpdate = async () => {
		const fileExtension = title.split(".").pop().toLowerCase();
		dispatch(
			handleUpdateName(id, {
				documentNameToShow: `${input}.${fileExtension}`,
			})
		);
		setLoader(false);
	};

	return (
		<>
			<div
				className="mediaTileMainContainer"
				onMouseEnter={() => setIsHovered(true)}
				onMouseLeave={() => setIsHovered(false)}
			>
				{/* <div> */}
				{type === "pdf" ? (
					<div className="pdfImage" o>
						<img
							className="pdfImage"
							src={require("../../assets/images/pdf_icon.png")}
						/>
					</div>
				) : (
					<div className="URLImageContainer">
						<img className="URLImage" src={link} />
					</div>
				)}
				{/* </div> */}
				<div
					// className='MediaTileDetails'
					className={
						isHovered
							? "MediaTileDetails nameLimit"
							: "MediaTileDetails"
					}
				>
					<div className="titlediv">
						<h3>
							{modifiedTitle ? modifiedTitle : title ? title : ""}
						</h3>
					</div>
					<p>{`${time1 ? time1 : ""} by ${
						creator ? creator : ""
					}`}</p>
					<p>{size ? `${size} MB` : ""} </p>
				</div>
				{isHovered && (
					<div className="buttonsForAction">
						<img
							src={require("../../assets/images/send-icon.png")}
							alt="Send"
							onClick={() => {
								setInput("");
								setSendEmail(true);
							}}
						/>
						<img
							src={require("../../assets/images/download-cloud.png")}
							alt="Download"
							onClick={() => {
								window.open(link);
								showAlert("File has been downloaded");
							}}
						/>
						<Popup
							className="my-popup"
							trigger={
								(creator.toString().toLowerCase() ===
									localStorage
										.getItem("username")
										.toString()
										?.toLowerCase() ||
									localStorage
										.getItem("username")
										.toString()
										?.toLowerCase() === "jose") && (
									<img
										style={{ height: "24px" }}
										src={require("../../assets/images/more-options.png")}
										alt="more-options"
									/>
								)
							}
							on={"hover"}
							position="top left"
						>
							<div>
								<img
									src={require("../../assets/images/sequence-edit-button.png")}
									alt="edit"
									style={{
										borderRight: "1px solid black",
										paddingRight: "11px",
									}}
									onClick={async () => {
										const getName = async (file) => {
											const filename1 = file.replace(
												/(\.[\w\d_-]+)$/i,
												""
											);
											return filename1;
										};
										const fileName = await getName(
											modifiedTitle
												? modifiedTitle
												: title
												? title
												: ""
										);
										setInput(fileName);
										setEditName(true);
									}}
								/>
								<img
									src={require("../../assets/images/delete-icon.png")}
									alt="Delete"
									style={{
										paddingLeft: "10px",
									}}
									onClick={() => setDelete(true)}
								/>
							</div>
						</Popup>
					</div>
				)}
			</div>
			{sendEmail && (
				<EmailTemplateModal
					show={sendEmail}
					onHide={() => setSendEmail(false)}
					type={type}
					title="Send Attachment"
					fileName={
						modifiedTitle ? modifiedTitle : title ? title : ""
					}
					input={setInput}
					textArea={setTextArea}
					yesFunc={handleSend}
					loader={loader}
					setLoader={setLoader}
					showAlert={showAlert}
					edit={false}
				/>
			)}
			{editName && (
				<EmailTemplateModal
					show={sendEmail}
					onHide={() => setEditName(false)}
					type={type}
					title="Edit File Name"
					fileName={
						modifiedTitle ? modifiedTitle : title ? title : ""
					}
					input={setInput}
					getInput={input}
					yesFunc={handleUpdate}
					loader={loader}
					setLoader={setLoader}
					showAlert={showAlert}
					edit={true}
				/>
			)}
			{getDelete && (
				<Confirmation
					show={getDelete}
					onHide={() => setDelete(false)}
					title="Confirmation"
					description="Are you sure you want to delete this media?"
					yesFunc={handleDelete}
				/>
			)}
		</>
	);
}

export default MediaTile;
