import axios from "axios";
import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import Popup from "reactjs-popup";
import Loader from "react-loader-spinner";
import addLeadIcon from "../assets/images/add-lead-icon.svg";

export default (props) => {
	const [urgency, setUrgency] = useState("");
	const [name, setName] = useState("");
	const [phone, setPhone] = useState("");
	const [email, setEmail] = useState("");
	const [service, setService] = useState("");
	const [address, setAddress] = useState("");
	const [source, setSource] = useState("");
	const [time, setTime] = useState("");
	const [open, setOpen] = useState(false);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);

	const restForm = () => {
		setUrgency("1");
		setName("");
		setPhone("");
		setEmail("");
		setService("");
		setAddress("");
		setSource("Omega");
		setTime("");
		setOpen(false);
		setError(null);
		setLoading(false);
	};
	const onSubmitClick = (e) => {
		e.preventDefault();

		if (name == "" || phone == "" || email == "" || service == "") {
			setError("Please fill all fields");
			return;
		}
		setLoading(true);

		axios
			.post(
				"https://knm3mo03xf.execute-api.us-east-2.amazonaws.com/uat/leads",
				// "https://Qknm3mo03xf.execute-api.us-east-2.amazonaws.com/uat/leads",
				{
					urgency: "1",
					name: name,
					phone_number: phone,
					email: email,
					service_requested: service,
					address: address,
					source: "Omega",
					wait_time: "0",
				},
				{
					headers: {
						"x-api-key": "pIqIln2x586GSAPMuMn03acWXuQeMAfp4GIQUlYu",
					},
				}
			)
			.then((res) => {
				setOpen(false);
				restForm();
			})
			.catch((error) => {
				setError(error.message);
				setLoading(false);
			});
	};
	const onCancelClick = () => {
		setOpen(false);
		setSource("");
		// console.log(props.leadWebSourceData);
		// var webSourceData = [];
		// props.leadWebSourceData && props.leadWebSourceData.map((item, index) => {
		//   webSourceData.push(item);
		// });
		// console.log(webSourceData);
	};
	const onClose = () => {
		setOpen(false);
		setSource("");
	};

	const autoFormatPhone = (number) => {
		number = number.replace(/\D/g, "");
		let new_number = "";
		// automatically add dashes
		if (number.length > 2) {
			// matches: 123 || 123-4 || 123-45
			new_number = number.substring(0, 3) + "-";
			if (number.length === 4 || number.length === 5) {
				// matches: 123-4 || 123-45
				new_number += number.substr(3);
			} else if (number.length > 5) {
				// matches: 123-456 || 123-456-7 || 123-456-789
				new_number += number.substring(3, 6) + "-";
			}
			if (number.length > 6) {
				// matches: 123-456-7 || 123-456-789 || 123-456-7890
				new_number += number.substring(6);
			}
		} else {
			new_number = number;
		}

		return new_number;
	};
	return (
		<span style={{ textAlign: "left" }}>
			<button
				style={{
					width: 18,
					height: 19,
					marginTop: 5,
					backgroundImage: `url(${addLeadIcon})`,
					backgroundRepeat: "no-repeat",
					backgroundSize: "contain",
					border: "none",
					backgroundColor: "transparent",
					cursor: "pointer",
				}}
				onClick={() => setOpen((o) => !o)}
			></button>
			<span className="tooltip-text add-lead-tooltip">Add New Lead</span>

			<Popup open={open} onClose={onClose} className="add-new-lead-popup">
				<div style={{ padding: 12, color: "white" }}>
					<h2 style={{ textAlign: "center" }}> Add New Lead </h2>
					<Form onSubmit={onSubmitClick}>
						{/* <Form.Group className="mb-3 margin-zero">
							<Form.Label className="font-siize-12">
								Urgency
							</Form.Label>
							<Form.Control
								type="text"
								placeholder=""
								autoFocus
								value={urgency}
								onChange={(e) => {
									setUrgency(e.target.value);
								}}
							/>
							<Form.Text className="text-muted-add-lead">
								Urgency can be described as 0 to 10
							</Form.Text>
						</Form.Group> */}

						<Form.Group className="mb-3">
							<Form.Label className="font-siize-12">
								Name
							</Form.Label>
							<Form.Control
								type="text"
								placeholder="Enter name"
								value={name}
								onChange={(e) => {
									setName(e.target.value);
								}}
							/>
						</Form.Group>

						<Form.Group className="mb-3">
							<Form.Label className="font-siize-12">
								Phone Number
							</Form.Label>
							<Form.Control
								type="text"
								placeholder="Enter phone number"
								value={autoFormatPhone(phone)}
								onChange={(e) => {
									setPhone(e.target.value);
								}}
							/>
						</Form.Group>

						<Form.Group className="mb-3">
							<Form.Label className="font-siize-12">
								Email address
							</Form.Label>
							<Form.Control
								type="text"
								placeholder="Enter email address"
								value={email}
								onChange={(e) => {
									setEmail(e.target.value);
								}}
							/>
						</Form.Group>

						<Form.Group className="mb-3">
							<Form.Label className="font-siize-12">
								Service Requested
							</Form.Label>
							<Form.Control
								type="text"
								placeholder="Enter service requested"
								value={service}
								onChange={(e) => {
									setService(e.target.value);
								}}
							/>
						</Form.Group>

						<Form.Group className="mb-3">
							<Form.Label className="font-siize-12">
								Address
							</Form.Label>
							<Form.Control
								type="text"
								placeholder="Enter address"
								value={address}
								onChange={(e) => {
									setAddress(e.target.value);
								}}
							/>
						</Form.Group>

						{/* <Form.Group className="mb-3">
							<Form.Label className="font-siize-12">
								Source
							</Form.Label>
							<Form.Control
								type="text"
								placeholder="Enter source"
								value={source}
								onChange={(e) => {
									setSource(e.target.value);
								}}
							/>
							<Form.Text className="text-muted-add-lead">
								Source (Omega, Omega 2.0, Plano)
							</Form.Text>
						</Form.Group> */}
						{/* <Form.Group className="mb-3">
              <Form.Label className="font-siize-12">Source</Form.Label>
              <select
                className="form-control web-sources-drop-down"
                style={{ height: '34px' }}
                // onChange={this.handleCllrailChannelChange}
                key={"time_filter" + "source"}
                value={source}
                onChange={(e) => {
                  setSource(e.target.value);
                }}
              >
                <option value='' disabled></option>
                {
                  props.leadWebSourceData && props.leadWebSourceData.map((item, index) => {
                      return (
                        <option key={item.name + index} value={item.name}>{item.source}</option>
                      )
                  })
                }
              </select>
            </Form.Group> */}

						{error && (
							<span style={{ color: "red", paddingBottom: 10 }}>
								{error}
							</span>
						)}

						<Form.Group className="mb-3" controlId="formBasicEmail">
							<Button
								variant="secondary"
								style={{ fontSize: 16 }}
								onClick={onCancelClick}
							>
								Cancel
							</Button>{" "}
							<Button
								variant="primary"
								style={{ fontSize: 16 }}
								type="submit"
							>
								Submit
							</Button>{" "}
						</Form.Group>
					</Form>
				</div>

				{loading && (
					<div
						style={{
							position: "absolute",
							top: 0,
							bottom: 0,
							left: 0,
							right: 0,
							backgroundColor: "#a2a2a2a3",
							zIndex: 9999,
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<Loader
							type="Oval"
							color="#00BFFF"
							height={80}
							width={80}
						/>
					</div>
				)}
			</Popup>
		</span>
	);
};
