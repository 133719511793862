import React, { useState, useEffect, useRef } from "react";
import moment from "moment";

import "./DateDropdown.css";

function DateDropdown({ custom, active, setDate, date = "" }) {
  const dropdownRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    console.log(active);
  }, [active]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef} className='date-dropdown'>
      <div
        className={`dateDropdownMainContainer ${
          active
            ? "dateDropdownMainContainerActive"
            : "dateDropdownMainContainerNotActive"
        }`}
        onClick={() => {
          if (active) setIsOpen(!isOpen);
        }}
      >
        <div className='itemsContainer'>
          <p>
            {date && date !== ""
              ? date
              : isOpen
              ? "Set for"
              : "Tomorrow or any other day"}
          </p>
        </div>
        <div className='arrowsContainer'>
          <img
            className={
              isOpen
                ? "arrow-container-img-rotate"
                : "arrow-container-img-rotate-back"
            }
            src={require("../../../../assets/images/down_arrow.png")}
          />
        </div>
      </div>
      {isOpen && (
        <div className={"date-dropdown-menu"}>
          <div
            className='date-dropdown-item'
            onClick={() => {
              setIsOpen(false);
              setDate(moment().add(1, "days").format("MM-DD-YYYY"));
            }}
          >
            <p>Tomorrow</p>
            <p>{moment().add(1, "days").format("dddd, Do")}</p>
          </div>
          <div
            className='date-dropdown-item'
            onClick={() => {
              setIsOpen(false);
              setDate(moment().add(2, "days").format("MM-DD-YYYY"));
            }}
          >
            <p>Day after tomorrow</p>
            <p>{moment().add(2, "days").format("dddd, Do")}</p>
          </div>
          <div
            className='date-dropdown-item'
            onClick={() => {
              setIsOpen(false);
              setDate(moment().add(1, "weeks").format("MM-DD-YYYY"));
            }}
          >
            <p>Next Week</p>
            <p>{moment().add(1, "weeks").format("dddd, Do")}</p>
          </div>
          <div
            className='date-dropdown-item border-top'
            onClick={() => {
              setIsOpen(false);
              custom();
            }}
          >
            <img
              src={require("../../../../assets/images/reminder-calendar.png")}
              alt='select-date'
            />
            <p>Pick a custom date</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default DateDropdown;
