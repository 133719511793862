import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";

import { Confirmation } from "../../Modals/Confirmation/confirmation";
import * as api from "../../../../api/api";
import "./Details.css";

const copy = require("clipboard-copy");

function Details({
  ddata,
  completeData,
  checkId,
  setCheckId,
  setUpdate,
  showAlert,
  type,
  updateLists,
  setUpdateLists,
  showDetailsLoader,
  setShowDetailsLoader,
  detailsNoData,
  setDetailsNoData,
  loadingBar,
  detailsLoadingBar,
  setLoadingBar,
  attachment,
  setIdToUpdate,
  defaultTemplate,
}) {
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (ddata)
      //{
      // setDetailsNoData(false);
      // setShowDetailsLoader(false);
      setData(ddata);
    // } else setDetailsNoData(true);
  }, [ddata]);

  useEffect(() => {
    setData(completeData[parseInt(checkId)]);
  }, [checkId]);

  const handleCopyText = (val) => {
    copy(val);
    showAlert("Template is copied successfully");
  };

  const handleDelete = async () => {
    const { status } = await api.deleteEmailTemplate({
      temp_id: data.temp_id,
      message_type: type,
    });
    if (status === 200) {
      showAlert("Template deleted successfully");
      setUpdateLists(updateLists ? false : true);
    } else showAlert("Template deletion failed");
  };

  const handleMakeDefault = async () => {
    const res = await api.updateDefaultTemplate({
      tempId: data.temp_id,
      type: type,
    });
    if (res.status === 200) {
      setUpdateLists(updateLists ? false : true);
      showAlert("Template has been made default");
    } else if (res.status === 201) {
      showAlert(
        "Please add this template to selected templates before making it default"
      );
    } else showAlert("Operation unsucessful");
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleHideModal = () => {
    setShowModal(false);
  };

  return (
    <>
      {data && !loadingBar && !detailsLoadingBar && (
        <div className='detailsPart'>
          <div className='actionButton'>
            {data && data.name === localStorage.getItem("username") && (
              <img
                src={require("../../../assets/images/edit-button.png")}
                alt='edit'
                onClick={() => {
                  setIdToUpdate(data.temp_id);
                  setUpdate(true);
                }}
              />
            )}
            {defaultTemplate !== data.temp_id &&
              data &&
              data.name === localStorage.getItem("username") && (
                <img
                  src={require("../../../assets/images/deleteButton1.png")}
                  alt='delete'
                  onClick={() => handleShowModal()}
                />
              )}
            <img
              src={require("../../../assets/images/copy-button-blue.png")}
              alt='copy'
              onClick={() => handleCopyText(data.value)}
            />
            {defaultTemplate !== data.temp_id && (
              <div
                style={{ backgroundColor: "#EFFFF9" }}
                onClick={handleMakeDefault}
              >
                <div></div>
                <p>Make Default</p>
              </div>
            )}
          </div>
          <div className='details'>
            <div>
              <h6>Title:</h6>
              <h5>{data ? data.label : "No Data to show"}</h5>
            </div>
            <hr />
            <div>
              <h6>Template Text:</h6>
              <p>{data ? data.value : ""}</p>
            </div>
            <hr />
            {attachment && (
              <div>
                <h6>Attachments:</h6>
                <p>
                  {data.attachment ? (
                    data.attachment.file_name ? (
                      <u
                        onClick={() =>
                          window.open(data.attachment.attachment_link)
                        }
                      >
                        {data.attachment.file_name}
                      </u>
                    ) : (
                      "-" //"No attachments found"
                    )
                  ) : (
                    "-" //"No attachments found"
                  )}
                </p>
              </div>
            )}
            {attachment && <hr />}
            <div>
              <h6>Created by:</h6>
              <h3 className='creator'>
                <img src={require("../../../assets/images/user.png")} />
                {data ? data.name : ""}
              </h3>
            </div>
          </div>
        </div>
      )}
      {!data && !loadingBar && (
        // && showDetailsLoader && (
        //   <div className='noDetails'>
        //     <Loader type='Oval' color='black' height={38} width={38} />
        //   </div>
        // )}
        // {detailsNoData &&
        <div className='noDetails'>
          <p>Select a template to show details</p>
        </div>
      )}
      <Confirmation
        show={showModal}
        onHide={handleHideModal}
        description={"Are you sure you want to delete this template?"}
        yesFunc={handleDelete}
      />
      {!loadingBar && detailsLoadingBar && (
        <div className='noDetails'>
          <Loader type='Oval' color='black' height={38} width={38} />
        </div>
      )}
    </>
  );
}

export default Details;
