import React from "react";
import snoozeIcon from "../assets/images/snooze_icon.png";
import Popup from "reactjs-popup";
import "../styles/snoozebutton.css";
import "react-datepicker/dist/react-datepicker.css";
import SnoozeModal from "../ReusabelComponents/SnoozeModal";

class SnoozeButton extends React.PureComponent {
  positions = [];

  constructor(props) {
    //
    super(props);
    this.state = {
      showCustomPicker: false,
      showSnoozeModal: false,
    };

    if (props.row.index % 20 < 5) {
      this.positions.push("left top");
    } else {
      this.positions.push("left top");
      this.positions.push("left bottom");
    }
  }

  submitValue = (endDate, option) => {
    if (endDate == null) {
      return;
    }

    this.setState({ showSnoozeModal: false });
    this.props.handleSubmit(
      this.props.row.index,
      this.props.row,
      endDate,
      option
    );
    // this.props.confirmAction(
    //   "Snooze Lead",
    //   "Are you sure you want to snooze this lead?",
    //   () => {
    //    this.props.handleSubmit(
    //      this.props.row.index,
    //      this.props.row,
    //      endDate,
    //      option
    //    );
    //   },
    //   () => {}
    // );
  };
  render() {
    return (
      <div>
        <Popup
          trigger={
            <button
              style={{
                width: 25,
                height: 25,
                marginLeft: 25,
                border: "none",
                backgroundRepeat: "no-repeat",
                backgroundColor: "transparent",
                backgroundSize: "contain",
                backgroundImage: `url(${snoozeIcon})`,
              }}
              onClick={() => {
                this.setState({ showSnoozeModal: true });
              }}
            ></button>
          }
          position={this.positions}
          on={"hover"}
        >
          <span>Snooze this lead</span>
        </Popup>
        {this.state.showSnoozeModal && (
          <SnoozeModal
            show={this.state.showSnoozeModal}
            submitValue={this.submitValue}
            onCancelEditModal={() => {
              this.setState({ showSnoozeModal: false });
            }}
          />
        )}
      </div>
    );
  }
}
export default SnoozeButton;
