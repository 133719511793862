import Axios from "axios";
import { addUserToTempObjConcate, baseURL } from "./Constants";

export function addTemplateApi(userName, title, label, customTemplate, handelLoader, showAlert, attachment) {
  return new Promise((resolve, reject) => {
    let resObject = {
      status: false,
      type: null,
      result: null,
    };
    handelLoader("true");
    Axios.post(
      baseURL() + "/api/v1/users/add-template-message",
      {
        added_by: userName ? userName : "Web Portal",
        message_type: title,
        label: label,
        value: customTemplate,
        attachment: attachment,
      },
      {
        headers: {
          "x-access-token": localStorage.getItem("jwtToken"),
          "app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
          socket_id: localStorage.getItem("socket_id"),
        },
      }
    )
      .then((res) => {
        // console.log("api_res", res.data.data.data)
        if (res.data.data.message_type == "SMS") {
          // let filterArray = messageList.filter((user) => {
          //     return user.email != userEmail
          // })
          // let resArray = addUserToTempObj(res.data.data.data.auto_followup_canned_sms_temp)
          // console.log("concat", filterArray, "--", resArray)
          // let newArray = [...resArray, ...filterArray]
          // setMessageList(newArray)
          resObject = {
            status: true,
            type: "SMS",
            result: res.data.data.data.auto_followup_canned_sms_temp,
          };
          // let list = res.data.data.data.auto_followup_canned_sms_temp
          // setSelectedOption({
          //     label: list[list.length - 1].label,
          //     value: list[list.length - 1].value
          // })
        } else {
          // let filterArray = messageList.filter((user) => {
          //     return user.email != userEmail
          // })
          // let resArray = addUserToTempObj(res.data.data.data.auto_followup_canned_email_temp)
          // console.log("concat", filterArray, "--", resArray)
          // let newArray = [...resArray, ...filterArray]
          // setMessageList(newArray)

          resObject = {
            status: true,
            type: "email",
            result: res.data.data.data.auto_followup_canned_email_temp,
          };
          // let list = res.data.data.data.auto_followup_canned_email_temp
          // setSelectedOption({
          //     label: list[list.length - 1].label,
          //     value: list[list.length - 1].value
          // })
        }
        // handelLoader("false")
        showAlert("New template added", 1000);
        resolve(resObject);
      })
      .catch((error) => {
        // handelLoader("false")
        showAlert("Failed to save changes, please try again", 1000);
        reject(resObject);
      });
  });
}

export function selectedTemplateApi(selectedTemplates, type) {
  // console.log("props", selectedTemplates, type)

  return new Promise((resolve, reject) => {
    let resObject = {
      status: false,
      type: null,
      result: null,
    };
    Axios.post(
      baseURL() + "/api/v1/users/selected-template",
      {
        selectedTemplates: selectedTemplates,
        message_type: type,
      },
      {
        headers: {
          "x-access-token": localStorage.getItem("jwtToken"),
          "app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
          socket_id: localStorage.getItem("socket_id"),
        },
      }
    )
      .then((res) => {
        // console.log("api_res", res.data.data.data)
        // resObject = {
        //     status: true,
        //     type: "email",
        //     result: res.data.data.data
        // }
        // return
        if (res.data.data.message_type == "SMS") {
          resObject = {
            status: true,
            type: "SMS",
            result: res.data.data.data.auto_followup_canned_sms_temp,
          };
        } else {
          resObject = {
            status: true,
            type: "email",
            result: res.data.data.data.auto_followup_canned_email_temp,
          };
        }
        // showAlert("New template added", 1000);
        resolve(resObject);
      })
      .catch((error) => {
        // showAlert("Failed to save changes, please try again", 1000);
        reject(resObject);
      });
  });
}

export function handleDeleteTemplate(selectedTempID, title, showAlert, fetchDataChange, setUserLoader) {
  // console.log("del props", selectedTempID, title, showAlert, fetchDataChange)
  setUserLoader(true);
  // const selectedTempID = messageList.find((option) => option.value === selectedDelVal).temp_id;
  return new Promise((resolve, reject) => {
    let resObject = {
      status: false,
      type: null,
      result: null,
    };
    // setLoader(true)
    // console.log("selectedTempID", selectedTempID)
    Axios.post(
      baseURL() + "/api/v1/users/delete-template-message",
      {
        temp_id: selectedTempID,
        message_type: title,
      },
      {
        headers: {
          "x-access-token": localStorage.getItem("jwtToken"),
          "app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
          socket_id: localStorage.getItem("socket_id"),
        },
      }
    )
      .then((res) => {
        // console.log("delresponse", res)
        // setLoader(false)
        // setIsDeleteModal(false)
        // setShowAddModal(false)
        // setSelectedDelVal("")
        // setSelectedOption({
        //   label: "",
        //   value: ""
        // })
        if (res.data.data.message_type == "SMS") {
          resObject = {
            status: true,
            type: "SMS",
            result: res.data.data.data.auto_followup_canned_sms_temp,
          };
          fetchDataChange(res.data.data);
          //   let filterArray = messageList.filter((user) => {
          //     return user.email != userEmail
          //   })
          //   let resArray = addUserToTempObjConcate(res.data.data.data.auto_followup_canned_sms_temp)
          //   let newArray = [...resArray, ...filterArray]
          //   // get selection list of logged in user and filter templates
          //   let selectionArray = res.data.data.data.sms_selected_templates
          //   const filteredTemp = newArray.filter((item) => selectionArray.includes(item.temp_id));
          //   setMessageList(filteredTemp)
          // dispatch(addSmsSelectedTemplates(selectionArray))

          // setMessageList(newArray)
          resolve(resObject);
          setUserLoader(false);
        } else {
          resObject = {
            status: true,
            type: "email",
            result: res.data.data.data.auto_followup_canned_email_temp,
          };
          fetchDataChange(res.data.data);
          //   let filterArray = messageList.filter((user) => {
          //     return user.email != userEmail
          //   })
          //   let resArray = addUserToTempObjConcate(res.data.data.data.auto_followup_canned_email_temp)
          //   let newArray = [...resArray, ...filterArray]

          //   let selectionArray = res.data.data.data.email_selected_templates
          //   const filteredTemp = newArray.filter((item) => selectionArray.includes(item.temp_id));
          //   setMessageList(filteredTemp)
          // dispatch(addEmailSelectedTemplates(selectionArray))
          // setMessageList(newArray)
          resolve(resObject);
          setUserLoader(false);
        }
        showAlert("Template deleted", 1000);
      })
      .catch((error) => {
        // setLoader(false)
        // setSelectedOption({
        //   label: "",
        //   value: ""
        // })
        // setIsDeleteModal(false)
        // setShowAddModal(false)
        // setSelectedDelVal("")
        resolve(resObject);
        setUserLoader(false);
        showAlert("Failed to save changes, please try again", 1000);
      });
  });
}

export function handleUpdateTemplate(temp_id, userName, title, label, customTemplate, handelLoader, showAlert, attachment) {
  return new Promise((resolve, reject) => {
    let resObject = {
      status: false,
      type: null,
      result: null,
    };
    handelLoader("true");
    Axios.post(
      baseURL() + "/api/v1/users/update-template-message",
      {
        added_by: userName ? userName : "Web Portal",
        message_type: title,
        label: label,
        value: customTemplate,
        attachment: attachment,
        temp_id: temp_id,
      },
      {
        headers: {
          "x-access-token": localStorage.getItem("jwtToken"),
          "app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
          socket_id: localStorage.getItem("socket_id"),
        },
      }
    )
      .then((res) => {
        console.log("api_res", res.data.data.data);
        if (res.data.data.message_type == "SMS") {
          // let filterArray = messageList.filter((user) => {
          //     return user.email != userEmail
          // })
          // let resArray = addUserToTempObj(res.data.data.data.auto_followup_canned_sms_temp)
          // console.log("concat", filterArray, "--", resArray)
          // let newArray = [...resArray, ...filterArray]
          // setMessageList(newArray)
          resObject = {
            status: true,
            type: "SMS",
            result: res.data.data.data.auto_followup_canned_sms_temp,
          };
          // let list = res.data.data.data.auto_followup_canned_sms_temp
          // setSelectedOption({
          //     label: list[list.length - 1].label,
          //     value: list[list.length - 1].value
          // })
        } else {
          // let filterArray = messageList.filter((user) => {
          //     return user.email != userEmail
          // })
          // let resArray = addUserToTempObj(res.data.data.data.auto_followup_canned_email_temp)
          // console.log("concat", filterArray, "--", resArray)
          // let newArray = [...resArray, ...filterArray]
          // setMessageList(newArray)

          resObject = {
            status: true,
            type: "email",
            result: res.data.data.data.auto_followup_canned_email_temp,
          };
          // let list = res.data.data.data.auto_followup_canned_email_temp
          // setSelectedOption({
          //     label: list[list.length - 1].label,
          //     value: list[list.length - 1].value
          // })
        }
        // handelLoader("false")
        showAlert("Template updated", 500);
        resolve(resObject);
      })
      .catch((error) => {
        // handelLoader("false")
        showAlert("Failed to save changes, please try again", 500);
        reject(resObject);
      });
  });
}

export function saveWebDeviceTokenApi(device_token) {
  return new Promise((resolve, reject) => {
    let resObject = {
      status: false,
      message: null,
    };

    Axios.post(
      baseURL() + "/api/v1/users/save-web-devicetoken",
      {
        device_token: device_token,
      },
      {
        headers: {
          "x-access-token": localStorage.getItem("jwtToken"),
          "app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
          socket_id: localStorage.getItem("socket_id"),
        },
      }
    )
      .then((res) => {
        if (res.data.success) {
          resObject = {
            status: true,
            message: res.data.message,
          };

          //   resolve(resObject);
        } else {
          resObject.message = res.data.message || "Failed to save web token";
          console.log("fcm response", resObject);

          //   reject(resObject);
        }
      })
      .catch((error) => {
        resObject.message = error.message || "Error saving web token";
        reject(resObject);
      });
  });
}
