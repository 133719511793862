import React, { useRef } from "react";
import { Row, Col } from "react-bootstrap";
import { CSVLink } from "react-csv";
import csvExportIcon from "../assets/images/Frame-2.png";
import csvDownloadIcon from "../assets/images/csv_download.png";
import crossIconX from "../assets/images/x-2.png";

const FilteredDataHeader = ({
	filterData,
	onCSVGenerate,
	filteredLeadsData,
	handleClearFilters,
	CSVFileName,
	exportableData,
	allowToUpdate,
	selectRowByAssignee,
}) => {
	const csvLinkEl = useRef(null);

	const exportLeadCSV = () => {
		const fileName =
			"LMS-Filtered-Customers-" + new Date().toISOString() + ".csv";
		let uniqueData = new Map(filterData.map((item) => [item._id, item]));
		uniqueData = Array.from(uniqueData.values());
		const temp = uniqueData.map(
			({ name, phone_number, email, address }) => ({
				name,
				phone_number,
				email,
				address,
			})
		);

		onCSVGenerate(temp, fileName);
		setTimeout(() => {
			csvLinkEl.current.link.click();
		}, 1000);
	};

	return (
		<div
			style={{
				minHeight: 60,
				display: "flex",
				flexDirection: "row",
			}}
		>
			<div style={{ width: "50wv" }}>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						justifycontent: "flex-start", // Align items at the start of the container
						width: "auto",
						padding: "10px 5rem",
					}}
				>
					<p
						style={{
							fontSize: "19px",
							color: "#000000",
							fontWeight: 500,
						}}
					>
						Filtered Data
					</p>
					<span
						style={{
							fontFamily: "SF Pro Display Semibold",
							fontSize: "17px",
							color: "#6C6C6C",
							margin: "0px 15px",
							padding: "5px 0px",

							// padding: "0px 3px",
						}}
					>
						{filteredLeadsData.length}(Result Found)
					</span>

					<span
						onClick={exportLeadCSV}
						style={{
							border: "1px solid #4256D0",
							display:
								filteredLeadsData.length > 0 ? "flex" : "none",
							flexDirection: "row",
							padding: "5px 15px",
							borderRadius: 2,
							cursor: "pointer",
						}}
					>
						<img
							style={{ height: 18, width: 18 }}
							src={csvExportIcon}
							alt="Export CSV"
						/>
						<p
							style={{
								fontSize: "12px",
								color: "#4256D0",
								margin: "0 5px",
							}}
						>
							Export CSV
						</p>
						<img
							style={{ height: 15, width: 15 }}
							src={csvDownloadIcon}
							alt="Download CSV"
						/>
					</span>
					<CSVLink
						data={exportableData}
						filename={CSVFileName}
						className="hidden"
						ref={csvLinkEl}
						target="_blank"
					/>
				</div>
			</div>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					width: "50wv",
				}}
			></div>
			{/* <Col
				md={2}
				style={{
					display: "none",
					flexDirection: "row",
					alignItems: "center",
				}}
			>
				<span
					// onClick={handleClearFilters}
					style={{
						width: "11rem",
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						justifyContent: "space-between",
						borderBottom: "1px solid #828282",
						cursor: "pointer",
					}}
				>
					<img
						style={{ height: 15, width: 15 }}
						src={crossIconX}
						alt="Clear Results"
					/>
					<span
						style={{
							fontSize: "14px",
							fontWeight: 500,
							color: "#6C6C6C",
						}}
					>
						Clear Results
					</span>
				</span>
			</Col> */}
		</div>
	);
};

export default FilteredDataHeader;
