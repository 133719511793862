import { makeStyles } from "@material-ui/core";

export default makeStyles(() => ({
  modal: {
    margin: "auto",
    borderRadius: 10,
    height: "100vh",
    background: " rgba(39, 38, 38, 0.5)",
    top: "15vh",
  },
  mainContainer: {
    // paddingTop: "5vh",
    // paddingLeft: "3vw",
    // paddingRight: "3vw",
  },
  headingContainer: {
    paddingTop: "5vh",
    paddingLeft: "3vw",
    paddingRight: "3vw",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  heading: {
    fontFamily: "Gilroy Semi-Bold",
    fontSize: "26px",
    // fontWeight: "bold",
  },
  closeIcon: {
    width: "32px",
    height: "32px",
    cursor: "pointer",
  },
  line: {
    marginTop: "1vh",
    marginBottom: "1vh",
    width: "100%",
    height: "0.01vh",
    backgroundColor: "#9F9F9F",
  },
  subheadingsContainer: {
    // paddingTop: "2vh",
    paddingLeft: "3vw",
    paddingRight: "3vw",
  },
  subheadings: {
    fontFamily: "Gilroy Semi-Bold",
    fontSize: "16px",
    marginTop: "1vh",
    marginBottom: "1vh",
  },
  inputs: {
    fontFamily: "Gilroy Regular",
    fontSize: "12px",
    width: "100%",
    padding: "2% 5%",
  },
  inputArea: {
    fontFamily: "Gilroy Regular",
    fontSize: "12px",
    width: "100%",
    padding: "2% 5%",
    height: "15vh",
  },
  buttonsContainer: {
    display: "flex",
    flexDirection: "row",
    marginTop: "2vh",
    paddingBottom: "5vh",
    paddingLeft: "3vw",
    paddingRight: "3vw",
    justifyContent: "space-between",
  },
  cancelButton: {
    fontFamily: "Gilroy Regular",
    fontSize: "15px",
    backgroundColor: "#454545",
    color: "white",
    width: "49%",
    height: "38px", //5vh",
    border: "none",
  },
  saveButton: {
    fontFamily: "Gilroy Regular",
    fontSize: "15px",
    backgroundColor: "#4256D0",
    color: "white",
    width: "49%",
    height: "38px", //5vh",
    border: "none",
  },
  filterDropdown: {
    height: "4vh",
    width: "100%",
    paddingLeft: "0.9vw",
    backgroundPosition: `right 10px center`,
    fontSize: "12px",
    fontFamily: "Gilroy Regular",
  },
  filterOptions: {
    // fontSize: "12px",
    // fontFamily: "Arial, Helvetica, sans-serif",
  },
  filesUploaded: {
    paddingTop: "1.2vh",
    paddingBottom: "1vh",
    fontFamily: "Gilroy Regular",
    fontSize: "10px",
  },
  loader: {
    width: "49%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
