import React, { useRef, useState } from "react";
import Loader from "react-loader-spinner";

import { SwatchesPicker } from "react-color";
import "./ColorPicker.css";

function ColorPickerModal({ show, close, color, onChange }) {
  const [confirmLoader, setConfirmLoader] = useState(false);
  const [selectedColor, setSelectedColor] = useState(color);
  const modalRef = useRef(null);

  const handleEnterPress = (event) => {
    if (event.key === "Enter") {
      close();
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      close();
    }
  };
  const handleModalClick = (e) => {
    if (e.target === modalRef.current) {
      close();
    }
  };

  return (
    show && (
      <div className='modalReminder' ref={modalRef} onClick={handleModalClick}>
        <div
          className='ModalBodyReminder ModalBodyColorPicker'
          onKeyDown={handleKeyDown}
          onKeyPress={handleEnterPress}
        >
          <p className='colorHeading'>Select Color:</p>
          <div className='colorPickerDiv'>
            <div className='selected-color'>
              <p>Previous Color:</p>
              <div style={{ backgroundColor: color }}></div>
            </div>
            <SwatchesPicker
              color={selectedColor}
              onChange={(color) => setSelectedColor(color)}
            />
          </div>
          <div className='ReminderActionButtons ColorPickerActionButtons'>
            <button className='CloseButton' onClick={close}>
              Close
            </button>
            {confirmLoader ? (
              <div className='ReminderLoader'>
                <Loader
                  className='modalLoader'
                  type='Oval'
                  color='black'
                  height={35}
                  width={35}
                />
              </div>
            ) : (
              <button
                className='confirmButton'
                onClick={async () => {
                  setConfirmLoader(true);
                  await onChange(selectedColor);
                  close();
                }}
              >
                Confirm
              </button>
            )}
          </div>
        </div>
      </div>
    )
  );
}

export default ColorPickerModal;
