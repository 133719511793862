import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "react-loader-spinner";

import LeftDiv from "./LeftDiv/LeftDiv";
import RightDiv from "./RightDiv/rightDiv";

import * as api from "../../Store/Sequences/sequenceActions";
import "./sequences.css";
import { SEQUENCES_SWITCH_CATEGORY } from "../../Store/Sequences/sequenceActionTypes";

function Sequences({
  users,
  selectedTemplatesEmail,
  selectedTemplatesSMS,
  showAlert,
}) {
  const dispatch = useDispatch();
  const { sequenceisLoading, sequence_error, sequenceDataToShow } = useSelector(
    (state) => state.sequences
  );

  const [options, setOptions] = useState("Recent");

  useEffect(() => {
    dispatch({ type: SEQUENCES_SWITCH_CATEGORY, payload: "All" });
  }, []);
  useEffect(() => setOptions(users), [users]);

  useEffect(() => {
    dispatch(api.getTemplates());
    dispatch(api.getSequences());
  }, []);

  useEffect(() => {
    if (sequence_error) showAlert("Operation Failed");
  }, [sequence_error]);

  return sequenceisLoading || (sequenceisLoading && !sequenceDataToShow) ? (
    <div className='loaderDiv'>
      <Loader type='Oval' color='black' height={38} width={38} />
    </div>
  ) : (
    <div className='sequencesMainContainer'>
      <div className='leftDiv'>
        <LeftDiv users={users} />
      </div>
      <div className='line'></div>
      <div className='rightDiv'>
        <RightDiv
          showAlert={showAlert}
          selectedTemplatesEmail={selectedTemplatesEmail}
          selectedTemplatesSMS={selectedTemplatesSMS}
        />
      </div>
    </div>
  );
}

export default Sequences;
