import axios from "axios";
import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import Popup from "reactjs-popup";
import Loader from "react-loader-spinner";
import SettingsContainer from "../SettingsManagement/SettingsContainer";
import leadSourceIcon from "../assets/images/lead-source-icon.svg";

export default (props) => {
  const [open, setOpen] = useState(false);

  const onCancelClick = () => {
    setOpen(false);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <span style={{ textAlign: "left" }}>
      <button
        style={{
          width: 20,
          height: 18,
          marginTop: 5,
          backgroundImage: `url(${leadSourceIcon})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          border: "none",
          backgroundColor: "transparent",
          cursor: "pointer",
        }}
        onClick={() => setOpen((o) => !o)}
      ></button>
      <span className='tooltip-text leads-source-tooltip'>Lead Source</span>
      <Popup onClose={onClose} open={open} className='lead-source-popup'>
        {/* <div
          style={{
            maxHeight: "80vh",
            overflowY: "scroll",
            width: "100%",
            padding: "4vh 2vw",
          }}
        > */}
        <div
          style={{
            maxHeight: "80vh",
            overflowY: "scroll",
            width: "100%",
            padding: "4vh 2vw",
          }}
          // style={{
          //   maxHeight: "80vh",
          //   overflowY: "scroll",
          //   width: "100%",
          // }}
        >
          <div className='lead-source-heading-container'>
            <h2 style={{ margin: 0, fontSize: "3rem", marginBottom: "2vh" }}>
              Lead Source
            </h2>
          </div>
          <SettingsContainer
            showAlert={props.showAlert}
            onCancelClick={onCancelClick}
          />
        </div>
        {/* </div> */}
      </Popup>
    </span>
  );
};
