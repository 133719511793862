import React from "react";
import ".././Home/Home.css";
import { Modal } from "react-bootstrap";
import { IsTextHTML } from "../Helpers/Constants";

export class DescriptionModal extends React.PureComponent {
  positions = [];
  state = {
    show: true,
    isHTML: false,
  };

  render() {
    this.setState({
      isHTML: IsTextHTML(
        this.props.descriptionModal.length > 0
          ? this.props.descriptionModal
          : "plain text"
      ),
    });
    return (
      <div style={{ fontFamily: "Arial, Helvetica, sans-serif" }}>
        <Modal show={this.props.show} onHide={this.handleClose}>
          <Modal.Header>
            <Modal.Title style={{ fontFamily: "Arial, Helvetica, sans-serif" }}>
              Description
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ paddingLeft: 20, paddingRight: 20 }}>
            {this.state.isHTML ? (
              <div
                style={{
                  width: "fit-content",
                }}
                dangerouslySetInnerHTML={{
                  __html: this.props.descriptionModal.toString(),
                }}
              ></div>
            ) : (
              <p
                style={{
                  fontFamily: "Arial, Helvetica, sans-serif",
                  fontSize: 12,
                }}
              >
                {this.props.descriptionModal.length > 0
                  ? this.props.descriptionModal
                  : "No Description"}
              </p>
            )}
          </Modal.Body>
          <Modal.Footer>
            <button
              className='edit-modal-save-button'
              onClick={this.props.onCancelDescrpModal}
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
export default DescriptionModal;
