import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";

import * as api from "../../../../api/api";
import { Confirmation } from "../../Modals/Confirmation/confirmation";
import { toggleItem } from "../../../Helpers/Constants";
import "./TemplateTiles.css";

const copy = require("clipboard-copy");

function TemplateTiles({
  id,
  total,
  creator,
  title,
  description,
  link,
  tempId,
  selectedTemplates,
  setShowData,
  temp,
  showAlert,
  setEdit,
  type,
  select,
  updateLists,
  setUpdateLists,
  defaultTemplate,
  setIdToUpdate,
  setShowSaveButton,
  setSelectedEmailTemplates,
  handleDragStart,
  handleDragOver,
  handleDrop,
  selectedOption,
  draggable,
}) {
  const [showModal, setShowModal] = useState(false);
  const [selected, setSelected] = useState(false);
  const [first, setFirst] = useState(true);
  const [makeDefaultLoader, setMakeDefaultLoader] = useState(false);

  useEffect(() => {
    setSelected(selectedTemplates.includes(tempId));
  }, [tempId, selectedTemplates, first === false]);

  const handleChangeSelection = async (id, type) => {
    let selectedTempArray = [...selectedTemplates];

    if (selectedTemplates.length == 1 && selectedTemplates.includes(id)) {
      return;
    }
    let array = await toggleItem(selectedTempArray, id);
    setSelectedEmailTemplates(array);
    return array;
  };

  const handleCopyText = () => {
    copy(description);
    showAlert("Template is copied successfully");
  };
  const handleDelete = async () => {
    const { status } = await api.deleteEmailTemplate({
      temp_id: tempId,
      message_type: type,
    });
    if (status === 200) {
      setUpdateLists(updateLists ? false : true);
      showAlert("Template deleted successfully");
    } else showAlert("Template deletion failed");
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleHideModal = () => {
    setShowModal(false);
  };

  const handleMakeDefault = async () => {
    const res = await api.updateDefaultTemplate({
      tempId: tempId,
      type: type,
    });
    if (res.status === 200) {
      setUpdateLists(updateLists ? false : true);
      showAlert("Template has been made default");
    } else if (res.status === 201) {
      showAlert(
        "Please add this template to selected templates before making it default"
      );
    } else showAlert("Operation unsucessful");
    setMakeDefaultLoader(false);
  };

  return (
    <>
      <div
        draggable={
          draggable && selectedOption.toLowerCase() === "selected templates"
        }
        onMouseDown={() => {
          handleDragStart();
          setShowData(id);
        }}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        className={
          parseInt(select) === parseInt(id)
            ? `checkboxContainer borderWidthAndColor .${tempId}`
            : defaultTemplate
            ? ` checkboxContainer defaultTemplateContainer .${tempId}`
            : `checkboxContainer .${tempId}`
        }
      >
        <div>
          <div
            className={
              selected
                ? `checkButton-checked .${tempId}`
                : `checkButton-unchecked .${tempId}`
            }
            onClick={async () => {
              if (!defaultTemplate) {
                // temp(tempId);
                // setSelected(selected ? false : true);
                setShowSaveButton(true);
                await handleChangeSelection(tempId, type);
              } else {
                showAlert("You can not unselect the default template");
              }
            }}
          >
            {selected && (
              <img
                src={require("../../../assets/images/template-check.png")}
                alt='check'
              />
            )}
          </div>
          {/* <input
            type='checkbox'
            className='rounded-checkbox'
            checked={selected} //{selectedTemplates.includes(tempId)}
            onChange={() => {
              if (!defaultTemplate) {
                temp(tempId);
                setSelected(selected ? false : true);
              }
            }}
          /> */}
        </div>
        <div
          className={`mainTilesContainer .${tempId}`}
          onClick={() => {
            setShowData(id);
          }}
        >
          <div className={`spaceAndFlex .${tempId}`}>
            <div className={`imageContainer .${tempId}`}>
              <img
                className={`userImage .${tempId}`}
                src={require("../../../assets/images/user.png")}
              />
              <p style={{ height: "100%" }}>{creator ? creator : ""}</p>
            </div>
            <div className={`imageContainer .${tempId}`}>
              {defaultTemplate && (
                <div className={`defaultTemplate .${tempId}`}>
                  <p>Default</p>
                  <div></div>
                </div>
              )}
              <p className={`borderContainer .${tempId}`}>
                {id + 1}/{total}
              </p>
              <img
                className={`copyImg .${tempId}`}
                src={require("../../../assets/images/copy.png")}
                onClick={handleCopyText}
              />
              {!defaultTemplate &&
                (makeDefaultLoader ? (
                  <Loader
                    type='Oval'
                    color='black'
                    height={24}
                    width={24}
                    style={{ marginTop: "3px", marginLeft: "10px" }}
                  />
                ) : (
                  <div
                    style={{ backgroundColor: "#EFFFF9" }}
                    className='actionButton2'
                    onClick={() => {
                      setMakeDefaultLoader(true);
                      handleMakeDefault();
                    }}
                  >
                    <div></div>
                    <p>Make Default</p>
                  </div>
                ))}
            </div>
          </div>
          <h3 className={`mhr-title .${tempId}`}>{title ? title : ""}</h3>
          <div className={`spaceAndFlex .${tempId}`}>
            <p className={`mhr-description .${tempId}`}>
              {description ? description : "No Description"}
            </p>
            {creator &&
              creator.toLowerCase() ===
                localStorage.getItem("username").toLowerCase() && (
                <div className={`editDeleteContainer .${tempId}`}>
                  <button
                    onClick={() => {
                      setIdToUpdate(tempId);
                      setEdit(true);
                    }}
                    className={`actionButtons .${tempId}`}
                  >
                    Edit
                  </button>
                  {!defaultTemplate && (
                    <>
                      <p>{" | "}</p>
                      <button
                        onClick={handleShowModal}
                        className={`actionButtons .${tempId}`}
                      >
                        Delete
                      </button>
                    </>
                  )}
                </div>
              )}
          </div>
          {link ? (
            link.file_name ? (
              <div
                className={`link .${tempId}`}
                onClick={() => window.open(link.attachment_link)}
              >
                <img
                  className={`linkImg .${tempId}`}
                  src={require("../../../assets/images/link.png")}
                  alt='download'
                />
                <p>{link ? <u>{link.file_name}</u> : ""}</p>
              </div>
            ) : null
          ) : null}
        </div>
      </div>
      <Confirmation
        show={showModal}
        onHide={handleHideModal}
        title='Confirm?'
        description={"Are you sure you want to delete this template?"}
        yesFunc={handleDelete}
      />
    </>
  );
}

export default TemplateTiles;
