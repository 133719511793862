import React, { useEffect, useRef, useState } from "react";

import "./genericDropDown.css";

const GenericDropdown = ({
  options,
  defaultOption,
  onSelect,
  optionSelected,
  active = true,
  random = false,
  rounded = true,
  borderAroundIcon = true,
}) => {
  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    onSelect(optionSelected);
    setSelectedOption(optionSelected);
    setIsOpen(false);
  }, [optionSelected]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleOptionClick = (option) => {
    onSelect(option);
    setSelectedOption(option);
    setIsOpen(false);
  };

  return (
    <div ref={dropdownRef} className='generic-dropdown'>
      <div
        className={`generic-dropdown-button ${
          rounded && "rounded-dropdown-border "
        }`}
        onClick={() => {
          if (active) setIsOpen(!isOpen);
        }}
      >
        <div className='generic-dropdown-text'>
          <p>
            {selectedOption
              ? random
                ? selectedOption.sequenceTitle
                : selectedOption.documentNameToShow &&
                  selectedOption.documentNameToShow !== ""
                ? selectedOption.documentNameToShow
                : selectedOption.documentName
              : defaultOption
              ? defaultOption
              : "Select Media and Files"}
          </p>
        </div>
        <div className={`arrowContainer ${!borderAroundIcon && "noBorder"}`}>
          <div>
            <img src={require("../../assets/images/down_arrow.png")} />
          </div>
        </div>
      </div>
      {isOpen && (
        <div className='generic-dropdown-menu'>
          {options &&
            options.length !== 0 &&
            options.map((option, index) => (
              <div
                key={index}
                className='generic-dropdown-item'
                onClick={() => handleOptionClick(option)}
              >
                {random
                  ? option.sequenceTitle
                  : option.documentNameToShow &&
                    option.documentNameToShow !== ""
                  ? option.documentNameToShow
                  : option.documentName}
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default GenericDropdown;
