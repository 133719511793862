import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import PdfShareModal from "./PdfShareModal";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import moment from "moment";
import jsPDF from "jspdf";
import axios from "axios";
import config from "../Helpers/config";
import autoTable from "jspdf-autotable";
import "../styles/export.css";
import {
	baseURL,
	convertHTMLToPlainText,
	phoneNumberConvert,
	// isValidPhoneNumber,
	isValidEmail,
	countTrueValues,
} from "../Helpers/Constants";
import { sendPdfMms, updateUserSelectedOptions } from "../../api/api";
const AWS = require("aws-sdk");

AWS.config.update({
	accessKeyId: config.AWS_ACCESS_KEY_ID,
	secretAccessKey: config.AWS_SECRET_ACCESS_KEY,
	region: config.AWS_region,
});

function ExportModal({
	showModal,
	handleClose,
	dataObject,
	includeKeys,
	showAlert,
	handlePopupClose,
}) {
	const [checkedItems, setCheckedItems] = useState({});
	const [selectAll, setSelectAll] = useState(false);
	const [loader, setLoader] = useState(false);
	const [shareStatus, setShareStatus] = useState("");
	const [showShareModal, setShowShareModal] = useState(false);
	const [message, setMessage] = useState("");
	const [number, setNumber] = useState("");
	const [email, setEmail] = useState("");
	const [emailValidation, setEmailValidation] = useState(true);
	const [phoneValidation, setPhoneValidation] = useState(true);
	const [type, setType] = useState("phone");
	const [numbersList, setNumbersList] = useState([]);
	const [plainNumber, setPlainNumber] = useState("");
	const [emailList, setEmailList] = useState([]);

	const ColumnNames = {
		address: "Address",
		assigned_to: "Assigned To",
		category: "Category",
		channel: "Channel",
		// source: "Source",
		utc_time_stamp: "Time Stamp",
		email: "Email",
		name: "Name",
		// urgency: "Urgency",
		phone_number: "Phone Number",
		svc_requested: "Description",
	};
	//// functions////////

	const handlePhoneChange = (e) => {
		setNumber(
			e.target.value
				.replace("+1", "")
				.match(/\d*/g)
				.join("")
				.match(/(\d{0,3})(\d{0,3})(\d{0,9})/)
				.slice(1)
				.join("-")
				.replace(/-*$/g, "")
		);
		setPlainNumber(e.target.value);
		setPhoneValidation(isValidPhoneNumber(e.target.value));
	};
	const handleEmailChange = (e) => {
		setEmail(e.target.value);
		setEmailValidation(isValidEmail(e.target.value));
	};
	const isValidPhoneNumber = (value) => {
		let phoneNumberWithoutNonNumeric;
		var phoneNumber = value ? value : number;
		if (phoneNumber == null || phoneNumber == "") {
			return false;
		}
		if (typeof phoneNumber != "object") {
			phoneNumberWithoutNonNumeric = phoneNumber?.replace(/\D/g, "");

			if (
				phoneNumberWithoutNonNumeric.length > 17 ||
				phoneNumberWithoutNonNumeric.length < 10
			) {
				return false;
			}
			phoneNumberWithoutNonNumeric = phoneNumberWithoutNonNumeric.trim();
			var phoneNumberRegex =
				/^(?:(?:\+))[(]{0,1}[1-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;
			// console.log('PhoneNumber is:', phoneNumberRegex.test(phoneNumberWithoutNonNumeric));
			return !phoneNumberRegex.test(phoneNumberWithoutNonNumeric);
		}
	};
	const validatePhoneNumber = (number) => {
		setPhoneValidation(isValidPhoneNumber(number));
	};
	const validateEmail = (e) => {
		setEmailValidation(isValidEmail(e.target.value));
	};
	const handleMessageChange = (e) => {
		setMessage(e.target.value);
	};
	const filteredData = Object.keys(dataObject)
		.filter((key) => includeKeys.includes(key)) // Only include specified keys
		.sort((a, b) => includeKeys.indexOf(a) - includeKeys.indexOf(b)) // Sorting keys based on their order in includeKeys
		.reduce((obj, key) => {
			// Check if the key is 'category' and if it's an object with a 'label' property
			if (
				key === "category" &&
				dataObject[key] &&
				typeof dataObject[key] === "object" &&
				"label" in dataObject[key]
			) {
				obj[key] = dataObject[key].label; // Use category.label
			} else {
				obj[key] = dataObject[key]; // Use the value directly
			}
			return obj;
		}, {});
	//////
	const updateUserSelectedFeilds = async () => {
		//ma
		const storedColumnsString = localStorage.getItem("userSelectedColumn");
		if (storedColumnsString)
			var storedColumns = JSON.parse(storedColumnsString);
		const storedEmailList = localStorage.getItem("userPdfShareEmail");
		if (storedEmailList) var storedEmail = JSON.parse(storedEmailList);
		const storedNumberList = localStorage.getItem("userPdfShareNumber");
		if (storedNumberList) var storedNumber = JSON.parse(storedNumberList);

		var data = {
			email: localStorage.getItem("email"),
			selectedColumns: storedColumns,
			emailsList: storedEmail,
			numbersList: storedNumber,
		};
		try {
			const response = await updateUserSelectedOptions(data);
			if (response.status == 200) {
			}
		} catch (error) {
			return false;
		}
	};
	// const updateUserSelectedFeilds = async () => {
	// 	const storedColumnsString = localStorage.getItem("userSelectedColumn");
	// 	const storedColumns = JSON.parse(storedColumnsString);
	// 	var data = {
	// 		email: localStorage.getItem("email"),
	// 		selectedArray: storedColumns,
	// 	};
	// 	try {
	// 		const response = await updateUserSelectedColumns(data);
	// 		if (response.status == 200) {
	// 			console.log("response", response.data.data);
	// 		}
	// 	} catch (error) {
	// 		return false;
	// 	}
	// };
	const handleCheckChange = (event) => {
		const { name, checked } = event.target;
		if (name === "selectAll") {
			setSelectAll(checked);
			setCheckedItems(
				Object.keys(filteredData).reduce((acc, key) => {
					acc[key] = checked;
					return acc;
				}, {})
			);
		} else {
			setCheckedItems({ ...checkedItems, [name]: checked });
		}
	};
	const handleShareExportData = async () => {
		if (email && emailValidation) {
			setLoader(true);
			var formattedDate;
			var array = JSON.stringify(checkedItems);
			localStorage.setItem("userSelectedColumn", array);

			////
			if (emailList.length > 0) {
				// Check if numbersList has elements
				if (email.trim() !== "") {
					// Check for non-empty input
					const newEmails = [...emailList, email];
					const updatedEmails = [...new Set(newEmails)];

					// Create new array with added number
					setEmailList(updatedEmails); // Update state directly with the new array
					localStorage.setItem(
						"userPdfShareEmail",
						JSON.stringify(updatedEmails)
					);
					setEmail("");
					setMessage("");

					// Reset input field
				}
			} else {
				// Handle the initial empty list scenario
				setEmailList([number.trim()]); // Create a new array with the first number
				localStorage.setItem(
					"userPdfShareEmail",
					JSON.stringify([email.trim()]) // Store as a single-element array
				);
				setEmail("");
				setMessage("");
				// Reset input field
			}
			const selectedItems = Object.keys(checkedItems).reduce(
				(acc, key) => {
					if (checkedItems[key]) {
						if (key === "utc_time_stamp") {
							formattedDate = moment(dataObject[key]).format(
								"MM-DD-YY h:mm A"
							);
						}
						if (key === "phone_number") {
							var phoneNumber = phoneNumberConvert(
								dataObject[key]
							);
						}
						if (key === "svc_requested") {
							var serviceRequested = convertHTMLToPlainText(
								dataObject[key]
							);
						}
						const value =
							key === "category" &&
							dataObject[key] &&
							typeof dataObject[key] === "object"
								? dataObject[key].label
								: key === "utc_time_stamp"
								? formattedDate
								: key === "phone_number"
								? phoneNumber
								: key === "svc_requested"
								? serviceRequested
								: dataObject[key];
						acc.push([ColumnNames[key], value]);
					}
					return acc;
				},
				[]
			);

			if (selectedItems.length > 0) {
				const doc = new jsPDF();
				autoTable(doc, {
					head: [["Variable", "Value"]],
					body: selectedItems,
					theme: "striped",
					didDrawPage: (data) => {
						doc.text("Lead Details", data.settings.margin.left, 10);
					},
					columnStyles: {
						0: { fontStyle: "bold" },
						// Yellow background for the first column (variables)
					},
				});
				var documentName = `${filteredData.name}.pdf`;
				doc.save(documentName);
				setLoader(true);
				// Generate the PDF and convert to base64
				const pdfDataUri = doc.output("datauristring");
				const base64String = pdfDataUri.split(",")[1];

				await axios
					.post(
						baseURL() + "/api/v1/leads/share-lead-pdf",
						{
							to: email,
							subject: "Subject of the email",
							message: message,
							replyTo: null,
							attachment: {
								fileName: documentName,
								content: base64String,
							},
						},
						{
							headers: {
								"x-access-token":
									localStorage.getItem("jwtToken"),
								"app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
								socket_id: localStorage.getItem("socket_id"),
							},
						}
					)
					.then((res) => {
						if (res.status === 200) {
							showAlert("PDF shared successfully.");
							setLoader(false);
						} else {
							setLoader(false);
							showAlert("Failed to share pdf, please try again");
						}
						handleCloseShareModal();
					})
					.catch((error) => {
						setLoader(false);
						showAlert("Failed to share pdf, please try again");
					});
				handleCloseShareModal();

				// console.log("payload", payload);
				handleClose();
				updateUserSelectedFeilds();
			} else {
				showAlert("Please select any value");
				handleCloseShareModal();
				setLoader(false);
			}
		} else showAlert("Please enter valid email");
	};
	const handleOpenShareModal = (val) => {
		setShowShareModal(true);
		setShareStatus(val);
	};

	const handleType = (val) => {
		setType(val);
		setMessage("");
	};
	const handleClearType = (val) => {
		setType("");
	};
	const handleCloseShareModal = () => {
		setShowShareModal(false);
	};

	const handleExportForMMS = async () => {
		var formattedDate;
		var array = JSON.stringify(checkedItems);
		localStorage.setItem("userSelectedColumn", array);
		let updatedNumbers; // Declare here

		////////////////
		if (numbersList.length > 0) {
			// Check if numbersList has elements
			if (number.trim() !== "") {
				// Check for non-empty input
				const newNumbers = [...numbersList, plainNumber]; // Create new array with added number
				const updatedNumbers = [...new Set(newNumbers)];
				setNumbersList(updatedNumbers); // Update state directly with the new array
				localStorage.setItem(
					"userPdfShareNumber",
					JSON.stringify(updatedNumbers)
				);
				setNumber("");
				setMessage("");
				// Reset input field
			}
		} else {
			// Handle the initial empty list scenario
			setNumbersList([number.trim()]); // Create a new array with the first number
			localStorage.setItem(
				"userPdfShareNumber",
				JSON.stringify([number.trim()]) // Store as a single-element array
			);
			setNumber("");
			setMessage("");
			// Reset input field
		}

		const selectedItems = Object.keys(checkedItems).reduce((acc, key) => {
			if (checkedItems[key]) {
				if (key === "utc_time_stamp") {
					formattedDate = moment(dataObject[key]).format(
						"MM-DD-YY h:mm A"
					);
				}
				if (key === "phone_number") {
					var phoneNumber = phoneNumberConvert(dataObject[key]);
				}
				if (key === "svc_requested") {
					var serviceRequested = convertHTMLToPlainText(
						dataObject[key]
					);
				}
				const value =
					key === "category" &&
					dataObject[key] &&
					typeof dataObject[key] === "object"
						? dataObject[key].label
						: key === "utc_time_stamp"
						? formattedDate
						: key === "phone_number"
						? phoneNumber
						: key === "svc_requested"
						? serviceRequested
						: dataObject[key];
				acc.push([ColumnNames[key], value]);
			}
			return acc;
		}, []);

		if (selectedItems.length > 0) {
			const doc = new jsPDF();
			autoTable(doc, {
				head: [["Variable", "Value"]],
				body: selectedItems,
				theme: "striped",
				didDrawPage: (data) => {
					doc.text("Lead Details", data.settings.margin.left, 10);
				},
				columnStyles: {
					0: { fontStyle: "bold" },
				},
			});
			var documentName = `${filteredData.name}.pdf`;
			doc.save(documentName);
			const pdfBlob = doc.output("blob");

			// Convert Blob to File
			const pdfFile = new File([pdfBlob], documentName, {
				type: "application/pdf",
			});
			// Upload to S3
			uploadToS3(pdfFile);
			updateUserSelectedFeilds();
		} else {
			handleCloseShareModal();
			showAlert("Please select any value");
			setLoader(false);
		}
	};

	const uploadToS3 = (file) => {
		console.log("S3");
		const fileExtension = file.name.split(".").pop().toLowerCase();
		const filename = file.name.replace(
			/(\.[\w\d_-]+)$/i,
			`.${fileExtension}`
		);
		var photoBucket = new AWS.S3({
			params: {
				Bucket: "lms-email-attachement",
			},
		});
		photoBucket.upload(
			{
				ACL: "public-read",
				Body: file,
				Key: filename, //files.name,
				ContentType: "application/pdf",
			},
			(err, response) => {
				if (err) {
					console.error("Error uploading PDF:", err);
				} else if (response) {
					console.log(
						"PDF uploaded successfully:",
						response.Location
					);
					sendToTwilio(response.Location);
					// Handle the success scenario here, like showing a success message or updating the UI
				} else {
					console.log("Something went wrong");
				}
			}
		);
	};
	const sendToTwilio = async (url) => {
		var urlArray = [];
		try {
			urlArray.push(url);
			const res = await sendPdfMms({
				phone_number: number, ///check added for test receiver
				message: message,
				url: urlArray,
			});
			setLoader(false);
			showAlert("Shared successfully");
			handleCloseShareModal();
		} catch (error) {
			console.log("Error", error);
			setLoader(false);
			handleCloseShareModal();
		}
	};
	const handleMMS = async () => {
		if (number && phoneValidation) {
			setLoader(true);
			handleExportForMMS();
		} else showAlert("Plese enter a valid number");
		// var urlArray = [];
		// try {
		// 	urlArray.push(url);
		// 	console.log("data", number, message, urlArray);
		// 	return;
		// 	const res = await sendPdfMms({
		// 		phone_number: number, ///check added for test receiver
		// 		message: message,
		// 		url: urlArray,
		// 	});
		// 	setLoader(false);
		// 	showAlert("Shared successfully");
		// 	handleCloseShareModal();
		// 	console.log("MMS response", res);
		// } catch (error) {
		// 	console.log("Error", error);
		// 	setLoader(false);
		// 	handleCloseShareModal();
		// }
	};
	const handleExport = () => {
		var formattedDate;
		var array = JSON.stringify(checkedItems);
		localStorage.setItem("userSelectedColumn", array);
		// var array = JSON.stringify(checkedItems);
		// localStorage.setItem("userSelectedColumn", array);
		const selectedItems = Object.keys(checkedItems).reduce((acc, key) => {
			if (checkedItems[key]) {
				if (key === "utc_time_stamp") {
					formattedDate = moment(dataObject[key]).format(
						"MM-DD-YY h:mm A"
					);
				}
				if (key === "phone_number") {
					var phoneNumber = phoneNumberConvert(dataObject[key]);
				}
				if (key === "svc_requested") {
					var serviceRequested = convertHTMLToPlainText(
						dataObject[key]
					);
				}
				const value =
					key === "category" &&
					dataObject[key] &&
					typeof dataObject[key] === "object"
						? dataObject[key].label
						: key === "utc_time_stamp"
						? formattedDate
						: key === "phone_number"
						? phoneNumber
						: key === "svc_requested"
						? serviceRequested
						: dataObject[key];
				acc.push([ColumnNames[key], value]);
			}
			return acc;
		}, []);

		if (selectedItems.length > 0) {
			const doc = new jsPDF();
			autoTable(doc, {
				head: [["Variable", "Value"]],
				body: selectedItems,
				theme: "striped",
				didDrawPage: (data) => {
					doc.text("Lead Details", data.settings.margin.left, 10);
				},
				columnStyles: {
					0: { fontStyle: "bold" },
					// Yellow background for the first column (variables)
				},
			});
			var documentName = `${filteredData.name}.pdf`;
			doc.save(documentName);
			handleClose();
			updateUserSelectedFeilds();
		} else {
			showAlert("Please select any value");
			setLoader(false);
			handleCloseShareModal();
			// alert("Please select any value");
		}
	};

	useEffect(() => {
		const storedColumnsStringy = localStorage.getItem("userSelectedColumn");
		const userPdfNumber = localStorage.getItem("userPdfShareNumber");
		if (userPdfNumber) {
			try {
				const parsedNumbers = JSON.parse(userPdfNumber);
				if (Array.isArray(parsedNumbers)) {
					setNumbersList(parsedNumbers);
				} else {
					console.error("Stored numbers is not an array");
				}
			} catch (e) {
				console.error("Failed to parse stored numbers:", e);
			}
		}

		const userPdfEmail = localStorage.getItem("userPdfShareEmail");
		if (userPdfEmail) {
			try {
				const parsedEmail = JSON.parse(userPdfEmail);
				if (Array.isArray(parsedEmail)) {
					setEmailList(parsedEmail);
				} else {
					console.error("Stored numbers is not an array");
				}
			} catch (e) {
				console.error("Failed to parse stored numbers:", e);
			}
		}
		// Check if storedColumnsString is not null and not undefined
		if (storedColumnsStringy) {
			try {
				// Parse the JSON string back into an object
				const storedColumns = JSON.parse(storedColumnsStringy);

				const initialCheckState = {};

				// Iterate over the keys in filteredData and initialize the check state based on storedColumns
				Object.keys(filteredData).forEach((key) => {
					// Directly use the boolean value from storedColumns
					initialCheckState[key] = !!storedColumns[key]; // The !! ensures the value is treated as a boolean
				});
				let trueLength = countTrueValues(
					JSON.parse(storedColumnsStringy)
				);
				if (trueLength === 6) {
					setSelectAll(true);
				}
				setCheckedItems(initialCheckState);
			} catch (error) {
				console.error("Error parsing stored columns:", error);
			}
		}
	}, []);
	return (
		<Modal
			show={showModal}
			onHide={handleClose}
			centered
			style={{
				justifyContent: "center",
				alignItems: "center",
				display: "flex",
			}}
		>
			<div
				style={
					showShareModal
						? {
								...styles.modalContainer2,
								height:
									numbersList &&
									numbersList.length > 0 &&
									type === "phone"
										? "42rem"
										: emailList &&
										  emailList.length > 0 &&
										  type === "email"
										? "42rem"
										: "36rem",
						  }
						: styles.modalContainer
				}
			>
				<Modal.Header
					closeButton
					style={styles.header}
					className="custom-modal-header"
				>
					<Modal.Title style={styles.modalTitle}>
						{showShareModal ? "Share PDF" : "Save Lead To PDF"}
					</Modal.Title>
				</Modal.Header>
				<div
				// style={{
				// 	marginTop:
				// 		emailList && emailList.length > 0
				// 			? -15
				// 			: numbersList && numbersList.length > 0
				// 			? -15
				// 			: -50,
				// }}
				>
					<Modal.Body style={styles.modalBody}>
						{!showShareModal ? (
							<Form>
								<Form.Check.Input
									type="checkbox"
									name="selectAll"
									checked={selectAll}
									onChange={handleCheckChange}
									style={styles.formCheckInput}
								/>
								<p style={{ fontSize: 16, marginLeft: 40 }}>
									Select All
								</p>
								<div
									style={{
										marginTop: 10,
										marginBottom: 10,
										height: 1,
										width: "35rem",
										backgroundColor: "lightgray",
									}}
								></div>
								{Object.keys(filteredData).map((key) => (
									<div
										key={key}
										style={{
											display: "flex",
											flexDirection: "row",
											alignItems: "center",
											marginBottom: 2,
										}}
									>
										<Form.Check.Input
											type="checkbox"
											style={styles.formCheckInput}
											name={key}
											checked={checkedItems[key] || false}
											onChange={handleCheckChange}
										/>
										<p
											style={{
												fontSize: 16,
												marginLeft: 40,
											}}
										>
											{`${ColumnNames[key]}`}
										</p>
									</div>
								))}
							</Form>
						) : (
							<PdfShareModal
								handlePhoneChange={handlePhoneChange}
								handleEmailChange={handleEmailChange}
								handleMessageChange={handleMessageChange}
								number={number}
								email={email}
								emailValidation={emailValidation}
								phoneValidation={phoneValidation}
								validatePhoneNumber={validatePhoneNumber}
								validateEmail={validateEmail}
								handleType={handleType}
								numbersList={numbersList}
								emailsList={emailList}
								message={message}
							/>
						)}
					</Modal.Body>
				</div>
				<div style={{ marginTop: -30 }}>
					<Modal.Footer>
						{!showShareModal ? (
							<>
								<input
									type="text"
									style={{
										opacity: 0,
										height: 0,
										width: 0,
										position: "absolute",
									}}
									autoFocus
								/>
								<Button
									size="lg"
									className={styles.noFocus}
									autoFocus={false}
									variant="primary"
									onClick={handleClose}
								>
									Close
								</Button>
								<Button
									size="lg"
									variant="primary"
									onClick={handleExport}
								>
									Export as PDF
								</Button>
								<Button
									size="lg"
									variant="primary"
									onClick={() => handleOpenShareModal()}
								>
									Share as Email/MMS
								</Button>
							</>
						) : (
							<>
								<Button
									size="lg"
									style={{
										outline: "none",
										boxShadow: "none",
									}}
									autoFocus={false}
									// variant="secondary"
									onClick={() => {
										setShowShareModal(false);
									}}
								>
									Back
								</Button>
								{loader ? (
									<div className="loader-cont">
										{" "}
										<p
											style={{
												fontSize: 11,
												paddingRight: 4,
												color: "gray",
												marginTop: 1,
											}}
										>
											Sharing file...
										</p>
										<Loader
											type="Oval"
											color="black"
											height={20}
											width={20}
										/>
									</div>
								) : type === "email" ? (
									<Button
										size="lg"
										variant="primary"
										onClick={() => {
											handleShareExportData();
										}}
									>
										Share via Email
									</Button>
								) : type === "phone" ? (
									<Button
										size="lg"
										variant="primary"
										onClick={() => {
											handleMMS();
										}}
									>
										Share via MMS
									</Button>
								) : null}
							</>
						)}
					</Modal.Footer>
				</div>
			</div>
		</Modal>
	);
}

export default ExportModal;

const styles = {
	modalContainer: {
		borderRadius: 5,
		backgroundColor: "white",
		position: "fixed",
		top: "50vh",
		left: "50%",
		transform: "translate(-50%, -50%)",
		height: "34rem",
		width: "40rem",
		// maxWidth: "25vw",
		// overflowY: "auto",
		// overflowX: "hidden",
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
	},
	modalContainer2: {
		borderRadius: 5,
		backgroundColor: "white",
		position: "fixed",
		top: "50vh",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: "50rem",
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
	},
	modalTitle: {
		fontSize: "1.7rem",
		color: "#0056b3",
	},
	header: { padding: "1.2rem 2rem", margin: "0rem 0rem" },
	modalBody: {
		fontSize: "1rem",
		padding: "0rem 1.8rem",
		// margin: 0,
		marginTop: -50,
	},
	formCheckInput: {
		margin: "0.75rem",
		accentColor: "#007bff",
		transform: "scale(1.5)", // Scale the checkbox larger by 1.5 times
	},
};
