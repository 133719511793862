import React, { useEffect, useRef, useState } from "react";

import "./Dropdown.css";

const DropdownForUsers = ({
  options,
  defaultOption,
  onSelect,
  optionSelected,
  active = true,
  roundBorders = true,
}) => {
  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    onSelect(optionSelected);
    setSelectedOption(optionSelected);
    setIsOpen(false);
  }, [optionSelected]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleOptionClick = (option) => {
    onSelect(option);
    setSelectedOption(option);
    setIsOpen(false);
  };

  return (
    <div
      ref={dropdownRef}
      className={`dropdown-mhr ${!active && "greyBackground"}`}
    >
      <div
        className={`dropdown-button-mhr ${
          roundBorders && "dropdown-button-mhr-roundBorders"
        }`}
        onClick={() => {
          if (active) setIsOpen(!isOpen);
        }}
      >
        {selectedOption && selectedOption.label
          ? selectedOption.label
          : defaultOption}
        <img src={require("../../assets/images/down_arrow.png")} />
      </div>
      {isOpen && (
        <div className='dropdown-menu-mhr'>
          <div
            key={"-1"}
            className='dropdown-item-mhr'
            onClick={() => handleOptionClick("All")}
          >
            {"All"}
          </div>

          {options.map((option, index) =>
            option.label.toLowerCase() !== "un-assign" ? (
              <div
                key={index}
                className='dropdown-item-mhr'
                onClick={() => handleOptionClick(option)}
              >
                {option.label}
              </div>
            ) : null
          )}
        </div>
      )}
    </div>
  );
};

export default DropdownForUsers;
