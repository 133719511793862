/* eslint-disable eqeqeq */
import React from "react";
import PropTypes from "prop-types";
const defaultButton = (props) => <button {...props}>{props.children}</button>;

export default class Pagination extends React.Component {
	constructor(props) {
		super();

		this.changePage = this.changePage.bind(this);

		this.state = {
			visiblePages: this.getVisiblePages(null, props.pages),
		};
	}

	static propTypes = {
		pages: PropTypes.number,
		page: PropTypes.number,
		PageButtonComponent: PropTypes.any,
		onPageChange: PropTypes.func,
		previousText: PropTypes.string,
		nextText: PropTypes.string,
	};

	componentDidUpdate(prevProps, prevState) {
		// console.log("in update", prevProps)
		if (this.props.pages != prevProps.pages) {
			this.setState({
				visiblePages: this.getVisiblePages(
					this.props.page + 1,
					this.props.pages
				),
			});
		}
		this.changePage(this.props.page + 1);
	}

	filterPages = (visiblePages, totalPages) => {
		return visiblePages.filter((page) => page <= totalPages);
	};

	getVisiblePages = (page, total) => {
		if (total < 7) {
			return this.filterPages([1, 2, 3, 4, 5, 6], total);
		} else {
			if (page % 5 >= 0 && page > 4 && page + 2 < total) {
				return [1, page - 1, page, page + 1, total];
			} else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
				return [1, total - 3, total - 2, total - 1, total];
			} else {
				return [1, 2, 3, 4, 5, total];
			}
		}
	};

	changePage(page) {
		const activePage = this.props.page + 1;

		if (page === activePage) {
			return false;
		}

		const visiblePages = this.getVisiblePages(page, this.props.pages);

		this.setState({
			visiblePages: this.filterPages(visiblePages, this.props.pages),
		});

		this.props.onPageChange(page - 1);

		return false;
	}

	render() {
		const { PageButtonComponent = defaultButton } = this.props;
		const { visiblePages } = this.state;
		const activePage = this.props.page + 1;
		return (
			<React.Fragment>
				<div
					className="Table__pagination"
					style={{
						display: "flex",
						height: "70px",
						// marginLeft: '42%',
						justifyContent: "center",
						alignItems: "center",
						// padding: '1px',
						fontSize: "12px",
						fontFamily: "SF Pro Text Bold",
						borderTop: "1px solid #BEBEBE",
					}}
				>
					<div className="Table__prevPageWrapper">
						<PageButtonComponent
							style={{
								background: "transparent",
								border: "none",
							}}
							className="Table__pageButton"
							onClick={() => {
								if (activePage === 1) return;
								this.changePage(activePage - 1);
							}}
							disabled={activePage === 1}
						>
							{this.props.previousText}
						</PageButtonComponent>
					</div>
					<div
						className="Table__visiblePagesWrapper"
						style={{
							width: "20%",
						}}
					>
						{visiblePages.map((page, index, array) => {
							return (
								<PageButtonComponent
									key={page}
									className={
										activePage === page
											? "Table__pageButton Table__pageButton--active"
											: "Table__pageButton"
									}
									onClick={this.changePage.bind(null, page)}
								>
									{array[index - 1] + 2 < page
										? `...${page}`
										: page}
								</PageButtonComponent>
							);
						})}
					</div>
					<div className="Table__nextPageWrapper">
						<PageButtonComponent
							className="Table__pageButton"
							onClick={() => {
								if (activePage === this.props.pages)
									return false;
								this.changePage(activePage + 1);
							}}
							disabled={activePage === this.props.pages}
						>
							{this.props.nextText}
						</PageButtonComponent>
					</div>
				</div>
			</React.Fragment>
		);
	}
}
