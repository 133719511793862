import React from "react";
import ".././Home/Home.css";
import NotesModalMultiLine from "./NotesModalMultiLine";

export class NoteModal extends React.PureComponent {

    render() {
        return <NotesModalMultiLine
            showAlert={this.props.showAlert}
            leadData={this.props.leadData}
            defaultValue={this.props.defaultValue}
            show={this.props.show}
            submitValue={this.props.submitValue}
            onCancelEditModal={this.props.onCancelEditModal}
            // refreshData={this.props.refresData}
            allowToUpdate={this.props.allowToUpdate}
            updateDataByID={this.props.updateDataByID}
            users={this.props.users}

        />
    }
}
export default NoteModal;