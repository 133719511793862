import {
  FETCH_ALL_SEQUENCES,
  SEARCH_SEQUENCES,
  DELETE_SEQUENCE,
  SEQUENCES_START_LOADING,
  SEQUENCES_END_LOADING,
  SEQUENCES_DELETED_TRUE,
  SEQUENCES_FETCH_EMAIL,
  SEQUENCES_FETCH_SMS,
  SEQUENCES_ERROR,
  CREATESEQUENCE,
  SEQUENCES_UPDATE,
  SEQUENCES_SEARCHING_FALSE,
  SEQUENCES_SAVE_BUTTON_START_LOADING,
  SEQUENCES_SAVE_BUTTON_END_LOADING,
  SEQUENCES_DELETED_FALSE,
  SEQUENCE_ERROR_TRUE,
} from "./sequenceActionTypes";
import * as api from "../../../api/api";

export const getTemplates = () => async (dispatch) => {
  try {
    dispatch({ type: SEQUENCES_START_LOADING });
    const { status, data } = await api.getAllTemplates();
    if (status === 200) {
      dispatch({
        type: SEQUENCES_FETCH_EMAIL,
        payload: data.data.all_email_templates,
      });
      dispatch({
        type: SEQUENCES_FETCH_SMS,
        payload: data.data.all_sms_templates,
      });
    } else {
      dispatch({
        type: SEQUENCES_ERROR,
        payload: data.message,
      });
    }
    dispatch({ type: SEQUENCES_END_LOADING });
  } catch (error) {
    console.log(error);
    dispatch({ type: SEQUENCE_ERROR_TRUE });
    dispatch({ type: SEQUENCES_END_LOADING });
  }
};

export const getSequences = () => async (dispatch) => {
  try {
    dispatch({ type: SEQUENCES_START_LOADING });
    const { status, data } = await api.fetchAllSequences();
    if (status === 200) {
      dispatch({ type: FETCH_ALL_SEQUENCES, payload: data });
    } else {
      dispatch({
        type: SEQUENCES_ERROR,
        payload: data.message,
      });
    }
    dispatch({ type: SEQUENCES_END_LOADING });
  } catch (error) {
    console.log(error);
    dispatch({ type: SEQUENCE_ERROR_TRUE });
    dispatch({ type: SEQUENCES_END_LOADING });
  }
};

export const createSequence = (post) => async (dispatch) => {
  try {
    dispatch({ type: SEQUENCES_SAVE_BUTTON_START_LOADING });
    const { status, data } = await api.createSequence(post);
    if (status === 201) {
      dispatch({ type: CREATESEQUENCE, payload: data });
    } else {
      dispatch({
        type: SEQUENCES_ERROR,
        payload: data.message,
      });
    }
    // dispatch({ type: SEQUENCES_SAVE_BUTTON_END_LOADING });
  } catch (error) {
    console.log(error);
    dispatch({ type: SEQUENCE_ERROR_TRUE });
    dispatch({ type: SEQUENCES_SAVE_BUTTON_END_LOADING });
  }
};

export const updateSequence = (id, post) => async (dispatch) => {
  try {
    dispatch({ type: SEQUENCES_SAVE_BUTTON_START_LOADING });
    const { status, data } = await api.updateSequence(id, post);
    if (status === 200) {
      dispatch({ type: SEQUENCES_UPDATE, payload: data.data });
    } else {
      dispatch({
        type: SEQUENCES_ERROR,
        payload: data.message,
      });
    }
    // dispatch({ type: SEQUENCES_SAVE_BUTTON_END_LOADING });
  } catch (error) {
    console.log(error);
    dispatch({ type: SEQUENCE_ERROR_TRUE });
    dispatch({ type: SEQUENCES_SAVE_BUTTON_END_LOADING });
  }
};

export const searchSequence = (post) => async (dispatch) => {
  try {
    const { status, data } = await api.searchSequence(post);
    if (status === 200) {
      dispatch({ type: SEARCH_SEQUENCES, payload: data.data });
    } else {
      dispatch({ type: SEQUENCES_SEARCHING_FALSE });
      dispatch({
        type: SEQUENCES_ERROR,
        payload: data.message,
      });
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: SEQUENCE_ERROR_TRUE });
  }
};

export const deleteSequence = (id) => async (dispatch) => {
  try {
    dispatch({ type: SEQUENCES_DELETED_TRUE });
    const { status, data } = await api.deleteSequence(id);
    if (status === 200) {
      dispatch({ type: DELETE_SEQUENCE, payload: id });
    } else {
      dispatch({
        type: SEQUENCES_ERROR,
        payload: data.message,
      });
    }
    // dispatch({ type: SEQUENCES_DELETED_FALSE });
  } catch (error) {
    console.log(error);
    dispatch({ type: SEQUENCE_ERROR_TRUE });
    dispatch({ type: SEQUENCES_DELETED_FALSE });
  }
};
