import React, { useEffect, useState } from "react";
import Select from "react-select";
import invert from "invert-color";
import { darkenHexColor, isColorDark } from "../Helpers/Constants";

function NewAssigneeDropdown({ row, rowValue, users, updateAssignee }) {
	const [assignee, setAssignee] = useState(
		users.find((option) => option.name === rowValue)
	);
	const [assigneeColor, setAssigneeColor] = useState("#707070");

	useEffect(() => {
		if (users && users.length !== 0 && rowValue) settingAssignee();
	}, [rowValue]);

	//Functions
    const settingAssignee = async () => {
        const assign = users.find((option) => option.name === rowValue);
        if (assign) {
            setAssignee(assign);
            setAssigneeColor(assign.color?assign.color:"#000000");
        }
    };
    const getPrefixClassName = (name) => {
        if (name === undefined) return "assigned";
        // checking if lead is not assigned to anyone make className prefix to assigned else assigne to make arrow white and black
        if (name.color === null || name.color === undefined) return "assigned";
        if (invert(name?.color, true) == "#000000") {
            return "assigned";
        } else {
            return "assigne";
        }
    };
    const onChangeAssigne = (selectedOption) => {
        setAssignee(selectedOption);
        setAssigneeColor(selectedOption.color?selectedOption.color:"#000000");
        updateAssignee(selectedOption.value, row.original._id, row.index);
    };


	//Styles
	const getCustomColor = (color, name) => {
		// console.log("this.state.assigneColor", assigneeColor);
		if (name === undefined) {
			return {
				control: (base, state) => {
					return {
						...base,
						backgroundColor: "#707070",
						borderColor: darkenHexColor("#707070", 0.2),
						minWidth: "100%",
						padding: 0,
						minHeight: "27px",
						height: "27px",
						maxHeight: "27px",
						margin: 0,
						textAlign: "center",
						borderWidth: "2px",
						color: "white",
						"&:hover": {
							borderColor: darkenHexColor("#707070", 0.2),
						},
					};
				},
				input: (provided) => {
					return {
						...provided,
						margin: 0,
						margin: "auto",
						padding: 0,
						height: "100%",
						color: "white",
						fontSize: "1.1rem",
						paddingLeft: "6px",
						paddingRight: "6px",
					};
				},
				singleValue: (provided) => {
					return {
						...provided,
						fontSize: "1.1rem",
						textAlign: "center",
						minWidth: "100%",
						padding: 0,
						margin: 0,
						color: "white",
					};
				},
				placeholder: (provided) => {
					return {
						...provided,
						fontSize: "1.1rem",
						minWidth: "100%",
						color: "white",
					};
				},
				dropdownIndicator: () => ({
					display: "none",
				}),
				indicatorSeparator: () => ({
					display: "none", // Hide the border separator
				}),
				Input: (provided) => {
					return { ...provided, margin: 0, padding: 0 };
				},
				valueContainer: (provided) => {
					return { ...provided, margin: 0, padding: 0 };
				},
			};
		}
		return {
			control: (base, state) => {
				return {
					...base,
					backgroundColor: assigneeColor,
					minWidth: "100%",
					padding: 0,
					minHeight: "27px",
					height: "27px",
					margin: 0,
					minHeight: "fit-content",
					textAlign: "center",
					maxHeight: "27px",
					borderWidth: "2px",
					borderColor: darkenHexColor(assigneeColor, 0.2),
					"&:hover": {
						borderWidth: "2px",
						borderColor: darkenHexColor(assigneeColor, 0.2),
					},
				};
			},
			input: (provided) => {
				let isDarkColor = isColorDark(assigneeColor);
				return {
					...provided,
					margin: 0,
					margin: "auto",
					padding: 0,
					height: "100%",
					color: isDarkColor ? "white" : "#000000",
					fontSize: "1.1rem",
					paddingLeft: "6px",
					paddingRight: "6px",
				};
			},
			valueContainer: (provided) => {
				return { ...provided, margin: 0, padding: 0 };
			},
			singleValue: (provided, state) => {
				let isDarkColor = isColorDark(assigneeColor);
				return {
					...provided,
					fontSize: "1.1rem",
					height: "fit-content",
					minWidth: "100%",
					minWidth: "100%",
					padding: "0px",
					margin: "0px",
					color: isDarkColor ? "white" : "#000000",
					textAlign: "center",
				};
			},
			placeholder: (provided) => {
				return {
					...provided,
					fontSize: "1.1rem",
					minWidth: "100%",
				};
			},
			dropdownIndicator: () => ({
				display: "none",
			}),
			indicatorSeparator: () => ({
				display: "none", // Hide the border separator
			}),
		};
	};

	return (
		<div
			style={{
				height: "100%",
				width: "100%",
				maxWidth: "200px",
				display: "flex",
				flexDirection: "row",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<Select
				classNamePrefix={getPrefixClassName(assignee)}
				value={
					!assignee || assignee.label == "Un-Assign" ? null : assignee
				}
				options={users}
				styles={getCustomColor(row.original.lead_color, assignee)}
				placeholder="Select..."
				key={row.index + "assigned_to"}
				onChange={(selectedOption) => {
					onChangeAssigne(selectedOption);
				}}
				menuPlacement="auto"
			/>
		</div>
	);
}

export default NewAssigneeDropdown;
