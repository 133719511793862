import React from "react";
import archiveIcon from "../assets/images/archive_icon_black.svg";
import unarchiveIcon from "../assets/images/un-archive-icon_black.svg";
import Popup from "reactjs-popup";
class ArchiveButton extends React.PureComponent {
  constructor(props) {
    //
    super(props);
    this.state = {
      isArchived: props.row.original.archived,
    };
  }
  componentDidUpdate(previousProps, previousState) {
    if (previousProps.row.value !== this.props.row.value)
      this.setState({ isArchived: this.props.row.value });
  }
  componentDidMount() {
    //
    //this.setState({isArchived: props.row.original.archived});
  }
  buttonClick = () => {
    this.props.updateLeadArchive(this.props.index, this.props.row);
    this.setState({ isArchived: !this.state.isArchived });
  };
  render() {
    return (
      <Popup
        trigger={
          <button
            style={{
              width: 20,
              height: 20,
              marginLeft: 11,
              // marginTop: 20,
              border: "none",
              backgroundRepeat: "no-repeat",
              backgroundColor: "transparent",
              backgroundSize: "contain",
              backgroundImage: !this.state.isArchived
                ? `url(${archiveIcon})`
                : `url(${unarchiveIcon})`,
            }}
            onClick={() => {
              this.buttonClick();
            }}
          ></button>
        }
        on={"hover"}
        position="top right"
      >
        <div>{this.state.isArchived ? "Un-archive lead" : "Archive lead"}</div>
      </Popup>
    );
  }
}
export default ArchiveButton;
