/* eslint-disable no-redeclare */ /* eslint-disable eqeqeq */

import React, { useState, useEffect } from "react";
import "react-table-hoc-draggable-columns/dist/styles.css";
import "react-table/react-table.css";
import axios from "axios";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Typography } from "@material-ui/core";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useSelector, useDispatch } from "react-redux";
// internal exports
import {
  addEmailTemplates,
  addSmsTemplates,
  addEmailSelectedTemplates,
  addSmsSelectedTemplates,
  addFilteredEmailTemps,
  addSelectedFilter,
  addFilteredSmsTemps,
} from "../Store/actions";
import TemplateCard from "../ReusabelComponents/TemplateCard";
import TempAddDelModal from "../ReusabelComponents/TemplateAddDelModal";
import "../Home/Home.css";
import {
  baseURL,
  CarouselSetting,
  filteredSelectedList,
} from "../Helpers/Constants";
import { selectedTemplateApi } from "../Helpers/ApiCalls";
import FilterDropdown from "../ReusabelComponents/FilterTemplates";
import FilterDropdownSms from "../ReusabelComponents/FilterTemplatesSms";
import TemplateAddUpdate from "../ReusabelComponents/TemplateAddUpdate/TemplateAddUpdate";

// import skeleton from "../../components/ReusabelComponents/TemplateSkeleton"

// ----------------------------------
const TemplateScreen = (props) => {
  // console.log(props.users);
  // console.log(
  //   localStorage.getItem("username"),
  //   localStorage.getItem("email"),
  //   localStorage.getItem("jwtToken")
  // );
  // ------state-----------
  const [userLoader, setUserLoader] = useState(false);
  const [emailList, setEmailList] = useState([]);
  const [smsList, setSmsList] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [newTemplate, setNewTemplate] = useState("");
  const [delTemplate, setDelTemplate] = useState("");
  const [type, setType] = useState("");
  const [emailSelectedTemplates, setEmailSelectedTemplates] = useState([]);
  const [smsSelectedTemplates, setSmsSelectedTemplates] = useState([]);
  const [userEmail, setUserEmail] = useState(localStorage.getItem("email"));
  const [filterList, setFilterList] = useState([]);

  const [emailSelectedOption, setEmailSelectedOption] = useState("");
  const [smsSelectedOption, setSmsSelectedOption] = useState("");
  const [listType, setListType] = useState("");
  const [saveChanges, setSaveChanges] = useState("");

  // ---------------constants----------

  const dispatch = useDispatch();
  const _emailTemplates = useSelector((state) => state._emailTemplates);
  const _smsTemplates = useSelector((state) => state._smsTemplates);
  const _emailSelectedTemplates = useSelector(
    (state) => state._emailSelectedTemplates
  );
  const _smsSelectedTemplates = useSelector(
    (state) => state._smsSelectedTemplates
  );
  const _filterLabel = useSelector((state) => state._filterLabel);
  const _filterSmsLabel = useSelector((state) => state._filterSmsLabel);

  const _selectedOption = useSelector((state) => state._selectedOption);
  const _filteredEmailTemps = useSelector((state) => state._filteredEmailTemps);
  const _filteredSmsTemps = useSelector((state) => state._filteredSmsTemps);
  const [actionType, setActionType] = useState("");
  const [updateTempValue, setUpdateTempValue] = useState("");
  const [attachment, setAttachment] = useState({
    file_name: null,
    attachment_link: null,
  });

  useEffect(() => {
    let allUser = props.users;
    setUserLoader(true);
    axios
      .post(
        baseURL() + "/api/v1/users/all-selected-templates",
        {
          email: localStorage.getItem("email"),
        },
        {
          headers: {
            "x-access-token": localStorage.getItem("jwtToken"),
            "app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
          },
        }
      )
      .then((res) => {
        const compareByUpdatedAt = (a, b) => {
          if (!a.updated_at && !b.updated_at) {
            return 0;
          }
          if (!a.updated_at) {
            return 1;
          }
          if (!b.updated_at) {
            return -1;
          }
          return new Date(b.updated_at) - new Date(a.updated_at);
        };
        const all_email_templates =
          res.data.data.all_email_templates.sort(compareByUpdatedAt);
        const all_sms_templates =
          res.data.data.all_sms_templates.sort(compareByUpdatedAt);
        const selected_email_array = res.data.data.selected_email_array;
        const selected_sms_array = res.data.data.selected_sms_array;
        setEmailList(all_email_templates);
        if (_filteredEmailTemps.length > 0) {
          setEmailList(
            filteredSelectedList(
              allUser,
              all_email_templates,
              selected_email_array,
              emailSelectedOption != "" || !null
                ? emailSelectedOption
                : _filterSmsLabel
            )
          );
        }
        setSmsList(all_sms_templates);
        if (_filteredSmsTemps.length > 0) {
          let filterRes = filteredSelectedList(
            allUser,
            all_sms_templates,
            selected_sms_array,
            smsSelectedOption != "" || !null
              ? smsSelectedOption
              : _filterSmsLabel
          );
          setSmsList(filterRes);
        }
        dispatch(addEmailTemplates(all_email_templates));
        dispatch(addSmsTemplates(all_sms_templates));
        dispatch(addEmailSelectedTemplates(res.data.data.selected_email_array));
        dispatch(addSmsSelectedTemplates(res.data.data.selected_sms_array));
        setUserLoader(false);
      })
      .catch((error) => {
        setUserLoader(false);
      });
    setShowAddModal(false);
  }, [newTemplate, delTemplate]);

  useEffect(() => {
    let allUser = props.users;
    if (listType == "email") {
      dispatch(
        addFilteredEmailTemps(
          filteredSelectedList(
            allUser,
            _emailTemplates,
            _emailSelectedTemplates,
            emailSelectedOption != "" || !null
              ? emailSelectedOption
              : _filterLabel
          )
        )
      );
      setEmailList(
        filteredSelectedList(
          allUser,
          _emailTemplates,
          _emailSelectedTemplates,
          emailSelectedOption != "" || !null
            ? emailSelectedOption
            : _filterLabel
        )
      );
    } else if (listType == "SMS") {
      dispatch(
        addFilteredSmsTemps(
          filteredSelectedList(
            allUser,
            _smsTemplates,
            _smsSelectedTemplates,
            smsSelectedOption != "" || !null
              ? smsSelectedOption
              : _filterSmsLabel
          )
        )
      );
      setSmsList(
        filteredSelectedList(
          allUser,
          _smsTemplates,
          _smsSelectedTemplates,
          smsSelectedOption != "" || !null ? smsSelectedOption : _filterSmsLabel
        )
      );
    } else {
    }
  }, [emailSelectedOption, smsSelectedOption]);

  // -------------------functions-----------------

  const handleEmailFilterOption = (label, type) => {
    setListType(type);
    setEmailSelectedOption(label);
  };
  const handleSmsFilterOption = (label, type) => {
    setListType(type);
    setSmsSelectedOption(label);
  };

  const handelLoader = (state) => {
    if (state == "false") setUserLoader(false);
    else setUserLoader(true);
  };
  const handleCLoseModal = () => {
    setShowAddModal(false);
  };

  const handleSaveChanges = (type) => {
    let selectedTemplates = [];
    if (type == "email") {
      selectedTemplates = [..._emailSelectedTemplates];
    } else {
      selectedTemplates = _smsSelectedTemplates;
    }
    setUserLoader(true);
    selectedTemplateApi(selectedTemplates, type)
      .then((apiRes) => {
        setDelTemplate(apiRes.result);
        setUserLoader(false);
      })
      .catch((error) => {
        setUserLoader(false);
      });
  };

  const handleUpdateNote = (item, type, attachment) => {
    setAttachment(attachment);
    setType(type);
    setActionType("update");
    setUpdateTempValue(item);
    setShowAddModal(true);
  };
  // ------------------------------View----------------------------
  // console.log("rendering..", selectedOption)
  return (
    <React.Fragment>
      <div
        style={{
          marginTop: 0,
          backgroundColor: "white",
          fontFamily: "Arial, Helvetica, sans-serif",
          height: "100%",
        }}
      >
        <div className='template-screen-body'>
          <Typography variant='h3' style={{ paddingLeft: 50 }}>
            Template Dashboard
          </Typography>
          {userLoader ? (
            <div className='template-screen-loader-body'>
              {" "}
              <Loader type='Oval' color='black' height={38} width={38} />
            </div>
          ) : (
            <>
              <div className='temp-card-container' style={{ marginBottom: 40 }}>
                <div className='flex-row-space sub-heading-cont'>
                  <div className='temp-card-cont-1'>
                    <Typography
                      variant='h5'
                      className='heading-medium'
                      style={{
                        fontWeight: "bold",
                        paddingRight: 2,
                      }}
                    >
                      {" "}
                      Email Templates
                    </Typography>
                    <FilterDropdown
                      filterList={props.users}
                      handleFilterOption={handleEmailFilterOption}
                      selectedOption={emailSelectedOption}
                    />
                  </div>
                  <div className='temp-card-filter-cont'>
                    {/* <FilterDropdown
                      filterList={props.users}
                      handleFilterOption={handleFilterOption}
                      selectedOption={selectedOption}
                      type="email"
                    /> */}
                  </div>
                  <div
                    className='temp-card-cont-2'
                    style={{ position: "relative" }}
                  >
                    <div className='temp-card-button-cont'>
                      <button
                        className='temp-card-save-button'
                        onClick={() => {
                          setActionType("new");
                          setShowAddModal(true);
                          setType("email");
                        }}
                      >
                        Add Template
                      </button>
                      <button
                        className='temp-card-save-button'
                        onClick={() => {
                          handleSaveChanges("email");
                        }}
                      >
                        Save Changes
                      </button>
                    </div>
                  </div>
                </div>
                <Slider
                  {...CarouselSetting(emailList)}
                  className='slider'
                  // infinite={emailList.length > 1}
                >
                  {emailList?.length > 0 ? (
                    emailList?.map((item, index) => (
                      <div key={index}>
                        <TemplateCard
                          type={"email"}
                          item={item}
                          index={index}
                          Listlength={emailList?.length}
                          showAlert={props.showAlert}
                          fetchDataChange={setDelTemplate}
                          setUserLoader={setUserLoader}
                          handleUpdateNote={handleUpdateNote}
                        />
                      </div>
                    ))
                  ) : (
                    <>No template Found</>
                  )}
                </Slider>
              </div>

              <div className='temp-card-container'>
                <div className='flex-row-space sub-heading-cont'>
                  <div className='temp-card-cont-1'>
                    <Typography
                      variant='h5'
                      className='heading-medium'
                      style={{
                        fontWeight: "bold",
                        paddingRight: 2,
                      }}
                    >
                      {" "}
                      SMS Templates
                    </Typography>
                    <FilterDropdownSms
                      filterList={props.users}
                      handleFilterOption={handleSmsFilterOption}
                      selectedOption={smsSelectedOption}
                    />
                  </div>
                  <div className='temp-card-filter-cont'>
                    {/* <FilterDropdown
                      filterList={props.users}
                      handleFilterOption={handleFilterOption}
                      selectedOption={selectedOption}
                      type="SMS"
                    /> */}
                  </div>
                  <div
                    className='temp-card-cont-2'
                    style={{ position: "relative" }}
                  >
                    <div className='temp-card-button-cont'>
                      <button
                        className='temp-card-save-button'
                        onClick={() => {
                          setShowAddModal(true);
                          setType("SMS");
                        }}
                      >
                        Add Template
                      </button>
                      <button
                        className='temp-card-save-button'
                        onClick={() => {
                          handleSaveChanges("SMS");
                        }}
                      >
                        Save Changes
                      </button>
                    </div>
                  </div>
                </div>

                <Slider {...CarouselSetting(smsList)} className='slider'>
                  {smsList.length > 0 ? (
                    smsList.map((item, index) => (
                      <div key={index}>
                        <TemplateCard
                          type={"SMS"}
                          item={item}
                          index={index}
                          Listlength={smsList?.length}
                          showAlert={props.showAlert}
                          fetchDataChange={setDelTemplate}
                          setUserLoader={setUserLoader}
                          handleUpdateNote={handleUpdateNote}
                        />
                      </div>
                    ))
                  ) : (
                    <>No template Found</>
                  )}
                </Slider>
              </div>
            </>
          )}
        </div>
        {showAddModal && (
          // <TemplateAddUpdate
          //   title='Email'
          //   handleShowModal={handleCLoseModal}
          //   close={setShowAddModal}
          // />
          <TempAddDelModal
            handleCLoseModal={handleCLoseModal}
            handelLoader={handelLoader}
            showAlert={props.showAlert}
            fetchDataChange={setNewTemplate}
            title={type}
            actionType={actionType}
            updateTempValue={updateTempValue}
            updateAttachment={attachment}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default TemplateScreen;
