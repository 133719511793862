import React, { useEffect, useState } from "react";
import emailIcon from "../assets/images/email-new-button.svg";
import cross from "../assets/images/x.png";

const MultipleEmailSms = ({
	selectedEmails,
	handleOpenFollowUpMode,
	handleSetTitle,
	clearChecks,
	handelSelectedEmails,
	pageIndex,
}) => {
	const [newSelectedEmails, setNewSelectedEmails] = useState([]);
	useEffect(() => {
		if (selectedEmails.length > 30 && pageIndex == 0) {
			console.log("pageIndex", pageIndex);
			const newArray = selectedEmails.filter((item) => {
				return item.index > selectedEmails.length - 31;
			});
			console.log("newArray", newArray);
			setNewSelectedEmails(newArray);
			handelSelectedEmails(newArray);
		} else if (selectedEmails.length > 30 && pageIndex == 1) {
			console.log("pageIndex", pageIndex);
			const newArray = selectedEmails.filter((item) => {
				return item.index < selectedEmails.length - 30;
			});
			console.log("newArray", newArray);
			setNewSelectedEmails(newArray);
			handelSelectedEmails(newArray);
		} else {
			setNewSelectedEmails(selectedEmails);
		}
	}, [selectedEmails]);
	return (
		<div
			style={{
				display: "flex",
				height: "6rem",
				width: "55rem",
				backgroundColor: "white",
				alignSelf: "center",
				justifyContent: "center",
				alignItems: "center",
				padding: 20,
				borderColor: "#586173",
				borderStyle: "solid",
				borderWidth: 2,
				borderRadius: 5,
			}}
		>
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					flexDirection: "row",
				}}
			>
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						flexDirection: "row",
					}}
				>
					<span
						style={{
							backgroundColor: "#4256d0",
							height: "2rem",
							width: "2rem",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							padding: 2,
							borderRadius: 5,
							marginRight: 5,
							color: "white",
						}}
					>
						{newSelectedEmails.length}
					</span>
					<span
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							marginRight: 5,
							fontSize: "13px",
						}}
					>
						Selected
					</span>
				</div>
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						flexDirection: "row",
						marginLeft: 5,
						// width: "55rem",
					}}
				>
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							flexDirection: "row",
							backgroundColor: "#F8F8F8",
							paddingTop: 5,
							paddingBottom: 5,
							borderColor: "#D4D4D4",
							borderStyle: "solid",
							borderWidth: 1,
							borderRadius: 5,
							marginRight: 10,
						}}
					>
						<div
							className="tabElement"
							style={{
								justifyContent: "center",
								alignItems: "center",
							}}
							onClick={() => {
								handleOpenFollowUpMode();
								handleSetTitle("email");
							}}
						>
							<button
								style={{
									width: 30,
									height: 20,
									backgroundImage: `url(${emailIcon})`,
								}}
							></button>
							<span
								style={{
									marginLeft: -12,
									fontWeight: "700",
									fontFamily: "SF Pro Display Medium",
								}}
							>
								Send Email{" "}
							</span>
						</div>
					</div>
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							flexDirection: "row",
							backgroundColor: "#F8F8F8",
							paddingTop: 5,
							paddingBottom: 5,
							borderColor: "#D4D4D4",
							borderStyle: "solid",
							borderWidth: 1,
							borderRadius: 5,
						}}
					>
						<div
							className="tabElement"
							style={{
								justifyContent: "center",
							}}
							onClick={() => {
								handleOpenFollowUpMode();
								handleSetTitle("SMS");
							}}
						>
							<button
								style={{
									width: 30,
									height: 20,
									backgroundImage: `url(${emailIcon})`,
								}}
							></button>
							<span
								style={{
									marginLeft: -12,
									fontWeight: "700",
									fontFamily: "SF Pro Display Medium",
								}}
							>
								Send Sms
							</span>
						</div>
					</div>
					<div
						style={{
							marginLeft: 30,
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<img
							onClick={() => {
								clearChecks();
							}}
							style={{
								width: 30,
								height: 30,
							}}
							src={cross}
							alt=""
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MultipleEmailSms;
