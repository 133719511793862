import React, { Fragment, useEffect } from "react";
import LocationListItem from "./LocationListItem";
// import '../../scss/settings.scss'
export default (props) => {
  const renderPackingDetailsHeader = () => {
    return (
      <div className='row ' style={{ fontSize: "1.5rem" }}>
        <div className='col-lg-4 location-list-item-header margin-right-10'>
          <label>Source</label>
        </div>
        <div className='col-lg-4 location-list-item-header margin-right-10'>
          <label>Name</label>
        </div>
      </div>
    );
  };
  return (
    <div style={{ marginBottom: 30 }}>
      {props.locations.length > 0 && renderPackingDetailsHeader()}
      {props.locations &&
        props.locations.map((item, index) => {
          return (
            <LocationListItem
              key={item.name + index}
              index={index}
              item={item}
              editLocation={props.editLocation}
              saveLocation={props.saveLocation}
              onDelete={props.onDelete}
              onCancel={props.onCancel}
            />
          );
        })}
    </div>
  );
};
