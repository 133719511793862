import React, { useRef, useState, useEffect } from "react";

import "./categoryDropdown.css";

export const CategoryDropDown = ({
	options,
	active = true,
	top,
	onSelect,
	optionSelected,
	backColor,
}) => {
	const dropdownRef = useRef(null);

	const [isOpen, setIsOpen] = useState(false);
	const [isDark, setIsDark] = useState(false);
	const [selectedOption, setSelectedOption] = useState(null);
	const [inputText, setInputText] = useState("");
	const [Option, setOption] = useState([]);

	useEffect(() => {
		if (options && options.length !== 0) setOption(options);
	}, [options]);

	useEffect(() => {
		onSelect(optionSelected);
		setSelectedOption(optionSelected);
		setIsOpen(false);
		setInputText(optionSelected?.label);
	}, [optionSelected]);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				dropdownRef.current &&
				!dropdownRef.current.contains(event.target)
			) {
				setIsOpen(false);
			}
		};

		document.addEventListener("click", handleClickOutside);
		return () => {
			document.removeEventListener("click", handleClickOutside);
		};
	}, []);

	const handleOptionClick = (option) => {
		onSelect(option);
		setSelectedOption(option);
		setIsOpen(false);
	};

	const isColorDark = async (color) => {
		let r = parseInt(color.slice(1, 3), 16);
		let g = parseInt(color.slice(3, 5), 16);
		let b = parseInt(color.slice(5, 7), 16);
		const brightness = Math.sqrt(
			0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b)
		);
		setIsDark(brightness < 128);
	};

	useEffect(() => {
		if (backColor && backColor !== "") isColorDark(backColor);
	}, [backColor]);

	return (
		<div ref={dropdownRef} className="sequences-dropdown2">
			<div
				className={`SequencesDropdownMainContainer2 ${
					active
						? "SequencesDropdownMainContainerActive2"
						: "SequencesDropdownMainContainerNotActive2"
				}`}
				onClick={() => {
					if (active) {
						setIsOpen(!isOpen);
					}
				}}
			>
				<div
					className="itemsContainer2"
					style={{
						minHeight: "40px",
						backgroundColor: backColor ? backColor : "#ccc",
					}}
				>
					<p
						style={{
							color:
								backColor &&
								backColor !== "" &&
								backColor !== "#ccc"
									? "white"
									: "black",
						}}
					>
						{active
							? selectedOption
								? selectedOption.name
								: "None"
							: "None"}
					</p>
				</div>
				<div
					className="arrowsContainer2"
					style={{
						minHeight: "40px",
						maxWidth: "50px",
						backgroundColor: "#404040",
						borderLeftColor: "white",
					}}
				>
					<img
						style={{ height: "13px" }}
						src={require("../../../assets/images/dropdown-arrow-white.png")}
					/>
				</div>
			</div>
			{isOpen && (
				<div
					className={
						top
							? "sequence-dropdown-menu4"
							: "sequence-dropdown-menu3"
					}
				>
					{Option.map(
						(option, index) =>
							option.name &&
							option.name.trim() !== "" && (
								<div
									key={index}
									className="sequence-dropdown-item2"
									onClick={() => {
										handleOptionClick(option);
										setInputText(option.name);
									}}
								>
									{option.name}
								</div>
							)
					)}
				</div>
			)}
		</div>
	);
};
