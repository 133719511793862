import React, { useState } from "react";
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import { isInclusivelyBeforeDay } from 'react-dates';
import moment from 'moment';

function DatePicker(props) {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState('startDate');
  const handleDatesChange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
    props.datesChanged(startDate, endDate);
  };
  return (
    // <div className="App">
      <DateRangePicker
        startDate={startDate}
        startDateId="tata-start-date"
        endDate={endDate}
        endDateId="tata-end-date"
        // isOutsideRange={() => false} 
        isOutsideRange={day => !isInclusivelyBeforeDay(day, moment())}
        onDatesChange={handleDatesChange}
        focusedInput={focusedInput}
        onFocusChange={focusedInput => {
            if(focusedInput != "startDate" && focusedInput != "endDate" ){
               props.hideComponent();
            }
            setFocusedInput(focusedInput)
        }}
      />
    // </div>
  );
}

export default DatePicker;
