import React from "react";
import "./headerTooltip.css"; // Import your CSS file

const HeaderTooltip = ({ text, children }) => {
	return (
		<div className='tooltip-containerr'>
			{children} {/* The element that triggers the tooltip */}
			<div className='tooltip-boxx'>
				<div className='tooltip-arroww'></div>
				<div className='tooltip-textt'>{text}</div>
			</div>
		</div>
	);
};

export default HeaderTooltip;
