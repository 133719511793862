import React, { useState, useEffect } from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
	startOfWeek,
	endOfWeek,
	startOfMonth,
	endOfMonth,
	startOfQuarter,
	endOfQuarter,
	subWeeks,
	subMonths,
	subQuarters,
	endOfDay,
	startOfDay,
} from "date-fns";
import { Typography, Box, Grid } from "@material-ui/core";

const RangeEnum = {
	today: "Today",
	lastWeek: "Last Week",
	lastMonth: "Last Month",
	lastQuarter: "Last Quarter",
	custom: "Custom Date",
};

const DateRangePickerComponent = ({ onDatesChange }) => {
	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());
	const [selectedRange, setSelectedRange] = useState("");
	const [predefinedRanges, setPredefinedRanges] = useState({});
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);

	useEffect(() => {
		const today = startOfDay(new Date());
		const todayEnd = endOfDay(today); // Use endOfDay from date-fns
		const lastWeekStart = startOfWeek(subWeeks(today, 1));
		const lastWeekEnd = endOfWeek(subWeeks(today, 1));
		const lastMonthStart = startOfMonth(subMonths(today, 1));
		const lastMonthEnd = endOfMonth(subMonths(today, 1));
		const lastQuarterStart = startOfQuarter(subQuarters(today, 1));
		const lastQuarterEnd = endOfQuarter(subQuarters(today, 1));

		const ranges = {
			today: { start: today, end: todayEnd },
			lastWeek: { start: lastWeekStart, end: lastWeekEnd },
			lastMonth: { start: lastMonthStart, end: lastMonthEnd },
			lastQuarter: { start: lastQuarterStart, end: lastQuarterEnd },
		};
		setPredefinedRanges(ranges);
	}, []);

	const toUTCDate = (date) => {
		const utcDate = new Date(
			Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
		);
		console.log("toUTCDate---", utcDate);
		return utcDate;
	};

	const handleStartDateChange = (date) => {
		setStartDate(date);
		onDatesChange({
			startDate: startOfDay(toUTCDate(date)),
			endDate: endOfDay(toUTCDate(endDate)),
		});
	};

	const handleEndDateChange = (date) => {
		setEndDate(date);
		onDatesChange({
			startDate: startOfDay(toUTCDate(startDate)),
			endDate: endOfDay(toUTCDate(date)),
		});
	};

	const handleRangeChange = (selectedOption) => {
		console.log("selectedOption", selectedOption);
		const range = selectedOption.value;
		setSelectedRange(range);
		const { start, end } = predefinedRanges[range] || {};

		if (start && end) {
			setStartDate(start);
			setEndDate(end);
			onDatesChange({
				startDate: toUTCDate(start),
				endDate: toUTCDate(end),
			});
		}
		setIsDropdownOpen(false);
	};
	const handleMenuOpen = () => {
		setIsDropdownOpen(true);
	};

	const handleMenuClose = () => {
		setIsDropdownOpen(false);
	};

	const formatRange = (start, end) => {
		const formatDate = (date) =>
			`${date?.getDate()} ${date?.toLocaleString("default", {
				month: "short",
			})} ${date?.getFullYear().toString().slice(-2)}`;
		return `${formatDate(start)} - ${formatDate(end)}`;
	};

	const options = [
		{
			value: "today",
			label: (
				<div
					style={{ display: "flex", justifyContent: "space-between" }}
				>
					<Typography style={{ fontSize: 12 }}>Today</Typography>
					<Typography style={{ fontSize: 12 }}>
						{formatRange(
							predefinedRanges.today?.start,
							predefinedRanges.today?.end
						)}
					</Typography>
				</div>
			),
		},
		{
			value: "lastWeek",
			label: (
				<div
					style={{ display: "flex", justifyContent: "space-between" }}
				>
					<Typography style={{ fontSize: 12 }}>Last Week</Typography>
					<Typography style={{ fontSize: 12 }}>
						{formatRange(
							predefinedRanges.lastWeek?.start,
							predefinedRanges.lastWeek?.end
						)}
					</Typography>
				</div>
			),
		},
		{
			value: "lastMonth",
			label: (
				<div
					style={{ display: "flex", justifyContent: "space-between" }}
				>
					<Typography style={{ fontSize: 12 }}>Last Month</Typography>
					<Typography style={{ fontSize: 12 }}>
						{formatRange(
							predefinedRanges.lastMonth?.start,
							predefinedRanges.lastMonth?.end
						)}
					</Typography>
				</div>
			),
		},
		{
			value: "lastQuarter",
			label: (
				<div
					style={{ display: "flex", justifyContent: "space-between" }}
				>
					<Typography style={{ fontSize: 12 }}>
						Last Quarter
					</Typography>
					<Typography style={{ fontSize: 12 }}>
						{formatRange(
							predefinedRanges.lastQuarter?.start,
							predefinedRanges.lastQuarter?.end
						)}
					</Typography>
				</div>
			),
		},
		{
			value: "custom",
			label: (
				<Typography style={{ fontSize: 12 }}>Custom date</Typography>
			),
		},
	];

	return (
		<>
			{/* // 	marginTop: 5,
		// 	height: isDropdownOpen ? "10rem" : "auto",
		// 	// maxHeight: "10rem", // Ensure the height does not exceed this value
		// 	overflowY: isDropdownOpen ? "scroll" : "auto", // Apply scroll only when dropdown is open
		// 	backgroundColor: "green",
		// }} */}

			<Select
				className="basic-single"
				classNamePrefix="select"
				options={options}
				value={options.find((option) => option.value === selectedRange)}
				onChange={handleRangeChange}
				onMenuOpen={handleMenuOpen}
				onMenuClose={handleMenuClose}
				placeholder="Select Date Range"
				components={{
					SingleValue: ({ data }) => (
						<p style={{ fontSize: 13 }}>{RangeEnum[data.value]}</p>
					),
				}}
				styles={{
					control: (base) => ({
						...base,
						height: "4vh",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}),
					menu: (base) => ({
						...base,
						backgroundColor: "white",
						color: "black",
					}),
					menuPortal: (base) => ({ ...base, zIndex: 9999 }),
					placeholder: (base) => ({
						...base,
						fontSize: "1.3rem", // Adjust the font size as needed
					}),
				}}
			/>
			{selectedRange === "custom" && (
				<Box mt={2}>
					<Grid container spacing={2} alignItems="center">
						<Grid item xs={6}>
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<Typography variant="body1">From:</Typography>
								<DatePicker
									selected={startDate}
									onChange={handleStartDateChange}
									selectsStart
									startDate={startDate}
									endDate={endDate}
									maxDate={new Date()} // Disables future dates
									dateFormat="MM/dd/yyyy"
								/>
							</div>
						</Grid>
						<Grid item xs={6}>
							{/* <div
								style={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "center",
									alignItems: "center",
								}}
							> */}
							<span>To:</span>
							<DatePicker
								selected={endDate}
								onChange={handleEndDateChange}
								selectsEnd
								startDate={startDate}
								endDate={endDate}
								minDate={startDate}
								dateFormat="MM/dd/yyyy"
							/>
							{/* </div> */}
						</Grid>
					</Grid>
				</Box>
			)}
		</>
	);
};

export default DateRangePickerComponent;
