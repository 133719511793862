import React, { useRef, useState, useEffect } from "react";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import moment from "moment"
import ".././Home/Home.css";
import Loader from "react-loader-spinner";
import { Modal } from "react-bootstrap";

import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { IconButton } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';

// import IconButton from '@mui/material/IconButton';
// import DeleteIcon from '@mui/icons-material/Delete';
const useStyles = makeStyles((theme) => ({
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
    width: "100%"
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
// Import


function UserModal(props) {
  const [loader, setLoader] = useState(false)
  const [userList, setUserList] = useState([])
  const [selectedOption, setSelectedOption] = useState({
    label: "",
    value: ""
  });
  const classes = useStyles();

  useEffect(() => {
    setUserList(props.users)
  }, [])
  const handleChange = (event) => {
    console.log("event", event)
    const selectedLabel = userList.find((option) => option.value === event.target.value).label;
    props.handleSaveName(selectedLabel)

    setSelectedOption(selectedLabel);
    setSelectedOption({
      label: selectedLabel,
      value: event.target.value
    })
  }
  return (
    <div style={{ fontFamily: "Arial, Helvetica, sans-serif" }}>
      <Modal show={props.showUserModal}
        style={{ borderRadius: 10 }}
        size="lg"
        onHide={() => {
          props.handleCloseUserModal()          // props.handleNo()
        }}
      >
        <div style={{ padding: 40 }}
        // ref={modalRef}
        >
          <p className="followup-modal-heading"> Share Lead Details</p>
          <p className="followup-note-lead-name-container">
            You are about to share the lead<p style={{ fontWeight: "600", paddingLeft: 3, paddingRight: 3 }}>{props.leadName}</p>  details.
          </p>
          <div className="followup-modal-body">
            <p className="followup-sub-heading">Users</p>
            <div className="flex-row-center">
              <FormControl
                style={{ marginRight: loader ? 10 : 0 }}
                disabled={loader}
                variant="outlined" className={classes.formControl}>
                <Select
                  style={{ fontSize: "1.25em" }}
                  id="demo-simple-select"
                  // open={selectOpen}
                  value={selectedOption.value}
                  onChange={handleChange}
                // onOpen={handleMenuOpen}
                // onClose={handleMenuClose}
                // MenuProps={{
                //   onClose: handleMenuClose,
                //   onExited: handleMenuClose
                // }}
                >
                  <MenuItem disabled value="" style={{ fontSize: "1.25em" }}
                  >
                    <em>Select an user</em>
                  </MenuItem>
                  {userList?.map((option) => {
                    return <MenuItem style={{ display: "flex", justifyContent: "space-between", width: "100%", fontSize: "1.25em" }}
                      title={option.label} value={option.value}>{option.label}
                    </MenuItem>
                  })}

                </Select>
              </FormControl>
              {loader ? <Loader type="Oval" color="gray" height={18} width={18} /> : null}
            </div>

          </div>
          <div className="followup-modal-footer">
            <div style={{ display: 'flex', justifyContent: "flex-end", alignItems: "flex-end", width: "100%" }}>
              <button
                disabled={selectedOption.value == ""}
                className="note-edit-modal-save-button"
                onClick={() => {
                  props.handleShareDetail(selectedOption.value)
                  props.handleCloseUserModal()

                }}
              >
                Share
              </button>
              <button
                className="temp-modal-save-button"
                onClick={() => {
                  props.handleCloseUserModal()          // props.handleNo()
                }}>
                Close
              </button>
            </div>

          </div>

        </div>
      </Modal>
    </div>
  );
}
export default UserModal;

