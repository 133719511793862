import React, { useRef } from "react";
import ".././Home/Home.css";
import { Modal } from "react-bootstrap";

function EditModalMultiLine(props) {
  const textareaRef = useRef();
  return (
    <div style={{ fontFamily: "Arial, Helvetica, sans-serif" }}>
      <Modal show={props.show}>
        <Modal.Header>
          <Modal.Title style={{ fontFamily: "Arial, Helvetica, sans-serif" }}>
            Edit your text
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            <textarea
              className="form-control"
              ref={textareaRef}
              style={{
                fontFamily: "Arial, Helvetica, sans-serif",
                fontSize: 12,
                marginBottom: 5,
              }}
              rows={props.defaultValue.length}
              defaultValue={props.defaultValue.join("\r\n")}
              onBlur={() => textareaRef.current.setSelectionRange(0, 0)}
              onChange={(e) => {
                // this.text = e.target.value;
                //
              }}
            ></textarea>
          }
        </Modal.Body>
        <Modal.Footer>
          <button
            className="edit-modal-save-button"
            onClick={props.onCancelEditModal}
          >
            Close
          </button>
          <button
            className="edit-modal-save-button"
            onClick={() => {
              props.submitValue(textareaRef.current.value);
            }}
          >
            Save Changes
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
export default EditModalMultiLine;
