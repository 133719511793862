/* eslint-disable eqeqeq */
import React from "react";
import Select from "react-select";
import invert from "invert-color";
class AssigneDropdown extends React.PureComponent {
  options = [];
  constructor(props) {
    super(props);
    this.state = {
      leadColor: "#ffffff",
      assigne: this.props.users.find(
        (option) => option.name === this.props.row.value
      ),
    };
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.row.value !== this.props.row.value)
      this.setState({
        assigne: this.props.users.find(
          (option) => option.name === this.props.row.value
        ),
      });
  }
  getCustomColor = (color, name) => {
    if (name === undefined) {
      return {
        control: (base, state) => {
          return {
            ...base,
            backgroundColor: "#707070",
            borderColor: this.darkenHexColor("#707070", 0.2),
            minWidth: "100%",
            padding: 0,
            // paddingTop: "3px",
            // paddingBottom: "3px",
            minHeight: "27px",
            height: "27px",
            maxHeight: "27px",
            margin: 0,
            // minHeight: "fit-content",
            textAlign: "center",
            borderWidth: "2px",
            color: "white",
            "&:hover": {
              borderColor: this.darkenHexColor("#707070", 0.2),
            },
          };
        },
        input: (provided) => {
          return {
            ...provided,
            margin: 0,
            margin: "auto",
            padding: 0,
            height: "100%",
            color: "white",
            fontSize: "1.1rem",
            paddingLeft: "6px",
            paddingRight: "6px",
          };
        },
        singleValue: (provided) => {
          return {
            ...provided,
            fontSize: "1.1rem",
            textAlign: "center",
            minWidth: "100%",
            padding: 0,
            margin: 0,
            color: "white",
          };
        },
        placeholder: (provided) => {
          return {
            ...provided,
            fontSize: "1.1rem",
            minWidth: "100%",
            color: "white",
          };
        },
        dropdownIndicator: () => ({
          display: "none",
        }),
        indicatorSeparator: () => ({
          display: "none", // Hide the border separator
        }),
        Input: (provided) => {
          return { ...provided, margin: 0, padding: 0 };
        },
        valueContainer: (provided) => {
          return { ...provided, margin: 0, padding: 0 };
        },
      };
    }
    let color1 = this.props.users.find(
      (item) => item.value === name.value
    ).color;
    return {
      control: (base, state) => {
        let isDarkColor = this.isColorDark(color1);
        return {
          ...base,
          backgroundColor: color1,
          minWidth: "100%",
          padding: 0,
          minHeight: "27px",
          height: "27px",
          margin: 0,
          minHeight: "fit-content",
          textAlign: "center",
          maxHeight: "27px",
          borderWidth: "2px",
          borderColor: this.darkenHexColor(color1, 0.2),
          "&:hover": {
            borderWidth: "2px",
            borderColor: this.darkenHexColor(color1, 0.2),
          },
        };
      },
      input: (provided) => {
        let isDarkColor = this.isColorDark(color1);
        return {
          ...provided,
          margin: 0,
          margin: "auto",
          padding: 0,
          height: "100%",
          color: isDarkColor ? "white" : "#000000",
          fontSize: "1.1rem",
          paddingLeft: "6px",
          paddingRight: "6px",
        };
      },
      valueContainer: (provided) => {
        return { ...provided, margin: 0, padding: 0 };
      },
      singleValue: (provided, state) => {
        let isDarkColor = this.isColorDark(color1);
        return {
          ...provided,
          fontSize: "1.1rem",
          height: "fit-content",
          minWidth: "100%",
          minWidth: "100%",
          padding: "0px",
          margin: "0px",
          color: isDarkColor ? "white" : "#000000",
          textAlign: "center",
        };
      },
      placeholder: (provided) => {
        return {
          ...provided,
          fontSize: "1.1rem",
          minWidth: "100%",
        };
      },
      dropdownIndicator: () => ({
        display: "none",
      }),
      indicatorSeparator: () => ({
        display: "none", // Hide the border separator
      }),
    };
  };
  darkenHexColor = (hex, percent) => {
    hex = hex.replace(/^#/, "");
    // Parse hex into RGB
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    // Darken the color
    const darkenedR = Math.round(r * (1 - percent));
    const darkenedG = Math.round(g * (1 - percent));
    const darkenedB = Math.round(b * (1 - percent));
    // Convert back to hex
    const darkenedHex = (
      (darkenedR << 16) +
      (darkenedG << 8) +
      darkenedB
    ).toString(16);
    return "#" + ("000000" + darkenedHex).slice(-6);
  };
  isColorDark = (color) => {
    let r = parseInt(color.slice(1, 3), 16);
    let g = parseInt(color.slice(3, 5), 16);
    let b = parseInt(color.slice(5, 7), 16);
    const brightness = Math.sqrt(
      0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b)
    );
    return parseInt(brightness) < 200;
  };
  getPrefixClassName = (name) => {
    if (name === undefined) return "assigned";
    // checking if lead is not assigned to anyone make className prefix to assigned else assigne to make arrow white and black
    if (invert(name.color, true) == "#000000") {
      return "assigned";
    } else {
      return "assigne";
    }
  };
  onChangeAssigne = (selectedOption) => {
    this.setState({ assigne: selectedOption });
    this.props.updateAssignee(
      selectedOption.value,
      this.props.row.original._id,
      this.props.row.index
    );
  };
  onUpdateAssignee = (option) => {
    this.setState({ assigne: option });
  };

  render() {
    let assignedValue = this.props.users.find(
      (option) => option.name === this.props.row.value
    );
    if (assignedValue == null || assignedValue.label == "Un-Assign") {
      assignedValue = null;
    }
    return (
      <div
        style={{
          height: "100%",
          width: "100%",
          maxWidth: "200px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Select
          classNamePrefix={this.getPrefixClassName(this.state.assigne)}
          value={assignedValue}
          options={this.props.users}
          styles={this.getCustomColor(
            this.props.row.original.lead_color,
            this.state.assigne
          )}
          placeholder='Select...'
          key={this.props.row.index + "assigned_to"}
          onChange={(selectedOption) => {
            this.onChangeAssigne(selectedOption);
          }}
          menuPlacement='auto'
        />
      </div>
    );
  }
}
export default AssigneDropdown;
