import React, { useRef } from "react";
import { Row, Col } from "react-bootstrap";
import { CSVLink } from "react-csv";
import csvExportIcon from "../assets/images/Frame-2.png";
import csvDownloadIcon from "../assets/images/csv_download.png";
import crossIconX from "../assets/images/x-2.png";

const SearchHeader = ({
	clearSearchHeader
}) => {


	return (
		<div
			style={{
				minHeight: 60,
				display: "flex",
				flexDirection: "row",
				justifyContent:"space-between",
				padding:"0px 20px"
			}}
		>
			<div style={{ width: "50wv",height:10 }}>
			<p
						style={{
							fontSize: "19px",
							color: "#000000",
							fontWeight: 500,
						}}
					>
					</p>
				{/* <div
					style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						justifycontent: "flex-start", // Align items at the start of the container
						width: "auto",
						padding: "10px 5rem",
					}}
				>
					<p
						style={{
							fontSize: "19px",
							color: "#000000",
							fontWeight: 500,
						}}
					>
						Filtered Data
					</p>
					<span
						style={{
							fontFamily: "SF Pro Display Semibold",
							fontSize: "17px",
							color: "#6C6C6C",
							margin: "0px 15px",
							padding: "5px 0px",

							// padding: "0px 3px",
						}}
					>
					Result Found
					</span>

					<span
						// onClick={exportLeadCSV}
						style={{
							border: "1px solid #4256D0",
							display: "flex",
							padding: "5px 15px",
							borderRadius: 2,
							cursor: "pointer",
						}}
					>
						<img
							style={{ height: 18, width: 18 }}
							src={csvExportIcon}
							alt="Export CSV"
						/>
						<p
							style={{
								fontSize: "12px",
								color: "#4256D0",
								margin: "0 5px",
							}}
						>
							Export CSV
						</p>
						<img
							style={{ height: 15, width: 15 }}
							src={csvDownloadIcon}
							alt="Download CSV"
						/>
					</span>
				
				</div> */}
			</div>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					justifyContent:"flex-end",
					width: "50wv",
				}}
			>
<span
					onClick={clearSearchHeader}
					style={{
						width: "11rem",
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						justifyContent: "space-between",
						borderBottom: "1px solid #828282",
						cursor: "pointer",
					}}
				>
					<img
						style={{ height: 15, width: 15 }}
						src={crossIconX}
						alt="Clear Search"
					/>
					<span
						style={{
							fontSize: "14px",
							fontWeight: 500,
							color: "#6C6C6C",
						}}
					>
						Clear Search
					</span>
				</span>

			</div>
	
				
		</div>
	);
};

export default SearchHeader;
