import React, { useState, useEffect, useRef } from "react";

import "./sequencesDropdown.css";

function SequencesDropdown({
  title,
  options,
  onSelect,
  optionSelected,
  active = false,
  top = false,
}) {
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [inputText, setInputText] = useState("");
  const [first, setFirst] = useState(true);
  const [Option, setOption] = useState([]);

  useEffect(() => {
    if (isOpen) inputRef.current.focus();
  }, [isOpen]);

  useEffect(() => {
    if (options && options.length !== 0) setOption(options);
  }, [options]);

  useEffect(() => {
    onSelect(optionSelected);
    setSelectedOption(optionSelected);
    setIsOpen(false);
    setInputText(optionSelected.label);
  }, [optionSelected]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleOptionClick = (option) => {
    onSelect(option);
    setSelectedOption(option);
    setIsOpen(false);
  };

  const handleInputChange = async (e) => {
    if (first) setFirst(false);
    setInputText(e.target.value);
    const input = e.target.value;
    if (input && input !== "" && options && options.length !== 0) {
      const filteredData = await options.filter((item) => {
        if (
          item.label &&
          item.label.toLowerCase().trim().includes(input.toLowerCase())
        )
          return item;
      });
      setOption(filteredData);
    } else if (!input || input === "") {
      setOption(options);
    }
  };

  return (
    <div ref={dropdownRef} className='sequences-dropdown'>
      <div
        className={`SequencesDropdownMainContainer ${
          active
            ? "SequencesDropdownMainContainerActive"
            : "SequencesDropdownMainContainerNotActive"
        }`}
        onClick={() => {
          if (active) {
            setIsOpen(!isOpen);
          }
        }}
      >
        <div className='itemsContainer'>
          {active ? (
            <input
              className='inputTextField'
              ref={inputRef}
              value={inputText}
              onChange={handleInputChange}
              placeholder={first ? "Select templates..." : "Search by Title"}
            />
          ) : (
            <p>
              {active
                ? selectedOption
                  ? selectedOption.label
                  : ""
                : title
                ? title
                : ""}
            </p>
          )}
        </div>
        <div className='arrowsContainer'>
          <img src={require("../../../assets/images/down_arrow.png")} />
        </div>
      </div>
      {isOpen && (
        <div
          className={top ? "sequence-dropdown-menu2" : "sequence-dropdown-menu"}
        >
          {Option.map(
            (option, index) =>
              option.label &&
              option.label.trim() !== "" && (
                <div
                  key={index}
                  className='sequence-dropdown-item'
                  onClick={() => {
                    handleOptionClick(option);
                    setInputText(option.label);
                  }}
                >
                  {option.label}
                </div>
              )
          )}
        </div>
      )}
    </div>
  );
}

export default SequencesDropdown;
