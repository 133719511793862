import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";

import { SequenceConfirmation } from "../../Confirmation/SequenceConfirmation";
import { formatDate2 } from "../../../../Helpers/Constants";
import "./pauseSequencDetailsModal.css";

export const PauseSequencDetailsModal = ({
  show,
  onHide,
  title,
  yesFunc,
  dltFunc,
  sequenceId,
  showAlert,
  totalTemplates,
  startDate,
  leadId,
  email,
  nextTemplateNumber,
  showPauseButton = true,
  selectedSequence,
  firstTemplateDate,
  secondTemplateDate,
  thirdTemplateDate,
}) => {
  const [handleShowModal, setHandleShowModal] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [loader, setloader] = useState(false);
  const [pauseButtonLoader, setPauseButtonLoader] = useState(false);
  const [deleteButtonLoader, setDeleteButtonLoader] = useState(false);
  // const [selectedSequence, setSelectedSequence] = useState(null);
  const [date, setDate] = useState("");
  const [date1, setDate1] = useState("");
  const [date2, setDate2] = useState("");
  const [date3, setDate3] = useState("");

  // useEffect(() => {
  //   if (sequenceId && sequenceId !== "") {
  //     const fetch = async () => {
  //       const { data, status } = await fetchSpecificSequence(sequenceId);
  //       if (status === 200) setSelectedSequence(data);
  //       else showAlert("Fetching failed.");
  //       setloader(false);
  //     };
  // fetch();
  //   }
  // }, [sequenceId]);

  useEffect(() => {
    if (selectedSequence && startDate && startDate !== "") {
      const runFunc = async () => {
        if (startDate !== null && startDate !== "") {
          let startingdate = `${startDate[3]}${startDate[4]}/${startDate[0]}${startDate[1]}/${startDate[6]}${startDate[7]}${startDate[8]}${startDate[9]}`;
          const originalDate = new Date(startingdate);
          const formattedDate = originalDate.toLocaleDateString("en-GB");
          const dateStart = await formatDate2(originalDate);
          setDate(dateStart.replaceAll("-", "/"));
          const firstTemplateDate2 = new Date(firstTemplateDate);
          const date1 = await formatDate2(firstTemplateDate2);
          setDate1(date1.replaceAll("-", "/"));
          if (totalTemplates > 1) {
            // const templateTwo = originalDate.setDate(
            //   originalDate.getDate() + selectedSequence.templateTwoAfter
            // );
            // const date2 = await formateDate(templateTwo);
            const secondTemplateDate2 = new Date(secondTemplateDate);
            const date2 = await formatDate2(secondTemplateDate2);
            setDate2(date2.replaceAll("-", "/"));
            if (totalTemplates > 2) {
              // const templateThree = originalDate.setDate(
              //   originalDate.getDate() + selectedSequence.templateThreeAfter
              // );
              // const date3 = await formateDate(templateThree);
              const thirdTemplateDate2 = new Date(thirdTemplateDate);
              const date3 = await formatDate2(thirdTemplateDate2);
              setDate3(date3.replaceAll("-", "/"));
            }
          }
        }
      };
      runFunc();
    }
  }, [startDate, totalTemplates, selectedSequence]);

  const formateDate = async (originalDateString) => {
    const originalDate = new Date(originalDateString);
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const dayOfWeek = daysOfWeek[originalDate.getDay()];
    const dayOfMonth = originalDate.getDate();
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const monthName = months[originalDate.getMonth()];
    const yearWithoutCentury = originalDate.getFullYear() % 100;
    return `${dayOfWeek} ${monthName} ${dayOfMonth}, ${yearWithoutCentury}`;
  };

  const handleCloseModal = (e) => {
    e.preventDefault();
    setHandleShowModal(false);
    onHide();
  };

  const handleConfirm = async () => {
    setPauseButtonLoader(true);
    await yesFunc();
    setPauseButtonLoader(false);
    onHide();
  };

  const handleDelete = async () => {
    setDeleteButtonLoader(true);
    await dltFunc();
    setDeleteButtonLoader(false);
    onHide();
  };
  return (
    <>
      handleShowModal && (
      <div className='modall'>
        <div
          className={`ModalBody ${
            totalTemplates >= 2
              ? totalTemplates >= 3
                ? "ModalBody3"
                : "ModalBody2"
              : ""
          }`}
        >
          <div className='headingEmailModal'>
            <h2>{title ? title : ""}</h2>
            <img
              src={require("../../../../assets/images/close-cross.png")}
              onClick={handleCloseModal}
            />
          </div>
          {loader ? (
            <div className='spinner-container-mhr' style={{ height: "80%" }}>
              <Loader
                className='modalLoader'
                type='Oval'
                color='black'
                height={38}
                width={38}
              />
            </div>
          ) : !selectedSequence ? (
            <div className='spinner-container-mhr' style={{ height: "80%" }}>
              <h4>No sequence found to show</h4>
            </div>
          ) : (
            <>
              <div className='emailModalLine'></div>
              <div className='SequencePauseDetailsModalBody'>
                <div className='TileAndDateOuterContainer'>
                  <div className='TitleContainerSequenceDetails'>
                    <p>Sequence</p>
                    <h3>
                      {selectedSequence ? selectedSequence.sequenceTitle : ""}
                    </h3>
                  </div>
                  <div className='DateContainerSequenceDetails'>
                    <div>
                      <p>Start Date</p>
                    </div>
                    <h3>{date ? date : ""}</h3>
                  </div>
                </div>
                <hr />
                <div className='TempalateDetailsOuterContainer'>
                  <div className='TemplateOneDetailsContainer'>
                    <div className='scheduleContainer'>
                      {nextTemplateNumber < 2 ? (
                        <>
                          <div>
                            <h3>{date1 ? date1 : ""}</h3>
                            <img
                              src={require("../../../../assets/images/calendar.png")}
                              alt='calender'
                            />
                          </div>
                          <p>{email ? "Email 01" : "SMS 01"}</p>
                        </>
                      ) : (
                        <h2>{email ? "Email 01" : "SMS 01"}</h2>
                      )}
                    </div>
                    <div className='StatusContainer'>
                      {nextTemplateNumber >= 2 ? (
                        <img
                          src={require("../../../../assets/images/sequence-pause-tick.png")}
                          alt='Template sent'
                        />
                      ) : (
                        <div className='TickEclipse'></div>
                      )}
                      {totalTemplates > 1 && (
                        <div
                          className={`statusLine1 ${
                            nextTemplateNumber >= 2 && "green"
                          }`}
                        ></div>
                      )}
                    </div>
                    <div className={`DetailsContainerMHR `}>
                      <div className='TemplateDetailsContainerOuter'>
                        <div className='TemplateDetailsContainerTitles'>
                          Title:
                        </div>
                        <div className='TemplateDetailsContainerDescriptions'>
                          <h2>
                            {selectedSequence
                              ? selectedSequence.templateOne.label
                              : ""}
                          </h2>
                        </div>
                      </div>
                      <div className='TemplateDetailsContainer'>
                        <div className='TemplateDetailsContainerTitles'>
                          Text:
                        </div>
                        <div className='TemplateDetailsContainerDescriptions'>
                          {selectedSequence
                            ? selectedSequence.templateOne.value
                            : ""}
                        </div>
                      </div>
                      <div className='TemplateDetailsContainer'>
                        <div className='TemplateDetailsContainerTitles'>
                          Files:
                        </div>
                        <div className='TemplateDetailsContainerDescriptions'>
                          <p>
                            {selectedSequence ? (
                              selectedSequence.templateOne.attachment &&
                              selectedSequence.templateOne.attachment
                                .file_name ? (
                                <u>
                                  {
                                    selectedSequence.templateOne.attachment
                                      .file_name
                                  }
                                </u>
                              ) : (
                                "-"
                              )
                            ) : (
                              "-"
                            )}
                          </p>
                        </div>
                      </div>
                      <div className='TemplateDetailsContainer'>
                        <div className='TemplateDetailsContainerTitles'>
                          By:
                        </div>
                        <div className='TemplateDetailsContainerDescriptions'>
                          {selectedSequence
                            ? selectedSequence.templateOne.name
                            : ""}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* ================2================== */}
                  {selectedSequence.templateTwo && (
                    <div className='TemplateOneDetailsContainer'>
                      <div className='scheduleContainer'>
                        {nextTemplateNumber < 3 ? (
                          <>
                            <div>
                              <h3>{date2 ? date2 : ""}</h3>
                              <img
                                src={require("../../../../assets/images/calendar.png")}
                                alt='calender'
                              />
                            </div>
                            <p>{email ? "Email 02" : "SMS 02"}</p>
                          </>
                        ) : (
                          <h2>{email ? "Email 02" : "SMS 02"}</h2>
                        )}
                      </div>
                      <div className='StatusContainer'>
                        {nextTemplateNumber >= 3 ? (
                          <img
                            src={require("../../../../assets/images/sequence-pause-tick.png")}
                            alt='Template sent'
                          />
                        ) : (
                          <div className='TickEclipse'></div>
                        )}
                        {totalTemplates > 2 && (
                          <div
                            className={`statusLine1 ${
                              nextTemplateNumber >= 3 && "green"
                            }`}
                          ></div>
                        )}
                      </div>
                      <div className='DetailsContainerMHR'>
                        <div className='TemplateDetailsContainerOuter'>
                          <div className='TemplateDetailsContainerTitles'>
                            Title:
                          </div>
                          <div className='TemplateDetailsContainerDescriptions'>
                            <h2>
                              {selectedSequence.templateTwo &&
                              selectedSequence.templateTwo.label
                                ? selectedSequence.templateTwo.label
                                : ""}
                            </h2>
                          </div>
                        </div>
                        <div className='TemplateDetailsContainer'>
                          <div className='TemplateDetailsContainerTitles'>
                            Text:
                          </div>
                          <div className='TemplateDetailsContainerDescriptions'>
                            {selectedSequence.templateTwo &&
                            selectedSequence.templateTwo.value
                              ? selectedSequence.templateTwo.value
                              : ""}
                          </div>
                        </div>
                        <div className='TemplateDetailsContainer'>
                          <div className='TemplateDetailsContainerTitles'>
                            Files:
                          </div>
                          <div className='TemplateDetailsContainerDescriptions'>
                            <p>
                              {selectedSequence ? (
                                selectedSequence.templateTwo.attachment &&
                                selectedSequence.templateTwo.attachment
                                  .file_name ? (
                                  <u>
                                    {
                                      selectedSequence.templateTwo.attachment
                                        .file_name
                                    }
                                  </u>
                                ) : (
                                  "-"
                                )
                              ) : (
                                "-"
                              )}
                            </p>
                          </div>
                        </div>
                        <div className='TemplateDetailsContainer'>
                          <div className='TemplateDetailsContainerTitles'>
                            By:
                          </div>
                          <div className='TemplateDetailsContainerDescriptions'>
                            {selectedSequence.templateTwo &&
                            selectedSequence.templateTwo.name
                              ? selectedSequence.templateTwo.name
                              : ""}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* ================3================== */}
                  {selectedSequence.templateThree && (
                    <div className='TemplateOneDetailsContainer'>
                      <div className='scheduleContainer'>
                        {nextTemplateNumber < 4 ? (
                          <>
                            <div>
                              <h3>{date3 ? date3 : ""}</h3>
                              <img
                                src={require("../../../../assets/images/calendar.png")}
                                alt='calender'
                              />
                            </div>
                            <p>{email ? "Email 03" : "SMS 03"}</p>
                          </>
                        ) : (
                          <h2>{email ? "Email 03" : "SMS 03"}</h2>
                        )}
                      </div>
                      <div className='StatusContainer'>
                        {nextTemplateNumber > 3 ? (
                          <img
                            src={require("../../../../assets/images/sequence-pause-tick.png")}
                            alt='Template sent'
                          />
                        ) : (
                          <div className='TickEclipse'></div>
                        )}
                      </div>
                      <div className='DetailsContainerMHR'>
                        <div className='TemplateDetailsContainerOuter'>
                          <div className='TemplateDetailsContainerTitles'>
                            Title:
                          </div>
                          <div className='TemplateDetailsContainerDescriptions'>
                            <h2>
                              {selectedSequence.templateThree &&
                              selectedSequence.templateThree.label
                                ? selectedSequence.templateThree.label
                                : ""}
                            </h2>
                          </div>
                        </div>
                        <div className='TemplateDetailsContainer'>
                          <div className='TemplateDetailsContainerTitles'>
                            Text:
                          </div>
                          <div className='TemplateDetailsContainerDescriptions'>
                            {selectedSequence.templateThree &&
                            selectedSequence.templateThree.value
                              ? selectedSequence.templateThree.value
                              : ""}
                          </div>
                        </div>
                        <div className='TemplateDetailsContainer'>
                          <div className='TemplateDetailsContainerTitles'>
                            Files:
                          </div>
                          <div className='TemplateDetailsContainerDescriptions'>
                            <p>
                              {selectedSequence ? (
                                selectedSequence.templateThree.attachment &&
                                selectedSequence.templateThree.attachment
                                  .file_name ? (
                                  <u>
                                    {
                                      selectedSequence.templateThree.attachment
                                        .file_name
                                    }
                                  </u>
                                ) : (
                                  "-"
                                )
                              ) : (
                                "-"
                              )}
                            </p>
                          </div>
                        </div>
                        <div className='TemplateDetailsContainer'>
                          <div className='TemplateDetailsContainerTitles'>
                            By:
                          </div>
                          <div className='TemplateDetailsContainerDescriptions'>
                            {selectedSequence.templateThree &&
                            selectedSequence.templateThree.name
                              ? selectedSequence.templateThree.name
                              : ""}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className={`actionButtonS ${
                    totalTemplates >= 2
                      ? totalTemplates >= 3
                        ? "totalTemplates3"
                        : "totalTemplates2"
                      : ""
                  }`}
                >
                  {showPauseButton ? (
                    deleteButtonLoader ? (
                      <button
                        className='actionButton3'
                        style={{
                          backgroundColor: "white",
                          border: "none",
                          height: "38px",
                          maxHeight: "38px",
                          width: "4vw",
                          maxWidth: "66px",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Loader
                          type='Oval'
                          color='black'
                          height={38}
                          width={38}
                        />
                      </button>
                    ) : (
                      <button
                        className='actionButton3'
                        style={{
                          backgroundColor: "#f04141",
                          width: "4vw",
                          maxWidth: "66px",
                        }}
                        onClick={() => setShowDeleteConfirmation(true)}
                      >
                        <img
                          style={{ height: "18px" }}
                          src={require("../../../../assets/images/delete-button-bold.png")}
                          alt='pause'
                        />
                      </button>
                    )
                  ) : null}
                  <button className='actionButton1' onClick={handleCloseModal}>
                    Close
                  </button>
                  {showPauseButton ? (
                    pauseButtonLoader ? (
                      <div className='loaderonbutton'>
                        <Loader
                          type='Oval'
                          color='black'
                          height={38}
                          width={38}
                        />
                      </div>
                    ) : (
                      <button
                        className='actionButton3'
                        onClick={
                          () => {
                            setShowConfirmation(true);
                          }
                          // handleConfirm
                        }
                      >
                        <div>
                          <img
                            style={{ height: "18px" }}
                            src={require("../../../../assets/images/pause-yellow.png")}
                            alt='pause'
                          />
                          <p>Pause Sequence</p>
                        </div>
                      </button>
                    )
                  ) : null}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      )
      {showConfirmation && (
        <SequenceConfirmation
          show={showConfirmation}
          onHide={() => {
            setShowConfirmation(false);
          }}
          title={"Are you sure you want to pause the sequence?"}
          description={
            "After you pause the sequence there will be an option to continue the sequence."
          }
          yesFunc={async () => {
            await handleConfirm();
          }}
          yesTitle={"Pause"}
          showAlert={showAlert}
        />
      )}
      {showDeleteConfirmation && (
        <SequenceConfirmation
          show={showDeleteConfirmation}
          onHide={() => {
            setShowDeleteConfirmation(false);
          }}
          title={
            "Are you sure you want to unassign the sequence from this lead?"
          }
          description={
            "After you unassign the sequence you will be able to assign sequence to this lead again."
          }
          yesFunc={async () => {
            await handleDelete();
          }}
          yesTitle={"Remove"}
          showAlert={showAlert}
        />
      )}
    </>
  );
};
