import {
  FETCH_ALL,
  CREATE,
  SEARCH,
  LENGTH,
  UPDATE,
  DELETE,
  START_LOADING,
  END_LOADING,
  SWITCH_TABS,
  DELETED_TRUE,
  DELETED_FALSE,
  UPDATED_TRUE,
  UPDATED_FALSE,
  SEARCHING_TRUE,
  SEARCHING_FALSE,
  TAB_DATA,
} from "./mediaActionTypes";

const initialState = {
  isLoading: false,
  data: [],
  size: 0,
  error: null,
  currentPage: 1,
  numberOfPages: 0,
  totalItems: 0,
  deleted: false,
  searchedData: [],
  searching: false,
  searchedDataTotal: 0,
  selectedTab: 0,
  tabData: [],
  tabDataTotal: 0,
  updated: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case START_LOADING:
      return { ...state, isLoading: true };
    case END_LOADING:
      return { ...state, isLoading: false };
    case DELETED_TRUE:
      return { ...state, deleted: true };
    case DELETED_FALSE:
      return { ...state, deleted: false };
    case UPDATED_TRUE:
      return { ...state, updated: true };
    case UPDATED_FALSE:
      return { ...state, updated: false };
    case SEARCHING_TRUE:
      return { ...state, searching: true };
    case SEARCHING_FALSE:
      return { ...state, searching: false };
    case FETCH_ALL:
      return {
        ...state,
        data: action.payload.data,
        currentPage: action.payload.currentPage,
        numberOfPages: action.payload.numberOfPages,
        totalItems: action.payload.totalItems,
      };
    case SEARCH:
      return {
        ...state,
        searchedData: action.payload.data,
        searchedDataTotal: action.payload.size,
        currentPage: action.payload.currentPage,
        numberOfPages: action.payload.numberOfPages,
        totalItems: action.payload.totalItems,
      };
    case LENGTH:
      return { ...state, size: action.payload };
    case CREATE:
      return { ...state, data: [...state.data, action.payload] };
    case SWITCH_TABS:
      return {
        ...state,
        selectedTab: action.payload,
      };
    case TAB_DATA:
      return {
        ...state,
        tabData: action.payload,
        tabDataTotal: action.payload ? action.payload.length : 0,
      };
    case UPDATE:
      return {
        ...state,
        data: state.data.map((post) =>
          post._id === action.payload._id ? action.payload : post
        ),
      };
    case DELETE:
      return {
        ...state,
        data: state.data.filter((post) => post._id !== action.payload),
      };
    default:
      return state;
  }
};
