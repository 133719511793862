import React, { Component } from 'react'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'
export class LoaderFile extends Component
{
    render()
    {
        return (
            <div className="container h-100 d-flex justify-content-center">
                <div
                    className="jumbotron"
                    style={{ marginTop: 170, backgroundColor: 'transparent' }}
                >
                    <div className="row" style={{ height: 45 }}>
                        <p
                            style={{
                                fontSize: 34,
                                // fontWeight: 400,
                                color: '#0A84FE',
                                textAlign: 'left',
                                fontFamily: 'SF Pro Display Light',
                                letterSpacing: -1
                            }}
                        >
                            Welcome to
						</p>
                    </div>
                    <div className="row" style={{ height: 158 }}>
                        <p
                            style={{
                                fontSize: 48,
                                // fontWeight: 500,
                                color: '#FFFFFF',
                                lineHeight: 1.1,
                                letterSpacing: -1,
                                textAlign: 'left',
                                fontFamily: 'SF Pro Display Semibold'
                            }}
                        >
                            Lead <br /> Management <br /> System
						</p>
                    </div>
                    <div className="row" style={{ marginTop: 60 }}>

                    </div>
                    <div className="row" style={{ marginTop: 5, alignItems: 'center', justifyContent: 'center' }}>
                        <Loader
                            type="Oval"
                            color="#00BFFF"
                            height={50}
                            width={50}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default LoaderFile
