import React, { useState } from "react";
import ".././Home/Home.css";
import { Modal} from "react-bootstrap";
import moment from "moment";
import DateTimePicker from 'react-datetime-picker';

function SnoozeModal(props) {

  const [value, onChange] = useState(new Date());
  const [selected, onSelectedChange] = useState(1);
  var date = new Date();
  date.setHours(date.getHours() + 24);
  let tomorrowDate = date;

  date = new Date();
  var lastday = date.getDate() - (date.getDay() - 1) + 6;
  let weekEndDate = new Date(date.setDate(lastday));

  date = new Date();
  var firstDay = date.getDate() - (date.getDay() - 1) + 7;
  let nextWeekDate = new Date(date.setDate(firstDay));
  return (
    <div style={{ fontFamily: "Arial, Helvetica, sans-serif" }}>
      <Modal show={props.show}>
        <Modal.Header>
          <Modal.Title style={{ fontFamily: "Arial, Helvetica, sans-serif" }}>
            <h2 style={{ margin: "auto" }}>Snooze Lead</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            <div>
              <ul style={{ listStyle: "none", padding: 0 }}>
                <li className="snoozeoption" style={{backgroundColor: selected === 1 ? '#ececec': 'transparent'}}>
                  <div
                    style={{
                      fontSize: 14,
                      cursor: "pointer",
                      width: "100%",
                      margin: "auto",
                      marginTop: 10,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                    onClick={() => {
                      // props.submitValue(tomorrowDate, 1);
                      onSelectedChange(1)
                    }}
                  >
                    <div>Tomorrow</div>
                    <div>{moment(tomorrowDate).format("dddd hh:mm a")}</div>
                  </div>
                </li>
                <li className="snoozeoption" style={{backgroundColor: selected === 2 ? '#ececec': 'transparent'}}>
                  <div
                    style={{
                      fontSize: 14,
                      cursor: "pointer",
                      width: "100%",
                      margin: "auto",
                      marginTop: 10,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                    onClick={() => {
                      // props.submitValue(weekEndDate, 2);
                      onSelectedChange(2)
                    }}
                  >
                    <div>This Weekend</div>
                    <div>{moment(weekEndDate).format("dddd hh:mm a")}</div>
                  </div>
                </li>
                <li className="snoozeoption" style={{backgroundColor: selected === 3 ? '#ececec': 'transparent'}}>
                  <div
                    style={{
                      fontSize: 14,
                      cursor: "pointer",
                      width: "100%",
                      margin: "auto",
                      marginTop: 10,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                    onClick={() => {
                      onSelectedChange(3)
                      // props.submitValue(nextWeekDate, 3);
                    }}
                  >
                    <div>Next Week</div>
                    <div>{moment(nextWeekDate).format("dddd hh:mm a")}</div>
                  </div>
                </li>
                <li className="snoozeoption" style={{backgroundColor: selected === 4 ? '#ececec': 'transparent'}}>
                  <div
                    style={{
                      fontSize: 14,
                      cursor: "pointer",
                      width: "100%",
                      margin: "auto",
                      marginTop: 10,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                    onClick={() => {
                      // props.submitValue(weekEndDate, 2);
                      onSelectedChange(4)
                    }}
                  >
                    <div><span>Pick date & time</span></div>
                    <DateTimePicker
                      amPmAriaLabel={"AM/PM"}
                      clearIcon={null}
                      formate={'y-MM-dd h:mm:ss a'}
                      onChange={onChange}
                      minDate={new Date()}
                      value={value}
                      locale="en-US"
                    />
                  </div>
                </li>
                <li></li>
              </ul>
            </div>
          }
        </Modal.Body>
        <Modal.Footer>
          <div
          >
          <button
            className="edit-modal-cancel-button"
            style={{marginRight: 10}}
            onClick={props.onCancelEditModal}
          >
            Cancel
          </button>
          <button
            className="edit-modal-cancel-button"
            onClick={()=>{
              switch(selected){
                case 1:
                    props.submitValue(tomorrowDate, 1);
                  break
                case 2:
                    props.submitValue(weekEndDate, 2);
                  break
                case 3:
                    props.submitValue(nextWeekDate, 3);
                  break
                case 4:
                    props.submitValue(value, 4);
                  break
                default: 
                    props.submitValue(value, 4);
                break;
              }
              

            }}
          >
            Save
          </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
export default SnoozeModal;
