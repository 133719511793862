import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Tabs, Tab } from "react-bootstrap";
import "./Custom.css"; // Import the custom CSS file

const PdfShareModal = ({
	handleCloseModal,
	handlePhoneChange,
	handleEmailChange,
	handleMessageChange,
	message,
	number,
	email,
	emailValidation,
	phoneValidation,
	validatePhoneNumber,
	validateEmail,
	handleType,
	numbersList,
	emailsList,
	// Add this prop
}) => {
	const [key, setKey] = useState("phone");

	useEffect(() => {
		handleType("phone");
	}, [emailsList, phoneValidation]);

	const handleSelectPhoneChange = (e) => {
		handlePhoneChange({
			target: {
				value: e.target.value,
			},
		});
	};

	const handleSelectEmailChange = (e) => {
		handleEmailChange({
			target: {
				value: e.target.value,
			},
		});
	};

	return (
		<div style={{ marginTop: -10 }}>
			<Tabs
				id="controlled-tab-example"
				activeKey={key}
				onSelect={(k) => {
					setKey(k);
					handleType(k);
				}}
				className="mb-1"
				style={{
					fontSize: 14,
					margin: "0rem 0rem",
					padding: "0rem 0rem",
				}}
			>
				<Tab
					eventKey="phone"
					title="Phone"
					style={{ fontSize: 13, margin: "0rem 0rem" }}
				>
					<Form.Group controlId="formPhoneNumber">
						<Form.Label style={{ fontSize: 12 }}>
							Phone Number
						</Form.Label>
						<Form.Control
							className="placeholder-color"
							style={{
								fontSize: 13,
								height: "4vh",
								color: "gray",
							}}
							type="email"
							placeholder="Enter phone number"
							value={number}
							onChange={handlePhoneChange}
							// onBlur={validatePhoneNumber}
							isInvalid={!phoneValidation}
						/>
						<Form.Control.Feedback type="invalid">
							Please enter a correct phone number.
						</Form.Control.Feedback>
					</Form.Group>
					{numbersList && numbersList.length > 0 && (
						<Form.Group controlId="formExistingPhoneNumbers">
							<Form.Label style={{ fontSize: 12 }}>
								Select Existing Phone Number
							</Form.Label>
							<Form.Control
								as="select"
								style={{
									fontSize: 13,
									height: "4vh",
									color: "gray",
									padding: "0rem 0.1rem",
								}}
								onChange={handleSelectPhoneChange}
							>
								<option value="">Select a number</option>
								{numbersList.map((num, index) => (
									<option key={index} value={num}>
										{num}
									</option>
								))}
							</Form.Control>
						</Form.Group>
					)}
				</Tab>
				<Tab eventKey="email" title="Email" style={{ fontSize: 13 }}>
					<Form.Group controlId="formEmail">
						<Form.Label style={{ fontSize: 13 }}>
							Email address
						</Form.Label>
						<Form.Control
							style={{
								fontSize: 13,
								height: "4vh",
								color: "gray",
							}}
							type="email"
							placeholder="Enter email"
							value={email}
							onChange={handleEmailChange}
							onBlur={validateEmail}
							isInvalid={!emailValidation}
						/>
						<Form.Control.Feedback type="invalid">
							Please enter a correct email.
						</Form.Control.Feedback>
					</Form.Group>
					{emailsList && emailsList.length > 0 && (
						<Form.Group controlId="formExistingEmails">
							<Form.Label style={{ fontSize: 13 }}>
								Select Existing Email
							</Form.Label>
							<Form.Control
								as="select"
								style={{
									fontSize: 13,
									height: "4vh",
									color: "gray",
									padding: "0rem 0.1rem",
								}}
								onChange={handleSelectEmailChange}
							>
								<option value="">Select an email</option>
								{emailsList.map((em, index) => (
									<option key={index} value={em}>
										{em}
									</option>
								))}
							</Form.Control>
						</Form.Group>
					)}
				</Tab>
			</Tabs>
			<Form.Group controlId="formMessage">
				<Form.Label style={{ fontSize: 12 }}>Message</Form.Label>
				<Form.Control
					style={{ fontSize: 13 }}
					as="textarea"
					rows={3}
					placeholder="Enter your message"
					value={message}
					onChange={handleMessageChange}
				/>
			</Form.Group>
		</div>
	);
};

export default PdfShareModal;

const styles = {
	modalContainer: {
		borderRadius: 5,
		backgroundColor: "white",
		position: "fixed",
		top: "50vh",
		left: "50%",
		transform: "translate(-50%, -50%)",
		height: "39vh",
		width: "35vw",
		maxWidth: "35vw",
		overflowY: "auto",
		overflowX: "hidden",
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
	},
	modalTitle: {
		fontSize: "1.8rem",
		color: "#0056b3",
	},
	modalBody: {
		fontSize: "1.2rem",
	},
	formCheckInput: {
		margin: "0.75rem",
		accentColor: "#007bff",
		transform: "scale(1.5)", // Scale the checkbox larger by 1.5 times
	},
};
