import React, { useState } from "react";
import { Modal } from "react-bootstrap";

import "./sequenceModal.css";

export const SequenceModal = ({
  show,
  onHide,
  title,
  fileName,
  yesFunc,
  type,
  input,
  textArea,
  link,
  setData,
  data,
}) => {
  const [handleShowModal, setHandleShowModal] = useState(true);

  const handleCloseModal = (e) => {
    e.preventDefault();
    setHandleShowModal(false);
    setData(null);
    onHide();
  };
  return (
    <Modal show={handleShowModal} className='emailModal' size='md'>
      <div>
        <div className='headingEmailModal'>
          <h2>Template Details</h2>
          <img
            src={require("../../../assets/images/close-cross.png")}
            onClick={handleCloseModal}
          />
        </div>
        <div className='emailModalLine'></div>
        <div className='emailTemplateModalBody'>
          <p className='sequence-modal-subheading'>Title:</p>
          <p className='sequence-modal-title'>{data ? data.label : ""}</p>
          <div className='sequence-modal-line'></div>
          <p className='sequence-modal-subheading'>Text:</p>
          <p className='sequence-modal-description'>{data ? data.value : ""}</p>
          <div className='sequence-modal-line-upper'></div>
          <div className='sequence-modal-inner-container'>
            <div className='sequence-modal-inner-inner-container1'>
              <p className='sequence-modal-subheading'>Attachment:</p>
              <div className='sequence-inner-container'>
                <img src={require("../../../assets/images/link.png")} />
                <p
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    if (
                      data &&
                      data.attachment &&
                      data.attachment.attachment_link
                    )
                      window.open(data.attachment.attachment_link);
                  }}
                >
                  {data ? (
                    data.attachment ? (
                      <u>{data.attachment.file_name}</u>
                    ) : (
                      "-"
                    )
                  ) : (
                    "-"
                  )}
                </p>
              </div>
            </div>
            <div className='sequence-modal-vline'></div>
            <div className='sequence-modal-inner-inner-container2'>
              <p className='sequence-modal-subheading'>Created by:</p>
              <div className='sequence-inner-container'>
                <img src={require("../../../assets/images/user.png")} />
                <p>{data ? data.name : ""}</p>
              </div>
            </div>
          </div>
          <div className='sequence-modal-line-lower'></div>
        </div>
        <div className='sequence-buttonsContainerModal'>
          <button className='sequence-close' onClick={handleCloseModal}>
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
};
