import React, { useState, useEffect } from "react";
import {
	Typography,
	MenuItem,
	FormControl,
	InputLabel,
	TextField,
	Button,
	Collapse,
	IconButton,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Remove";
import Select from "react-select";

const customStyles = {
	control: (provided) => ({
		...provided,
		background: "white",
		color: "red",
	}),
};
const SavedFilterComponent = ({
	savedFiltersUpdate,
	icon,
	onAddSavedFilter,
	handleSaveFilterName,
	isFilterAdded,
	openFileNameInput,
}) => {
	const [savedFilters, setSavedFilters] = useState([]);
	const [selectedFilterName, setSelectedFilterName] = useState("");
	const [filterName, setFilterName] = useState("");
	const [isFilterNameFieldOpen, setIsFilterNameFieldOpen] = useState(false);
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const [filterCount, setFilterCount] = useState(0);

	// useEffect(() => {
	// 	console.log("useEffect1");
	// 	// Fetch saved filters from local storage
	// 	const filters = JSON.parse(localStorage.getItem("savedFilters")) || [];
	// 	setSavedFilters(filters);
	// }, [savedFiltersUpdate]);
	useEffect(() => {
		console.log("useEffect1");
		const storedFilters = localStorage.getItem("savedFilters");
		if (storedFilters) {
			try {
				const filters = JSON.parse(storedFilters);
				setSavedFilters(filters);
			} catch (error) {
				console.error(
					"Failed to parse saved filters from local storage",
					error
				);
			}
		}
	}, [savedFiltersUpdate]);

	useEffect(() => {
		if (!selectedFilterName || isFilterAdded) {
			toggleDropdown();
		}
	}, [isFilterAdded, selectedFilterName]);

	useEffect(() => {
		if (openFileNameInput) {
			setDropdownOpen(true);
		} else {
			setDropdownOpen(false);
			setFilterName("");
		}
	}, [openFileNameInput]);

	const handleFilterChange = (selectedOption) => {
		setSelectedFilterName(selectedOption.value);
		const filterData = savedFilters.find(
			(filter) => filter.name === selectedOption.value
		);
		onAddSavedFilter(filterData.categories, selectedOption.value);
	};

	// const handleFilterChange = (event) => {
	// 	const selectedFilter = event.target.value;
	// 	console.log("on changes selectedFilter", selectedFilter.name);
	// 	setIsFilterNameFieldOpen(false);

	// 	setSelectedFilterName(selectedFilter);
	// 	onAddSavedFilter(selectedFilter.categories, selectedFilter.name);
	// };
	const toggleDropdown = () => {
		setDropdownOpen(!dropdownOpen);
	};
	const handleFilterName = (name) => {
		handleSaveFilterName(name);
		setFilterName(name);
	};
	const options = savedFilters.map((filter) => ({
		value: filter.name,
		label: filter.name,
	}));
	return (
		<div style={{ marginBottom: "16px" }}>
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
				}}
			>
				<Typography
					// variant="subtitle1"
					// gutterBottom
					style={{
						display: "flex",
						alignItems: "center",
						gap: "8px",
					}}
				>
					<img
						src={icon}
						alt="Saved Filter"
						style={{
							width: "16px",
							height: "16px",
							marginRight: "5px",
						}}
					/>
					<span
						style={{
							marginRight: "5px",
							fontSize: "14px",
							fontWeight: 600,
							fontFamily: "SF Pro Display Semibold",
						}}
					>
						Saved Filters
					</span>
					<span
						style={{
							background: "#D9D9D9",
							padding: "2px 10px",
							borderRadius: "10px",
							marginLeft: "5px",
						}}
					>
						{savedFilters.length}
					</span>
				</Typography>
				<IconButton onClick={toggleDropdown}>
					{dropdownOpen ? <CloseIcon /> : <AddIcon />}
				</IconButton>
			</div>
			{savedFilters.length > 0 ? (
				// <div style={{ width: "80%", marginBottom: "8px" }}>
				// 	<label>Select Saved Filter</label>

				// 	<Select
				// 		options={savedFilters}
				// 		menuPlacement="auto"
				// 		value={selectedFilterName}
				// 		onChange={handleFilterChange}
				// 		styles={customStyles}
				// 		// key={index + "category"}
				// 		// isDisabled={disable}
				// 	/>

				<div style={{ width: "80%", marginBottom: "8px" }}>
					<Select
						options={options}
						value={options.find(
							(option) => option.value === selectedFilterName
						)}
						onChange={handleFilterChange}
						placeholder="Select Saved Filter"
						components={{
							SingleValue: ({ data }) => (
								<p style={{ fontSize: 13 }}>{data.value}</p>
							),
						}}
						styles={{
							control: (base) => ({
								...base,
								height: "4vh",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}),
							menu: (base) => ({
								...base,
								backgroundColor: "white",
								color: "black",
							}),
							menuPortal: (base) => ({ ...base, zIndex: 9999 }),
							placeholder: (base) => ({
								...base,
								fontSize: "1.3rem", // Adjust the font size as needed
							}),
						}}
					/>
				</div>
			) : // </div>
			// <div style={{ width: "80%", marginBottom: "8px" }}>
			// 	<FormControl variant="outlined" fullWidth>
			// 		<InputLabel>Select Saved Filter</InputLabel>
			// 		<Select
			// 			style={{
			// 				height: "4vh",
			// 				display: "flex",
			// 				justifyContent: "center",
			// 				alignItems: "center",
			// 			}}
			// 			value={selectedFilterName}
			// 			onChange={handleFilterChange}
			// 			label="Select Saved Filter"
			// 		>
			// 			{savedFilters.map((filter, index) => (
			// 				<MenuItem key={index} value={filter}>
			// 					{filter.name}
			// 				</MenuItem>
			// 			))}
			// 		</Select>
			// 	</FormControl>
			// </div>
			null}
			<Collapse in={dropdownOpen}>
				<div
					style={{
						width: "80%",
						marginBottom: "8px",
						margin: 0,
						padding: 0,
					}}
				>
					<input
						style={{
							margin: 0,
							padding: "10px 15px",
							width: "100%",
							border:
								openFileNameInput && filterName === ""
									? "1px solid red"
									: "1px solid #D2D2D2",
							borderRadius: 4,
							fontSize: "1.3rem",
						}}
						placeholder="Enter filter name"
						value={filterName}
						onChange={(e) => handleFilterName(e.target.value)}
					/>
				</div>
			</Collapse>
		</div>
	);
};

export default SavedFilterComponent;
