import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { SEQUENCES_SELECTEDINDEX } from "../../../Store/Sequences/sequenceActionTypes";
import "./sequenceTile.css";

function SequencesTile({ index, creator, title, description, type }) {
  const dispatch = useDispatch();
  const { sequence_changeStatus } = useSelector((state) => state.sequences);

  const handleSelectedIndex = () => {
    if (sequence_changeStatus === 0)
      dispatch({ type: SEQUENCES_SELECTEDINDEX, payload: index });
  };
  return (
    <div className='sequenceTileMainContainer' onClick={handleSelectedIndex}>
      <div className='innerContainer'>
        <h6>{index + 1}.</h6>
        <div className='innerInnerContainer'>
          <div className='textContainer'>
            <h5>{`Created by ${creator ? creator : ""}`}</h5>
            <h3>{`${title ? title : ""}`}</h3>
            <h4>{`${description ? description : ""}`}</h4>
          </div>
          {type === "Email" ? (
            <img src={require("../../../assets/images/email_sequence.png")} />
          ) : (
            <img src={require("../../../assets/images/sms-sequence.png")} />
          )}
        </div>
      </div>
      <div className='tileLine'></div>
    </div>
  );
}

export default SequencesTile;
