import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import Loader from "react-loader-spinner";

import GenericDropDown from "../../../GenericDropdown/GenericDropDown";
import "./startSequenceModal.css";
import SequenceDetails from "./SequenceDetails/SequenceDetails";
import {
	assignSequence,
	fetchAllSequences,
	fetchSpecificSequence,
	startPauseSequenceWithNewDate,
} from "../../../../../api/api";
import {
	formatDate,
	secretUserSequenceFilter,
} from "../../../../Helpers/Constants";

export const StartSequenceModal = ({
	rowId,
	show,
	onHide,
	title,
	yesFunc,
	showAlert,
	email = true,
	state,
	UpdateLeadAfterAssigningSequence,
	unPause = false,
	unPauseId,
}) => {
	const dateInputRef = useRef(null);

	const [handleShowModal, setHandleShowModal] = useState(true);
	const [showDetails, setShowDetails] = useState(false);
	const [loader, setloader] = useState(false);
	const [assigning, setAssigning] = useState(false);
	const [first, setfirst] = useState(true);
	const [allSequences, setAllSequences] = useState([]);
	const [selectedSequence, setSelectedSequence] = useState(null);
	const [selectDate, setSelectedDate] = useState("");
	const [selectedDate1, setSelectedDate1] = useState("");

	const tomorrow = new Date();
	tomorrow.setDate(tomorrow.getDate() + 1);
	const dd = String(tomorrow.getDate()).padStart(2, "0");
	const mm = String(tomorrow.getMonth() + 1).padStart(2, "0");
	const yyyy = tomorrow.getFullYear();
	const formattedTomorrow = `${yyyy}-${mm}-${dd}`;

	useEffect(() => {
		setSelectedDate(formattedTomorrow);
	}, [formattedTomorrow]);

	const handleKeyDown = (e) => {
		e.preventDefault(); // Prevent any keyboard input
	};

	useEffect(() => {
		const formatDate = async () => {
			setSelectedDate1(
				`${selectDate[5]}${selectDate[6]}-${selectDate[8]}${selectDate[9]}-${selectDate[0]}${selectDate[1]}${selectDate[2]}${selectDate[3]}`
			);
		};
		// if ((unPause && !first) || !unPause) {
		if (selectDate && selectDate !== "") formatDate();
		// }
	}, [selectDate]);

	const fetch = async () => {
		setloader(true);
		const { data } = await fetchAllSequences();
		var seqData = secretUserSequenceFilter(data);
		console.log("seq", seqData);
		let arr;
		if (email) {
			arr = seqData.filter((item) => {
				if (item.sequenceType === "Email") return item;
			});
		} else {
			arr = seqData.filter((item) => {
				if (item.sequenceType === "SMS") return item;
			});
		}
		setAllSequences(arr);
		setloader(false);
	};

	// async function getFormatDate(newDate) {
	//   const currentDate = new Date(newDate);
	//   const formattedDate = currentDate.toLocaleDateString("en-GB", {
	//     day: "2-digit",
	//     month: "2-digit",
	//     year: "numeric",
	//   });

	//   return formattedDate;
	// }

	const handleButtonClick = () => {
		dateInputRef.current.showPicker();
	};

	const FetchSpecificSequence = async () => {
		try {
			setloader(true);
			if (unPauseId && unPauseId !== "") {
				const { data } = await fetchSpecificSequence(unPauseId);
				if (data) {
					setSelectedSequence(data.sequenceTitle);
					setAllSequences([data]);
				}
				setloader(false);
			}
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		if (!unPause) {
			fetch();
		} else {
			FetchSpecificSequence();
		}
	}, []);

	useEffect(() => {
		if (allSequences && allSequences !== 0) {
			setSelectedSequence(allSequences[0]);
		}
	}, [allSequences]);

	const handleCloseModal = (e) => {
		e.preventDefault();
		setHandleShowModal(false);
		onHide();
	};

	const handleConfirm = async () => {
		setAssigning(true);
		let date = new Date(selectDate).getTime();
		if (unPause) {
			// const newDate = await getFormatDate(selectDate);
			const newDate1 = await formatDate(new Date(selectDate));

			const { status, data } = await startPauseSequenceWithNewDate(
				rowId,
				{
					email: email,
					date: newDate1.replaceAll("-", "/"),
				}
			);
			if (status === 200) {
				if (email) {
					UpdateLeadAfterAssigningSequence(rowId, data.updatedData);
				} else {
					UpdateLeadAfterAssigningSequence(rowId, data.updatedData);
				}
				showAlert("Sequence has been Started");
				onHide();
			} else {
				showAlert("Operation unsuccessful");
			}
		} else {
			const result = await assignSequence(rowId, {
				sequenceId: selectedSequence._id,
				startDate: date,
				sequenceType: email ? "Email" : "SMS",
			});
			if (result.status === 200) {
				showAlert("Sequence has been assigned");
				await UpdateLeadAfterAssigningSequence(
					rowId,
					result.data.result
				);

				onHide();
			} else showAlert("Setting sequence unsuccessful");
		}
	};
	return (
		<Modal show={handleShowModal} className="emailModal1111" size="md">
			{allSequences && allSequences.length !== 0 && !loader && (
				<>
					<div>
						{showDetails ? (
							<div className="headingEmailModal">
								<img
									src={require("../../../../assets/images/go-back.png")}
									onClick={() => setShowDetails(false)}
								/>
							</div>
						) : (
							<div className="headingEmailModal">
								<h2>{title ? title : ""}</h2>
								<img
									src={require("../../../../assets/images/close-cross.png")}
									onClick={handleCloseModal}
								/>
							</div>
						)}
						<div className="emailModalLine"></div>
						{showDetails ? (
							<div className="SequenceStartModalBody">
								<SequenceDetails
									setShowDetails={setShowDetails}
									SequenceDetails={selectedSequence}
								/>
							</div>
						) : (
							<div className="SequenceStartModalBody">
								<p className="SequenceStartModalBody-sh">
									Sequence
								</p>
								<GenericDropDown
									options={allSequences}
									onSelect={setSelectedSequence}
									optionSelected={selectedSequence}
									defaultOption="Select Sequence"
									random={true}
									rounded={false}
									borderAroundIcon={false}
									active={!unPause ? true : false}
								/>
								<div
									className={`viewSequenceDetails ${
										!unPause &&
										"viewSequenceDetails-visible"
									}`}
									onClick={() => setShowDetails(true)}
								>
									<img
										src={require("../../../../assets/images/sequence-view-icon.png")}
									/>
									<p>View Sequence</p>
								</div>
								<p className="SequenceStartModalBody-sh">{`${
									!unPause ? "Start" : "Resume"
								} sequence on`}</p>
								<div
									className={`ReminderCustomDateSelector1`}
									onClick={handleButtonClick}
								>
									<div className="reminder-date-show1">
										<p>
											{selectedDate1 &&
											selectedDate1 !== ""
												? selectedDate1
												: "MM / DD / YYYY"}
										</p>
									</div>
									<div className={`line-reminder`}></div>
									<div className="reminder-date1">
										<img
											src={require("../../../../assets/images/sequence-calendar.png")}
											alt="select-date"
										/>
									</div>
								</div>
								<input
									type="date"
									ref={dateInputRef}
									onKeyDown={handleKeyDown}
									min={formattedTomorrow}
									value={selectDate}
									onChange={(e) => {
										setSelectedDate(e.target.value);
									}}
								/>
								{/* <input
                  type='date'
                  onKeyDown={handleKeyDown}
                  placeholder='DD/MM/YYYY'
                  min={formattedTomorrow}
                  value={selectDate}
                  onChange={(e) => {
                    setSelectedDate(e.target.value);
                  }}
                /> */}
							</div>
						)}
					</div>
					{!showDetails && (
						<div className="buttonsContainerModal">
							<button
								className="cancelButton"
								onClick={handleCloseModal}
							>
								Cancel
							</button>
							{assigning ? (
								<Loader
									className="modalLoader"
									type="Oval"
									color="black"
									height={38}
									width={38}
								/>
							) : (
								<button
									className="sendButton"
									onClick={handleConfirm}
								>
									{`${
										!unPause ? "Start" : "Resume"
									} Sequence`}
								</button>
							)}
						</div>
					)}
				</>
			)}
			{loader && (
				<div className="seuqneceLoader">
					<Loader
						className="modalLoader"
						type="Oval"
						color="black"
						height={38}
						width={38}
					/>
				</div>
			)}
			{(!allSequences && !loader) ||
				(allSequences && allSequences.length === 0 && !loader && (
					<>
						<div className="headingEmailModal">
							<h2>{title ? title : ""}</h2>
							<img
								src={require("../../../../assets/images/close-cross.png")}
								onClick={handleCloseModal}
							/>
						</div>
						<div className="emailModalLine"></div>
						<div className="seuqneceLoader">
							<p>No sequences found</p>
						</div>
					</>
				))}
		</Modal>
	);
};
