/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import Collapsible from 'react-collapsible';

import './index.css';
 
class Checklist extends Component{
    
    handleCheck = (_key, column)=>{
        this.props.onUpdateColumnSelection(_key, column)
    }
    getColumnLabel = (value)=>{
        switch (value){
            case 'utc_time_stamp':
                return 'TIMESTAMP';
            case 'name':
                return 'NAME';
            case 'email':
                return 'EMAIL';
            case 'address':
                return 'ADDRESS';
            case 'phone_number':
                return 'PHONE';
            case 'svc_requested':
                return 'DESCRIPTION';
            case 'assigned_to':
                return 'ASSIGNED TO';
            case 'lead_status':
                return 'LEAD STATUS';
            case 'archived':
                return 'ARCHIVE';
            case 'star':
                return 'STAR';
            case 'source':
                return 'SOURCE';
            case 'notes':
                return 'NOTES';
            case 'extra_links':
                return 'EASY ACCESS LINKS';
            case 'automatic_email_sent':
                return 'TIMESTAMP';
            case 'updated_at':
                return 'LAST CONTACTED';
            default:
                return '';
        }
    }
    render(){
        return (
        <div className='check-list-container'>
        <Collapsible trigger={<div className='check-list-heading'><h2>{this.props.heading}</h2></div>}>
        <ul className='check-list'>
        {this.props.allAvailableColumns.map((v, i) => (
            <li key={i} className='check-list-item'>
            <input
                type="checkbox"
                key={v+i}
                data-key={v}                  // 3
                onChange={(element) => {this.handleCheck(this.props._key, v)}}
                checked={this.props.selectedColumns.find(e => e == v) != null} // 5
            />
            <label>{this.getColumnLabel(v)}</label>
            </li>
        ))}
    
        </ul>
        </Collapsible>
        </div>
    )
    
    }
    
}

export default Checklist;