// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken } from "firebase/messaging";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBqa15AXNRtfghE52s3caR04RUZK23DV8Q",
  authDomain: "leads-ms.firebaseapp.com",
  projectId: "leads-ms",
  storageBucket: "leads-ms.firebasestorage.app",
  messagingSenderId: "700052127389",
  appId: "1:700052127389:web:05e4aa8c051bd25714de74",
  measurementId: "G-BDBEGCDBD3",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
console.log("Firebase successfully configured:", app);

// Initialize Analytics
const analytics = getAnalytics(app);
console.log("Firebase Analytics initialized:", analytics);

// Initialize Messaging
const messaging = getMessaging(app);
console.log("Firebase Messaging initialized:", messaging);

export { messaging, getToken };
