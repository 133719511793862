import React, { useState } from "react";
import Loader from "react-loader-spinner";
import { Modal } from "react-bootstrap";

import "./Confirmations.css";

export const SequenceConfirmation = ({
  show,
  onHide,
  title,
  description,
  yesFunc,
  yesTitle,
  showAlert,
  state,
}) => {
  const [loader, setLoader] = useState(false);

  const handleConfirm = async () => {
    setLoader(true);
    await yesFunc();
    setLoader(false);
    onHide();
  };
  return (
    <div className='mhr-confirmModalContainer'>
      <Modal
        show={show}
        onHide={onHide}
        style={{ top: "30%", borderRadius: 10 }}
        size='md'
      >
        <Modal.Body>
          <div className='mhr-descriptionContainer'>
            <h3>{title}</h3>
            <p>{description}</p>
          </div>
        </Modal.Body>
        <div className='mhr-buttonsContainer2'>
          <button className='mhr-buttonsContainer2-button1 ' onClick={onHide}>
            Cancel
          </button>
          {loader ? (
            <div className='mhr-loader-button'>
              <Loader type='Oval' color='black' height={38} width={38} />
            </div>
          ) : (
            <button
              className='mhr-buttonsContainer2-button2 '
              onClick={handleConfirm}
            >
              {yesTitle}
            </button>
          )}
        </div>
      </Modal>
    </div>
  );
};
