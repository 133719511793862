import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import BarChart from "../BarChart";
import LineChart from "../LineChart";
import PieChart from "../PieChart";
import DatePicker from "../DatePicker";
import "../styles/analytics.css";
var moment = require("moment");

class AnalyticsScreen extends Component {

	state = {
		greenColors: [
			"#008000",
			"#98fb98",
			"#90ee90",
			"#8fbc8f",
			"#adff2f",
			"#00ff00",
			"#00ff7f",
			"#7fff00",
			"#32cd32",
			"#00fa9a",
			"#3cb371",
			"#2e8b57",
			"#228b22",
			"#006400",
			"#d0f0c0",
			"#98fb98",
			"#addfad",
			"#93c572",
			"#8fbc8f",
			"#74c365",
		],
		blueColors: [
			"#0047AB",
			"#4682B4",
			"#5D3FD3",
			"#0A84FE",
			"#1F51FF",
			"#4169E1",
			"#0818A8",
			"#0437F2",
			"#add8e6",
			"#6495ed",
			"#4682b4",
			"#bcd4e6",
			"#0072bb",
			"#0d98ba",
			"#1560bd",
			"#21abcd",
			"#545aa7",
			"#4682b4",
			"#00bfff",
			"#007fff",
			"#318ce7",
			"#4f86f7",
			"#73c2fb",
		],
		chartData: {
			labels: null,
			datasets: [
				{
					label: "Leads",
					data: null,
					// backgroundColor: [],
					backgroundColor: [
						"#F9C770",
						"#A862FC",
						"#63B4FF",
						"#6964FF",
						"#59D9E6",
						"#C5E770",
						"#72D59F",
						"#FE6593",

						"#FF5252",
						"#EEFF41",
						"#FFAB40",
						"#FF6E40",
						"#8D6E63",

						"#ECEFF1",
						"#CFD8DC",
						"#B0BEC5",
						"#90A4AE",
						"#78909C",
						"#607D8B",
						"#546E7A",
						"#455A64",

						"#0047AB",
						"#4682B4",
						"#5D3FD3",
						"#0A84FE",
						"#1F51FF",
						"#4169E1",
						"#0818A8",
						"#0437F2",
					],
					borderColor: "black",
					borderWidth: 0,
					fill: false,
				},
			],
		},
		timeRangeValue: "All",
		webSourceValue: "All",
		callRailChannelValue: "All",
		totalLeads: 0,
		totalCallrailLeads: 0,
		totalWebSourceLeads: 0,
		deletedChecked: false,
		archivedChecked: false,
		showCustomDateRange: false,
		customStartDate: null,
		customEndDate: null,
		customFilterText: 'Custom',
		prevTimeRangeValue: "All",
		// let [showInfo1, setShowInfo1] = useState(false);
	};

	// eslint-disable-next-line no-useless-constructor
	constructor(props) {
		super(props);
		// this.users = props.users;
	}

	componentDidMount() {
		this.state.timeRangeValue = "All";
		this.state.webSourceValue = "All";
		this.state.callRailChannelValue = "All";
		this.updateChartWithAllTimeData();
	}

	getBackgroundColor(value, index) {
		if (value && /[a-zA-Z]/.test(value.toString().toLowerCase()[0])) {
			return this.state.blueColors[index];
		} else {
			return this.state.greenColors[index];
		}
	}
	
	handleSubmit = (values) => {
		//
	};

	toggleDeletedLeadsCheckbox = (e) => {
		this.setState({ showCustomDateRange: false});
		if(e.target.checked){
			this.state.deletedChecked = true;
		}else{
			this.state.deletedChecked = false;
		}
		// if (this.state.deletedChecked == false) {
		// 	this.state.deletedChecked = true;
		// } else {
		// 	this.state.deletedChecked = false;
		// }
		// this.handleTimeRangeChange(this.state.timeRangeValue);
		if(this.state.timeRangeValue == "Custom"){
			this.handleTimeRangeChange("custom-dates");
		}else{
			this.handleTimeRangeChange(this.state.timeRangeValue);
		}  
	};

	toggleArchivedLeadsCheckbox = (e) => {
		this.setState({ showCustomDateRange: false});
		if(e.target.checked){
			this.state.archivedChecked = true;
		}else{
			this.state.archivedChecked = false;
		}
		// if (this.state.archivedChecked == false) {
		// 	this.state.archivedChecked = true;
		// } else {
		// 	this.state.archivedChecked = false;
		// }
		// this.handleTimeRangeChange(this.state.timeRangeValue);
		if(this.state.timeRangeValue == "Custom"){
			this.handleTimeRangeChange("custom-dates");
		}else{
			this.handleTimeRangeChange(this.state.timeRangeValue);
		}  
	};

	/** to  format and filter the all time leads' data */
	updateChartWithAllTimeData() {
		var allAnalyticsData = this.props.analyticsData;
		var allTimeData = [];
		var filteredData = [];
		let webSourceFilteredData = [];
		let callRailFilteredData = [];
		var filteredData = [];

		if (allAnalyticsData != "") {
			allAnalyticsData.all_leads.web_source.forEach((element) => {
				let leadData = {
					source: element.name,
					leadsCount: element.leads_count,
					deletedLeadsCount: element.deleted_leads_count,
					archivedLeadsCount: element.archived_leads_count,
				};
				webSourceFilteredData.push(leadData);
				allTimeData.push(leadData);
			});
			allAnalyticsData.all_leads.callrail_source.forEach((element) => {
				let leadData = {
					source: element.name,
					leadsCount: element.leads_count,
					deletedLeadsCount: element.deleted_leads_count,
					archivedLeadsCount: element.archived_leads_count,
				};
				callRailFilteredData.push(leadData);
				allTimeData.push(leadData);
			});

			/**
			 * apply filters
			 */

			/** apply web source filters */
			if (this.state.webSourceValue != "All") {
				webSourceFilteredData = allTimeData.filter((leadData) => {
					return leadData.source == this.state.webSourceValue;
				});
			}
			/** apply callrail channel filters */
			if (this.state.callRailChannelValue != "All") {
				callRailFilteredData = allTimeData.filter((leadData) => {
					return leadData.source == this.state.callRailChannelValue;
				});
			}
			/** apply ALL value filter */
			if (
				this.state.webSourceValue == "All" &&
				this.state.callRailChannelValue == "All"
			) {
				filteredData = allTimeData;
			} else {
				/** update filteredData as per web and callrail selected filters */
				if (webSourceFilteredData && webSourceFilteredData.length > 0) {
					filteredData = filteredData.concat(webSourceFilteredData);
				}
				if (callRailFilteredData && callRailFilteredData.length > 0) {
					filteredData = filteredData.concat(callRailFilteredData);
				}
			}

		}
		this.state.totalLeads = allAnalyticsData && allAnalyticsData.all_leads && allAnalyticsData.all_leads.leads_count;
		this.updateCharts(
			filteredData,
			webSourceFilteredData,
			callRailFilteredData
		);
	}

	/** to format and filter the specified year's leads' data */
	updateChartWithYearData(currentYear) {
		// var currentYear = new Date().getFullYear();
		var allAnalyticsData = this.props.analyticsData;
		var currentYearLeadsData = [];
		var filteredData = [];
		let webSourceFilteredData = [];
		let callRailFilteredData = [];
		var currentYearLeads = allAnalyticsData.yearly_leads.filter(
			(element) => element.year == currentYear
		);
		if (currentYearLeads != "") {
			currentYearLeads[0].web_source.forEach((element) => {
				let leadData = {
					source: element.name,
					leadsCount: element.leads_count,
					deletedLeadsCount: element.deleted_leads_count,
					archivedLeadsCount: element.archived_leads_count,
				};
				webSourceFilteredData.push(leadData);
				currentYearLeadsData.push(leadData);
			});
			currentYearLeads[0].callrail_source.forEach((element) => {
				let leadData = {
					source: element.name,
					leadsCount: element.leads_count,
					deletedLeadsCount: element.deleted_leads_count,
					archivedLeadsCount: element.archived_leads_count,
				};
				callRailFilteredData.push(leadData);
				currentYearLeadsData.push(leadData);
			});

			/**
			 * apply filters
			 */

			/** apply web source filters */
			if (this.state.webSourceValue != "All") {
				webSourceFilteredData = currentYearLeadsData.filter((leadData) => {
					return leadData.source == this.state.webSourceValue;
				});
			}
			/** apply callrail channel filters */
			if (this.state.callRailChannelValue != "All") {
				callRailFilteredData = currentYearLeadsData.filter((leadData) => {
					return leadData.source == this.state.callRailChannelValue;
				});
			}
			/** apply ALL value filter */
			if (
				this.state.webSourceValue == "All" &&
				this.state.callRailChannelValue == "All"
			) {
				filteredData = currentYearLeadsData;
			} else {
				/** update filteredData as per web and callrail selected filters */
				if (webSourceFilteredData && webSourceFilteredData.length > 0) {
					filteredData = filteredData.concat(webSourceFilteredData);
				}
				if (callRailFilteredData && callRailFilteredData.length > 0) {
					filteredData = filteredData.concat(callRailFilteredData);
				}
			}
		}
		this.setState({
			totalLeads: (currentYearLeads[0] && currentYearLeads[0].leads_count) || 0,
		});
		this.updateCharts(
			filteredData,
			webSourceFilteredData,
			callRailFilteredData
		);
	}

	/** to format and filter the specified month leads' data */
	updateChartWithMonthData(currentYear, currentMonth) {

		var allAnalyticsData = this.props.analyticsData;
		var currentMonthLeadsData = [];
		var filteredData = [];
		let webSourceFilteredData = [];
		let callRailFilteredData = [];
		var currentMonthLeads = allAnalyticsData.monthly_leads.filter(
			(element) => element.year == currentYear && element.month == currentMonth
		);
		if (currentMonthLeads != "") {
			currentMonthLeads[0].web_source.forEach((element) => {
				let leadData = {
					source: element.name,
					leadsCount: element.leads_count,
					deletedLeadsCount: element.deleted_leads_count,
					archivedLeadsCount: element.archived_leads_count,
				};
				webSourceFilteredData.push(leadData);
				currentMonthLeadsData.push(leadData);
			});
			currentMonthLeads[0].callrail_source.forEach((element) => {
				let leadData = {
					source: element.name,
					leadsCount: element.leads_count,
					deletedLeadsCount: element.deleted_leads_count,
					archivedLeadsCount: element.archived_leads_count,
				};
				callRailFilteredData.push(leadData);
				currentMonthLeadsData.push(leadData);
			});

			/**
			 * apply filters
			 */

			/** apply web source filters */
			if (this.state.webSourceValue != "All") {
				webSourceFilteredData = currentMonthLeadsData.filter((leadData) => {
					return leadData.source == this.state.webSourceValue;
				});
			}
			/** apply callrail channel filters */
			if (this.state.callRailChannelValue != "All") {
				callRailFilteredData = currentMonthLeadsData.filter((leadData) => {
					return leadData.source == this.state.callRailChannelValue;
				});
			}
			/** apply ALL value filter */
			if (
				this.state.webSourceValue == "All" &&
				this.state.callRailChannelValue == "All"
			) {
				filteredData = currentMonthLeadsData;
			} else {
				/** update filteredData as per web and callrail selected filters */
				if (webSourceFilteredData) {
					filteredData = filteredData.concat(webSourceFilteredData);
				}
				if (callRailFilteredData) {
					filteredData = filteredData.concat(callRailFilteredData);
				}
			}

		}

		this.setState({
			totalLeads:
				(currentMonthLeads[0] && currentMonthLeads[0].leads_count) || 0,
		});
		this.updateCharts(
			filteredData,
			webSourceFilteredData,
			callRailFilteredData
		);
	}

	/** to format and filter the current week leads' data */
	// updateChartWithCurrentWeekData() {
	//   // var date = currentDate.getDate() - 1;
	//   var currentYear = new Date().getFullYear();
	//   var currentMonth = new Date().getMonth();
	//   var currentWeek = this.weekOfMonth();
	//   console.log(
	//     "Current Week " + currentYear + " - " + currentMonth + " - " + currentWeek
	//   );
	//   var allAnalyticsData = this.props.analyticsData;
	//   console.table(allAnalyticsData.weekly_leads);
	//   var currentWeekLeadsData = [];
	//   var filteredData = [];
	//   let webSourceFilteredData = [];
	//   let callRailFilteredData = [];
	//   var currentWeekLeads = allAnalyticsData.weekly_leads.filter(
	//     (element) =>
	//       element.year == currentYear &&
	//       element.month == currentMonth &&
	//       element.week == currentWeek
	//   );
	//   console.table(currentWeekLeads);
	//   if (currentWeekLeads != "") {
	//     currentWeekLeads[0].web_source.forEach((element) => {
	//       let leadData = {
	//         source: element.name,
	//         leadsCount: element.leads_count,
	//         deletedLeadsCount: element.deleted_leads_count,
	//       };
	//       webSourceFilteredData.push(leadData);
	//       currentWeekLeadsData.push(leadData);
	//     });
	//     currentWeekLeads[0].callrail_source.forEach((element) => {
	//       let leadData = {
	//         source: element.name,
	//         leadsCount: element.leads_count,
	//         deletedLeadsCount: element.deleted_leads_count,
	//       };
	//       callRailFilteredData.push(leadData);
	//       currentWeekLeadsData.push(leadData);
	//     });
	//     /**
	//      * apply filters
	//      */

	//     /** apply web source filters */
	//     if (this.state.webSourceValue != "All") {
	//       webSourceFilteredData = currentWeekLeadsData.filter((leadData) => {
	//         return leadData.source == this.state.webSourceValue;
	//       });
	//     }
	//     /** apply callrail channel filters */
	//     if (this.state.callRailChannelValue != "All") {
	//       callRailFilteredData = currentWeekLeadsData.filter((leadData) => {
	//         return leadData.source == this.state.callRailChannelValue;
	//       });
	//     }
	//     /** apply ALL value filter */
	//     if (
	//       this.state.webSourceValue == "All" &&
	//       this.state.callRailChannelValue == "All"
	//     ) {
	//       filteredData = currentWeekLeadsData;
	//     } else {
	//       /** update filteredData as per web and callrail selected filters */
	//       if (webSourceFilteredData) {
	//         filteredData = filteredData.concat(webSourceFilteredData);
	//       }
	//       if (callRailFilteredData) {
	//         filteredData = filteredData.concat(callRailFilteredData);
	//       }
	//     }
	//     console.table(webSourceFilteredData);
	//     console.table(callRailFilteredData);
	//     console.log("filtered: ");
	//     console.table(filteredData);
	//   }

	//   this.setState({
	//     totalLeads: (currentWeekLeads[0] && currentWeekLeads[0].leads_count) || 0,
	//   });
	//   this.updateCharts(
	//     filteredData,
	//     webSourceFilteredData,
	//     callRailFilteredData
	//   );
	// }

	/** to format and filter today's leads' data */
	// updateChartWithCurrentDayData() {
	//   // var currentDate = new Date().getDate() - 1;
	//   var currentDate = new Date().getDate();
	//   var currentYear = new Date().getFullYear();
	//   var currentMonth = new Date().getMonth();
	//   var currentWeek = this.weekOfMonth();
	//   console.log(
	//     "Current Date " +
	//       currentYear +
	//       " - " +
	//       currentMonth +
	//       " - " +
	//       currentWeek +
	//       " - " +
	//       currentDate
	//   );
	//   var allAnalyticsData = this.props.analyticsData;
	//   console.table(allAnalyticsData.daily_leads);
	//   var currentDayLeadsData = [];
	//   var filteredData = [];
	//   let webSourceFilteredData = [];
	//   let callRailFilteredData = [];
	//   var currentDayLeads = allAnalyticsData.daily_leads.filter(
	//     (element) =>
	//       element.year == currentYear &&
	//       element.month == currentMonth &&
	//       element.date == currentDate
	//   );
	//   console.table(currentDayLeads);
	//   if (currentDayLeads != "") {
	//     currentDayLeads[0].web_source.forEach((element) => {
	//       let leadData = {
	//         source: element.name,
	//         leadsCount: element.leads_count,
	//         deletedLeadsCount: element.deleted_leads_count,
	//       };
	//       webSourceFilteredData.push(leadData);
	//       currentDayLeadsData.push(leadData);
	//     });
	//     currentDayLeads[0].callrail_source.forEach((element) => {
	//       let leadData = {
	//         source: element.name,
	//         leadsCount: element.leads_count,
	//         deletedLeadsCount: element.deleted_leads_count,
	//       };
	//       callRailFilteredData.push(leadData);
	//       currentDayLeadsData.push(leadData);
	//     });

	//     /**
	//      * apply filters
	//      */

	//     /** apply web source filters */
	//     if (this.state.webSourceValue != "All") {
	//       webSourceFilteredData = currentDayLeadsData.filter((leadData) => {
	//         return leadData.source == this.state.webSourceValue;
	//       });
	//     }
	//     /** apply callrail channel filters */
	//     if (this.state.callRailChannelValue != "All") {
	//       callRailFilteredData = currentDayLeadsData.filter((leadData) => {
	//         return leadData.source == this.state.callRailChannelValue;
	//       });
	//     }
	//     /** apply ALL value filter */
	//     if (
	//       this.state.webSourceValue == "All" &&
	//       this.state.callRailChannelValue == "All"
	//     ) {
	//       filteredData = currentDayLeadsData;
	//     } else {
	//       /** update filteredData as per web and callrail selected filters */
	//       if (webSourceFilteredData) {
	//         filteredData = filteredData.concat(webSourceFilteredData);
	//       }
	//       if (callRailFilteredData) {
	//         filteredData = filteredData.concat(callRailFilteredData);
	//       }
	//     }
	//     console.table(webSourceFilteredData);
	//     console.table(callRailFilteredData);
	//     console.log("filtered: ");
	//     console.table(filteredData);
	//   }
	//   this.setState({
	//     totalLeads: (currentDayLeads[0] && currentDayLeads[0].leads_count) || 0,
	//   });
	//   this.updateCharts(
	//     filteredData,
	//     webSourceFilteredData,
	//     callRailFilteredData
	//   );
	// }

	/** 
	 * To format and filter last N days leads' data 
	 * Today
	 * Yesterday
	 * Last 7 days 
	 * Last 30 days
	 * Recent (Last 7 Days + Today)
	 * Custom
	*/
	updateChartWithCustomData(startYear, startMonth, startDate, endYear, endMonth, endDate){
		var allAnalyticsData = this.props.analyticsData;
		var customLeadsData = [];
		var filteredData = [];
		let webSourceFilteredData = [];
		let callRailFilteredData = [];
		if(startMonth == endMonth){
			var customLeads = null;
			customLeads = allAnalyticsData.daily_leads.filter(
				(element) =>
					( element.year == startYear && element.month == startMonth && (element.date >= startDate && element.date <= endDate))
			);
		}else{
			customLeads = allAnalyticsData.daily_leads.filter(
				(element) =>
					( element.year == startYear && element.month == startMonth && element.date >= startDate) || (element.year == endYear && element.month == endMonth && element.date <= endDate)
					
			);
		}

		var totalLeadsCount = 0;
		if (customLeads && customLeads != null) {
			for(var i = 0; i < customLeads.length; i++){
				customLeads[i].web_source.forEach((element) => {
					let leadData = {
						source: element.name,
						leadsCount: element.leads_count,
						deletedLeadsCount: element.deleted_leads_count,
						archivedLeadsCount: element.archived_leads_count,
					};
					let index = webSourceFilteredData.findIndex(lead => lead.source == leadData.source);
					if( index == -1 ){
						webSourceFilteredData.push(leadData);
					}else{
						webSourceFilteredData[index].leadsCount += leadData.leadsCount; 
						webSourceFilteredData[index].deletedLeadsCount += leadData.deletedLeadsCount; 
						webSourceFilteredData[index].archivedLeadsCount += leadData.archivedLeadsCount; 
					}
					index = customLeadsData.findIndex(lead => lead.source == leadData.source && leadData.leadsCount <= 0);
					if( index == -1 ){
						if(customLeadsData.findIndex(lead => lead.source == leadData.source) == -1){
							customLeadsData.push(leadData);
						}
					}else{
						customLeadsData[index].leadsCount += leadData.leadsCount; 
						customLeadsData[index].deletedLeadsCount += leadData.deletedLeadsCount; 
						customLeadsData[index].archivedLeadsCount += leadData.archivedLeadsCount; 
					}
				});
				customLeads[i].callrail_source.forEach((element) => {
					let leadData = {
						source: element.name,
						leadsCount: element.leads_count,
						deletedLeadsCount: element.deleted_leads_count,
						archivedLeadsCount: element.archived_leads_count,
					};
					let index = callRailFilteredData.findIndex(lead => lead.source == leadData.source);
					if( index == -1 ){
						callRailFilteredData.push(leadData);
					}else{
						callRailFilteredData[index].leadsCount += leadData.leadsCount; 
						callRailFilteredData[index].deletedLeadsCount += leadData.deletedLeadsCount; 
						callRailFilteredData[index].archivedLeadsCount += leadData.archivedLeadsCount; 
					}
					index = customLeadsData.findIndex(lead => lead.source == leadData.source && leadData.leadsCount <= 0);
					if( index == -1 ){
						if(customLeadsData.findIndex(lead => lead.source == leadData.source) == -1){
							customLeadsData.push(leadData);
						}
					}else{
						customLeadsData[index].leadsCount += leadData.leadsCount; 
						customLeadsData[index].deletedLeadsCount += leadData.deletedLeadsCount; 
						customLeadsData[index].archivedLeadsCount += leadData.archivedLeadsCount; 
					}
				});

				/* update totalLeads count acc to del and arch selection **/
				if (this.state.deletedChecked == false && this.state.archivedChecked == false) {
					totalLeadsCount += customLeads[i].leads_count - customLeads[i].deleted_leads_count - customLeads[i].archived_leads_count;
				}else if (this.state.deletedChecked == true && this.state.archivedChecked == true) {
					totalLeadsCount += customLeads[i].leads_count;
				}else if (this.state.deletedChecked == false && this.state.archivedChecked == true) {
					totalLeadsCount += customLeads[i].leads_count - customLeads[i].deleted_leads_count;
				}else if (this.state.deletedChecked == true && this.state.archivedChecked == false) {
					totalLeadsCount += customLeads[i].leads_count - customLeads[i].archived_leads_count;
				}
			}
			/**
			 * apply filters
			 */

			/** apply web source filters */
			if (this.state.webSourceValue != "All") {
				webSourceFilteredData = customLeadsData.filter((leadData) => {
					return leadData.source == this.state.webSourceValue;
				});
			}
			/** apply callrail channel filters */
			if (this.state.callRailChannelValue != "All") {
				callRailFilteredData = customLeadsData.filter((leadData) => {
					return leadData.source == this.state.callRailChannelValue;
				});
			}
			
			/** apply ALL value filter */
			if (this.state.webSourceValue == "All" && this.state.callRailChannelValue == "All") {
				filteredData = customLeadsData;
			} else {
				/** update filteredData as per web and callrail selected filters */
				if (webSourceFilteredData) {
					filteredData = filteredData.concat(webSourceFilteredData);
				}
				if (callRailFilteredData) {
					filteredData = filteredData.concat(callRailFilteredData);
				}
			}
		}
		this.setState({
			totalLeads: totalLeadsCount || 0,
		});
		this.updateCharts(
			filteredData,
			webSourceFilteredData,
			callRailFilteredData
		);
	}

	/** to update the charts with filtered data stats */
	updateCharts(filteredData, webSourceFilteredData, callRailFilteredData) {
		let tempCallRailCount = 0;
		let tempWebSourceCount = 0;

		if(this.state.deletedChecked == false && this.state.archivedChecked == false ){
			/** doesn't include deleted leads and archived leads */
			this.setState(
				(this.state.chartData.labels = filteredData
					.filter(function (leadData) {
						if(leadData.source.toString().trim().length === 0){
							leadData.source = "Empty";
						}
						return leadData.leadsCount - leadData.deletedLeadsCount - leadData.archivedLeadsCount > 0 && leadData.source != null;
					})
					.map((data) => data.source))
			);
			this.setState(
				(this.state.chartData.datasets[0].data = filteredData
					.filter(function (leadData) {
						return leadData.leadsCount - leadData.deletedLeadsCount - leadData.archivedLeadsCount > 0 && leadData.source != null;
					})
					.map((data) => data.leadsCount - data.deletedLeadsCount - data.archivedLeadsCount))
			);
			for (var i = 0; i < webSourceFilteredData.length; i++) {
				if(webSourceFilteredData[i].source != null){
					tempWebSourceCount +=
						webSourceFilteredData[i].leadsCount -
						webSourceFilteredData[i].deletedLeadsCount - webSourceFilteredData[i].archivedLeadsCount;
				}
			}
			for (var i = 0; i < callRailFilteredData.length; i++) {
				if(callRailFilteredData[i].source != null){
					tempCallRailCount +=
						callRailFilteredData[i].leadsCount -
						callRailFilteredData[i].deletedLeadsCount - callRailFilteredData[i].archivedLeadsCount;
				}
			}
		}else if(this.state.deletedChecked == true && this.state.archivedChecked == true ){
			/** include deleted leads and archived leads */
			this.setState(
				(this.state.chartData.labels = filteredData
					.filter(function(leadData){
						if(leadData.source.toString().trim().length === 0){
							leadData.source = "Empty";
						}
						return leadData.source != null;
					})
					.map((data) => data.source))
			);
			this.setState(
				(this.state.chartData.datasets[0].data = filteredData
					.filter(function(leadData){
						return leadData.source != null;
					})
					.map((data) => data.leadsCount
				))
			);

			for (var i = 0; i < webSourceFilteredData.length; i++) {
				if(webSourceFilteredData[i].source != null){
					tempWebSourceCount += webSourceFilteredData[i].leadsCount;
				}
			}
			for (var i = 0; i < callRailFilteredData.length; i++) {
				if(callRailFilteredData[i].source != null){
					tempCallRailCount += callRailFilteredData[i].leadsCount;
				}
			}
		}else if(this.state.deletedChecked == true && this.state.archivedChecked == false ){
			/** include archived leads, not deleted */
			this.setState(
				(this.state.chartData.labels = filteredData
					.filter(function (leadData) {
						if(leadData.source.toString().trim().length === 0){
							leadData.source = "Empty";
						}
						return leadData.leadsCount - leadData.archivedLeadsCount > 0 && leadData.source != null;
					})
					.map((data) => data.source))
			);
			this.setState(
				(this.state.chartData.datasets[0].data = filteredData
					.filter(function (leadData) {
						return leadData.leadsCount - leadData.archivedLeadsCount > 0 && leadData.source != null;
					})
					.map((data) => data.leadsCount - data.archivedLeadsCount))
			);
			for (var i = 0; i < webSourceFilteredData.length; i++) {
				if(webSourceFilteredData[i].source != null){
					tempWebSourceCount +=
						webSourceFilteredData[i].leadsCount - webSourceFilteredData[i].archivedLeadsCount;
				}
			}
			for (var i = 0; i < callRailFilteredData.length; i++) {
				if(callRailFilteredData[i].source != null){
					tempCallRailCount +=
						callRailFilteredData[i].leadsCount - callRailFilteredData[i].archivedLeadsCount;
				}
			}
		}else if(this.state.deletedChecked == false && this.state.archivedChecked == true ){
			this.setState(
				(this.state.chartData.labels = filteredData
					.filter(function (leadData) {
						if(leadData.source.toString().trim().length === 0){
							leadData.source = "Empty";
						}
						return leadData.leadsCount - leadData.deletedLeadsCount > 0 && leadData.source != null;
					})
					.map((data) => data.source))
			);
			this.setState(
				(this.state.chartData.datasets[0].data = filteredData
					.filter(function (leadData) {
						return leadData.leadsCount - leadData.deletedLeadsCount > 0 && leadData.source != null;
					})
					.map((data) => data.leadsCount - data.deletedLeadsCount))
			);
			for (var i = 0; i < webSourceFilteredData.length; i++) {
				if(webSourceFilteredData[i].source != null){
					tempWebSourceCount +=
						webSourceFilteredData[i].leadsCount -
						webSourceFilteredData[i].deletedLeadsCount;
				}
			}
			for (var i = 0; i < callRailFilteredData.length; i++) {
				if(callRailFilteredData[i].source != null){
					tempCallRailCount +=
						callRailFilteredData[i].leadsCount -
						callRailFilteredData[i].deletedLeadsCount;
				}
			}
		}
		var tempTotalLeads = 0;
		if(this.state.webSourceValue == "None" && this.state.callRailChannelValue == "None"){
			tempTotalLeads = 0;
		}else{
			if(tempWebSourceCount > tempCallRailCount){
				tempTotalLeads = tempWebSourceCount;
			}else{
				tempTotalLeads = tempCallRailCount;
			}
		}

		this.setState({ totalLeads: tempTotalLeads});
		this.setState({ totalCallrailLeads: tempCallRailCount });
		this.setState({ totalWebSourceLeads: tempWebSourceCount });
	}

	weekOfMonth(currentDate = new Date()) {
		var date = moment(currentDate); //saturday
		var day = date.day(); //6 = saturday
		return Math.ceil(date.date() / 7); //1
	}

	handleTimeRangeFocus = (event) => {
		this.setState({ prevTimeRangeValue: this.state.timeRangeValue });
	}

	handleTimeRangeChange = (event) => {
		// this.setState({ showCustomDateRange: false});
		if (event.target) {
			this.setState({ timeRangeValue: event.target.value });
		} else {
			this.setState({ timeRangeValue: event });
		}
		if ((event.target && event.target.value != "Custom") || event != "Custom" && event != "custom-dates") {
			this.state.showCustomDateRange = false;
			this.setState({ customStartDate: null});
			this.setState({ customEndDate: null});
			this.setState({ customFilterText: "Custom"});
		}
		if ((event.target && event.target.value == "This Month") || event == "This Month") {
			let currentYear = new Date().getFullYear();
			let currentMonth = new Date().getMonth();
			this.updateChartWithMonthData(currentYear, currentMonth);
		} else if ((event.target && event.target.value == "Last Month") || event == "Last Month") {
			var tempDateObj = new Date();
			if(tempDateObj.getMonth() == 0) {
				tempDateObj.setYear(tempDateObj.getFullYear() - 1);
				tempDateObj.setMonth(11);
			}else{
				tempDateObj.setMonth(tempDateObj.getMonth() - 1);
			}
			this.updateChartWithMonthData(tempDateObj.getFullYear(), tempDateObj.getMonth());
		} else if ((event.target && event.target.value == "This Year") || event == "This Year") {
			this.updateChartWithYearData(new Date().getFullYear());
		} else if ((event.target && event.target.value == "Last Year") || event == "Last Year") {
			this.updateChartWithYearData(new Date().getFullYear() - 1);
		} else if ((event.target && event.target.value == "Last 7 Days") || event == "Last 7 Days") {
			let todayDate = new Date();
			let prevDate = new Date();
			todayDate.setDate(todayDate.getDate() - 1);
			prevDate.setDate(prevDate.getDate() - 7);
			let endDate = todayDate.getDate();
			let endMonth = todayDate.getMonth();
			let endYear = todayDate.getFullYear();
			let startDate = prevDate.getDate();
			let startMonth = prevDate.getMonth();
			let startYear = prevDate.getFullYear();
			this.updateChartWithCustomData(startYear, startMonth, startDate, endYear, endMonth, endDate);
		} else if ((event.target && event.target.value == "Last 30 Days") || event == "Last 30 Days") {
			let todayDate = new Date();
			let prevDate = new Date();
			prevDate.setDate(prevDate.getDate() - 29);
			let endDate = todayDate.getDate();
			let endMonth = todayDate.getMonth();
			let endYear = todayDate.getFullYear();
			let startDate = prevDate.getDate();
			let startMonth = prevDate.getMonth();
			let startYear = prevDate.getFullYear();
			this.updateChartWithCustomData(startYear, startMonth, startDate, endYear, endMonth, endDate);
		} else if ((event.target && event.target.value == "Recent (Last 7 Days + Today)") || event == "Recent (Last 7 Days + Today)") {
			let todayDate = new Date();
			let prevDate = new Date();
			prevDate.setDate(prevDate.getDate() - 7);
			let endDate = todayDate.getDate();
			let endMonth = todayDate.getMonth();
			let endYear = todayDate.getFullYear();
			let startDate = prevDate.getDate();
			let startMonth = prevDate.getMonth();
			let startYear = prevDate.getFullYear();
			this.updateChartWithCustomData(startYear, startMonth, startDate, endYear, endMonth, endDate);
		} else if ((event.target && event.target.value == "Yesterday") || event == "Yesterday") {
			let todayDate = new Date();
			todayDate.setDate(todayDate.getDate() - 1);
			let endDate = todayDate.getDate();
			let endMonth = todayDate.getMonth();
			let endYear = todayDate.getFullYear();
			let startDate = todayDate.getDate();
			let startMonth = todayDate.getMonth();
			let startYear = todayDate.getFullYear();
			this.updateChartWithCustomData(startYear, startMonth, startDate, endYear, endMonth, endDate);
		} else if ((event.target && event.target.value == "Today") || event == "Today") {
			let todayDate = new Date();
			let endDate = todayDate.getDate();
			let endMonth = todayDate.getMonth();
			let endYear = todayDate.getFullYear();
			let startDate = todayDate.getDate();
			let startMonth = todayDate.getMonth();
			let startYear = todayDate.getFullYear();
			this.updateChartWithCustomData(startYear, startMonth, startDate, endYear, endMonth, endDate);
		} else if ((event.target && event.target.value == "All") || event == "All") {
			this.updateChartWithAllTimeData();
		} else if ((event.target && event.target.value == "Custom") || event == "Custom") {
			this.setState({ customStartDate: null});
			this.setState({ customEndDate: null});
			this.customFilterText = "Custom";
			this.state.showCustomDateRange = true;
		}else{
			let endDate = this.state.customEndDate.getDate();
			let endMonth = this.state.customEndDate.getMonth();
			let endYear = this.state.customEndDate.getFullYear();
			let startDate = this.state.customStartDate.getDate();
			let startMonth = this.state.customStartDate.getMonth();
			let startYear = this.state.customStartDate.getFullYear();
			this.updateChartWithCustomData(startYear, startMonth, startDate, endYear, endMonth, endDate);
		}
	};

	handleWebSourceChange = (event) => {
		this.setState({ showCustomDateRange: false});
		this.state.webSourceValue = event.target.value;
		if(this.state.timeRangeValue == "Custom"){
			this.handleTimeRangeChange("custom-dates");
		}else{
			this.handleTimeRangeChange(this.state.timeRangeValue);
		}  
	};

	handleCllrailChannelChange = (event) => {
		this.setState({ showCustomDateRange: false});
		this.state.callRailChannelValue = event.target.value;
		if(this.state.timeRangeValue == "Custom"){
			this.handleTimeRangeChange("custom-dates");
		}else{
			this.handleTimeRangeChange(this.state.timeRangeValue);
		}    
	};

	render() {
		return (
			<React.Fragment>
				<div className="analytics-container">
					<p className="analytics-heading">Lead Management System Stats</p>
					<div className="filters-heading">
						I want to see data for &nbsp;
						<select
							className="select filters-drop-down"
							onChange={this.handleTimeRangeChange}
							onFocus={this.handleTimeRangeFocus}
							key={"time_filter" + "date"}
							value={this.state.timeRangeValue}
						>
							<option value="Custom">{this.state.customFilterText}</option>
							<option value="Recent (Last 7 Days + Today)">Recent (Last 7 Days + Today)</option>
							<option value="Today">Today</option>
							<option value="All">All</option>
							<option value="Yesterday">Yesterday</option>
							<option value="Last 7 Days">Last 7 Days</option>
							<option value="Last 30 Days">Last 30 Days</option>
							<option value="This Month">This Month</option>
							<option value="Last Month">Last Month</option>
							<option value="This Year">This Year</option>
							<option value="Last Year">Last Year</option>
						</select>
						&nbsp;&nbsp;&nbsp;but from this phone number&nbsp;&nbsp;&nbsp;
						<select
							className="select filters-drop-down"
							onChange={this.handleCllrailChannelChange}
							key={"time_filter" + "callrail"}
							value={this.state.callRailChannelValue}
						>
							<option value="All">All Callrail Numbers</option>
							<option value="None">None</option>
							<option value="+12144735858">214-473-5858</option>
							<option value="+19729925600">972-992-5600</option>
							<option value="+12143010822">214-301-0822</option>
							<option value="+19726452920">972-645-2920</option>
							<option value="+19727369299">972-736-9299</option>
							<option value="+12142259001">214-225-9001</option>
							<option value="+12148844205">214-884-4205</option>
							<option value="+16146650375">614-665-0375</option>
							<option value="+19722009388">972-200-9388</option>
							<option value="+12149353924">214-935-3924</option>
							<option value="+12147314382">214-731-4382</option>
							<option value="+12149974628">214-997-4628</option>
							<option value="+12142208897">214-220-8897</option>
							<option value="+19722841817">972-284-1817</option>
							<option value="+19724497759">972-449-7759</option>
							<option value="+19728468765">972-846-8765</option>
							<option value="+19728958120">972-895-8120</option>
						</select>
						&nbsp;&nbsp;&nbsp;and&nbsp;&nbsp;&nbsp;
						<select
							className="select filters-drop-down"
							onChange={this.handleWebSourceChange}
							key={"time_filter" + "web"}
							value={this.state.webSourceValue}
						>
							<option value="All">All Web Sources</option>
							<option value="None">None</option>
							{
								this.props.leadWebSourceData && this.props.leadWebSourceData.map((item, index) => {
										return (
											<option key={item.name + index} value={item.source}>{item.source}</option>
										)
								})
							}
						</select>
						<br />
						{ this.state.showCustomDateRange ? 
							<div>
								<span style={{visibility: 'hidden'}}>I want to see data for &nbsp;</span>
								<DatePicker
									datesChanged = { (startDate, endDate) => {
										var tempStartDate = new Date(startDate);
										var tempEndDate = new Date(endDate);
										if(tempStartDate.getFullYear() != 1970){
											this.setState({ customStartDate: tempStartDate});
										}
										if(tempEndDate.getFullYear() != 1970){
											this.setState({ customEndDate: tempEndDate});                        
										}
										const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
										
										if(tempStartDate.getFullYear() != 1970 && tempEndDate.getFullYear() != 1970){
											this.state.customFilterText = 
											monthNames[tempStartDate.getMonth()] + " " + tempStartDate.getDate() +", " +  tempStartDate.getFullYear()
											+ " - " +
											monthNames[tempEndDate.getMonth()] + " " + tempEndDate.getDate() +", " +  tempEndDate.getFullYear();
											// update chart for custom
											let endDate = tempEndDate.getDate();
											let endMonth = tempEndDate.getMonth();
											let endYear = tempEndDate.getFullYear();
											let startDate = tempStartDate.getDate();
											let startMonth = tempStartDate.getMonth();
											let startYear = tempStartDate.getFullYear();
											this.updateChartWithCustomData(startYear, startMonth, startDate, endYear, endMonth, endDate);
										}
									}}
									hideComponent = {() => {
										if(this.state.customFilterText == "Custom" && this.state.timeRangeValue == "Custom" && (this.state.customStartDate == null || this.state.customEndDate == null)){
											this.handleTimeRangeChange("All");
										}
										this.setState({ showCustomDateRange: false});
									}}
								/> 
							</div>
							:
							null 
						}
					</div>
					{
						this.state.showCustomDateRange ?
							null 
						: 
							<div className="filters-heading text-center">
								<p className="deleted-check-box">
									<input
										type="checkbox"
										name="deleted"
										value="deleted"
										label="deleted"
										checked={this.state.deletedChecked}
										onChange={this.toggleDeletedLeadsCheckbox}
									/>
									&nbsp;Include Deleted Leads
								</p>
								<p className="archived-check-box">
									<input
										type="checkbox"
										name="archived"
										value="archived"
										label="archived"
										checked={this.state.archivedChecked}
										onChange={this.toggleArchivedLeadsCheckbox}
									/>
									&nbsp;Include Archived Leads
								</p>
							</div>
					}
					<div className="divider"></div>

					<div className="stats-row">
						<div className="stats-box">
							<p className="stats-heading">Total Leads</p>
							<p className="stats-numbers">{this.state.totalLeads}</p>
						</div>

						<div className="stats-box">
							<p className="stats-heading">Total Callrail Leads</p>
							<p className="stats-numbers">{this.state.totalCallrailLeads}</p>
						</div>

						<div className="stats-box">
							<p className="stats-heading">Total Websource Leads</p>
							<p className="stats-numbers">{this.state.totalWebSourceLeads}</p>
						</div>
					</div>

					<div className="charts-row">
						<div className="box-1">
							<div className="box-1-chart" style={{height:"55vh", position:"relative", marginBottom:"5%", padding:"5%"}}>
								<BarChart chartData={this.state.chartData} />
							</div>
						</div>
						<div className="box-2">
							<div className="box-2-chart" style={{height:"55vh", position:"relative", marginBottom:"5%", padding:"5%"}}>
								<PieChart chartData={this.state.chartData} />
							</div>
						</div>
					</div>
					
					<div className="stats-note-heading">
						The LMS Analytics data is shown from the date 10th of November, 2022.
					</div>
				</div>
			</React.Fragment>
		)
	}
}

export default AnalyticsScreen;
