import React from "react";
import Loader from "react-loader-spinner";

import "./sequenceDetails.css";

function SequenceDetails({ setShowDetails, SequenceDetails }) {
  return SequenceDetails ? (
    <div className='Sequence-details-main-container'>
      <p>Sequence</p>
      <h3>Request for Further details</h3>
      <hr />
      {/* 1 */}
      <div className='tile-outer-container'>
        <div className='number-container'>
          <p>1</p>
          {SequenceDetails.templateTwo &&
            SequenceDetails.templateTwoAfter !== 0 && (
              <div className='number-container-line2'></div>
            )}
        </div>
        <div className='tile-container'>
          <h5 className='sequence-number-container-p'>
            <u>Start</u>
          </h5>
          <h3>
            {SequenceDetails.templateOne
              ? SequenceDetails.templateOne.label
                ? SequenceDetails.templateOne.label
                : ""
              : ""}
          </h3>
          <div className='description-container'>
            <p>
              {SequenceDetails.templateOne
                ? SequenceDetails.templateOne.value
                  ? SequenceDetails.templateOne.value
                  : ""
                : ""}
            </p>
          </div>
          <div className='creator-container'>
            {SequenceDetails.templateOne &&
              SequenceDetails.templateOne.attachment &&
              SequenceDetails.templateOne.attachment.file_name &&
              SequenceDetails.templateOne.attachment.attachment_link && (
                <div className='creator-outer-border'>
                  <img src={require("../../../../../assets/images/link.png")} />
                  <p>
                    <u>{SequenceDetails.templateOne.attachment.file_name}</u>
                  </p>
                </div>
              )}
            <div className='creator-outer-border'>
              <img src={require("../../../../../assets/images/user.png")} />
              <p>
                {SequenceDetails.templateOne.name
                  ? SequenceDetails.templateOne.name
                  : ""}
              </p>
            </div>
          </div>
          {SequenceDetails.templateTwo &&
            SequenceDetails.templateTwoAfter !== 0 && <hr />}
        </div>
      </div>
      {/* 2 */}
      {SequenceDetails.templateTwo &&
        SequenceDetails.templateTwoAfter !== 0 && (
          <div className='tile-outer-container'>
            <div className='number-container'>
              <p>2</p>
              {SequenceDetails.templateThree &&
                SequenceDetails.templateThreeAfter !== 0 && (
                  <div className='number-container-line2'></div>
                )}
            </div>
            <div className='tile-container'>
              <div className='sequence-number-container'>
                <img
                  src={require("../../../../../assets/images/calendar.png")}
                />
                <p
                  style={{ fontFamily: "Gilroy Semi-Bold", fontSize: "1.3rem" }}
                >
                  <u>
                    After{" "}
                    {SequenceDetails.templateTwoAfter
                      ? SequenceDetails.templateTwoAfter
                      : ""}
                    {" day(s)"}
                  </u>
                </p>
              </div>
              <h3>
                {SequenceDetails.templateOne
                  ? SequenceDetails.templateTwo.label
                    ? SequenceDetails.templateTwo.label
                    : ""
                  : ""}
              </h3>
              <div className='description-container'>
                <p>
                  {SequenceDetails.templateTwo
                    ? SequenceDetails.templateTwo.value
                      ? SequenceDetails.templateTwo.value
                      : ""
                    : ""}
                </p>
              </div>
              <div className='creator-container'>
                {SequenceDetails.templateTwo &&
                  SequenceDetails.templateTwo.attachment &&
                  SequenceDetails.templateTwo.attachment.file_name &&
                  SequenceDetails.templateTwo.attachment.attachment_link && (
                    <div className='creator-outer-border'>
                      <img
                        src={require("../../../../../assets/images/link.png")}
                      />
                      <p>
                        <u>
                          {SequenceDetails.templateTwo.attachment.file_name}
                        </u>
                      </p>
                    </div>
                  )}
                <div className='creator-outer-border'>
                  <img src={require("../../../../../assets/images/user.png")} />
                  <p>
                    {SequenceDetails.templateTwo.name
                      ? SequenceDetails.templateTwo.name
                      : ""}
                  </p>
                </div>
              </div>
              {SequenceDetails.templateThree &&
                SequenceDetails.templateThreeAfter !== 0 && <hr />}
            </div>
          </div>
        )}
      {/* 3 */}
      {SequenceDetails.templateThree &&
        SequenceDetails.templateThreeAfter !== 0 && (
          <div className='tile-outer-container'>
            <div className='number-container'>
              <p>3</p>
            </div>
            <div className='tile-container'>
              <div className='sequence-number-container'>
                <img
                  src={require("../../../../../assets/images/calendar.png")}
                />
                <p
                  style={{ fontFamily: "Gilroy Semi-Bold", fontSize: "1.3rem" }}
                >
                  <u>
                    After{" "}
                    {SequenceDetails.templateThreeAfter
                      ? SequenceDetails.templateThreeAfter
                      : ""}
                    {" day(s)"}
                  </u>
                </p>
              </div>
              <h3>
                {SequenceDetails.templateThree
                  ? SequenceDetails.templateThree.label
                    ? SequenceDetails.templateThree.label
                    : ""
                  : ""}
              </h3>
              <div className='description-container'>
                <p>
                  {SequenceDetails.templateThree
                    ? SequenceDetails.templateThree.value
                      ? SequenceDetails.templateThree.value
                      : ""
                    : ""}
                </p>
              </div>
              <div className='creator-container'>
                {SequenceDetails.templateThree &&
                  SequenceDetails.templateThree.attachment &&
                  SequenceDetails.templateThree.attachment.file_name &&
                  SequenceDetails.templateThree.attachment.attachment_link && (
                    <div className='creator-outer-border'>
                      <img
                        src={require("../../../../../assets/images/link.png")}
                      />
                      <p>
                        <u>
                          {SequenceDetails.templateThree.attachment.file_name}
                        </u>
                      </p>
                    </div>
                  )}
                <div className='creator-outer-border'>
                  <img src={require("../../../../../assets/images/user.png")} />
                  <p>
                    {SequenceDetails.templateThree.name
                      ? SequenceDetails.templateThree.name
                      : ""}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      <button onClick={() => setShowDetails(false)}>
        <div>
          <img
            src={require("../../../../../assets/images/go-back(white).png")}
          />
          <p>Back to Schedule</p>
        </div>
      </button>
    </div>
  ) : (
    <div className='seuqneceLoader'>
      <Loader
        className='modalLoader'
        type='Oval'
        color='black'
        height={38}
        width={38}
      />
    </div>
  );
}

export default SequenceDetails;
