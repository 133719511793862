import {
	FETCH_ALL,
	SEARCH,
	DELETE,
	START_LOADING,
	END_LOADING,
	DELETED_TRUE,
	UPDATED_TRUE,
	UPDATE,
	UPDATED_FALSE,
} from "./mediaActionTypes";
import * as api from "../../../api/api";

export const getMediaFiles = (page) => async (dispatch) => {
	try {
		dispatch({ type: START_LOADING });
		const {
			data: { data, currentPage, numberOfPages, totalItems },
		} = await api.getAllDocuments(Number(page));
		dispatch({
			type: FETCH_ALL,
			payload: { data, currentPage, numberOfPages, totalItems },
		});
		dispatch({ type: END_LOADING });
	} catch (error) {
		console.log(error);
	}
};

export const searchMediaFiles = (page, searchText) => async (dispatch) => {
	try {
		dispatch({ type: START_LOADING });
		const {
			data: { data, currentPage, numberOfPages, totalItems },
		} = await api.searchDocuments(Number(page), { searchText: searchText });
		const size = await data.length;
		dispatch({
			type: SEARCH,
			payload: {
				data,
				size,
				currentPage,
				numberOfPages,
				totalItems,
			},
		});
		dispatch({ type: END_LOADING });
	} catch (error) {
		console.log(error);
	}
};

export const getUserMediaFiles = (page, userId) => async (dispatch) => {
	try {
		dispatch({ type: START_LOADING });
		const {
			data: { data, currentPage, numberOfPages, totalItems },
		} = await api.getSpecificUserDocuments(Number(page), {
			userId: userId,
		});
		dispatch({
			type: FETCH_ALL,
			payload: { data, currentPage, numberOfPages, totalItems },
		});
		dispatch({ type: END_LOADING });
	} catch (error) {
		console.log(error);
		dispatch({ type: END_LOADING });
	}
};

export const searchUserMediaFiles =
	(userId, page, searchText) => async (dispatch) => {
		try {
			dispatch({ type: START_LOADING });
			const {
				data: { data, currentPage, numberOfPages, totalItems },
			} = await api.searchSpecificUserDocuments(Number(page), {
				userId: userId,
				searchText: searchText,
			});
			const size = await data.length;
			dispatch({
				type: SEARCH,
				payload: {
					data,
					size,
					currentPage,
					numberOfPages,
					totalItems,
				},
			});
			dispatch({ type: END_LOADING });
		} catch (error) {
			console.log(error);
		}
	};

export const handleUpdateName = (id, updatedData) => async (dispatch) => {
	try {
		const { status, data } = await api.updateDocumentName(id, updatedData);
		if (status === 200) {
			dispatch({ type: UPDATED_TRUE });
			dispatch({ type: UPDATE, payload: data.data });
		}
	} catch (error) {
		dispatch({ type: UPDATED_FALSE });
		console.log(error.message);
	}
};

export const deletePost = (id) => async (dispatch) => {
	try {
		const { status } = await api.deleteDocument(id);
		if (status === 200) {
			dispatch({ type: DELETED_TRUE });
			dispatch({ type: DELETE, payload: id });
		}
	} catch (error) {
		console.log(error.message);
	}
};
