import {
	FETCH_LEADS,
	FETCH_LEADS_FAILED,
	ADD_SMS_TEMPLATES,
	ADD_EMAIL_TEMPLATES,
	ADD_EMAIL_SELECTED_TEMPLATES,
	ADD_SMS_SELECTED_TEMPLATES,
	SELECTED_FILTER_OPTION,
	FILTER_LABEL,
	FILTERED_EMAIL_TEMPS,
	FILTER_SMS_LABEL,
	FILTERED_SMS_TEMPS,
	SELECTED_FILTER_SMS_OPTION,
	USER_DATA,
	FILTER_DATA,
	REMOVE_FILTER_DATA,
	FILTER_TRIGGER,
} from "./actions";

const initialState = {
	_filterTrigger: 0,
	_allLeads: [],
	_userData: ["name"],
	_filterData: [],
	_emailTemplates: [],
	_smsTemplates: [],
	_emailSelectedTemplates: [],
	_smsSelectedTemplates: [],
	_selectedOption: "",
	_selectedSmsOption: "",
	_filterLabel: "",
	_filterSmsLabel: "",
	_filteredEmailTemps: [],
	_filteredSmsTemps: [],

	// Add a templates property to the state
};

function reducer(state = initialState, action) {
	switch (action.type) {
		case FETCH_LEADS:
			return {
				number: state.number + 1,
			};
		case FETCH_LEADS_FAILED:
			return {
				...state,
				_emailTemplates: action.payload,
			};
		case USER_DATA:
			return {
				...state,
				_userData: action.payload,
			};

		case FILTER_DATA:
			return {
				...state,
				_filterData: action.payload, // Update _filterData state with the payload
			};
		case REMOVE_FILTER_DATA:
			return {
				...state,
				_filterData: [], // Update _filterData state with the payload
			};

		case FILTER_TRIGGER:
			return {
				...state,
				_filterTrigger: action.payload, // Update _filterData state with the payload
			};
		// case FILTER_DATA:
		// 	console.log("payload", action.payload);
		// 	return {
		// 		_filterData: action.payload,
		// 	};
		case ADD_EMAIL_TEMPLATES:
			return {
				...state,
				_emailTemplates: action.payload, // Update the templates state with the payload
			};
		case ADD_SMS_TEMPLATES:
			return {
				...state,
				_smsTemplates: action.payload, // Update the templates state with the payload
			};
		case ADD_EMAIL_SELECTED_TEMPLATES:
			return {
				...state,
				_emailSelectedTemplates: action.payload, // Update the templates state with the payload
			};
		case ADD_SMS_SELECTED_TEMPLATES:
			return {
				...state,
				_smsSelectedTemplates: action.payload, // Update the templates state with the payload
			};
		case SELECTED_FILTER_OPTION:
			return {
				...state,
				_selectedOption: action.payload,
			};

		case SELECTED_FILTER_SMS_OPTION:
			return {
				...state,
				_selectedSmsOption: action.payload,
			};
		case FILTER_LABEL:
			return {
				...state,
				_filterLabel: action.payload,
			};
		case FILTER_SMS_LABEL:
			return {
				...state,
				_filterSmsLabel: action.payload,
			};
		case FILTERED_EMAIL_TEMPS:
			return {
				...state,
				_filteredEmailTemps: action.payload,
			};
		case FILTERED_SMS_TEMPS:
			return {
				...state,
				_filteredSmsTemps: action.payload,
			};
		default:
			return state;
	}
}
export default reducer;
