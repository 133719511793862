import refferal from "../assets/images/referal_for_message.png";
import refreview from "../assets/images/review-for-text-message.png";
// import refreview from "../assets/images/refreview.png";
// import refferal from "../assets/images/refferal.png";
import orangeIcon from "../assets/images/orangeicon.png";
import sentCheckIcon from "../assets/images/sentCheck.png";
import "../Home/Home.css";

import { Button } from "@material-ui/core";
import React, { Component, useState } from "react";

function RefReview({ val, lead, callback, noCallback }) {
  const [refReviewType, setRefReviewType] = useState("review");
  const [phone_number, setPhone_number] = useState(
    localStorage.getItem("phone")
  );
  const [test_receiver, setTest_receiver] = useState(null);
  const [testingStatus, setTestingStatus] = useState(false);

  return (
    <div className='react-confirm-alert-body'>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div className='heading-cont-refModal'>
          <h4>Do you want to send Review/Referral?</h4>

          <span> Please select your option.</span>
          <h6>{val}</h6>
        </div>
        <div className='refRev-tester-container'>
          <div className='refRev-checkbox-container'>
            <input
              type='checkbox'
              style={{
                fontSize: 16,
                height: 20,
                width: 20,
                alignSelf: "center",
                textAlign: "center",
                backgroundColor: "gray",
                marginTop: -2,
              }}
              onChange={(e) => {
                if (e.target.checked) {
                  setTestingStatus(true);
                  setTest_receiver(phone_number);
                } else {
                  setTestingStatus(false);
                  setTest_receiver(null);
                }
              }}
            />
            <p className='tester-label'>Test Receiver</p>
          </div>
        </div>
      </div>
      <div>
        <div
          style={{
            marginTop: 27,
          }}
        >
          <Button
            style={{
              marginLeft: 11,
              borderStyle: "solid",
              borderWidth: 2,
              borderRadius: 7,
              height: 43,
              width: 126,
              borderColor: refReviewType == "review" ? "#53DBA7" : "gray",
            }}
            onClick={
              () => setRefReviewType("review")
              // this.setState({refReviewType:"review"})
            }
          >
            {/* <h6>{refReviewType}</h6> */}
            {lead.auto_review_count > 0 ? (
              <img
                style={{
                  width: 10,
                  height: 10,
                  top: -2,
                  right: -5,
                  position: "absolute",
                }}
                src={sentCheckIcon}
              />
            ) : null}
            <img
              style={{
                cursor: "pointer",
                width: "28px",
                height: "28px",
                marginRight: 7,
                marginBottom: 1,
              }}
              src={refreview}
            />
            Review{" "}
          </Button>

          <Button
            style={{
              marginLeft: 11,
              borderStyle: "solid",
              borderWidth: 2,
              borderRadius: 7,
              height: 43,
              width: 126,
              borderColor: refReviewType == "referral" ? "#53DBA7" : "gray",
            }}
            onClick={
              () => setRefReviewType("referral")
              // this.setState({refReviewType:"referral"})
            }
          >
            {lead.auto_referral_count > 0 ? (
              <img
                style={{
                  width: 10,
                  height: 10,
                  top: -2,
                  right: -5,
                  position: "absolute",
                }}
                src={orangeIcon}
              />
            ) : null}
            <img
              style={{
                cursor: "pointer",
                width: "28px",
                height: "28px",
                marginRight: 7,
                marginBottom: 1,
              }}
              src={refferal}
            />
            Referral
          </Button>
        </div>
        <div className='refRev-tester-container'>
          {testingStatus && (
            <input
              onChange={(val) => {
                setTest_receiver(val.target.value);
              }}
              value={test_receiver}
              aria-label='minimum height'
              className='test-receiver-textarea'
              placeholder='Enter receiver'
            />
          )}
        </div>

        <div
          className='react-confirm-alert-button-group'
          style={{
            marginTop: 13,
          }}
        >
          <Button
            disabled={!test_receiver && testingStatus}
            style={{
              marginLeft: 14,
              backgroundColor: "#333333",
              border: "none",
              display: "inline",
              padding: "5px 0px",
              color: "white",
              marginRight: 10,
              marginTop: 20,
              borderRadius: 5,
              fontSize: 12,
              cursor: "pointer",
              textTransform: "none",
            }}
            onClick={async () => {
              await callback(refReviewType, test_receiver);
              noCallback();
            }}
          >
            Yes{" "}
          </Button>
          <Button
            style={{
              backgroundColor: "#333333",
              border: "none",
              display: "inline",
              padding: "5px 0px",
              color: "white",
              marginRight: 10,
              marginTop: 20,
              borderRadius: 5,
              fontSize: 12,
              cursor: "pointer",
              textTransform: "none",
            }}
            onClick={() => noCallback()}
          >
            No{" "}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default RefReview;
// export class RefReview extends Component {
//     constructor(props) {
//         //
//         super(props);
//         this.state = {
//             refReviewType:"review",
//         };
//       }
//   render() {
//     return (
//         <div className="react-confirm-alert-body">
//         <h4>Do you want to send Review/Referral?</h4>

//         <span> Please select your option.</span>
//         <h6>{this.props.val}</h6>

//         <div>
//           <div
//             style={{
//              marginTop:27,
//               }}>

//         <Button
//           style={{marginLeft: 11,
//             borderStyle: "solid",
//             borderWidth:2,
//             borderRadius:7,
//             height:43,
//             width:126,
//             borderColor:refReviewType=="review"?"#53DBA7": "gray",
//           }}
//         onClick={() =>this.setState({refReviewType:"review"})}
//         >
//        {/* <h6>{refReviewType}</h6> */}
//        {this.props.lead.auto_review_count > 0 ? (
//                   <img
//                     style={{
//                       width: 10,
//                       height: 10,
//                       top: -2,
//                       right: -5,
//                       position: "absolute",
//                     }}
//                     src={sentCheckIcon}
//                   />
//                 ) : null
//               }

//        <img
//        style={{ cursor: "pointer", width: "27px", height: "22px" , marginRight:7, marginBottom:1}}
//        src={refreview}
//             />
//           Review </Button>

//         <Button
//              style={{
//              marginLeft: 11,
//              borderStyle: "solid",
//             borderWidth:2,
//             borderRadius:7,
//             height:43,
//             width:126,
//               borderColor:refReviewType=="referral"?"#53DBA7": "gray",

//             }}

//           onClick={() => this.setState({refReviewType:"referral"})}
//         >
//            {this.props.lead.auto_referral_count > 0 ? (
//                   <img
//                     style={{
//                       width: 10,
//                       height: 10,
//                       top: -2,
//                       right: -5,
//                       position: "absolute",
//                     }}
//                     src={orangeIcon}
//                   />
//                 ) : null
//               }
//            <img

// style={{cursor: "pointer", width: "27px", height: "22px" , marginRight:7, marginBottom:1}}
// src={refferal}
// />
//           Referral
//         </Button>
//         </div>
//         <div className="react-confirm-alert-button-group"
//           style={{
//             marginTop:13,
//              }}>
//         <Button
//            style={{
//             marginLeft:14,
//             backgroundColor: "#333333",
//             border: "none",
//            display: "inline",
//            padding: "5px 0px",
//             color: "white",
//             marginRight: 10,
//             marginTop:20,
//             borderRadius: 5,
//             fontSize: 12,
//             cursor: "pointer",
//             textTransform: "none",

//           }}
//         onClick={() => this.props.callback(refReviewType)}
//         >
//           Yes </Button>
// <Button
//    style={{

//     backgroundColor: "#333333",
//     border: "none",
//    display: "inline",
//    padding: "5px 0px",
//     color: "white",
//     marginRight: 10,
//     marginTop:20,
//     borderRadius: 5,
//     fontSize: 12,
//     cursor: "pointer",
//     textTransform: "none",

//   }}
//    onClick={() =>this.props.noCallback()}
//         >No  </Button>
//         </div>
//         </div>

//       </div>
//     );
// }
// }

// export default RefReview
