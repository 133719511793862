import React, { useEffect, useRef, useState } from "react";
import Loader from "react-loader-spinner";
import ReactQuill from "react-quill";

import {
	removeReminder,
	setReminder,
	updateReminder,
} from "../../../../api/api";
import { formatDate2 } from "../../../Helpers/Constants";
import { Confirmation } from "../Confirmation/confirmation";
import DateDropdown from "./ReminderDateDropdown/DateDropdown";
import "./reminder.css";

export const ReminderModal = ({
	show,
	leadId,
	update = false,
	onHide,
	showAlert,
	leadName,
	reminderId,
	reminderDate,
	reminderDescription,
	UpdateLeadAfterAssigningSequence,
	reminderAdmin,
	assignLead,
	leadAssignedTo,
	leads,
	updatingAssigned,
}) => {
	const dateInputRef = useRef(null);

	const [handleShowModal, setHandleShowModal] = useState(true);
	const [handleRemove, setHandleRemove] = useState(false);
	const [remove, setRemove] = useState(false);
	const [confirmLoader, setConfirmLoader] = useState(false);
	const [selectedDate, setSelectedDate] = useState("");
	const [selectedDate1, setSelectedDate1] = useState("");
	const [description, setDescription] = useState("");
	const [outline, setOutline] = useState(false);
	const [first, setfirst] = useState(true);
	const [newLead, setNewLead] = useState();

	var colors = ["#000000", "#ffffff", "#e60000", "#ff9900"];

	var background = ["#000000", "#ffffff", "#facccc", "#ffebcc"];
	const modules = {
		toolbar: [
			[{ header: [1, 2, false] }],
			["bold", "italic", "underline"],
			[{ color: colors }, { background: background }],
			[{ list: "ordered" }, { list: "bullet" }],
		],
	};

	useEffect(() => {
		const UpdateLead = async () => {
			const update = await leads.find((rem) => rem._id === leadId);
			setNewLead(update);
		};
		UpdateLead();
	}, [leads]);

	const tomorrow = new Date();
	const dd = String(tomorrow.getDate()).padStart(2, "0");
	const mm = String(tomorrow.getMonth() + 1).padStart(2, "0");
	const yyyy = tomorrow.getFullYear();
	const formattedTomorrow = `${yyyy}-${mm}-${dd}`;

	useEffect(() => {
		if (update) {
			if (reminderDate && reminderDate !== "") {
				const formatingDate = async () => {
					const newdate = await formatDate2(new Date(reminderDate));
					setSelectedDate1(newdate);
					setSelectedDate(newdate);
					if (reminderDescription && reminderDescription !== "") {
						setDescription(reminderDescription);
					}
					setfirst(false);
				};
				formatingDate();
			}
		}
	}, [reminderDate, reminderDescription]);

	useEffect(() => {
		const formatDate = async () => {
			const newdate = await formatDate2(new Date(selectedDate));
			setSelectedDate1(newdate);
		};
		if ((update && !first) || !update) {
			if (selectedDate && selectedDate !== "") formatDate();
		}
	}, [selectedDate]);

	const handleKeyDown = (e) => {
		e.preventDefault();
	};

	const handleInputChange = (event) => {
		setDescription(event);
	};

	const handleCloseModal = (e) => {
		e.preventDefault();
		setHandleShowModal(false);
		onHide();
	};

	const handleRemoveReminder = async () => {
		setHandleRemove(true);
		const { status, data } = await removeReminder(leadId, {
			reminderId: reminderId,
		});
		if (status === 200) {
			console.log("deleteee");
			// UpdateLeadAfterAssigningSequence(leadId, data.data);
			setConfirmLoader(false);
			showAlert("Reminder removed successfully");
			onHide();
		} else {
			showAlert("Removing reminder failed");
		}
		setHandleRemove(false);
	};

	const handleConfirm = async (e) => {
		e.preventDefault();
		if (selectedDate1 && selectedDate1 !== "") {
			setConfirmLoader(true);
			const { status, data } = await setReminder({
				leadID: leadId,
				reminderObj: {
					reminder_status: true,
					reminder_date: selectedDate1,
					reminder_description: description,
				},
			});
			if (status === 200) {
				// UpdateLeadAfterAssigningSequence(leadId, data.data);
				setConfirmLoader(false);
				showAlert("Reminder has been set successfully");
				onHide();
			} else {
				showAlert("Reminder setting failed");
			}
		} else {
			setOutline(true);
			showAlert("Please select the date first to set reminder");
		}
	};
	const handleButtonClick = () => {
		if (!reminderAdmin) dateInputRef.current.showPicker();
	};

	return (
		<>
			{handleShowModal && (
				<div className="modalReminder">
					<div className="ModalBodyReminder">
						<h1>{update ? "Edit" : "Set"} Reminder</h1>
						<h3>
							Your are about to set a reminder for{" "}
							<b>{leadName}</b>, Please select a date to set the
							reminder
						</h3>
						<h2>Date</h2>
						<div style={{ width: "100%", height: "100%" }}>
							<DateDropdown
								custom={handleButtonClick}
								active={
									!(
										reminderAdmin &&
										leadAssignedTo !==
											localStorage.getItem("username")
									)
								}
								setDate={setSelectedDate1}
								date={selectedDate1}
								notSelected={outline}
							/>
						</div>
						<input
							type="date"
							ref={dateInputRef}
							onKeyDown={handleKeyDown}
							min={formattedTomorrow}
							value={selectedDate}
							disabled={reminderAdmin}
							onChange={(e) => {
								setOutline(false);
								setSelectedDate(e.target.value);
							}}
						/>
						<h2>Reminder</h2>
						<ReactQuill
							modules={modules}
							style={{
								minHeight: 160,
								height: 160,
								maxHeight: 160,
								width: "100%",
							}}
							className="ql-container ql-height "
							value={description}
							onChange={handleInputChange}
						/>
						{update && (
							<div className="updateNote">
								{`${
									reminderAdmin
										? `${leadAssignedTo} has`
										: "You have"
								} already set a reminder for this lead, you are editing the reminder`}
							</div>
						)}
						<div className="ReminderActionButtons">
							{!reminderAdmin && (
								<div className="closeConfirmButtons">
									{handleRemove ? (
										<div className="ReminderLoader">
											<Loader
												className="modalLoader"
												type="Oval"
												color="black"
												height={35}
												width={35}
											/>
										</div>
									) : (
										update && (
											<button
												className="RemoveButton"
												onClick={() => setRemove(true)}
											>
												<img
													src={require("../../../assets/images/delete-button-bold.png")}
												/>
											</button>
										)
									)}
								</div>
							)}
							<button
								className="CloseButton"
								onClick={handleCloseModal}
							>
								Close
							</button>
							{confirmLoader ? (
								<div className="ReminderLoader">
									<Loader
										className="modalLoader"
										type="Oval"
										color="black"
										height={35}
										width={35}
									/>
								</div>
							) : (
								<button
									className="confirmButton"
									onClick={async (e) => {
										if (
											leadAssignedTo !==
											localStorage.getItem("username")
										) {
											setConfirmLoader(true);
											const { status, data } =
												await updateReminder(leadId, {
													reminderId: reminderId,
													updatedReminder: {
														reminder_status: true,
														reminder_date:
															selectedDate1,
														reminder_description:
															description,
														creator_id: reminderId,
														created_by:
															leadAssignedTo,
													},
												});
											if (status === 200) {
												// UpdateLeadAfterAssigningSequence(leadId, data.data);
												setConfirmLoader(false);
												showAlert(
													"Reminder has been set successfully"
												);
												onHide();
											} else {
												showAlert(
													"Setting reminder failed"
												);
											}
										} else handleConfirm(e);
									}}
								>
									Confirm
								</button>
							)}
						</div>
					</div>
				</div>
			)}
			{remove && (
				<Confirmation
					show={remove}
					onHide={() => setRemove(false)}
					title="Remove Reminder"
					description={`Are you sure you want to remove this reminder?`}
					yesFunc={async () => {
						await handleRemoveReminder();
					}}
				/>
			)}
		</>
	);
};
