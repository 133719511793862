import React, { Component } from "react";
import Home from "./Home/Home";
import LoginScreen from "./Authentication/LoginScreen";
import { Provider } from "react-redux";
import store from "./Store/index";
import "../config/firebase/firebase";

import "./styles/App.css";

// if ("serviceWorker" in navigator) {
//   navigator.serviceWorker
//     .register("/firebase-messaging-sw.js")
//     .then((registration) => {
//       console.log("Service Worker registered with scope:", registration.scope);
//     })
//     .catch((error) => {
//       console.error("Service Worker registration failed:", error);
//     });
// }

class App extends Component {
  render() {
    if (localStorage.getItem("isLoggedIn")) {
      return (
        <Provider store={store}>
          <div style={{ width: "100%", height: "100%" }}>
            <Home />
          </div>
        </Provider>
      );
    } else {
      return (
        <div style={{ width: "100%", height: "100%" }}>
          <LoginScreen />

          <p style={{ fontFamily: "Inter-Regular; display: none;" }}></p>
        </div>
      );
    }
  }
}
export default App;
