import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "react-loader-spinner";

import MediaTile from "../../ReusabelComponents/MediaAndFIles/MediaTile";
import {
  getMediaFiles,
  getUserMediaFiles,
  searchMediaFiles,
  searchUserMediaFiles,
} from "../../Store/MediaAndFiles/mediaActions";
import {
  DELETED_FALSE,
  SEARCH,
  SEARCHING_FALSE,
  SEARCHING_TRUE,
  SWITCH_TABS,
  TAB_DATA,
  UPDATED_FALSE,
} from "../../Store/MediaAndFiles/mediaActionTypes";
import DropdownForUsers from "../../ReusabelComponents/Dropdown/DropdownForUsers";
import "./MediaAndFiles.css";

function MediaAndFiles({
  tab,
  showAlert,
  updateLists,
  updatePage,
  setUpdatePage,
  loadingBar,
  updateMedia,
  users,
}) {
  const {
    data,
    searchedData,
    numberOfPages,
    isLoading,
    deleted,
    searching,
    searchedDataTotal,
    selectedTab,
    tabData,
    tabDataTotal,
    updated,
    currentPage,
  } = useSelector((state) => state.mediaFiles);
  const dispatch = useDispatch();
  const [nav, setNav] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");

  useEffect(() => {
    setOptions(users); //.map((obj) => obj.label)
  }, [users]);

  useEffect(() => {
    dispatch(getMediaFiles(nav));
  }, []);

  useEffect(() => {
    dispatch({ type: SWITCH_TABS, payload: 0 });
    if (searching) {
      if (
        selectedOption &&
        selectedOption.label &&
        selectedOption.label !== "All"
      )
        dispatch(searchUserMediaFiles(selectedOption.id, nav, searchText));
      else dispatch(searchMediaFiles(nav, searchText));
    } else {
      if (
        selectedOption &&
        selectedOption.label &&
        selectedOption.label !== "All"
      )
        dispatch(getUserMediaFiles(nav, selectedOption.id));
      else dispatch(getMediaFiles(nav));
    }
  }, [dispatch, updateMedia, nav]);

  useEffect(() => {
    if (deleted) {
      showAlert("Deletion successful");
      dispatch({ type: DELETED_FALSE });
    }
  }, [deleted]);
  useEffect(() => {
    if (updated) {
      showAlert("Updation successful");
      dispatch({ type: UPDATED_FALSE });
    }
  }, [updated]);

  useEffect(() => {
    if (searching) handleSearch();
  }, [tabData]);

  useEffect(() => {
    if (selectedTab === 0) {
      dispatch({ type: TAB_DATA, payload: data });
    } else if (selectedTab === 1) {
      let tabOne = data.filter((obj) => obj.documentType !== "pdf");
      dispatch({ type: TAB_DATA, payload: tabOne });
    } else if (selectedTab === 2) {
      let tabTwo = data.filter((obj) => obj.documentType === "pdf");
      dispatch({ type: TAB_DATA, payload: tabTwo });
    }
  }, [selectedTab, data]);

  const handleNavForward = (num) => {
    if (nav !== numberOfPages) setNav(nav + num);
  };
  const handleNavBack = (num) => {
    if (num === 1) {
      if (nav !== 1) setNav(nav - 1);
    } else if (num === 2) {
      if (nav - 1 !== 1) setNav(nav - 2);
    }
  };

  const handleSearch = () => {
    if (searchText && searchText != "") {
      setNav(1);
      dispatch({ type: SEARCHING_TRUE });
      // filterData(searchText, tabData);
      if (
        selectedOption &&
        selectedOption.label &&
        selectedOption.label !== "All"
      )
        dispatch(searchUserMediaFiles(selectedOption.id, 1, searchText));
      else dispatch(searchMediaFiles(1, searchText));
    } else {
      dispatch({ type: SEARCHING_FALSE });
      dispatch({ type: SEARCH, payload: [] });
    }
  };

  //For searching on the frontend
  const filterData = (searchText, data) => {
    let arr = [];
    const query = searchText.trim().toLowerCase();
    data.filter((item) => {
      if (item.createdBy && item.documentName) {
        if (
          item.documentName
            .toLowerCase()
            .trim()
            // .substring(0, query.length)
            .includes(query) ||
          item.createdBy
            .toLowerCase()
            .trim()
            // .substring(0, query.length)
            .includes(query)
        ) {
          arr = [...arr, item];
        }
      }
    });
    dispatch({ type: SEARCH, payload: { data: arr, size: arr.length } });
  };

  const handleEnterPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const searchEmpty = () => {
    dispatch({ type: SEARCHING_FALSE });
    dispatch({ type: SEARCH, payload: [] });
  };

  const handleOptionSelect = (option) => {
    if (option) {
      setSelectedOption(option);
      if (option && option.label && option.label !== "All")
        dispatch(getUserMediaFiles(1, option.id));
      else dispatch(getMediaFiles(1));
    }
  };

  return (
    <>
      {/* {numberOfPages !== 0 && ( */}
      <div className='rootContainer'>
        <div>
          <div className='topBar'>
            <div className='categoryOuterCOntainer'>
              <div className='category'>
                <div
                  onClick={() => dispatch({ type: SWITCH_TABS, payload: 0 })}
                >
                  <p
                    className={
                      selectedTab === 0
                        ? " categoriesTextBold"
                        : "categoriesTextFirst"
                    }
                  >
                    {`All Items ( ${
                      searching ? searchedDataTotal : tabDataTotal
                    } )`}
                  </p>
                  {/* <p
                    className={
                      selectedTab === 0
                        ? "categoriesTextTotal categoriesTextTotalBold"
                        : "categoriesTextTotal"
                    }
                  >
                    {` ( ${searching ? searchedDataTotal : tabDataTotal} )`}
                  </p> */}
                </div>
                <p
                  className={
                    selectedTab === 1
                      ? " categoriesTextBold2"
                      : "categoriesText"
                  }
                  onClick={() => dispatch({ type: SWITCH_TABS, payload: 1 })}
                >
                  Images
                </p>
                <p
                  className={
                    selectedTab === 2
                      ? " categoriesTextBold2"
                      : "categoriesText"
                  }
                  onClick={() => dispatch({ type: SWITCH_TABS, payload: 2 })}
                >
                  Documents
                </p>
              </div>
              <DropdownForUsers
                options={options}
                defaultOption='All'
                onSelect={handleOptionSelect}
                optionSelected={selectedOption}
              />
            </div>
            <div className='searchInput'>
              <input
                placeholder='Search by Title, created by'
                onChange={(e) => {
                  if (e.target.value === "") searchEmpty();
                  setSearchText(e.target.value);
                }}
                onKeyPress={handleEnterPress}
              />
              <img
                src={require("../../assets/images/searchIcon.png")}
                onClick={handleSearch}
              />
            </div>
          </div>
          {isLoading && numberOfPages !== 0 ? (
            <div className='media-loader'>
              <Loader type='Oval' color='black' height={38} width={38} />
            </div>
          ) : numberOfPages !== 0 &&
            searching &&
            searchedData &&
            searchedData.length !== 0 ? (
            <div className='MediaTiles'>
              {searchedData.map((tile, index) => (
                <MediaTile
                  key={index}
                  id={tile._id}
                  className='MediaTiles-item'
                  title={tile.documentName}
                  modifiedTitle={tile.documentNameToShow}
                  size={tile.documentSize}
                  type={tile.documentType}
                  time={tile.timestamp}
                  link={tile.documentLink}
                  creator={tile.createdBy}
                  showAlert={showAlert}
                />
              ))}
            </div>
          ) : (
            !searching &&
            tabData &&
            tabData.length !== 0 && (
              <div className='MediaTiles'>
                {tabData.map((tile, index) => (
                  <MediaTile
                    key={index}
                    id={tile._id}
                    className='MediaTiles-item'
                    title={tile.documentName}
                    modifiedTitle={tile.documentNameToShow}
                    size={tile.documentSize}
                    type={tile.documentType}
                    time={tile.timestamp}
                    link={tile.documentLink}
                    creator={tile.createdBy}
                    showAlert={showAlert}
                  />
                ))}
              </div>
            )
          )}
          {((!searching &&
            numberOfPages !== 0 &&
            tabData &&
            tabData.length !== 0) ||
            (searching &&
              numberOfPages !== 0 &&
              searchedData &&
              searchedData.length !== 0)) && (
            <div className='MediaNavigation'>
              <img
                src={require("../../assets/images/left-arrow.png")}
                alt='Go back one page'
                onClick={() => handleNavBack(1)}
              />
              <button
                className={`navigationButton ${
                  numberOfPages > 2
                    ? nav !== numberOfPages &&
                      nav !== numberOfPages - 1 &&
                      "navigationButtonSelected"
                    : numberOfPages > 1
                    ? nav !== numberOfPages && "navigationButtonSelected"
                    : "navigationButtonSelected"
                }`}
                onClick={() => {
                  if (numberOfPages > 2) {
                    if (nav === numberOfPages) handleNavBack(2);
                    else if (nav === numberOfPages - 1) {
                      handleNavBack(1);
                    }
                  } else if (numberOfPages > 1) {
                    if (nav === numberOfPages) {
                      handleNavBack(1);
                    }
                  }
                }}
              >
                {numberOfPages > 2
                  ? nav === numberOfPages
                    ? nav - 2
                    : nav === numberOfPages - 1
                    ? nav - 1
                    : nav
                  : numberOfPages > 1
                  ? nav === numberOfPages
                    ? nav - 1
                    : nav
                  : nav}
              </button>
              {numberOfPages > 1 ? (
                <button
                  className={`navigationButton ${
                    numberOfPages > 2
                      ? nav === numberOfPages - 1 && "navigationButtonSelected"
                      : numberOfPages > 1 &&
                        nav === numberOfPages &&
                        "navigationButtonSelected"
                  }`}
                  onClick={() => {
                    if (numberOfPages > 2) {
                      if (nav === numberOfPages) handleNavBack(1);
                      else if (nav !== numberOfPages - 1) handleNavForward(1);
                    } else if (numberOfPages > 1) {
                      if (nav === numberOfPages) return;
                      else if (nav !== numberOfPages) handleNavForward(1);
                    }
                  }}
                >
                  {numberOfPages > 2
                    ? nav === numberOfPages
                      ? nav - 1
                      : nav === numberOfPages - 1
                      ? nav
                      : nav + 1
                    : numberOfPages > 1
                    ? nav === numberOfPages
                      ? nav
                      : nav + 1
                    : nav + 1}
                </button>
              ) : null}
              {numberOfPages > 2 ? (
                <button
                  className={`navigationButton ${
                    nav === numberOfPages && "navigationButtonSelected"
                  }`}
                  onClick={() => {
                    if (numberOfPages > 2)
                      if (numberOfPages === nav + 1) handleNavForward(1);
                      else if (numberOfPages !== nav) handleNavForward(2);
                  }}
                >
                  {nav === numberOfPages
                    ? nav
                    : nav === numberOfPages - 1
                    ? nav + 1
                    : nav + 2}
                </button>
              ) : null}
              <img
                src={require("../../assets/images/right-arrow.png")}
                alt='Go forward one page'
                onClick={() => handleNavForward(1)}
              />
            </div>
          )}
        </div>
      </div>
      {/* )} */}
      {!isLoading &&
        (numberOfPages === 0 ||
          (!searching && tabData && tabData.length === 0) ||
          (searching && searchedData && searchedData.length === 0)) && (
          <div className='media-loader'>No Media Available to show</div>
        )}
      {isLoading && (
        <div className='media-loader'>
          <Loader type='Oval' color='black' height={38} width={38} />
        </div>
      )}
    </>
  );
}

export default MediaAndFiles;
