import React, { useEffect, useRef } from "react";

import "./Spotlight.css";

function SpotlightSearch({
  showSearchHeader,
  show,
  close,
  searchValue,
  onChange,
  searchFunc,
  clearValue,
}) {
  const textInputRef = useRef(null);
  const modalRef = useRef(null);

  useEffect(() => {
    show && textInputRef.current.focus();
  }, [show]);

  const handleEnterPress = (event) => {
    if (event.key === "Enter") {
      close();
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      close();
    }
  };
  const handleModalClick = (e) => {
    if (e.target === modalRef.current) {
      close();
    }
  };

  return (
    show && (
      <div
      style={{display:showSearchHeader?"none":"flex"}}
        className='modalReminder modalSpotlight'
        ref={modalRef}
        onClick={handleModalClick}
      >
        <div className='ModalBodyReminder modalBodySpotlight'>
          <img
            src={require("../../../assets/images/search-icon2-white.png")}
            alt=''
            onClick={() => {
              searchFunc();
              textInputRef.current.focus();
            }}
          />
          <input
            ref={textInputRef}
            className='spotlightInput'
            placeholder='Spotlight Search'
            onChange={onChange}
            onKeyPress={handleEnterPress}
            onKeyDown={handleKeyDown}
            value={searchValue}
          />
          {searchValue && searchValue !== "" && (
            <p
              onClick={() => {
                if (searchValue && searchValue !== "") {
                  clearValue();
                  textInputRef.current.focus();
                }
              }}
            >
              <u>Clear</u>
            </p>
          )}
        </div>
      </div>
    )
  );
}

export default SpotlightSearch;
