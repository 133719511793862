import React, { useState, useRef } from "react";
import {
	Modal,
	Backdrop,
	Fade,
	Typography,
	Button,
	IconButton,
	Grid,
	RadioGroup,
	Radio,
	Box,
	CircularProgress,
	FormControlLabel,
} from "@material-ui/core";
import { endOfDay, startOfDay } from "date-fns";
import { makeStyles } from "@material-ui/core/styles";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CloseIcon from "@material-ui/icons/Close";
import exportImage from "../assets/images/csv.png";
import calenderIcon from "../assets/images/calendar1.png";
import axios from "axios";
import { CSVLink } from "react-csv";
import { baseURL, calculateZoneSpecificTime } from "../Helpers/Constants";
var dateFormat = require("dateformat"); // Adjust the import path

const useStyles = makeStyles((theme) => ({
	modal: {
		display: "flex",
		alignItems: "flex-end",
		justifyContent: "flex-end",
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
		padding: theme.spacing(4, 6, 4, 6),
		position: "relative",
		maxWidth: 350,
		borderRadius: 3,
		width: "100%",
		textAlign: "center",
	},
	closeButton: {
		position: "absolute",
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
	buttonGroup: {
		marginTop: theme.spacing(7),
		display: "flex",
		justifyContent: "space-between",
	},
	loader: {
		marginTop: theme.spacing(2),
	},
}));

const ExportLeadsModal = ({ open, handleClose, showAlert, filteredLeads }) => {
	const classes = useStyles();
	const [selectedValue, setSelectedValue] = useState("current");
	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());
	const [CSVFileName, setCSVFileName] = useState("");
	const [loading, setLoading] = useState(false);
	const [exportableData, setExportableData] = useState([]);
	const csvLinkEl = useRef(null);

	const handleStartDateChange = (date) => {
		setStartDate(date);
	};

	// handleChangeToDate = (date) => {
	// 	this.setState({
	// 		toDate: date,
	// 	});
	// 	var tempFromDate = new Date(this.state.fromDate)
	// 		.setHours(0, 0, 0)
	// 		.getTime();
	// 	var tempToDate = new Date(date).getTime();
	// 	if (tempToDate < tempFromDate) {
	// 		this.setState({
	// 			fromDate: date,
	// 		});
	// 	}
	// };
	const handleEndDateChange = (date) => {
		setEndDate(date);
		// var tempFromDate = new Date(startDate).setHours(0, 0, 0).getTime();
		// var tempToDate = new Date(date).getTime();
		// if (tempToDate < tempFromDate) {
		// 	setStartDate(date);

		// 	// this.setState({
		// 	// 	fromDate: date,
		// 	// });
		// }
	};

	const handleExport = () => {
		if (selectedValue === "current") {
			console.log("in current check");
			// Export current data (filtered leads)
			if (filteredLeads.length === 0) {
				showAlert("No data available for export.");
				return;
			}
			const fileName =
				"LMS-Filtered-Customers-" +
				dateFormat(new Date(), "yyyy-mm-dd") +
				".csv";
			setCSVFileName(fileName);
			console.log("fileName", fileName);

			// for (var i = 0; i < filteredLeads.length; i++) {
			// 	let itemDate = new Date(filteredLeads[i].utc_time_stamp);
			// 	itemDate = calculateZoneSpecificTime(-5, itemDate);
			// 	delete filteredLeads[i].utc_time_stamp;
			// 	temp.push(filteredLeads[i]);
			// }
			let uniqueData = new Map(
				filteredLeads.map((item) => [item._id, item])
			);

			uniqueData = Array.from(uniqueData.values());
			const temp = uniqueData.map(
				({ name, phone_number, email, address }) => ({
					name,
					phone_number,
					email,
					address,
				})
			);
			setExportableData(temp);
			setTimeout(() => {
				csvLinkEl.current.link.click();
			}, 1000);
		} else if (selectedValue === "all") {
			const toUTCDate = (date) => {
				const utcDate = new Date(
					Date.UTC(
						date.getFullYear(),
						date.getMonth(),
						date.getDate()
					)
				);
				console.log("toUTCDate---", utcDate);
				return utcDate;
			};
			const startDateTime = startOfDay(startDate);
			const endDateTime = endOfDay(endDate);
			console.log(
				"timeee",
				new Date(startDate.setHours(0, 0, 0)).getTime(),
				"---",
				endDateTime.getTime()
			);
			// Export all data
			setLoading(true);
			axios
				.post(
					baseURL() + "/api/v1/leads/customersData",
					// {
					// 	fromDate: new Date(
					// 		startDate.setHours(0, 0, 0)
					// 	).getTime(),

					// 	toDate: endDateTime.getTime(),
					// },
					{
						fromDate: new Date(
							startDateTime.setHours(0, 0, 0)
						).getTime(),
						toDate: endDateTime.getTime(),
					},
					{
						headers: {
							"x-access-token": localStorage.getItem("jwtToken"),
							"app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
						},
					}
				)
				.then((res) => {
					setLoading(false);
					if (res.data.data.length <= 0) {
						showAlert("No data available for selected date.");
					} else {
						const fileName =
							"LMS-Customers-" +
							dateFormat(new Date(startDate), "yyyy-mm-dd") +
							"-" +
							dateFormat(new Date(endDate), "yyyy-mm-dd") +
							".csv";
						setCSVFileName(fileName);

						let temp = [];
						for (var i = 0; i < res.data.data.length; i++) {
							let itemDate = new Date(
								res.data.data[i].utc_time_stamp
							);
							itemDate = calculateZoneSpecificTime(-5, itemDate);
							delete res.data.data[i].utc_time_stamp;
							delete res.data.data[i]._id;
							temp.push(res.data.data[i]);
						}
						setExportableData(temp);
						setTimeout(() => {
							csvLinkEl.current.link.click();
						}, 1000);
					}
				})
				.catch((error) => {
					setLoading(false);
					console.error("Error exporting data:", error);
				});
		} else {
			showAlert("Please select an export option.");
		}
	};
	console.log("selectedValue", selectedValue);
	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			className={classes.modal}
			open={open}
			onClose={handleClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={open}>
				<div className={classes.paper}>
					<IconButton
						aria-label="close"
						className={classes.closeButton}
						onClick={handleClose}
					>
						<CloseIcon />
					</IconButton>
					<img
						src={exportImage}
						alt="Export"
						style={{
							marginBottom: 16,
							height: "5vh",
							width: "3vw",
						}}
					/>
					<Typography variant="h5" gutterBottom>
						Export Data
					</Typography>
					<Typography>
						You can export the data and use them in excel,
						spreadsheets and numbers etc
					</Typography>

					<RadioGroup
						style={{ marginTop: 5 }}
						value={selectedValue}
						onChange={(e) => setSelectedValue(e.target.value)}
					>
						<FormControlLabel
							value="current"
							control={<Radio size="medium" color="primary" />}
							label={
								<Typography style={{ fontSize: "14px" }}>
									Export current data
								</Typography>
							}
						/>
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "flex-start",
								flexDirection: "column",
								borderTop: "1px solid #B7B7B7",
							}}
						>
							<FormControlLabel
								value="all"
								control={<Radio color="primary" />}
								label={
									<Typography style={{ fontSize: "14px" }}>
										Export all data
									</Typography>
								}
							/>
							<Box mt={1}>
								<Grid container spacing={2} alignItems="center">
									<Grid item xs={12} md={12}>
										<Box
											style={{
												display: "flex",
												flexDirection: "row",
												backgroundColor: "white",
												justifyContent: "space-between",
												alignItems: "center",
												border: "1px solid #B7B7B7",
												padding: "0px 10px",
												borderRadius: 3,
											}}
										>
											<div
												style={{
													display: "flex",
													flexDirection: "row",
													justifyContent: "center",
													alignItems: "center",
												}}
											>
												<img
													style={{
														height: 13,
														width: 13,
														marginRight: 10,
													}}
													src={calenderIcon}
													alt="calendar"
												/>
												<Typography variant="body1">
													From
												</Typography>
											</div>
											<DatePicker
												disabled={
													selectedValue === "current"
												}
												selected={startDate}
												onChange={handleStartDateChange}
												selectsStart
												startDate={startDate}
												endDate={endDate}
												maxDate={new Date()}
												dateFormat="MM/dd/yyyy"
											/>
										</Box>
									</Grid>
									<Grid item xs={12} md={12}>
										<Box
											style={{
												display: "flex",
												flexDirection: "row",
												backgroundColor: "white",
												justifyContent: "space-between",
												alignItems: "center",
												border: "1px solid #B7B7B7",
												padding: "0px 10px",
												borderRadius: 3,
											}}
										>
											<div
												style={{
													display: "flex",
													flexDirection: "row",
													justifyContent: "center",
													alignItems: "center",
												}}
											>
												<img
													style={{
														height: 13,
														width: 13,
														marginRight: 10,
													}}
													src={calenderIcon}
													alt="calendar"
												/>
												<Typography variant="body1">
													To
												</Typography>
											</div>
											<DatePicker
												disabled={
													selectedValue === "current"
												}
												selected={endDate}
												onChange={handleEndDateChange}
												selectsEnd
												startDate={startDate}
												endDate={endDate}
												minDate={startDate}
												dateFormat="MM/dd/yyyy"
											/>
										</Box>
									</Grid>
								</Grid>
							</Box>
						</div>
					</RadioGroup>

					{loading && <CircularProgress className={classes.loader} />}

					<div className={classes.buttonGroup}>
						<Button
							onClick={handleClose}
							color="primary"
							variant="outlined"
						>
							Cancel
						</Button>
						<Button
							onClick={handleExport}
							color="primary"
							variant="contained"
							disabled={loading}
						>
							Export
						</Button>
						<CSVLink
							data={exportableData}
							filename={CSVFileName}
							className="hidden"
							ref={csvLinkEl}
							target="_blank"
						/>
					</div>
				</div>
			</Fade>
		</Modal>
	);
};

export default ExportLeadsModal;
